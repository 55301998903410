import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'

interface DialogCancelPreparationConfirmProps {
    isOpen: boolean
    handleClose: () => any
    onConfirm: () => any
}

const DialogCancelPreparationConfirm: React.FC<DialogCancelPreparationConfirmProps> = (
    {
        isOpen,
        handleClose,
        onConfirm
    }
) => {
    return (
        <Dialog
            title={
                <FormattedMessage
                    id="common.exitStock.print.dialog.title"
                    defaultMessage="Confirmation d'annulation d'une préparation"
                    description="Dialog title"
                />
            }
            isOpen={isOpen}
            handleClose={handleClose}
            buttons={[
                <Button onClick={handleClose} color="primary" key={0} variant="outlined">
                    <FormattedMessage
                        id="button.cancel"
                        defaultMessage="ANNULER"
                        description="Button label"
                    />
                </Button>,
                <Button color="primary" key={1} variant="contained" onClick={onConfirm}>
                    <FormattedMessage
                        id="button.confirm"
                        defaultMessage="CONFIRMER"
                        description="Button label"
                    />
                </Button>
            ]}
        >
            <Typography gutterBottom>
                <FormattedMessage
                    id="common.exitStock.print.dialog.content"
                    defaultMessage="Confirmation d'annulation d'une préparation"
                    description="Dialog content"
                />
            </Typography>
        </Dialog>
    )
}

export default DialogCancelPreparationConfirm
