import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import TemplateForm from './components/TemplateForm'
import { CreateTemplateModel, TemplateRowModel } from './services/templateModels'
import { createTemplate } from './services/templateApi'
import errorsMapper from '../../../utils/errorsMapper'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

interface TemplateCreateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const TemplateCreate: React.FC<TemplateCreateProps> = (
	{
		snackSuccess,
		snackError
	}
) => {
	const history = useHistory()
	const { reloadReferentiel } = useContext(PhidemDataContext)

	const onSubmit = (formValues: CreateTemplateModel) => {
		return createTemplate({
			nom: formValues.nom,
			type: formValues.type,
			file: formValues.file
		})
			.then((response: TemplateRowModel) => {
				history.push(`/templates/${response.id}`)
				snackSuccess({ id: 'template.createTemplateSuccess', defaultMessage: 'Le template a bien été créé', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.TEMPLATE)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'template.createTemplateError', defaultMessage: 'Une erreur est survenue, le template n\'a pas pu être créé', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<TemplateForm
			isCreationForm
			onSubmit={onSubmit}
		/>
	)
}

export default compose(injectSnackbarActions)(TemplateCreate)
