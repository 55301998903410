import React from 'react'
import { Save } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from './Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(2),
        }
    }),
)

interface ButtonSaveProps {
    type?: 'button' | 'reset' | 'submit' | undefined,
    isLoading?: boolean
}

const ButtonSave: React.FC<ButtonSaveProps> = (props) => {
    const classes = useStyles()

    return (
        <Button
            className={classes.button}
            variant="contained"
            startIcon={<Save />}
            {...props}
        >
            <FormattedMessage
                id="button.save"
                defaultMessage="Sauvegarder"
                description="Save button label"
            />
        </Button>
    )
}

export default ButtonSave
