import React, { useContext, useMemo } from 'react'
import EnterStockForm from './components/EnterStockForm'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { injectToolbarData } from 'isotope-client'
import { CENTER_TYPE, CENTER_TYPE_LOWERCASE, ORIGIN, TRANSFERT_TYPE } from '../../../../utils/constants'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { postEntryStock, postEntryStockWithBl } from './services/enterStockApi'
import { EnterStockFormValues } from './services/enterStockModels'

interface EnterStockProps {
    centerType: string,
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const EnterStock: React.FC<EnterStockProps> = (
    {
        centerType,
        snackSuccess,
        snackError
    }
) => {
    const { user: { selectedCenter } } = useContext(PhidemDataContext)
    const prefixCenter = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]
    const hasReferent = useMemo(() => selectedCenter.centreParents && selectedCenter.centreParents.filter(center => center.gestionStock).length > 0, [selectedCenter])
    const canUseTransfertOption = useMemo(() => centerType === CENTER_TYPE.DISPATCH || (centerType === CENTER_TYPE.REFERENT && hasReferent), [centerType, hasReferent])

    const onSubmit = async (values: EnterStockFormValues) => {
        const transfertData = values.isTransfert ? {
            transfertType: values.transfertType,
            transfertDate: values.transfertType !== TRANSFERT_TYPE.CONGELE ? new Date(values.transfertDatetime || '').toISOString() : undefined
        } : {}

        const promise = values.origin === ORIGIN.EXTERNAL ?
            postEntryStock(prefixCenter, {
                idProduit: values.product || '',
                quantite: values.quantity,
                lot: values.batchNumber || '',
                peremption: new Date(values.expirationDate || '').toISOString(),
                reception: new Date(values.enterDatetime || '').toISOString(),
                commentaire: values.comment,
                conforme: values.compliantReception,
                ...transfertData
            })
            : postEntryStockWithBl(prefixCenter, {
                referentBlId: values.referentBlId || '',
                reception: new Date(values.enterDatetime || '').toISOString(),
                commentaire: values.comment,
                conforme: values.compliantReception
            })

        return promise
            .then(() => snackSuccess({ id: 'common.enterStock.success', defaultMessage: 'L\'entrée en stock a bien été enregistrée', description: 'Success message' }))
            .catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
    }

    return (
        <EnterStockForm
            hasReferent={hasReferent}
            canUseTransfertOption={canUseTransfertOption}
            onSubmit={onSubmit}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="common.enterStock.pageTitle"
        defaultMessage="Nouvelle entrée en stock"
        description="Page title"
    />
})), injectSnackbarActions)(EnterStock)
