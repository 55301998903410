import React from 'react'

const SvgReplenishment = () => (<svg
  xmlns="http://www.w3.org/2000/svg"
  width={52.677}
  height={64.806}
>
  <g data-name="Groupe 380">
    <g data-name="Groupe 352">
      <path
        data-name="Rectangle 544"
        fill="#787878"
        d="M0 1.846h38.208v54.003H0z"
      />
      <path
        data-name="Rectangle 545"
        fill="#e6e6e6"
        d="M2.015 3.861h34.177v49.972H2.015z"
      />
      <path
        data-name="Rectangle 546"
        fill="#00b4c8"
        d="M11.503 0h15.202v5.878H11.503z"
      />
      <g data-name="Groupe 350" fill="#c8c8c8">
        <path
          data-name="Trac\xE9 1213"
          d="M11.756 8.9v4.7h-4.7V8.9zm1.343-1.343H5.711v7.392h7.388V7.557z"
        />
        <path
          data-name="Trac\xE9 1214"
          d="M11.756 20.547v4.7h-4.7v-4.7zm1.343-1.343H5.711v7.39h7.388v-7.39z"
        />
        <path
          data-name="Trac\xE9 1215"
          d="M11.756 32.191v4.7h-4.7v-4.7zm1.343-1.342H5.711v7.389h7.388v-7.389z"
        />
        <path
          data-name="Trac\xE9 1216"
          d="M11.756 43.838v4.7h-4.7v-4.7zm1.343-1.344H5.711v7.39h7.388v-7.39z"
        />
      </g>
      <g data-name="Groupe 351" fill="#c8c8c8">
        <path
          data-name="Rectangle 547"
          d="M16.377 7.557h16.181v1.989H16.377z"
        />
        <path
          data-name="Rectangle 548"
          d="M16.377 12.96h16.181v1.989H16.377z"
        />
        <path
          data-name="Rectangle 549"
          d="M16.377 19.204h16.181v1.986H16.377z"
        />
        <path
          data-name="Rectangle 550"
          d="M16.377 24.605h16.181v1.988H16.377z"
        />
        <path
          data-name="Rectangle 551"
          d="M16.377 30.849h16.181v1.986H16.377z"
        />
        <path
          data-name="Rectangle 552"
          d="M16.377 36.251h16.181v1.989H16.377z"
        />
        <path
          data-name="Rectangle 553"
          d="M16.377 42.494h16.181v1.987H16.377z"
        />
        <path
          data-name="Rectangle 554"
          d="M16.377 47.895h16.181v1.989H16.377z"
        />
      </g>
      <path
        data-name="Rectangle 555"
        fill="#fff"
        opacity={0.1}
        style={{
          mixBlendMode: "screen",
          isolation: "isolate",
        }}
        d="M19.104 1.846h19.104v54.003H19.104z"
      />
      <path
        data-name="Rectangle 556"
        fill="#00bed2"
        d="M19.105 0h7.6v5.878h-7.6z"
      />
    </g>
    <g data-name="Groupe 379">
      <g data-name="Groupe 353">
        <path
          data-name="Rectangle 557"
          fill="#0082aa"
          d="M35.844 29.592h2.559v4.578h-2.559z"
        />
      </g>
      <g data-name="Groupe 354">
        <path
          data-name="Rectangle 558"
          fill="#0082aa"
          d="M45.156 36.351l1.498-2.598 1.795 1.035-1.498 2.598z"
        />
      </g>
      <g data-name="Groupe 355">
        <path
          data-name="Rectangle 559"
          fill="#0082aa"
          d="M26.185 34.553l1.793-1.036 1.5 2.598-1.793 1.036z"
        />
      </g>
      <g data-name="Groupe 356">
        <circle
          data-name="Ellipse 2"
          cx={15.553}
          cy={15.553}
          r={15.553}
          transform="translate(21.57 33.699)"
          fill="#ffc800"
        />
      </g>
      <g data-name="Groupe 357">
        <path
          data-name="Trac\xE9 1217"
          d="M52.677 49.253A15.552 15.552 0 0037.123 33.7v31.1a15.553 15.553 0 0015.554-15.547z"
          fill="#ffdc00"
        />
      </g>
      <g data-name="Groupe 358">
        <circle
          data-name="Ellipse 3"
          cx={12.983}
          cy={12.983}
          r={12.983}
          transform="translate(24.14 36.269)"
          fill="#ebebeb"
        />
      </g>
      <g data-name="Groupe 359">
        <path
          data-name="Trac\xE9 1218"
          d="M50.106 49.253a12.983 12.983 0 00-12.983-12.984v25.967a12.982 12.982 0 0012.983-12.983z"
          fill="#f5f5f5"
        />
      </g>
      <g data-name="Groupe 360">
        <path
          data-name="Trac\xE9 1219"
          d="M37.123 52.451a3.2 3.2 0 113.2-3.2 3.2 3.2 0 01-3.2 3.2zm0-5.857a2.66 2.66 0 102.658 2.659 2.661 2.661 0 00-2.658-2.659z"
          fill="#c8c8c8"
        />
      </g>
      <g data-name="Groupe 361">
        <path
          data-name="Trac\xE9 1220"
          d="M37.665 49.656l-.951-.952 8.99-8.038z"
          fill="#0082aa"
        />
      </g>
      <g data-name="Groupe 362">
        <path
          data-name="Rectangle 560"
          fill="#00b4c8"
          d="M33.285 27.572h7.676v3.3h-7.676z"
        />
      </g>
      <g data-name="Groupe 363">
        <path
          data-name="Rectangle 561"
          fill="#00b4c8"
          d="M23.995 32.291l3.117-1.8 1.91 3.306-3.117 1.8z"
        />
      </g>
      <g data-name="Groupe 364">
        <path
          data-name="Rectangle 562"
          fill="#00b4c8"
          d="M45.611 34.033l1.909-3.305 3.118 1.8-1.909 3.306z"
        />
      </g>
      <g data-name="Groupe 365">
        <path
          data-name="Rectangle 563"
          fill="#0082aa"
          d="M32.784 52.254l3.516-3.52 1.335 1.333-3.516 3.52z"
        />
      </g>
      <g data-name="Groupe 366">
        <path
          data-name="Trac\xE9 1221"
          d="M37.982 49.974a1.122 1.122 0 110-1.587 1.121 1.121 0 010 1.587z"
          fill="#00b4c8"
        />
      </g>
      <g data-name="Groupe 367">
        <path
          data-name="Rectangle 564"
          fill="#c8c8c8"
          d="M36.584 37.065h1.078v1.908h-1.078z"
        />
      </g>
      <g data-name="Groupe 368">
        <path
          data-name="Rectangle 565"
          fill="#c8c8c8"
          d="M36.584 59.531h1.078v1.909h-1.078z"
        />
      </g>
      <g data-name="Groupe 369">
        <path
          data-name="Rectangle 566"
          fill="#c8c8c8"
          d="M47.402 48.714h1.908v1.077h-1.908z"
        />
      </g>
      <g data-name="Groupe 370">
        <path
          data-name="Rectangle 567"
          fill="#c8c8c8"
          d="M24.935 48.714h1.908v1.077h-1.908z"
        />
      </g>
      <g data-name="Groupe 371">
        <path
          data-name="Rectangle 568"
          fill="#c8c8c8"
          d="M26.434 43.392l.27-.467 1.652.954-.27.467z"
        />
      </g>
      <g data-name="Groupe 372">
        <path
          data-name="Rectangle 569"
          fill="#c8c8c8"
          d="M45.89 54.625l.269-.466 1.653.955-.269.466z"
        />
      </g>
      <g data-name="Groupe 373">
        <path
          data-name="Rectangle 570"
          fill="#c8c8c8"
          d="M42.03 40.216l.953-1.654.466.269-.953 1.654z"
        />
      </g>
      <g data-name="Groupe 374">
        <path
          data-name="Rectangle 571"
          fill="#c8c8c8"
          d="M30.796 59.671l.955-1.65.466.27-.956 1.65z"
        />
      </g>
      <g data-name="Groupe 375">
        <path
          data-name="Trac\xE9 1222"
          d="M31.751 40.485l-.954-1.651.466-.271.953 1.653z"
          fill="#c8c8c8"
        />
      </g>
      <g data-name="Groupe 376">
        <path
          data-name="Rectangle 572"
          fill="#c8c8c8"
          d="M42.029 58.288l.467-.27.955 1.653-.467.27z"
        />
      </g>
      <g data-name="Groupe 377">
        <path
          data-name="Trac\xE9 1223"
          d="M46.159 44.346l-.269-.467 1.652-.954.271.466z"
          fill="#c8c8c8"
        />
      </g>
      <g data-name="Groupe 378">
        <path
          data-name="Rectangle 573"
          fill="#c8c8c8"
          d="M26.435 55.114l1.652-.955.269.465-1.652.956z"
        />
      </g>
    </g>
    <path
      data-name="Trac\xE9 1224"
      d="M9.087 13.348L6.57 8.575l.741-.392 1.8 3.414 2.394-4.246.73.411z"
      fill="#f06400"
    />
    <path
      data-name="Trac\xE9 1225"
      d="M9.087 24.995L6.57 20.224l.741-.392 1.8 3.415L11.505 19l.73.411z"
      fill="#f06400"
    />
    <path
      data-name="Trac\xE9 1226"
      d="M9.087 36.64L6.57 31.867l.741-.392 1.8 3.415 2.394-4.246.73.41z"
      fill="#f06400"
    />
  </g>
</svg>)

export default SvgReplenishment
