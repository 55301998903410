import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { FormCheckbox, FormInput, FormSelect } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { OnChange } from 'react-final-form-listeners'
import FieldProduct from '../../../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../../../components/fields/FieldUnitProduct'
import FieldQuantity from '../../../../../components/fields/FieldQuantity'
import FieldBatchNumber from '../../../../../components/fields/FieldBatchNumber'
import { PhidemDataContext } from '../../../../common/phidemData/PhidemDataContext'
import FieldDatetime from '../../../../../components/fields/FieldDatetime'
import FieldMonth from '../../../../../components/fields/FieldMonth'
import { PRODUCT_EXPIRATION_TYPE, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../../../utils/constants'
import TotalCountCaption from '../../../../../components/fields/TotalCountCaption'
import { VaccinModel } from '../../../../admin/product/services/productModel'
import { WatchExternalValues } from '../services/enterStockModels'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		unitQuantity: {
			fontSize: 12,
			marginTop: -22
		}
	})
)

interface StockFormExternalProps {
	change: (name: any, value?: any) => void
	values: WatchExternalValues
	canUseTransfertOption: boolean
}

const StockFormExternal: React.FC<StockFormExternalProps> =
	({
		 change,
		 values,
		 canUseTransfertOption
	 }) => {
		const classes = useStyles()
		const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
		const { vaccins } = useContext(PhidemDataContext)
		const [selectedProduit, setSelectedProduit] = React.useState<VaccinModel | undefined>(undefined)
		const [doseTotal, setDoseTotal] = React.useState<number | undefined>()
		const transfertDateLabel = useMemo(() => values.transfertType === TRANSFERT_TYPE.DECONGELE ? 'limit' : 'limit20', [values.transfertType])

		const resetTransfert = () => {
			change('transfertType', TRANSFERT_TYPE.CONGELE)
			resetTransfertDate()
		}

		const resetTransfertDate = () => {
			change('transfertDatetime', undefined)
			change('transfertDate', undefined)
			change('transfertTime', undefined)
		}

		return (
			<>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="common.enterStock.formSection.external.product"
							defaultMessage="Sélectionner le produit"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<Grid item xs={10} md={4}>
					<FieldProduct required />
					<OnChange name="product">{(value) => {
						const foundProduct = vaccins.find(vaccin => value === vaccin.id)

						setSelectedProduit(foundProduct)
						change('isTransfert', false)
						change('expirationDate', undefined)
						change('expirationMonth', undefined)
						change('expirationYear', undefined)
						setDoseTotal((values?.quantity || 0) * (foundProduct ? foundProduct.nbDoses : 0))
					}}</OnChange>
				</Grid>
				<Grid item xs={6} md={4}>
					<FieldUnitProduct />
				</Grid>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="common.enterStock.formSection.external.receiving"
							defaultMessage="Réception du produit"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<FieldBatchNumber required />
				</Grid>
				{vaccins && values.product &&
					<Grid item xs={12}>
						<Typography className={classes.unitQuantity}>
							<FormattedMessage
								id="common.enterStock.form.containerPhrasing"
								description="Number of product per box"
								values={
									{
										nbUnitesBoite: vaccins.find(
											(vaccin) => values.product === vaccin.id)
											?.nbUnitesBoite,
										typeConditionnement: vaccins.find(
											(vaccin) => values.product === vaccin.id)
											?.typeConditionnement
									}
								}
							/>
						</Typography>
					</Grid>
				}
				<Grid item xs={12}>
					{selectedProduit?.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR ?
						<Grid item xs={12} md={5}>
							<Field
								name="expirationDate"
								component={FormInput}
								label={<FormattedMessage
									id="global.functionnal.expiration"
									defaultMessage="Péremption"
									description="Expiration date"
								/>}
								type="date"
								required
							/>
						</Grid>
						:
						<FieldMonth
							dateName="expirationDate"
							monthName="expirationMonth"
							yearName="expirationYear"
							dateLabel={
								<FormattedMessage
									id="global.functionnal.expiration"
									defaultMessage="Péremption"
									description="Expiration date"
								/>
							}
							monthLabel={
								<FormattedMessage
									id="common.enterStock.form.expirationMonth"
									defaultMessage="Mois de péremption"
									description="Expiration month"
								/>
							}
							yearLabel={
								<FormattedMessage
									id="common.enterStock.form.expirationYear"
									defaultMessage="Année de péremption"
									description="Expiration year"
								/>
							}
						/>
					}
				</Grid>
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
						<FormattedMessage
							id="common.enterStock.formSection.external.quantity"
							defaultMessage="Saisir l'entrée en stock"
							description="Form section title"
						/>
					</Typography>
				</Grid>
				<Grid item xs={4} sm={3} md={2}>
					<FieldQuantity required />
					<OnChange name="quantity">{(value) => {
						setDoseTotal(value * (selectedProduit ? selectedProduit.nbDoses : 0))
					}}</OnChange>
				</Grid>
				<TotalCountCaption count={doseTotal} />
				{(canUseTransfertOption && !!selectedProduit && !!selectedProduit.stabilite28) && (<Grid item container>
					<Grid item xs={5}>
						<Field
							name="isTransfert"
							component={FormCheckbox}
							label={
								<FormattedMessage
									id="common.enterStock.form.isTranfert"
									defaultMessage="Transfert de stock"
									description="Checkbox description"
								/>
							}
						/>
						<OnChange name="isTransfert">{resetTransfert}</OnChange>
					</Grid>
				</Grid>)}

				{values.isTransfert && <>
					<Grid item container>
						<Grid item xs={10} md={4}>
							<Field
								name="transfertType"
								component={FormSelect}
								label={
									<FormattedMessage
										id="common.enterStock.form.transfertType"
										defaultMessage="Type de transfert"
										description="Transfert type"
									/>
								}
							>
								{Object.values(TRANSFERT_TYPE).map((type: string, index: number) => (
									<MenuItem key={`type-${index}`} value={type}><FormattedMessage
										id={`enum.transfertType.${type}`}
										defaultMessage={type}
										description="Transfert type option"
									/></MenuItem>
								))}
							</Field>
						</Grid>
					</Grid>
					<OnChange name="transfertType">{() => resetTransfertDate()}</OnChange>
					{values.transfertType !== TRANSFERT_TYPE.CONGELE && <Grid item xs={12}>
						<FieldDatetime
							datetimeName="transfertDatetime"
							dateName="transfertDate"
							timeName="transfertTime"
							required={values.isTransfert}
							datetimeLabel={
								<FormattedMessage
									id={`common.enterStock.form.${transfertDateLabel}Datetime`}
									defaultMessage="Date (jj/mm/aaaa hh:mm)"
									description="Received Date"
								/>
							}
							dateLabel={
								<FormattedMessage
									id={`common.enterStock.form.${transfertDateLabel}Date`}
									defaultMessage="Date (jj/mm/aaaa)"
									description="Received Date"
								/>
							}
							timeLabel={
								<FormattedMessage
									id={`common.enterStock.form.${transfertDateLabel}Time`}
									defaultMessage="Heure (HH:MM)"
									description="Received Time"
								/>
							}
						/>
					</Grid>}
				</>}
			</>
		)
	}

export default StockFormExternal
