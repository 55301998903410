import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 10
        },
        titleContainer: {
            marginBottom: 20
        },
        title: {
            fontWeight: 'bold'
        },
        count: {
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
    })
)

interface ProductCountProps {
    day: number,
    week: number,
    month: number,
    typeCount: string,
    conditionningType: string,
    icon: any
}


const ProductCount: React.FC<ProductCountProps> = (
    {
        day,
        week,
        month,
        typeCount,
        conditionningType,
        icon
    }
) => {
    const classes = useStyles()

    return <Grid item xs={12} md={3}>
        <Paper className={classes.container}>
            <Grid item container xs={12} alignItems="center" className={classes.titleContainer}>
                <Grid item xs={2}>{icon}</Grid>
                <Grid item xs={10} className={classes.title}>
                    <FormattedMessage
                        id={`common.dashboard.productStatLabel.${typeCount}`}
                        defaultMessage="Stat card title"
                        description="Stat card title"
                    />
                </Grid>

            </Grid>
            <Grid item container xs={12} alignItems="center" justify="space-between">
                <Grid item xs={5}>
                    <FormattedMessage
                        id="common.dashboard.productStatLabel.day"
                        defaultMessage="24h"
                        description="Stat card day"
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
                <Grid item>
                    <Typography className={classes.count} component='span'>{day}</Typography>
                    <FormattedMessage
                        id="global.functionnal.conditioningType"
                        defaultMessage={conditionningType}
                        description="Conditioning type"
                        values={
                            {
                                count: day,
                                conditioningType: conditionningType
                            }
                        }
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="space-between">
                <Grid item xs={5}>
                    <FormattedMessage
                        id="common.dashboard.productStatLabel.week"
                        defaultMessage="7j"
                        description="Stat card week"
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
                <Grid item>
                    <Typography className={classes.count} component='span'>{week}</Typography>
                    <FormattedMessage
                        id="global.functionnal.conditioningType"
                        defaultMessage={conditionningType}
                        description="Conditioning type"
                        values={
                            {
                                count: week,
                                conditioningType: conditionningType
                            }
                        }
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="space-between">
                <Grid item xs={5}>
                    <FormattedMessage
                        id="common.dashboard.productStatLabel.month"
                        defaultMessage="30j"
                        description="Stat card month"
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
                <Grid item>
                    <Typography className={classes.count} component='span'>{month}</Typography>
                    <FormattedMessage
                        id="global.functionnal.conditioningType"
                        defaultMessage={conditionningType}
                        description="Conditioning type"
                        values={
                            {
                                count: month,
                                conditioningType: conditionningType
                            }
                        }
                    >
                        {msg => <Typography component='span'>{msg}</Typography>}
                    </FormattedMessage>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}

export default ProductCount
