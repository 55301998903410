import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			minHeight: '100vh'
		},
		absolute: {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0
		},
		circularProgress: {
			margin: 'auto'
		}
	})
)

interface LoaderProps {
	minHeight?: boolean,
	absolute?: boolean
	style?: string
}

const Loader: React.FC<LoaderProps> = ({ minHeight = false, absolute = false, style = '' }) => {
	const classes = useStyles()
	return (
		<Grid container className={classNames(style, { [classes.absolute]: absolute, [classes.container]: !absolute && minHeight })}>
			<Grid item className={classes.circularProgress}>
				<CircularProgress />
			</Grid>
		</Grid>
	)
}

export default Loader
