import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../../../../../components/layout/buttons/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Field, Form } from 'react-final-form'
import { useLocation } from 'react-router-dom'
import { CENTER_TYPE_LOWERCASE, PRODUCT_TYPES, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../../../utils/constants'
import { ProductModel } from '../../../../admin/product/services/productModel'
import { PhidemDataContext } from '../../../phidemData/PhidemDataContext'
import { FormInput, FormSelect } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import moment from 'moment'
import _ from 'lodash'
import FieldProduct from '../../../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../../../components/fields/FieldUnitProduct'
import FieldQuantity from '../../../../../components/fields/FieldQuantity'
import FieldDatetime from '../../../../../components/fields/FieldDatetime'
import DialogPrepareProductReturn from './DialogPrepareProductReturn'
import MenuItem from '@material-ui/core/MenuItem'
import TotalCountCaption from '../../../../../components/fields/TotalCountCaption'
import Loader from '../../../../../components/layout/Loader'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import { isDateInputTypeSupported } from '../../../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../../../utils/form/decorators'
import { compareLimitDate, getLimitDateWithStability } from '../../../../../utils/formUtils'
import { DATE_FORMAT, DATE_FORMAT_IN_FORM, displayDate, formatDateInForm, getPeremptionDate } from '../../../../../utils/dateUtils'
import { ExitStockProductValues } from '../services/exitStockModels'
import { DispatchReferentStockModel } from '../../stock/stockModels'
import { getStockByProduit } from '../../stock/stockApi'

const getInitialDates = () => {
	const currentDateTime = moment()
	return {
		sortie: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		exitDate: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		exitTime: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.HOUR)
	}
}

const useStyles = (isLargeScreen: boolean) => makeStyles((theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		marginComment: {
			marginBottom: 30
		},
		table: {
			width: '100%'
		},
		title: {
			marginTop: 5
		},
		dialogContainer: {
			...(isLargeScreen && { minWidth: 500 }),
			minHeight: 525
		}
	})
)

interface DialogAddProductProps {
	showOtherThanVaccin?: boolean
	centerType: string
	frostedMode?: boolean
	value?: ExitStockProductValues
	index?: number
	isOpen: boolean
	handleClose: () => any
	onConfirm: (values: ExitStockProductValues, index?: number) => void
}

const DialogAddProduct: React.FC<DialogAddProductProps> = ({
	                                                           showOtherThanVaccin = true,
	                                                           centerType,
	                                                           frostedMode = false,
	                                                           value,
	                                                           index,
	                                                           isOpen,
	                                                           handleClose,
	                                                           onConfirm
                                                           }) => {

	const prefixUrl = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]
	const [selectedProduit, setSelectedProduit] = React.useState<ProductModel | undefined>(undefined)
	const [doseTotal, setDoseTotal] = React.useState<number | undefined>()
	const [isReturnSelection, setIsReturnSelection] = React.useState<boolean>(false)
	const [decongele, setDecongele] = React.useState<boolean>(false)
	const [transfertType, setTransfertType] = React.useState<undefined | string>(undefined)
	const [transfertDate, setTransfertDate] = React.useState<undefined | string>(undefined)
	const [isLoadingStock, setIsLoadingStock] = React.useState<boolean>(false)
	const [showPrep, setShowPrep] = React.useState<boolean>(false)
	const [stocksNoReturn, setStocksNoReturn] = React.useState<DispatchReferentStockModel[]>([])
	const [stocksReturn, setStocksReturn] = React.useState<DispatchReferentStockModel[]>([])

	const [isOpenDialogReturnProduct, setIsOpenDialogReturnProduct] = React.useState<boolean>(false)

	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const classes = useStyles(isLargeScreen)()
	const intl = useIntl()
	const location = useLocation()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('exitDate', 'exitTime', 'sortie'),
				dateAndTimeDecorator('usageLimitDate', 'usageLimitTime', 'usageLimitDatetime')]
		, [isDatetimeSupported])

	const { vaccins, products, user: { isDateFormatEn } } = useContext(PhidemDataContext)

	const switchToReturnedProducts = () => {
		setIsReturnSelection(true)
		closeDialogReturnProduct()
	}

	const openDialogReturnProduct = () => setIsOpenDialogReturnProduct(true)
	const closeDialogReturnProduct = () => setIsOpenDialogReturnProduct(false)

	const getStock = useCallback((idProduit: string) => {
		setDoseTotal(0)
		setIsReturnSelection(false)
		setDecongele(false)
		setTransfertType(undefined)
		setTransfertDate(undefined)
		setIsLoadingStock(true)
		setShowPrep(false)
		const selectedProduit = [...vaccins, ...products].find((produit: ProductModel) => produit.id === idProduit)
		setSelectedProduit(selectedProduit)

		if (selectedProduit) {
			if (selectedProduit.type === PRODUCT_TYPES.VACCIN) {
				getStockByProduit(prefixUrl, idProduit)
					.then((stocks: DispatchReferentStockModel[]) => {
						setStocksNoReturn(stocks.filter((stock: DispatchReferentStockModel) => !stock.retourProduit && !stock.decongele))

						if (stocks.some((stock: DispatchReferentStockModel) => stock.retourProduit || stock.decongele) && !frostedMode) {
							if (!value) {
								openDialogReturnProduct()
							}

							// Gestion des vaccins décongelés comme les retours
							setStocksReturn(stocks
								.filter((stock: DispatchReferentStockModel) => stock.retourProduit || stock.decongele)
								.sort((a, b) => {
									// Durée de stabilité selon le stock du retour (dans ce cas : un bl = un stock)
									// - Stock classique = stabilite28
									// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
									const afirstBlLine = a.lignesBl && a.lignesBl.length > 0 ? a.lignesBl[0] : undefined
									const bfirstBlLine = b.lignesBl && b.lignesBl.length > 0 ? b.lignesBl[0] : undefined
									const stabilityA = (a.retourProduit && afirstBlLine && afirstBlLine.stockTransfertType === TRANSFERT_TYPE.MOINS_20 ? a.produit.stabilite28TransfertMoins20 : a.produit.stabilite28) || 0
									const stabilityB = (b.retourProduit && bfirstBlLine && bfirstBlLine.stockTransfertType === TRANSFERT_TYPE.MOINS_20 ? b.produit.stabilite28TransfertMoins20 : b.produit.stabilite28) || 0

									// Tri par date limite d'utilisation ou date transfert
									const usageLimitDateA = afirstBlLine ? compareLimitDate(moment(afirstBlLine && afirstBlLine.dateSortie).add(stabilityA, 'hours'), a.peremption, a.produit.peremptionType) || moment() : (a.transfertDate ? moment(a.transfertDate) : moment())
									const usageLimitDateB = bfirstBlLine ? compareLimitDate(moment(bfirstBlLine.dateSortie).add(stabilityB, 'hours'), b.peremption, b.produit.peremptionType) || moment() : (b.transfertDate ? moment(b.transfertDate) : moment())
									return usageLimitDateA.diff(usageLimitDateB)
								}))
						}
					})
					.finally(() => {
						setIsLoadingStock(false)
						setShowPrep(true)
					})
			} else {
				setIsLoadingStock(false)
				setShowPrep(true)
			}
		}

	}, [vaccins, products, value, prefixUrl, frostedMode])

	useEffect(() => {
		// @ts-ignore
		if (location?.state?.productID && location?.state?.quantity) {
			// @ts-ignore
			getStock(location.state.productID)
		}
	}, [location, getStock])

	useEffect(() => {
		if (!!value) {
			getStock(value.idProduit)
			setIsReturnSelection(value.isReturnSelection)
			setTransfertType(value.transfertType)
			setTransfertDate(value.transfertDate)
		}
	}, [value, getStock])


	const resetPrepaFields = (form: any) => {
		const initialDates = getInitialDates()

		form.change('sortie', initialDates.sortie)
		form.change('exitDate', initialDates.exitDate)
		form.change('exitTime', initialDates.exitTime)
		form.change('doseTotal', undefined)
		form.change('quantite', undefined)
		form.change('idStock', undefined)
		form.change('lot', undefined)
		form.change('peremption', undefined)
		form.change('peremptionType', undefined)
	}

	const submit = async (values: ExitStockProductValues) => {
		return onConfirm(
			{
				...values,
				nomCommercial: (selectedProduit && selectedProduit.nomCommercial) || '',
				isReturnSelection: isReturnSelection && !transfertType,
				transfertType,
				transfertDate,
				sortie: !frostedMode && selectedProduit && selectedProduit.stabilite28 ? values.sortie : undefined
			},
			index
		)
	}

	const onValidate = (values: ExitStockProductValues) => {
		const errors: any = {}
		const isVaccin = selectedProduit && selectedProduit.type === PRODUCT_TYPES.VACCIN

		if (!values.idProduit) {
			errors.idProduit = <ErrorMessageRequired />
		}
		if (!values.quantite) {
			errors.quantite = <ErrorMessageRequired />
		} else if (values.quantite <= 0) {
			errors.quantite = <FormattedMessage
				id="global.error.validation.positive"
				defaultMessage="La valeur doit être positive"
				description="Quantity error message"
			/>
		}
		if (!values.idStock && isVaccin) {
			errors.idStock = <ErrorMessageRequired />
		}
		if (selectedProduit && selectedProduit.stabilite28 && !frostedMode) {
			if (isDatetimeSupported) {
				if (!values.sortie) {
					errors.sortie = <ErrorMessageRequired />
				}
			} else {
				if (!values.exitDate) {
					errors.exitDate = <ErrorMessageRequired />
				}
				if (!values.exitTime) {
					errors.exitTime = <ErrorMessageRequired />
				}
			}
		}
		return errors
	}

	const initValues = useMemo(() => ({
		// @ts-ignore
		idProduit: location?.state?.productID,
		// @ts-ignore
		typeProduit: location && location.state && location.state.productID ? PRODUCT_TYPES.VACCIN : undefined,
		// @ts-ignore
		unitProduct: location && location?.state && location?.state?.unitProduct,
		// @ts-ignore
		quantite: location?.state?.quantity,
		// @ts-ignore
		replenishmentID: location?.state?.replenishmentID,
		...getInitialDates(),
		forceValidation: false,
		...value
	}), [location, value])

	return (
		<Dialog
			title={
				<FormattedMessage
					id="common.exitStock.dialogAddProduct.title"
					defaultMessage="Ajouter un produit"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
			containerClass={classes.dialogContainer}
		>
			<Form
				initialValues={initValues}
				onSubmit={submit}
				validate={onValidate}
				// @ts-ignore
				decorators={formDecorators}
				render={({ handleSubmit, submitting, form, values }) => {
					return (
						<Grid item xs={12}>
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'} className={classes.title}>
									<FormattedMessage
										id="common.exitStock.dialogAddProduct.formSection.product"
										defaultMessage="1. Sélectionner le produit"
										description="Form section title"
									/>
								</Typography>
							</Grid>

							<Grid container item xs={12} spacing={2}>
								<Grid item xs={10} md={6}>
									<FieldProduct name="idProduit" required showOtherThanVaccin={showOtherThanVaccin} disabled={index !== undefined} />
								</Grid>
								<Grid item xs={6} md={4}>
									<FieldUnitProduct />
								</Grid>
							</Grid>

							<OnChange name="idProduit">{(value) => {
								resetPrepaFields(form)
								if (!!value) {
									getStock(value)
								}
							}}</OnChange>

							{isLoadingStock && <Loader />}

							{showPrep && <>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="common.exitStock.dialogAddProduct.formSection.preparation"
											defaultMessage="2. Information sur le produit"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{((!isReturnSelection && stocksNoReturn.length > 0) ||
									(isReturnSelection && stocksReturn.length > 0))
								|| (selectedProduit && selectedProduit.type !== PRODUCT_TYPES.VACCIN) ?
									<>
										<Grid container item xs={12} spacing={2}>
											{selectedProduit && selectedProduit.type === PRODUCT_TYPES.VACCIN && <Grid item xs={12} md={5}>
												<Field
													name="idStock"
													component={FormSelect}
													label={
														isReturnSelection ?
															<FormattedMessage
																id="common.exitStock.dialogAddProduct.form.defrosted"
																defaultMessage="Produit décongelé"
																description="Defrosted product"
															/> :
															<FormattedMessage
																id="global.functionnal.batch"
																defaultMessage="Lot"
																description="Batch number"
															/>
													}
													required
												>
													{(isReturnSelection ? stocksReturn : _.sortBy(stocksNoReturn, stock => stock.lot.toLowerCase()))
														.map((stock: DispatchReferentStockModel) => {
															let suffix = ''
															let prefix = ''

															if (isReturnSelection) {
																prefix = `${stock.quantite} ${stock.produit.typeConditionnement}(s) : `
															}

															if (stock.decongele) {
																// On compare la date limite d'utilisation à la date de péremption du stock
																const firstBlLine = stock.lignesBl && stock.lignesBl.length > 0 ? stock.lignesBl[0] : undefined
																let usageLimitDate = firstBlLine && getLimitDateWithStability(stock.produit, firstBlLine.dateSortie, firstBlLine.stockTransfertType)
																usageLimitDate = compareLimitDate(usageLimitDate, stock.peremption, stock.produit.peremptionType) || moment()

																suffix = ` - ${displayDate(usageLimitDate, DATE_FORMAT.DATE, isDateFormatEn)}`
															} else if (isReturnSelection && !!stock.transfertType && stock.transfertType !== TRANSFERT_TYPE.CONGELE) {
																// On compare la date limite d'utilisation du transfert à la date de péremption du stock
																const usageLimitDate = compareLimitDate(moment(stock.transfertDate), stock.peremption, stock.produit.peremptionType) || moment()
																suffix = ` (${intl.formatMessage({ id: `enum.transfertType.${stock.transfertType}` })}) - ${displayDate(usageLimitDate, DATE_FORMAT.DATE, isDateFormatEn)}`
															} else if (!isReturnSelection) {
																// On affiche la péremption
																// Pour un transfert -70 -> -20 = date transfert
																if (!!stock.transfertType && stock.transfertType !== TRANSFERT_TYPE.CONGELE) {
																	const usageLimitDate = compareLimitDate(moment(stock.transfertDate), stock.peremption, stock.produit.peremptionType) || moment()
																	suffix = ` (${displayDate(usageLimitDate, DATE_FORMAT.DATE, isDateFormatEn)})`
																} else {
																	suffix = ` (${getPeremptionDate(isDateFormatEn, stock.peremption, stock.produit.peremptionType)})`
																}
															}

															return (
																<MenuItem key={stock.id} value={stock.id}>
																	{`${prefix}${stock.lot}${suffix}`}
																</MenuItem>
															)
														})}
												</Field>
												<OnChange name="idStock">
													{
														idStock => {
															if (idStock) {
																const stock = (isReturnSelection ? stocksReturn : stocksNoReturn).find((stock: DispatchReferentStockModel) => stock.id === idStock)

																if (stock) {
																	form.change('peremption', stock.peremption)
																	form.change('peremptionType', stock.produit?.peremptionType)
																	form.change('lot', stock.lot)

																	setDecongele(stock.decongele)
																	setTransfertType(stock.transfertType)
																	if (stock.transfertType !== TRANSFERT_TYPE.CONGELE) {
																		setTransfertDate(stock.transfertDate)
																	}

																	if (stock.retourProduit || stock.decongele) {
																		// Retour produit : exitDate et on calcule la limite d'utilisation
																		// Transfert décongelé: limite d'utilisation et on calcule la date de sortie
																		let exitDate: any = ''
																		let usageLimitDate: any = ''
																		if (stock.retourProduit || !stock.transfertType) {
																			const firstBlLine = stock.retourBl ? stock.retourBl : (stock.lignesBl && stock.lignesBl.length > 0 ? stock.lignesBl[0] : undefined)
																			exitDate = firstBlLine?.dateSortie

																			// Dans le cas d'un retour, on calcule la durée de stabilité selon le stock initial
																			// - Stock classique = stabilite28
																			// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
																			usageLimitDate = getLimitDateWithStability(stock.produit, exitDate, firstBlLine?.stockTransfertType)
																		} else {
																			usageLimitDate = stock.transfertDate
																			exitDate = moment(usageLimitDate).subtract(stock.produit?.stabilite28 || 0, 'hours')
																		}

																		form.change('sortie', exitDate ? formatDateInForm(moment(exitDate), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined)
																		form.change('exitDate', exitDate ? formatDateInForm(moment(exitDate), DATE_FORMAT_IN_FORM.DATE_DASH) : undefined)
																		form.change('exitTime', exitDate ? formatDateInForm(moment(exitDate), DATE_FORMAT_IN_FORM.HOUR) : undefined)

																		// On compare la date limite d'utilisation à la date de péremption du stock
																		usageLimitDate = compareLimitDate(usageLimitDate, stock.peremption, stock.produit.peremptionType)

																		form.change('usageLimitDatetime', usageLimitDate ? formatDateInForm(moment(usageLimitDate), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE) : undefined)
																		form.change('usageLimitDate', usageLimitDate ? formatDateInForm(moment(usageLimitDate), DATE_FORMAT_IN_FORM.DATE_DASH) : undefined)
																		form.change('usageLimitTime', usageLimitDate ? formatDateInForm(moment(usageLimitDate), DATE_FORMAT_IN_FORM.HOUR) : undefined)
																	}
																}
															}
														}
													}
												</OnChange>
											</Grid>}

											<Grid item xs={12} md={4}>
												<Field
													name="refExterne"
													component={FormInput}
													label={
														<FormattedMessage
															id="common.exitStock.dialogAddProduct.form.refExterne"
															defaultMessage="Référence externe"
															description="Extern reference"
														/>
													}
												/>
											</Grid>
										</Grid>
										<Grid item xs={4} md={2}>
											<FieldQuantity name="quantite" required />
											<OnChange name="quantite">{(value) => {
												if (selectedProduit && selectedProduit.type === PRODUCT_TYPES.VACCIN) {
													setDoseTotal(value * (selectedProduit.nbDoses || 0))
												}
											}}</OnChange>
										</Grid>
										{selectedProduit && selectedProduit.type === PRODUCT_TYPES.VACCIN && <TotalCountCaption count={doseTotal} />}
										{(!frostedMode && (!!selectedProduit?.stabilite28 || ((isReturnSelection || decongele) && values.sortie))) &&
											<Grid item xs={12}>
												<FieldDatetime
													gridMD={8}
													datetimeName="sortie"
													dateName="exitDate"
													timeName="exitTime"
													disabled={isReturnSelection || decongele}
													datetimeLabel={
														<FormattedMessage
															id="global.functionnal.exitTime"
															defaultMessage="Heure de sortie"
															description="Exit time of the product"
														/>
													}
													dateLabel={
														<FormattedMessage
															id="common.exitStock.dialogAddProduct.form.exitDate"
															defaultMessage="Date de sortie du congélateur (jj/mm/aaaa)"
															description="Exit date"
														/>
													}
													timeLabel={
														<FormattedMessage
															id="global.functionnal.exitTime"
															defaultMessage="Heure de sortie du congélateur (jj/mm/aaaa)"
															description="Exit time"
														/>
													}
												/>
												{(isReturnSelection || decongele) && values.usageLimitDatetime &&
													<FieldDatetime
														gridMD={8}
														datetimeName="usageLimitDatetime"
														dateName="usageLimitDate"
														timeName="usageLimitTime"
														disabled
														datetimeLabel={
															<FormattedMessage
																id="global.functionnal.usageLimitDate"
																defaultMessage="Heure de sortie"
																description="Limit time of the product"
															/>
														}
														dateLabel={
															<FormattedMessage
																id="global.functionnal.usageLimitDate"
																defaultMessage="Date de sortie du congélateur (jj/mm/aaaa)"
																description="Limit date"
															/>
														}
														timeLabel={
															<FormattedMessage
																id="global.functionnal.usageLimitTime"
																defaultMessage="Heure de sortie du congélateur (jj/mm/aaaa)"
																description="Limit time"
															/>
														}
													/>
												}
											</Grid>
										}
									</>
									:
									<Grid item xs={12}>
										<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
											<FormattedMessage
												id="common.exitStock.dialogAddProduct.stockNotFound"
												defaultMessage="Aucun lot ne possède ce produit"
												description="No batch found"
											/>
										</Typography>
									</Grid>}
								{isOpenDialogReturnProduct &&
									<DialogPrepareProductReturn
										isOpen={isOpenDialogReturnProduct}
										handleClose={closeDialogReturnProduct}
										onConfirm={switchToReturnedProducts}
									/>}
								<div className={classes.submitButtonRoot}>
									<Button variant="contained" isLoading={submitting} onClick={handleSubmit}>
										<FormattedMessage
											id="button.validate"
											defaultMessage="Valider"
											description="Message on form submission button"
										/>
									</Button>
								</div>
							</>}
						</Grid>
					)
				}}
			/>
		</Dialog>
	)
}

export default DialogAddProduct
