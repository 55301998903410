import React from 'react'

const SvgUsage = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={82.117}
    height={67.953}
>
  <path
      d="M63.069 7.883C60.891 3.203 42.658-.095 37.552.002c-5.1-.1-23.339 3.2-25.515 7.881-1.454 3.129-1.508 31.508 4.2 40.925 10.205 16.827 21.311 19.145 21.311 19.145s11.107-2.318 21.314-19.145c5.715-9.417 5.666-37.796 4.207-40.925z"
      fill="#006574"
  />
  <path
      d="M60.693 9.823C58.72 5.579 42.181 2.588 37.552 2.676c-4.628-.088-21.165 2.9-23.136 7.147-1.321 2.837-1.37 28.573 3.812 37.114 9.253 15.259 19.324 17.359 19.324 17.359s10.076-2.1 19.33-17.359c5.178-8.541 5.13-34.277 3.811-37.114z"
      fill="#01bbb6"
  />
  <path
      d="M37.552.002a36 36 0 00-5.055.421 66.22 66.22 0 006.277 26.02c4.8 9.759 14.3 17.362 20.528 21.577 5.269-10.111 5.184-37.09 3.767-40.137C60.891 3.203 42.658-.095 37.552.002z"
      fill="#bfbfbf"
      style={{
        mixBlendMode: "soft-light",
        isolation: "isolate",
      }}
  />
  <path
      d="M47.494 62.376c-6.628-4.532-8.087-17.806-12.683-27.1-5.37-10.862-17.883-23.287-19.045-30.39-1.858.909-3.226 1.915-3.729 3-1.454 3.129-1.508 31.508 4.2 40.925 10.205 16.827 21.311 19.145 21.311 19.145s4.272-.898 9.946-5.58z"
      fill="#454556"
      style={{
        mixBlendMode: "soft-light",
        isolation: "isolate",
      }}
  />
  <path
      d="M65.348 7.941c-.121-.166-.475-.114-.793.118s-.473.55-.355.716l8.954 12.273c.123.165.474.113.793-.119s.476-.549.355-.718z"
      fill="#ddf6f5"
  />
  <path
      fill="#53d1ce"
      d="M67.895 11.435l9.135-6.67 3.86 5.288-9.135 6.669z"
  />
  <path
      d="M30.042 39.037l3.765 5.159a1.254 1.254 0 001.749.275l36.317-26.385-5.245-7.184-36.31 26.383a1.252 1.252 0 00-.276 1.752z"
      fill="#ddf6f5"
  />
  <path
      fill="#ddf6f5"
      d="M27.826 42.767l3.223-2.35 1.83 2.51-3.224 2.35z"
  />
  <path
      fill="#98e3e2"
      d="M23.17 45.581l5.347-3.905 2.391 3.274-5.347 3.905z"
  />
  <path d="M0 64.449l2.036-.569 22.507-16.421-.439-.6z" fill="#263b40" />
  <path
      fill="#98e3e2"
      d="M27.758 41.562l.709-.518 2.948 4.033-.709.519zM42.445 29.887l3.858 5.286 24.3-17.618-3.857-5.287z"
  />
  <path
      d="M44.135 32.205l.476.649L78.977 7.896l-.473-.65z"
      fill="#ddf6f5"
  />
  <path
      fill="#b00000"
      d="M31.675 37.743l9.775-7.126 3.856 5.29-9.775 7.125z"
  />
  <path fill="#263b40" d="M41.448 30.614l.997-.727 3.858 5.288-.997.728z" />
  <path
      d="M76.967 3.474c-.069-.1-.382.013-.7.245s-.517.491-.448.59l5.134 7.037c.072.1.385-.013.7-.243s.518-.494.449-.593z"
      fill="#98e3e2"
  />
  <path
      d="M71.753 16.72l-1.711-2.4 8.582-6.167 1.694 2.322a11.216 11.216 0 00-1.859-.989c-.125.084-7 5.011-7 5.011z"
      fill="#01bbb6"
  />
  <path
      d="M32.543 37.38l9.284-6.703a.662.662 0 01.924.15l.3.415a.662.662 0 01-.15.924l-9.285 6.703a.662.662 0 01-.924-.15l-.299-.413a.663.663 0 01.15-.926z"
      fill="#fff"
      style={{
        mixBlendMode: "soft-light",
        isolation: "isolate",
      }}
  />
  <path
      d="M68.898 15.214l-.772 1.957-32.938 23.632-2.31-1.257-1.586 1.2 2.515 3.55a1.184 1.184 0 001.663.273l35.829-26.065z"
      fill="#454556"
      style={{
        mixBlendMode: "soft-light",
        isolation: "isolate",
      }}
  />
</svg>)

export default SvgUsage
