import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { LANGUAGES_CODE } from '../../utils/constants'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { updateIntl } from 'react-intl-redux'
import messagesFr from './fr'
import messagesEn from './en'
import { ModelTranslations } from '../../config/messages/messagesModel'
import { updateUserLanguage } from '../../modules/common/user/userApi'
import { fetchUser as fetchUserApi, getLocalUser } from 'isotope-client'
import { ApplicationConfiguration } from '../../modules/common/phidemData/phidemDataModel'

const useStyles = makeStyles(() =>
	createStyles({
		selectedFlag: {
			marginLeft: -10
		},
		menu: {
			backgroundColor: 'transparent',
			width: 40
		},
		flagOption: {
			padding: 5
		}
	})
)

interface LanguageSelectorProps {
	selectedLang: string
	appConfiguration: ApplicationConfiguration
	updateLang: (lang: string, messages: ModelTranslations) => void
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLang, appConfiguration, updateLang }) => {
	const classes = useStyles()
	const [open, setOpen] = React.useState<boolean>(false)
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleOpenFlagMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
		setOpen(true)
	}

	const changeLang = (lang: string) => {
		setOpen(false)
		updateLang(lang, lang === LANGUAGES_CODE.FR ? messagesFr : messagesEn)
		updateUserLanguage(lang)
	}

	useEffect(() => {
		// Au montage on vérifie notre user pour la langue
		const token = getLocalUser()
		fetchUserApi(token).then((user: User) => {
			if (user?.preferences.LANGUE !== selectedLang) {
				const newLang = user?.preferences.LANGUE || appConfiguration.defaultLanguage
				updateLang(newLang, newLang === LANGUAGES_CODE.FR ? messagesFr : messagesEn)
			}
		})
		// On ne veut le faire qu'au montage de l'app
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <>
		<IconButton className={classes.selectedFlag} onClick={handleOpenFlagMenu}>
			<img alt="logo" src={`/img/flag_${selectedLang}.png`} />
		</IconButton>
		<Popover
			open={open}
			onClose={() => setOpen(false)}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			classes={{ paper: classes.menu }}
			elevation={0}
		>
			<Grid container justify="center">
				{Object.values(LANGUAGES_CODE).filter((lang: string) => lang !== selectedLang).map((lang: string) => <Grid item xs={12} key={`lang-${lang}`}>
					<IconButton className={classes.flagOption} onClick={() => changeLang(lang)}>
						<img alt="logo" src={`/img/flag_${lang}.png`} />
					</IconButton>
				</Grid>)}
			</Grid>
		</Popover>
	</>

}

const mapStateToProps = (state: any) => ({
	selectedLang: state.intl.locale,
	appConfiguration: state.appConfiguration
})

const actions = {
	updateLang: (lang: string, messages: ModelTranslations) => (dispatch: any) => dispatch(updateIntl({ locale: lang, messages }))
}

export default connect(mapStateToProps, actions)(LanguageSelector)
