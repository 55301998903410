import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { Print } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            minWidth: 180,
            minHeight: 34
        },
        loader: {
            width: '20px !important',
            height: '20px !important',
            verticalAlign: 'middle',
            color: 'white',
        },
        printList: {
            zIndex: 100
        }
    }),
)

export interface SplitButtonItem {
    label: any
    onClick: (event: any) => void
}

interface SplitButtonProps {
    items: SplitButtonItem[]
    label?: string,
    isLoading?: boolean
    disabled?: boolean
    showIcon?: boolean
}

const SplitButton: React.FC<SplitButtonProps> = (
    {
        items,
        label = 'button.print.initial',
        isLoading = false,
        disabled = false,
        showIcon = true
    }
) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" disabled={disabled}>
                    <Button
                        className={classes.button}
                        onClick={handleToggle}
                        startIcon={(!isLoading && showIcon) && <Print />}
                    >
                        {isLoading ?
                            <CircularProgress className={classes.loader} />
                            :
                            <FormattedMessage
                                id={label}
                                defaultMessage="Imprimer"
                                description="Print button label"
                            />}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.printList}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {items.map((item) => (
                                            <MenuItem
                                                key={item.label}
                                                onClick={item.onClick}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    )
}

export default SplitButton
