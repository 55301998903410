import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'
import { useHistory } from 'react-router-dom'
import { SpringDataTable } from 'isotope-client'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import NotFoundPage from '../../../../../components/layout/NotFoundPage'
import { RequestStockFiltersModel, ResponseStockModel } from '../../../../admin/stock/services/stockModel'
import { CENTER_TYPE_LOWERCASE, colors, DOWNLOAD_FILE_TYPE, PEREMPTION_HOUR, PRODUCT_EXPIRATION_TYPE, SELECT_VALUES, TRANSFERT_TYPE } from '../../../../../utils/constants'
import ExportExcelButton from '../../../../../components/layout/buttons/ExportExcelButton'
import { getLimitDateWithStability } from '../../../../../utils/formUtils'
import { PhidemDataContext } from '../../../phidemData/PhidemDataContext'
import { DATE_FORMAT, displayDate } from '../../../../../utils/dateUtils'
import { DispatchReferentStockModel } from '../stockModels'

const useStyles = makeStyles(() =>
	createStyles({
		usageLimitDate: {
			color: colors.snackError
		}
	})
)

interface StockListDesktopProps {
	centerType: string
	filters: RequestStockFiltersModel,
	openDialog?: (row: ResponseStockModel) => void,
	showCenter?: boolean,
	showActions?: boolean
}

const StockListDesktop: React.FC<StockListDesktopProps> = (
	{
		centerType,
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false
	}
) => {
	const history = useHistory()
	const classes = useStyles()
	const { user: { selectedLang, isDateFormatEn } } = useContext(PhidemDataContext)
	const prefixCenter = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]

	const getHeaders = () => {
		const headers = [
			{
				key: 'warning',
				name: <FormattedMessage
					id="global.space"
					defaultMessage=" "
					description="Header column name"
				/>,
				sortable: true,
				render: (row: DispatchReferentStockModel) => <span>{row.retourProduit && <WarningIcon />}</span>
			},
			{
				key: 'lot',
				name: <FormattedMessage
					id="global.functionnal.batch"
					defaultMessage="Numéro de lot"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: DispatchReferentStockModel) => <span>
                    {row.lot}
					{!!row.transfertType && <> (<FormattedMessage
						id={`enum.transfertTypeInStock.${row.transfertType}`}
						description="Transfert type"
						defaultMessage="Type de transfert"
					/>)</>}
                </span>
			},
			{
				key: 'quantite',
				name: <FormattedMessage
					id="global.functionnal.quantity"
					defaultMessage="Quantité"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: DispatchReferentStockModel) => <span>
                            {row.quantite}
					<FormattedMessage
						id="global.functionnal.conditioningType"
						defaultMessage={row.produit.typeConditionnement}
						description="Conditioning type"
						values={
							{
								count: row.quantite,
								conditioningType: row.produit.typeConditionnement
							}
						}
					/>
                        </span>
			}
		]
		if (showCenter) {
			headers.push(
				{
					key: 'centreDispatch.nom',
					name: <FormattedMessage
						id="stockEdit.dataTable.centerName"
						defaultMessage="Centre"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: DispatchReferentStockModel) => <span>{row.centre.nom}</span>
				}
			)
		}

		headers.push({
			key: 'peremption',
			name: <FormattedMessage
				id="global.functionnal.expiration"
				defaultMessage="Péremption"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: DispatchReferentStockModel) => {
				let dateLimit = undefined
				if (row.retourProduit) {
					if (row.retourBl?.dateSortie) {
						// Dans le cas d'un retour, on calcule la durée de stabilité selon le stock initial
						// - Stock classique = stabilite28
						// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
						dateLimit = getLimitDateWithStability(row.produit, row.retourBl?.dateSortie, row.retourBl?.stockTransfertType)
					}
				} else if (row.decongele && row.lignesBl && row.lignesBl.length > 0 && row.lignesBl[0].dateSortie) {
					const firsLineBl = row.lignesBl && row.lignesBl.length > 0 ? row.lignesBl[0] : undefined
					dateLimit = getLimitDateWithStability(row.produit, firsLineBl?.dateSortie, firsLineBl?.stockTransfertType)
				} else if (!!row.transfertType && row.transfertType !== TRANSFERT_TYPE.CONGELE) {
					dateLimit = moment(row.transfertDate)
				}

				// On compare la date limite d'utilisation à la date de péremption du stock
				// Si la date de péremption est antérieure => on n'affiche pas la date limite
				const stockPeremption = row.peremption
				const isPeremptionJour = row.produit.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR
				if (dateLimit && stockPeremption) {
					const peremptionDate = isPeremptionJour ? moment(stockPeremption) : moment(stockPeremption).endOf('month')
					const peremptionLimit = peremptionDate.hours(PEREMPTION_HOUR).minutes(0)
					if (peremptionLimit.isBefore(dateLimit)) {
						dateLimit = undefined
					}
				}

				return (
					<span>
                        {isPeremptionJour ? displayDate(moment(row.peremption), DATE_FORMAT.DATE, isDateFormatEn)
	                        : displayDate(moment(row.peremption).locale(selectedLang), DATE_FORMAT.MONTH_YEAR, isDateFormatEn)}
						{dateLimit ?
							<span className={classes.usageLimitDate}>{` (${displayDate(dateLimit, DATE_FORMAT.DATETIME, isDateFormatEn)})`}</span>
							: ''
						}
                        </span>
				)
			}
		})

		if (!filters?.idProduit || filters?.idProduit === SELECT_VALUES.ALL) {
			headers.unshift({
				key: 'nomCommercial',
				name: <FormattedMessage
					id="global.functionnal.product"
					defaultMessage="Produit"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: DispatchReferentStockModel) => <span>{row.produit.nomCommercial}</span>
			})
		}

		return headers
	}

	return (
		<>
			<SpringDataTable
				apiUrl={`/${prefixCenter}/stock`}
				nom={`${prefixCenter}StockListDesktop`}
				headers={getHeaders()}
				filters={filters}
				noResultFragment={<NotFoundPage />}
				makeActions={(row: DispatchReferentStockModel) => {
					const actions = [
						{
							label: <FormattedMessage
								id="global.update"
								defaultMessage="Modifier"
								description="Update action"
							/>, action: () => {
								openDialog({
									id: row.id,
									produit: row.produit,
									quantite: row.quantite,
									peremption: row.peremption,
									lot: row.lot,
									centerType
								})
							}
						}
					]

					if (row.retourProduit) {
						actions.push(
							{
								label: <FormattedMessage
									id="stockEdit.dataTable.seePrint"
									defaultMessage="Voir la fiche de retour"
									description="Update action"
								/>, action: () => {
									history.push(`/dispatch/return/${row.id}`)
								}
							}
						)
					}

					return actions
				}}
				showActions={showActions}
			/>
			<ExportExcelButton
				url={`/${prefixCenter}/stock/export`}
				fileName={DOWNLOAD_FILE_TYPE.DISPATCH_REFERENT_STOCK.outputFile}
				filters={filters}
			/>
		</>
	)
}

export default StockListDesktop
