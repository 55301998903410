import React, { useContext, useMemo, useState } from 'react'
import { Search } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { FormInput, FormSelect, SpringDataTable } from 'isotope-client'
import PageLayout from '../../../components/layout/PageLayout'
import Button from '../../../components/layout/buttons/Button'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { VaccinModel } from '../../admin/product/services/productModel'
import { DOWNLOAD_FILE_TYPE, EXIT_MODE, MOVEMENT_SENS, MOVEMENT_TYPE, SELECT_VALUES } from '../../../utils/constants'
import { RequestMovementHistorySearchModel } from './movementHistoryModel'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { VaccinationMouvementModel } from '../services/vaccinationModels'
import FieldDatetime from '../../../components/fields/FieldDatetime'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import ExportExcelButton from '../../../components/layout/buttons/ExportExcelButton'
import { DATE_FORMAT, DATE_FORMAT_IN_FORM, displayDate, formatDateInForm } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface FormValues extends RequestMovementHistorySearchModel {
	startDatetime?: string
	endDatetime?: string
}

const DELAY = 10

const MovementHistory: React.FC = () => {
	const history = useHistory()
	const intl = useIntl()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[
				dateAndTimeDecorator('startDate', 'startTime', 'startDatetime'),
				dateAndTimeDecorator('endDate', 'endTime', 'endDatetime')
			]
		, [isDatetimeSupported])
	const initRequest = {
		startDatetime: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		startDate: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		startTime: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.HOUR),
		endDatetime: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		endDate: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		endTime: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.HOUR)
	}

	const classes = useStyles()
	const { vaccins } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestMovementHistorySearchModel>({
		startDate: new Date(initRequest.startDatetime).toISOString(),
		endDate: new Date(initRequest.endDatetime).toISOString()
	})

	const getMovements = (formValues: FormValues) => {
		setFilters({
			idProduit: formValues.idProduit !== SELECT_VALUES.ALL ? formValues.idProduit : undefined,
			numBl: formValues.numBl,
			lot: formValues.lot,
			startDate: new Date(formValues.startDatetime || '').toISOString(),
			endDate: new Date(formValues.endDatetime || '').toISOString()
		})
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						idProduit: SELECT_VALUES.ALL,
						...initRequest
					}}
					onSubmit={getMovements}
					// @ts-ignore
					decorators={formDecorators}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item container xs={12}>
									<Grid item xs={12} md={5}>
										<Field
											name="idProduit"
											component={FormSelect}
											label={
												<FormattedMessage
													id="global.functionnal.product"
													defaultMessage="Produit"
													description="Product"
												/>
											}
											required
										>
											<MenuItem key="default" value={SELECT_VALUES.ALL}>
												<FormattedMessage
													id="select.all"
													defaultMessage="Tous"
													description="All"
												/>
											</MenuItem>
											{vaccins.map((vaccin: VaccinModel) => (
												<MenuItem
													key={vaccin.id}
													value={vaccin.id}
												>
													{vaccin.nomCommercial}
												</MenuItem>
											))}

										</Field>
									</Grid>
								</Grid>

								<Grid item xs={12} md={3}>
									<Field
										name="numBl"
										component={FormInput}
										label={
											<FormattedMessage
												id="dispatch.movementHistory.search.bl"
												defaultMessage="N° bon de livraison"
												description="Bl number"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Field
										name="lot"
										component={FormInput}
										label={
											<FormattedMessage
												id="dispatch.movementHistory.search.lot"
												defaultMessage="N° de lot"
												description="Batch number"
											/>
										}
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography>
										<FormattedMessage
											id="vaccination.movementHistory.search.movementDate"
											defaultMessage="Date du mouvement"
											description="Section label for datetime"
										/>
									</Typography>
								</Grid>
								<FieldDatetime
									datetimeName="startDatetime"
									dateName="startDate"
									timeName="startTime"
									dateLabel={
										<FormattedMessage
											id="vaccination.movementHistory.search.startDate"
											defaultMessage="Date de début"
											description="input label"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="vaccination.movementHistory.search.startTime"
											defaultMessage="Heure de début"
											description="input label"
										/>
									}
								/>
								<FieldDatetime
									datetimeName="endDatetime"
									dateName="endDate"
									timeName="endTime"
									dateLabel={
										<FormattedMessage
											id="vaccination.movementHistory.search.endDate"
											defaultMessage="Date de fin"
											description="input label"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="vaccination.movementHistory.search.endTime"
											defaultMessage="Heure de fin"
											description="input label"
										/>
									}
								/>
								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>
			<SpringDataTable
				apiUrl="/vaccination/mouvement"
				nom="movementList"
				headers={[
					{
						key: 'history.creationDate',
						name: <FormattedMessage
							id="vaccination.movementHistory.table.creationDate"
							defaultMessage="Date"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: VaccinationMouvementModel) => <span>{displayDate(moment(row.history.creationDate), DATE_FORMAT.DATETIME, isDateFormatEn)}</span>
					},
					{
						key: 'nomCommercialProduit',
						name: <FormattedMessage
							id="vaccination.movementHistory.table.product"
							defaultMessage="Produit"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'type',
						name: <FormattedMessage
							id="vaccination.movementHistory.table.type"
							defaultMessage="Type de mouvement"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: VaccinationMouvementModel) => <span>
                        <FormattedMessage
	                        id={`enum.movementType.${row.type}`}
	                        defaultMessage="Type de mouvement"
	                        description="Header column name"
                        />
                    </span>
					},
					{
						key: 'quantite',
						name: <FormattedMessage
							id="global.functionnal.quantity"
							defaultMessage="Quantité"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: VaccinationMouvementModel) => {
							let quantite = row.quantite
							let cond = row.typeCondProduit

							if (row.type === MOVEMENT_TYPE.SORTIE_POINT && row.vaccinationBl && row.vaccinationBl.typeConditionnement === EXIT_MODE.SERINGUE) {
								quantite = row.vaccinationBl?.seringueQte || 0
								cond = intl.formatMessage({ id: `enum.typeCondPoint.${EXIT_MODE.SERINGUE}` })
							}

							return <span>
                                <span>{`${row.sens === MOVEMENT_SENS.SORTIE ? '-' : ''}${quantite}`}</span>
                                <FormattedMessage
	                                id="global.functionnal.conditioningType"
	                                defaultMessage={row.typeCondProduit}
	                                description="Conditioning type"
	                                values={
		                                {
			                                count: quantite,
			                                conditioningType: cond
		                                }
	                                }
                                />
                            </span>
						}
					},
					{
						key: 'numeroBl',
						name: <FormattedMessage
							id="vaccination.movementHistory.table.numBl"
							defaultMessage="Numéro bon de livraison"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'lot',
						name: <FormattedMessage
							id="global.functionnal.batchNumber"
							defaultMessage="Numéro de lot"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'vaccinationBl.pointVacc.nom',
						name: <FormattedMessage
							id="global.functionnal.destination"
							defaultMessage="Destination"
							description="Header column name"
						/>,
						render: (row: VaccinationMouvementModel) => <>{row.vaccinationBl?.pointVacc.nom}</>,
						sortable: true
					},
					{
						key: 'visibility',
						name: '',
						render: (row: VaccinationMouvementModel) => {
							if (row.type === MOVEMENT_TYPE.DECHET || row.vaccinationBl) {
								return <IconButton onClick={() => {
									history.push(`/vaccination/${row.vaccinationBl ? `exits` : `scrappings`}/${row.id}`)
								}}>
									<VisibilityIcon />
								</IconButton>
							}
							return <></>
						}
					}
				]}
				filters={filters}
				noResultFragment={<NotFoundPage />}
			/>
			<ExportExcelButton
				url="/vaccination/mouvement/export"
				fileName={DOWNLOAD_FILE_TYPE.VACCINATION_MOUVEMENT.outputFile}
				filters={filters}
			/>
		</PageLayout>
	)
}

export default MovementHistory
