import React from 'react'

const SvgStock = () => (<svg xmlns="http://www.w3.org/2000/svg" width={68} height={68}>
  <g transform="translate(-214.585 -141.572)">
    <rect
        width={68}
        height={5}
        rx={2.5}
        transform="translate(214.585 200.572)"
        fill="#787878"
    />
    <rect
        width={6}
        height={68}
        rx={3}
        transform="translate(214.585 141.572)"
        fill="#787878"
    />
    <rect
        width={5}
        height={68}
        rx={2.5}
        transform="translate(277.585 141.572)"
        fill="#787878"
    />
    <rect
        width={68}
        height={5}
        rx={2.5}
        transform="translate(214.585 168.572)"
        fill="#787878"
    />
    <path
        d="M253.879 144.757h-27.778a1.121 1.121 0 00-1.121 1.121v19.385a1.121 1.121 0 001.121 1.121h27.778a1.121 1.121 0 001.121-1.121v-19.385a1.121 1.121 0 00-1.121-1.121zm-3.864 14.515h-2.69v2.663a.969.969 0 01-1.937 0v-2.663h-2.636a.969.969 0 010-1.937h2.636v-2.663a.969.969 0 011.937 0v2.663h2.69a.969.969 0 110 1.937zM271.499 176.053h-27.774a1.121 1.121 0 00-1.121 1.121v19.385a1.121 1.121 0 001.121 1.121h27.774a1.121 1.121 0 001.121-1.121v-19.385a1.121 1.121 0 00-1.121-1.121zm-3.864 14.515h-2.69v2.663a.969.969 0 01-1.937 0v-2.663h-2.636a.969.969 0 110-1.937h2.636v-2.663a.969.969 0 111.937 0v2.663h2.69a.969.969 0 110 1.937z"
        fill="#008bb2"
    />
  </g>
</svg>)

export default SvgStock
