import { fetchFactory } from 'isotope-client'
import { AddOrUpdateProcurementFormModel, DuplicateProcurementModel, FetchProcurementProductsModel, ProcurementListRequestModel } from './procurementModels'
import { postMultipart } from '../../../../utils/fetch'

export const fetchProcurementProducts = (values: FetchProcurementProductsModel) => fetchFactory(
    `/vaccination/approvisionnement/produits?idCentre=${values.idCentre}&debutSemaine=${values.debutSemaine}`
)

export const addProcurementForm = (values: AddOrUpdateProcurementFormModel) => fetchFactory('/vaccination/approvisionnement',
    {
        method: 'POST',
        body: JSON.stringify(values)
    }
)

export const updateProcurementForm = (values: AddOrUpdateProcurementFormModel) => fetchFactory('/vaccination/approvisionnement',
    {
        method: 'PUT',
        body: JSON.stringify(values)
    }
)

export const getProcurementByID = (id: string) => fetchFactory(`/vaccination/approvisionnement/${id}`)

export const deleteProcurementByID = (id: string) => fetchFactory(`/vaccination/approvisionnement/${id}`, {
    method: 'DELETE'
})

export const createReplenishmentFromProcurement = (values: ProcurementListRequestModel) => fetchFactory('/vaccination/approvisionnement/generation', {
    method: 'POST',
    body: JSON.stringify(values)
})

export const duplicateProcurement = (values: DuplicateProcurementModel) => fetchFactory('/vaccination/approvisionnement/multiples',
    {
        method: 'POST',
        body: JSON.stringify(values)
    }
)

export const importProcurement = (values: any) => postMultipart('/vaccination/approvisionnement/import', {
    method: 'POST',
    body: values
})
