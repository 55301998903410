import React from 'react'
import { FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-final-form'

interface FieldQuantityProps {
    name?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean
}

const FieldQuantity: React.FC<FieldQuantityProps> = (
    {
        name = 'quantity',
        disabled = false,
        className = '',
        required = false
    }) => {
    return (
        <Field
            name={name}
            component={FormInput}
            label={
                <FormattedMessage
                    id="dispatch.stock.quantity"
                    defaultMessage="Quantité"
                    description="Quantity of selected product"
                />
            }
            type="number"
            inputProps={{ min: 0 }}
            disabled={disabled}
            className={className}
            required={required}
        />
    )
}

export default FieldQuantity
