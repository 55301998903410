import React from 'react'
import { RequestStockFiltersModel, ResponseStockModel } from '../../../admin/stock/services/stockModel'
import { SCREEN_SIZE } from '../../../../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import StockListDesktop from './components/StockListDesktop'
import StockListMobile from './components/StockListMobile'

interface StockListProps {
	centerType: string
	filters: RequestStockFiltersModel,
	openDialog?: (row: ResponseStockModel) => void,
	showCenter?: boolean,
	showActions?: boolean
	isOpenDialog: boolean
}

const StockList: React.FC<StockListProps> = (
	{
		centerType,
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false,
		isOpenDialog
	}
) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const listProps = {
		centerType,
		filters,
		openDialog,
		showCenter,
		showActions
	}

	return <>
		{isLargeScreen ? <StockListDesktop {...listProps} /> : <StockListMobile {...listProps} isOpenDialog={isOpenDialog} />}
	</>
}

export default StockList
