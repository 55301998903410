import React from 'react'
import { FormattedMessage } from 'react-intl'

const ErrorMessageRequired: React.FC = () => {
    return (
        <FormattedMessage
            id="global.error.validation.required"
            defaultMessage='Requis'
            description='Error message of a value that needs to be filled'
        />
    )
}

export default ErrorMessageRequired
