import React from 'react'
import { Add } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from './Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(2),
        }
    })
)

interface ButtonCreateProps {
    type?: 'button' | 'reset' | 'submit' | undefined,
    isLoading?: boolean
}

const ButtonCreate: React.FC<ButtonCreateProps> = (props) => {
    const classes = useStyles()

    return (
        <Button
            className={classes.button}
            variant="contained"
            startIcon={<Add />}
            {...props}
        >
            <FormattedMessage
                id="button.create"
                defaultMessage="Créer"
                description="Submit button label"
            />
        </Button>
    )
}

export default ButtonCreate
