import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { ReplenishmentDialogDataModel } from '../../ReplenishmentModel'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import DialogReplenishmentBody from '../DialogReplenishmentBody'
import { REPLENISHMENT_STATUS } from '../../../../../utils/constants'
import moment from 'moment'
import { DATE_FORMAT, displayDate } from '../../../../../utils/dateUtils'
import { PhidemDataContext } from '../../../phidemData/PhidemDataContext'

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		},
		sectionTitle: {
			fontWeight: 500,
			marginBottom: 16
		}
	})
)

interface DialogReplenishmentCancelProps {
	isOpen: boolean
	handleClose: () => any
	onSubmit: (value: any) => any,
	replenishment: ReplenishmentDialogDataModel
}

const DialogReplenishmentCancel: React.FC<DialogReplenishmentCancelProps> = (
	{
		isOpen,
		handleClose,
		onSubmit,
		replenishment
	}) => {
	const classes = useStyles()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const {
		currentStatut,
		productCond,
		quantiteConfirmee,
		dateConfirmee
	} = replenishment

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (!formValues.comment) {
			errors.comment = <ErrorMessageRequired />
		}

		return errors
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={onSubmit}
			render={({ values, handleSubmit }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.replenishmentList.dialog.cancel.title"
							defaultMessage="Annuler la demande de réapprovisionnement"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.return"
								defaultMessage="Retour"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => handleSubmit(values)}>
							<FormattedMessage
								id="button.confirm"
								defaultMessage="Confirmer"
								description="Button label"
							/>
						</Button>
					]}
				>
					{currentStatut === REPLENISHMENT_STATUS.CONFIRME &&
						<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
							<FormattedMessage
								id="dispatch.confirm.dialog.sections.initial"
								defaultMessage="Demande initiale"
								description="Dialog content"
							/>
						</Typography>
					}
					<DialogReplenishmentBody replenishment={replenishment} />
					{currentStatut === REPLENISHMENT_STATUS.CONFIRME &&
						<>
							<Typography gutterBottom variant="h5" className={`${classes.inputContainer} ${classes.sectionTitle}`}>
								<FormattedMessage
									id="dispatch.confirm.dialog.sections.confirmed"
									defaultMessage="Demande confirmée"
									description="Dialog content"
								/>
							</Typography>

							<Typography gutterBottom>
								<FormattedMessage
									id="dispatch.replenishmentList.dialog.process.quantiteConfirmee"
									defaultMessage={`Quantité confirmée : ${quantiteConfirmee}`}
									description="Dialog content"
									values={{
										quantity: quantiteConfirmee,
										conditioningType: productCond
									}}
								/>
							</Typography>
							<Typography gutterBottom>
								<FormattedMessage
									id="dispatch.replenishmentList.dialog.process.dateConfirmee"
									defaultMessage={`Date confirmée : ${displayDate(moment(dateConfirmee), DATE_FORMAT.DATETIME, isDateFormatEn)}`}
									description="Dialog content"
									values={{
										date: displayDate(moment(dateConfirmee), DATE_FORMAT.DATETIME, isDateFormatEn)
									}}
								/>
							</Typography>
						</>
					}
					<Field
						name="comment"
						component={FormInput}
						className={classes.inputContainer}
						label={
							<FormattedMessage
								id="dispatch.processing.dialog.cancel.comment"
								defaultMessage="Commentaire d'annulation"
								description="Comment label input"
							/>
						}
					/>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentCancel
