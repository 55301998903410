import React from 'react'
import { FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-final-form'

const FieldUnitProduct: React.FC = () => {
    return (
        <Field
            name='unitProduct'
            component={FormInput}
            label={
                <FormattedMessage
                    id='dispatch.stock.unitProduct'
                    defaultMessage='Conditionnement'
                    description='Conditioning'
                />
            }
            disabled
        />
    )
}

export default FieldUnitProduct
