import React, { useContext, useState } from 'react'
import { RequestStockFiltersModel, ResponseStockModel } from '../../admin/stock/services/stockModel'
import StockForm, { StockListFormValues } from '../../common/stock/StockForm'
import { SELECT_VALUES } from '../../../utils/constants'
import PageLayout from '../../../components/layout/PageLayout'
import VaccinationStockList from '../../common/stock/VaccinationStockList'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import DialogEditStock from '../../admin/stock/DialogEditStock'

const StockDispatchAffiliatedVaccinationCenters = () => {
    const { user: { isAdminDispatch } } = useContext(PhidemDataContext)
    const [filters, setFilters] = useState<RequestStockFiltersModel>({
        idProduit: undefined,
        quantiteNonNulle: true,
        onlyRetour: false
    })
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<ResponseStockModel | undefined>(undefined)
    const openDialog = (row: ResponseStockModel) => {
        setSelectedRow(row)
        setIsOpenDialog(true)
    }
    const closeDialog = () => setIsOpenDialog(false)

    const getStocks = (formValues: StockListFormValues) => {
        setFilters({
            idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
            lot: formValues.batchNumber,
            quantiteNonNulle: !formValues.isVisibleOutOfStock,
            onlyRetour: formValues.onlyRetour
        })
    }

    return (
        <PageLayout>
            <StockForm onSubmit={getStocks} />
            <VaccinationStockList
                filters={filters}
                openDialog={openDialog}
                showActions={isAdminDispatch}
                isOpenDialog={isOpenDialog}
                showCenter
            />
            {selectedRow && <DialogEditStock
                isOpen={isOpenDialog}
                handleClose={closeDialog}
                selectedRow={selectedRow}
            />}
        </PageLayout>
    )
}

export default StockDispatchAffiliatedVaccinationCenters
