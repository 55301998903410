import React, { useContext, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import moment from 'moment'
import { injectToolbarData } from 'isotope-client'
import ProcurementForm from './components/ProcurementForm'
import { addProcurementForm } from './services/procurementApi'
import { ProcurementFormValues } from './services/procurementModels'
import { PhidemDataContext } from '../phidemData/PhidemDataContext'
import { CENTER_TYPE } from '../../../utils/constants'

interface ProcurementAddProps {
	snackError: (value: any) => void
}

const ProcurementAdd: React.FC<ProcurementAddProps> = (
	{
		snackError
	}) => {
	const { user: { selectedCenter } } = useContext(PhidemDataContext)
	const location = useLocation()
	const history = useHistory()
	// @ts-ignore
	const initialWeekRange = useMemo(() => location && location.state && location.state.weekRange ? location.state.weekRange : ({
		from: moment().startOf('isoWeek').hours(12).toDate(),
		to: moment().endOf('isoWeek').hours(12).toDate()
	}), [location])
	const isVaccination = selectedCenter && selectedCenter.type === CENTER_TYPE.VACCINATION

	const onSubmit = async (formValues: ProcurementFormValues) => {
		return addProcurementForm({
			debutSemaine: formValues.weekRange.from?.toISOString() || '',
			idCentre: formValues.idCentre || '',
			idProduit: formValues.idProduit || '',
			modeQte: formValues.modeQte,
			lundi: formValues.lundi,
			mardi: formValues.mardi,
			mercredi: formValues.mercredi,
			jeudi: formValues.jeudi,
			vendredi: formValues.vendredi,
			samedi: formValues.samedi,
			dimanche: formValues.dimanche,
			lundi1: formValues.lundi1,
			lundi2: formValues.lundi2,
			mardi1: formValues.mardi1,
			mardi2: formValues.mardi2,
			mercredi1: formValues.mercredi1,
			mercredi2: formValues.mercredi2,
			jeudi1: formValues.jeudi1,
			jeudi2: formValues.jeudi2,
			vendredi1: formValues.vendredi1,
			vendredi2: formValues.vendredi2,
			samedi1: formValues.samedi1,
			samedi2: formValues.samedi2,
			dimanche1: formValues.dimanche1,
			dimanche2: formValues.dimanche2
		})
			.then(() => history.push('/procurements'))
			.catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
	}

	return (
		<ProcurementForm
			initialValues={{
				weekRange: initialWeekRange,
				modeQte: selectedCenter.modeQte,
				lundi: 0,
				lundi1: 0,
				lundi2: 0,
				mardi: 0,
				mardi1: 0,
				mardi2: 0,
				mercredi: 0,
				mercredi1: 0,
				mercredi2: 0,
				jeudi: 0,
				jeudi1: 0,
				jeudi2: 0,
				vendredi: 0,
				vendredi1: 0,
				vendredi2: 0,
				samedi: 0,
				samedi1: 0,
				samedi2: 0,
				dimanche: 0,
				dimanche1: 0,
				dimanche2: 0,
				idCentre: isVaccination ? selectedCenter.id : undefined
			}}
			isCreationForm
			onSubmit={onSubmit}
			snackError={snackError}
			isVaccination={isVaccination}
			centerList={isVaccination ? [selectedCenter] : selectedCenter.centreEnfantsByType.vaccinations}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="procurement.title"
		defaultMessage="Plan d'approvisionnement"
		description="Page title"
	/>
})), injectSnackbarActions)(ProcurementAdd)
