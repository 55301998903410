// https://stackoverflow.com/a/10199306/8184729
export const isDateInputTypeSupported = (type: string) => {
    const input = document.createElement('input')
    input.setAttribute('type', type)

    const notADateValue = 'not-a-date' // check if invalid date is recognized
    input.setAttribute('value', notADateValue)

    return (input.value !== notADateValue)
}
