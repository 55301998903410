import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { FormCheckbox, FormInput, FormSelect, injectToolbarData } from 'isotope-client'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import Button from '../../../components/layout/buttons/Button'
import { postPreparationStock } from '../services/dispatchApi'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { CentreModel } from '../../common/phidemData/phidemDataModel'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../admin/container/services/containerModel'
import { TrackerModel } from '../../admin/tracker/services/trackerModel'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import { CENTER_TYPE, colors, SCREEN_SIZE } from '../../../utils/constants'
import DialogAddProduct from '../../common/dispatchAndReferent/exitStock/components/DialogAddProduct'
import Dialog from '../../../components/layout/dialog/Dialog'
import DialogQtyProcurementWarning from './components/DialogQtyProcurementWarning'
import DialogExitStockReplenishmentProcess from '../../common/replenishment/components/DialogExitStockReplenishmentProcess'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import ProductsTable from '../../../components/layout/productsTable/ProductsTable'
import FieldAutocomplete from '../../../components/fields/FieldAutocomplete'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../utils/dateUtils'
import { ExitStockProductValues } from '../../common/dispatchAndReferent/exitStock/services/exitStockModels'

const getInitialDates = () => {
	const currentDateTime = moment()
	return {
		sortie: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		exitDate: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		exitTime: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.HOUR)
	}
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-start'
		},
		table: {
			width: '100%'
		},
		tableHeader: {
			backgroundColor: colors.primary,
			color: colors.elementOnPrimary
		},
		tableBody: {
			color: colors.text
		},
		tableFooter: {
			color: colors.primary
		},
		tableAction: {
			display: 'flex',
			flexDirection: 'row',
			borderBottomWidth: 0
		}
	})
)

interface ProductToEditValues {
	produit: ExitStockProductValues
	index: number
}

export interface ExitStockValues {
	produits: ExitStockProductValues[]
	isTransfert: boolean
	destination: number
	commentaireDestination?: string
	idTracker?: number
	numTracker?: string
	idTransporteur?: string
	idContenant?: number
	numContenant?: string
	suiviDesactive: boolean
	// to update process status (2 call in one)
	replenishmentID?: string
	comment?: string
	forceValidation: boolean
}

interface ExitStockProps {
	snackError: (value: any) => void,
}

/* REMINDER: Snackbar doesn't show up when redirecting */
const ExitStock: React.FC<ExitStockProps> = ({
	                                             snackError
                                             }) => {


	const [productToEdit, setProductToEdit] = React.useState<ProductToEditValues | undefined>(undefined)
	const [replenishmentID, setReplenishmentID] = React.useState<string>()

	// Commentaire à conserver pour l'envoyer lors du submit après éventuel warning de stock
	const [commentProcessReplenishment, setCommentProcessReplenishment] = React.useState<string | undefined>(undefined)

	const [isOpenDialogWarning, setIsOpenDialogWarning] = React.useState<boolean>(false)
	const [isOpenDialogAddProduct, setIsOpenDialogAddProduct] = React.useState<boolean>(false)
	const [isOpenDialogProcurementWarning, setIsOpenDialogProcurementWarning] = React.useState<boolean>(false)
	const [isOpenDialogReplenishmentProcess, setIsOpenDialogReplenishmentProcess] = React.useState<boolean>(false)

	const { application: { configuration }, user: { selectedCenter }, referentialData } = useContext(PhidemDataContext)
	const classes = useStyles()
	const intl = useIntl()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const history = useHistory()
	const location = useLocation()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('exitDate', 'exitTime', 'sortie'),
				dateAndTimeDecorator('usageLimitDate', 'usageLimitTime', 'usageLimitDatetime')]
		, [isDatetimeSupported])
	const [submitLoading, setSubmitLoading] = React.useState<boolean>(false)

	const openDialogWarning = () => setIsOpenDialogWarning(true)
	const closeDialogWarning = () => setIsOpenDialogWarning(false)

	const openDialogProcurementWarning = () => setIsOpenDialogProcurementWarning(true)
	const closeDialogProcurementWarning = () => setIsOpenDialogProcurementWarning(false)

	const openDialogReplenishmentProcess = () => setIsOpenDialogReplenishmentProcess(true)
	const closeDialogReplenishmentProcess = () => setIsOpenDialogReplenishmentProcess(false)

	const openDialogAddProduct = () => setIsOpenDialogAddProduct(true)
	const closeDialogAddProduct = () => {
		setProductToEdit(undefined)
		setIsOpenDialogAddProduct(false)
	}

	useEffect(() => {
		// @ts-ignore
		if (location?.state?.replenishmentID) {
			// Gestion de la redirection à partir des demandes
			// -> On ouvre directement la popup
			// -> On set la demande
			// @ts-ignore
			setReplenishmentID(location.state.replenishmentID)
			setIsOpenDialogAddProduct(true)
		}
	}, [location])

	const submit = async (values: ExitStockValues) => {
		setSubmitLoading(true)
		return postPreparationStock({
			produits: values.produits.map(produit => ({
				idProduit: produit.idProduit || '',
				typeProduit: produit.typeProduit || '',
				quantite: produit.quantite || 0,
				idStock: produit.idStock || '',
				sortie: produit.sortie ? new Date(produit.sortie || '').toISOString() : '',
				refExterne: produit.refExterne,
				isRetour: produit.isReturnSelection
			})),
			transfert: values.isTransfert,
			destination: values.isTransfert ? undefined : values.destination,
			commentaireDestination: values.commentaireDestination,
			idTracker: values.idTracker,
			numTracker: values.numTracker,
			idTransporteur: values.idTransporteur,
			idContenant: values.idContenant,
			numContenant: values.numContenant,
			suiviDesactive: values.suiviDesactive,
			// to update process status (2 call in one)
			replenishmentId: values?.replenishmentID,
			commentaire: values?.comment,
			forceValidation: values.forceValidation
		})
			.then((response: any) => {
				history.push(`/dispatch/exits/${response.id}`)
			})
			.catch((e: any) => {
				if (e && e.bodyError) {
					// Gestion du retour de warning pour allocation
					if (e.bodyError.globalErrors && e.bodyError.globalErrors.length > 0 && e.bodyError.globalErrors[0].code === 'quantitePrevision') {
						openDialogProcurementWarning()
					} else if (e.bodyError.fieldErrors && e.bodyError.fieldErrors.length > 0) {
						const erreursProduits = e.bodyError.fieldErrors.map((err: any) => {
							const produit = values.produits[err.code]
							return ` - ${produit.nomCommercial} (ligne ${Number(err.code) + 1})`
						})
						// Affichage de l'erreur de quantité
						snackError(<>
								{intl.formatMessage({ id: e.bodyError.fieldErrors[0].defaultMessage, defaultMessage: 'Erreur de quantité', description: 'Error message' })}
								<br />
								{erreursProduits.map((err: string) => <>{err}<br /></>)}
							</>
						)
					}
				} else {
					snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
				}
			})
			.finally(() => setSubmitLoading(false))
	}

	const onValidate = (values: ExitStockValues) => {
		const errors: any = {}

		if (!values.destination && !values.isTransfert) {
			errors.destination = <ErrorMessageRequired />
		}
		if (!!values.numContenant && !values.idContenant) {
			errors.idContenant = <ErrorMessageRequired />
		}
		if (!!values.numTracker && !values.idTracker) {
			errors.idTracker = <ErrorMessageRequired />
		}

		return errors
	}

	const convertProductToTable = (products: ExitStockProductValues[]) => {
		return products.map((product: ExitStockProductValues) => ({
			nom: product.nomCommercial,
			unit: product.unitProduct,
			lot: product.lot,
			reference: product.refExterne,
			peremption: product.peremption,
			peremptionType: product.peremptionType,
			quantite: product.quantite,
			sortie: product.sortie,
			usageLimit: product.usageLimitDate,
			isRetour: product.isReturnSelection,
			transfertType: product.transfertType,
			transfertDate: product.transfertDate
		}))
	}

	const initValues = useMemo(() => ({
		// @ts-ignore
		produits: [],
		isTransfert: false,
		// @ts-ignore
		destination: location?.state?.destination,
		// @ts-ignore
		replenishmentID: location?.state?.replenishmentID,
		...getInitialDates(),
		suiviDesactive: false,
		forceValidation: false,
		// @ts-ignore
		idTransporteur: location?.state?.carrier
	}), [location])

	// @ts-ignore
	const commentFromReplenishment = location?.state?.commentFromReplenishment

	return (
		<Form
			initialValues={initValues}
			onSubmit={replenishmentID ? openDialogReplenishmentProcess : submit}
			validate={onValidate}
			// @ts-ignore
			decorators={formDecorators}
			render={({ handleSubmit, submitting, form, values }) => {
				const { produits } = values

				return (
					<PageFormContainer
						onSubmit={handleSubmit}
					>
						{commentFromReplenishment && <>
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
									<FormattedMessage
										id="dispatch.exitStock.formSection.replenishment"
										defaultMessage="Informations sur la demande"
										description="Form section title"
									/>
								</Typography>
							</Grid>
							<Grid item xs={12} md={10}>
								<DisplayLineData
									label={
										<FormattedMessage
											id="dispatch.exitStock.replenishmentComment"
											defaultMessage="Commentaire du centre de vaccination"
											description="Replenishment comment"
										/>}
									data={commentFromReplenishment}
									multiline
								/>
							</Grid>
						</>}

						<ProductsTable
							produits={convertProductToTable(produits)}
							canEdit
							handleEdit={(index) => {
								setProductToEdit({ produit: values.produits[index], index })
								openDialogAddProduct()
							}}
							handleDelete={(index) => {
								const newProduits = [...values.produits]
								if (index >= 0 && index < newProduits.length) {
									newProduits.splice(index, 1)
								}
								form.change('produits', newProduits)
							}}
							handleAdd={openDialogAddProduct}
						/>

						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="common.exitStock.formSection.shipment"
									defaultMessage="Expédition"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						{configuration.transfertPreparation && <Grid item container>
							<Grid item xs={12} md={5}>
								<Field
									name="isTransfert"
									component={FormCheckbox}
									label={
										<FormattedMessage
											id="dispatch.enterStock.isTranfert"
											defaultMessage="Transfert de stock"
											description="Checkbox description"
										/>
									}
								/>
								<OnChange name="isTransfert">{() => form.change('destination', undefined)}</OnChange>
							</Grid>
						</Grid>}

						{!values.isTransfert && <Grid item xs={12} md={5}>
							<Field
								name="destination"
								label={<FormattedMessage
									id="global.functionnal.destination"
									defaultMessage="Destination"
									description="Destination to select"
								/>}
								component={FieldAutocomplete}
								options={selectedCenter.centreEnfantsByType.vaccinations.filter((centre: CentreModel) => centre.type !== CENTER_TYPE.VACCINATION_POINT).map((centre: CentreModel) => ({
									label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
									value: centre.id
								}))}
								required={!values.isTransfert}
							/>
							<OnChange name="destination">{(value) => {
								const selectedVacc = selectedCenter.centreEnfantsByType.vaccinations.find((centre: CentreModel) => centre.id === value)
								if (!!selectedVacc) {
									form.change('suiviDesactive', selectedVacc.desactivationSuivi)
									form.change('idTransporteur', selectedVacc.idTransporteur)
								}
							}}</OnChange>
						</Grid>}
						<Grid item xs={12} md={5}>
							<Field
								name="idTransporteur"
								component={FormSelect}
								label={
									<FormattedMessage
										id="global.functionnal.carrier"
										defaultMessage="Transporteur"
										description="Product carrier"
									/>
								}
							>
								<MenuItem key={0} value={undefined}><FormattedMessage
									id="select.none"
									defaultMessage="Aucun"
									description="No option"
								/></MenuItem>
								{referentialData.carriers.map((carrier: CarrierModel) => (
									<MenuItem key={carrier.id} value={carrier.id}>{carrier.nom}</MenuItem>
								))}
							</Field>
						</Grid>
						<Grid item xs={12} md={10}>
							<Field
								name="commentaireDestination"
								component={FormInput}
								label={
									<FormattedMessage
										id="common.exitStock.form.destinationComment"
										defaultMessage="Informations complémentaires"
										description="Destination comment input"
									/>
								}
								multiline
							/>
						</Grid>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={4}>
								<Field
									name="idContenant"
									component={FormSelect}
									label={
										<FormattedMessage
											id="common.exitStock.form.container"
											defaultMessage="Contenant"
											description="Product container"
										/>
									}
								>
									{referentialData.containers.map((container: ContainerModel) => (
										<MenuItem key={container.id} value={container.id}>{container.nom}</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									name="numContenant"
									component={FormInput}
									label={
										<FormattedMessage
											id="common.exitStock.form.containerNumber"
											defaultMessage="Nᵒ contenant"
											description="Container number"
										/>
									}
								/>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={4}>
								<Field
									name="idTracker"
									component={FormSelect}
									label={
										<FormattedMessage
											id="common.exitStock.form.tracker"
											defaultMessage="Trackeur température"
											description="Tracking temperature system"
										/>
									}
								>
									{referentialData.trackers.map((tracker: TrackerModel) => (
										<MenuItem key={tracker.id} value={tracker.id}>{tracker.nom}</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									name="numTracker"
									component={FormInput}
									label={
										<FormattedMessage
											id="common.exitStock.form.trackerNumber"
											defaultMessage="Trackeur №"
											description="Tracking temperature system ID"
										/>
									}
								/>
							</Grid>
						</Grid>
						{!values.isTransfert && <Grid item xs={12}>
							<Field
								name="suiviDesactive"
								component={FormCheckbox}
								label={
									<FormattedMessage
										id="dispatch.exitStock.disableFollowUp"
										defaultMessage="Désactiver le suivi"
										description="Checkbox description"
									/>
								}
								onClick={() => {
									// Lorsque l'on coche, on affiche le message
									if (!values.suiviDesactive) {
										openDialogWarning()
									}
								}}
							/>
						</Grid>}
						<div className={classes.submitButtonRoot}>
							<Button
								variant="contained"
								isLoading={submitting || submitLoading}
								onClick={handleSubmit}
								disabled={!values.produits || values.produits.length === 0}
							>
								<FormattedMessage
									id="button.validate"
									defaultMessage="Valider"
									description="Message on form submission button"
								/>
							</Button>
						</div>
						<Dialog
							title={
								<FormattedMessage
									id="dispatch.exitStock.dialogTitle"
									defaultMessage="Suivi désactivé"
									description="Dialog title"
								/>
							}
							isOpen={isOpenDialogWarning}
							handleClose={closeDialogWarning}
							buttons={[
								<Button autoFocus onClick={closeDialogWarning} color="primary" key={0}>
									<FormattedMessage
										id="dispatch.exitStock.dialogButtonLabel"
										defaultMessage="J'ai compris"
										description="Button label"
									/>
								</Button>
							]}
						>
							<Typography gutterBottom>
								<FormattedMessage
									id="dispatch.exitStock.dialogDescription"
									defaultMessage="Dialog description"
									description="Dialog description"
								/>
							</Typography>
						</Dialog>

						{isOpenDialogReplenishmentProcess &&
							<DialogExitStockReplenishmentProcess
								isOpen={isOpenDialogReplenishmentProcess}
								handleClose={closeDialogReplenishmentProcess}
								onSubmit={submit}
								data={values}
								replenishmentID={replenishmentID || ''}
								handleComment={setCommentProcessReplenishment}
								isLoading={submitLoading}
							/>}

						<DialogQtyProcurementWarning
							isOpen={isOpenDialogProcurementWarning}
							handleClose={closeDialogProcurementWarning}
							onConfirm={() => submit({
								...values,
								forceValidation: true,
								comment: commentProcessReplenishment
							})}
							loading={submitLoading}
						/>

						{isOpenDialogAddProduct && <DialogAddProduct
							isOpen={isOpenDialogAddProduct}
							centerType={CENTER_TYPE.DISPATCH}
							handleClose={closeDialogAddProduct}
							onConfirm={(produit, index) => {
								const newProduits = [...values.produits]
								if (index !== undefined && index >= 0 && index < newProduits.length) {
									newProduits.splice(index, 1, produit)
								} else {
									newProduits.push(produit)
								}
								form.change('produits', newProduits)
								closeDialogAddProduct()
							}}
							value={productToEdit && productToEdit.produit}
							index={productToEdit && productToEdit.index}
						/>}

					</PageFormContainer>
				)
			}}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="common.exitStock.pageTitle"
		defaultMessage="Nouvelle préparation"
		description="Page title"
	/>
})), injectSnackbarActions)(ExitStock)
