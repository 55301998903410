import { fetchFactory } from 'isotope-client'
import { RequestDeclarationOfUseFormModel, RequestEntryFormModel, RequestExitFridgeFormModel, RequestNeedsFormModel, RequestScrappingFormModel } from './vaccinationModels'

export const getBlList = () => fetchFactory('/dispatch/preparation/vaccination')

export const getAvailableBatchNumberListByProductID = (productID: string) => fetchFactory(`/vaccination/available-lot?idProduit=${productID}`)

export const getBatchNumberListByProductID = (productID: string) => fetchFactory(`/vaccination/lot?idProduit=${productID}`)

export const postEntryStock = (values: RequestEntryFormModel) => fetchFactory(`/vaccination/entree-stock`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const postExitFridge = (values: RequestExitFridgeFormModel) => fetchFactory(`/vaccination/sortieFrigo`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const getMovementById = (idMovement: string) => fetchFactory(`/vaccination/sortieFrigo/${idMovement}`)

export const postDeclarationOfUse = (values: RequestDeclarationOfUseFormModel) => fetchFactory(`/vaccination/utilisation`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const postScrapping = (values: RequestScrappingFormModel) => fetchFactory(`/vaccination/dechet`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const postNeeds = (values: RequestNeedsFormModel) => fetchFactory(`/vaccination/besoin`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const getDashboardProducts = () => fetchFactory('/vaccination/tdb')

export const getDechetByID = (idDechet: string) => fetchFactory(`/vaccination/dechet/${idDechet}`)

export const getAdministrationsByWeek = (values: any) => fetchFactory('/vaccination/utilisation/semaine', {
        method: 'POST',
        body: JSON.stringify(values)
    }
)

export const getSpecificAdministration = (values: any) => fetchFactory('/vaccination/utilisation/specific-jour', {
        method: 'POST',
        body: JSON.stringify(values)
    }
)
