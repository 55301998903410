import { fetchFactory } from 'isotope-client'
import { EnterStockWithBlFormModel, EnterStockWithoutBlFormModel } from './enterStockModels'

export const postEntryStock = (prefixCenter: string, values: EnterStockWithoutBlFormModel) => fetchFactory(`/${prefixCenter}/entree-stock`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const postEntryStockWithBl = (prefixCenter: string, values: EnterStockWithBlFormModel) => fetchFactory(`/${prefixCenter}/entree-stock-with-bl`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getReferentBlList = () => fetchFactory('/referent/preparation/children')
