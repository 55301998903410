import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Search from '@material-ui/icons/Search'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../utils/constants'

const useStyles = makeStyles(() =>
    ({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            alignItems: 'center',
            color: colors.notFound,
        },
        icon: {
            display: 'block',
            width: '3em',
            height: '3em',
            padding: '20px 0px',
            color: colors.notFound
        },
        message: {
            fontSize: '1em',
            whiteSpace: 'break-spaces'
        }
    })
)

type EmptyResultProps = {
    message?: string
}

const EmptyResult = ({ message = 'global.errors.notFound' }: EmptyResultProps) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <Search className={classes.icon} />
            <span className={classes.message}>
				<FormattedMessage
                    id={message}
                    defaultMessage="Aucun résultat trouvé"
                    description="Empty result message"
                />
			</span>
        </div>
    )
}

export default EmptyResult
