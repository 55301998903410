import React from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * Mapper des errors serveur pour final form
 * @param errors
 */
const errorsMapper = (errors: any) => {
    if (errors && errors.bodyError && errors.bodyError.fieldErrors && errors.bodyError.fieldErrors.length > 0) {
        return {
            errors: errors.bodyError.fieldErrors.reduce((acc: any, curr: any) => ({
                ...acc,
                [curr.field]: <FormattedMessage
                    id={curr.defaultMessage}
                    defaultMessage="Erreur sur le champ"
                    description="Form field error"
                />
            }), {}),
            displayFields: true
        }
    }
    return { errors, displayFields: false }
}

export default errorsMapper
