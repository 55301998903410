import React from 'react'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { FormInput } from 'isotope-client'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import Loader from '../../../../components/layout/Loader'
import { TrackerModel, RequestCreateOrUpdateTrackerFormModel } from '../services/trackerModel'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import { SCREEN_SIZE } from '../../../../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRoot: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        button: {
            margin: theme.spacing(2),
        },
        rowContainer: {
            margin: 0
        }
    }),
)

interface TrackerFormProps {
    isLoading?: boolean,
    initialValues: RequestCreateOrUpdateTrackerFormModel | undefined,
    onSubmit: (event: any) => void,
    isCreationForm: boolean,
    updateTrackerStatus?: () => void
}

const TrackerForm: React.FC<TrackerFormProps> = (
    {
        isLoading = false,
        initialValues,
        onSubmit,
        isCreationForm,
        updateTrackerStatus
    }) => {
    const classes = useStyles()
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)

    const onValidate = (formValues: TrackerModel) => {
        const errors: any = {}

        if (!formValues.fabricant) {
            errors.fabricant = <ErrorMessageRequired />
        }
        if (!formValues.nom) {
            errors.nom = <ErrorMessageRequired />
        }
        if (!formValues.type1) {
            errors.type1 = <ErrorMessageRequired />
        }
        if (!formValues.type2) {
            errors.type2 = <ErrorMessageRequired />
        }
        if (!formValues.minTemperature && formValues.minTemperature !== 0) {
            errors.minTemperature = <ErrorMessageRequired />
        }
        if (!formValues.maxTemperature && formValues.maxTemperature !== 0) {
            errors.maxTemperature = <ErrorMessageRequired />
        }

        return errors
    }

    return (
        <>
            {(isLoading)
                ?
                <Loader />
                :
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={onValidate}
                    render={({ handleSubmit, submitting }) => {

                        return (
                            <PageFormContainer onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Typography variant={isLargeScreen ? 'h1' : 'h3'}>
                                        <FormattedMessage
                                            id="tracker.formSection.information"
                                            defaultMessage="Informations du tracker"
                                            description="Form section title"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="nom"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.nom"
                                                    defaultMessage="Nom"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="fabricant"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.fabricant"
                                                    defaultMessage="Fabricant"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <Field
                                            name="type1"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.type1"
                                                    defaultMessage="Type 1"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name="type2"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.type2"
                                                    defaultMessage="Type 2"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={isLargeScreen ? 'h1' : 'h3'}>
                                        <FormattedMessage
                                            id="tracker.formSection.temperatureLimit"
                                            defaultMessage="Limites de température"
                                            description="Form section title"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={3}>
                                        <Field
                                            name="minTemperature"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.minTemperature"
                                                    defaultMessage="Température minimum"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name="maxTemperature"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="tracker.maxTemperature"
                                                    defaultMessage="Température maximum"
                                                    description="form input"
                                                />
                                            }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <div
                                    className={classes.buttonRoot}
                                    style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
                                >
                                    {isCreationForm
                                        ?
                                        <ButtonCreate
                                            type="submit"
                                            isLoading={submitting}
                                        />
                                        :
                                        <ButtonSave
                                            type="submit"
                                            isLoading={submitting}
                                        />
                                    }
                                    <ButtonActivate actif={initialValues?.actif} handleStatus={updateTrackerStatus} />
                                </div>
                            </PageFormContainer>
                        )
                    }}
                />
            }
        </>
    )
}

export default TrackerForm
