import React from 'react'

const SvgScrapping = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={58.664}
    height={90.127}
>
  <ellipse
      cx={29.332}
      cy={1.842}
      rx={29.332}
      ry={1.842}
      transform="translate(0 86.443)"
      fill="#dbdbdb"
  />
  <circle
      cx={1.882}
      cy={1.882}
      r={1.882}
      transform="rotate(-71.216 33.274 1.792)"
      fill="#dcb02d"
  />
  <path
      d="M21.562 32.37a1.9 1.9 0 00.2.323l3.308-1.629a1.872 1.872 0 00-.133-.356 1.894 1.894 0 00-.2-.323l-3.309 1.629a1.888 1.888 0 00.134.356z"
      fill="#dbdbdb"
  />
  <path
      d="M35.138 36.4l-6.08-5.5a.671.671 0 01-.047-.947l9.606-10.62a.671.671 0 01.947-.047l6.08 5.5a.67.67 0 01.047.947l-9.606 10.62a.671.671 0 01-.947.047z"
      fill="#e0e0e0"
  />
  <path
      d="M32.472 33.168a.1.1 0 01-.007-.142l9.55-10.559a.1.1 0 01.142-.007.1.1 0 01.007.142l-9.55 10.559a.1.1 0 01-.142.007z"
      fill="#d6d6d6"
  />
  <path
      d="M42.157 22.46a.1.1 0 00-.027-.016.1.1 0 01-.012.117l-9.55 10.559a.1.1 0 01-.115.023.1.1 0 00.161.018l9.55-10.559a.1.1 0 00-.007-.142z"
      fill="#d6d6d6"
  />
  <circle
      cx={1.228}
      cy={1.228}
      r={1.228}
      transform="rotate(-29.088 72.858 -43.33)"
      fill="#c7c7c7"
  />
  <circle
      cx={1.228}
      cy={1.228}
      r={1.228}
      transform="rotate(-42.7 53.762 -23.413)"
      fill="#dc3722"
  />
  <path
      d="M31.52 31.498a1.233 1.233 0 00.244.044l.66-2.314a1.235 1.235 0 00-.475-.135l-.66 2.314a1.228 1.228 0 00.231.091z"
      fill="#dbdbdb"
  />
  <path
      d="M30.902 29.989h-.018a.067.067 0 01-.047-.083 1.16 1.16 0 011.4-.668.067.067 0 11-.035.13 1.024 1.024 0 00-1.239.574.067.067 0 01-.061.047z"
      fill="#d6d6d6"
  />
  <g>
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-68.895 37.105 -9.721)"
        fill="#c7c7c7"
    />
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-29.088 68.562 -49.4)"
        fill="#dc3722"
    />
    <path
        d="M33.996 28.758a1.242 1.242 0 00.244.044l.659-2.314a1.236 1.236 0 00-.475-.135l-.659 2.314a1.231 1.231 0 00.231.091z"
        fill="#dbdbdb"
    />
    <path
        d="M33.38 27.25h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.067.067 0 11-.035.13 1.024 1.024 0 00-1.239.573.068.068 0 01-.061.048z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-14.144 114.675 -130.593)"
        fill="#c7c7c7"
    />
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-59.8 39.562 -17.905)"
        fill="#dc3722"
    />
    <path
        d="M36.475 26.019a1.218 1.218 0 00.244.044l.66-2.314a1.238 1.238 0 00-.475-.135l-.66 2.314a1.235 1.235 0 00.231.091z"
        fill="#dbdbdb"
    />
    <path
        d="M35.857 24.512h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.067.067 0 01-.035.13 1.023 1.023 0 00-1.239.574.068.068 0 01-.061.047z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-67.607 35.964 -16.752)"
        fill="#c7c7c7"
    />
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-14.07 104.39 -142.07)"
        fill="#dc3722"
    />
    <path
        d="M38.951 23.28a1.237 1.237 0 00.244.044l.66-2.314a1.244 1.244 0 00-.475-.135l-.659 2.314a1.244 1.244 0 00.23.091z"
        fill="#dbdbdb"
    />
    <path
        d="M38.335 21.772h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.068.068 0 11-.035.13 1.023 1.023 0 00-1.239.573.068.068 0 01-.061.048z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-36.741 67.04 -34.063)"
        fill="#c7c7c7"
    />
    <circle
        cx={1.228}
        cy={1.228}
        r={1.228}
        transform="rotate(-29.088 80.427 -48.403)"
        fill="#dc3722"
    />
    <path
        d="M35.009 34.651a1.234 1.234 0 00.244.044l.66-2.314a1.237 1.237 0 00-.475-.135l-.66 2.314a1.228 1.228 0 00.231.091z"
        fill="#dbdbdb"
    />
    <path
        d="M34.391 33.144h-.018a.067.067 0 01-.047-.083 1.16 1.16 0 011.4-.668.068.068 0 01-.035.131 1.024 1.024 0 00-1.239.573.067.067 0 01-.061.047z"
        fill="#d6d6d6"
    />
    <g>
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-29.07 76.663 -54.651)"
          fill="#c7c7c7"
      />
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-68.895 41.032 -10.695)"
          fill="#dc3722"
      />
      <path
          d="M37.486 31.913a1.234 1.234 0 00.244.044l.66-2.314a1.234 1.234 0 00-.475-.135l-.66 2.314a1.236 1.236 0 00.231.091z"
          fill="#dbdbdb"
      />
      <path
          d="M36.869 30.405h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.067.067 0 11-.035.13 1.024 1.024 0 00-1.239.574.068.068 0 01-.061.047z"
          fill="#d6d6d6"
      />
    </g>
    <g>
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-36.741 61.27 -44.26)"
          fill="#c7c7c7"
      />
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-29.088 72.349 -60.689)"
          fill="#dc3722"
      />
      <path
          d="M39.963 29.174a1.226 1.226 0 00.244.044l.66-2.314a1.229 1.229 0 00-.231-.092 1.261 1.261 0 00-.244-.044l-.66 2.314a1.244 1.244 0 00.231.092z"
          fill="#dbdbdb"
      />
      <path
          d="M39.345 27.668h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.068.068 0 01-.035.13 1.023 1.023 0 00-1.239.573.068.068 0 01-.061.048z"
          fill="#d6d6d6"
      />
    </g>
    <g>
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-29.088 68.564 -66.906)"
          fill="#c7c7c7"
      />
      <circle
          cx={1.228}
          cy={1.228}
          r={1.228}
          transform="rotate(-68.9 39.513 -17.043)"
          fill="#dc3722"
      />
      <path
          d="M42.44 26.435a1.225 1.225 0 00.244.044l.66-2.314a1.24 1.24 0 00-.475-.135l-.66 2.314a1.232 1.232 0 00.231.091z"
          fill="#dbdbdb"
      />
      <path
          d="M41.824 24.927h-.018a.068.068 0 01-.047-.083 1.16 1.16 0 011.4-.668.068.068 0 01-.035.13 1.024 1.024 0 00-1.239.573.067.067 0 01-.061.048z"
          fill="#d6d6d6"
      />
    </g>
  </g>
  <g>
    <path fill="#4a4a4a" d="M6.816 80.995h42.931v1.311H6.816z" />
    <path
        d="M42.584 87.864H15.2a2.679 2.679 0 01-2.612-2.478L7.82 36.906h42.145l-4.768 48.48a2.679 2.679 0 01-2.613 2.478z"
        fill="#3bbaf7"
    />
    <rect
        width={47.728}
        height={5.13}
        rx={2.565}
        fill="#3bbaf7"
        transform="translate(5.027 31.776)"
    />
    <path
        d="M7.466 36.906h42.849a2.5 2.5 0 002.439-2.565H5.027a2.5 2.5 0 002.439 2.565z"
        fill="#dbdbdb"
    />
    <path
        d="M14.861 36.906h2.277v4.676a1.138 1.138 0 01-1.139 1.138 1.138 1.138 0 01-1.138-1.138v-4.676zM27.753 36.906h2.277v4.676a1.138 1.138 0 01-1.139 1.138 1.138 1.138 0 01-1.138-1.138v-4.676zM40.644 36.906h2.277v4.676a1.138 1.138 0 01-1.139 1.138 1.138 1.138 0 01-1.138-1.138v-4.676z"
        fill="#b3b3b3"
    />
    <ellipse
        cx={10.611}
        cy={11.158}
        rx={10.611}
        ry={11.158}
        fill="#b3b3b3"
        transform="translate(18.28 48.406)"
    />
    <path
        d="M32.385 57.454a.422.422 0 01-.352-.188l-2.662-3.995a.526.526 0 00-.962 0l-2.662 3.995a.423.423 0 01-.7-.469l2.662-3.995a1.362 1.362 0 012.369 0l2.662 3.995a.423.423 0 01-.351.657z"
        fill="#fff"
    />
    <path
        d="M32.438 57.489a.422.422 0 01-.141-.025l-1.291-.457a.424.424 0 01.282-.8l.588.208-.124-.614a.423.423 0 01.828-.167l.271 1.345a.423.423 0 01-.414.506zM28.236 65.014h-.039l-4.621-.427a1.449 1.449 0 01-1.141-.691 1.54 1.54 0 01-.039-1.417l1.959-4.422a.423.423 0 11.773.342l-1.959 4.422a.711.711 0 00-.005.647.607.607 0 00.49.277l4.621.427a.423.423 0 01-.039.843z"
        fill="#fff"
    />
    <path
        d="M25.01 59.983a.422.422 0 01-.415-.343l-.124-.647-.411.39a.423.423 0 01-.581-.614l.972-.919a.423.423 0 01.706.228l.269 1.4a.422.422 0 01-.416.5z"
        fill="#fff"
    />
    <g>
      <path
          d="M29.546 65.014a.423.423 0 01-.038-.844l4.621-.427a.608.608 0 00.49-.277.711.711 0 00-.005-.647l-1.959-4.422a.423.423 0 11.773-.342l1.959 4.422a1.541 1.541 0 01-.039 1.417 1.449 1.449 0 01-1.141.691l-4.621.427z"
          fill="#fff"
      />
      <path
          d="M30.737 65.47a.426.426 0 01-.137-.023l-1.243-.425a.423.423 0 01-.15-.71l1.022-.947a.423.423 0 01.574.62l-.5.466.574.2a.423.423 0 01-.137.823z"
          fill="#fff"
      />
    </g>
    <g
        style={{
          mixBlendMode: "multiply",
          isolation: "isolate",
        }}
    >
      <path
          d="M45.195 85.386l4.768-48.48-10.868 50.958h3.488a2.68 2.68 0 002.612-2.478z"
          fill="#dbdbdb"
      />
    </g>
    <g
        style={{
          mixBlendMode: "multiply",
          isolation: "isolate",
        }}
    >
      <path
          d="M12.587 85.386l-4.768-48.48 10.868 50.958h-3.488a2.679 2.679 0 01-2.612-2.478z"
          fill="#dbdbdb"
      />
    </g>
    <g transform="translate(6.247 75.438)">
      <rect width={4.885} height={12.426} rx={2.442} fill="#4a4a4a" />
      <rect
          width={4.885}
          height={12.426}
          rx={2.442}
          fill="#4a4a4a"
          transform="translate(40.404)"
      />
    </g>
  </g>
  <g>
    <path
        d="M18.515 12.762l-3.07-6.567a.592.592 0 01.286-.788L27.195.051a.593.593 0 01.788.286L31.049 6.9a.593.593 0 01-.286.788l-11.464 5.356a.593.593 0 01-.784-.282z"
        fill="#d6d6d6"
    />
    <path
        d="M17.453 9.215a.089.089 0 01.043-.118l11.4-5.325a.089.089 0 01.118.043.089.089 0 01-.043.118l-11.4 5.325a.089.089 0 01-.118-.043z"
        fill="#d6d6d6"
    />
    <path
        d="M29.013 3.814a.086.086 0 00-.017-.023.088.088 0 01-.049.091l-11.4 5.325a.088.088 0 01-.1-.02.093.093 0 00.007.027.089.089 0 00.119.043l11.4-5.325a.089.089 0 00.04-.118z"
        fill="#d6d6d6"
    />
    <path
        d="M25.697 3.949a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.37z"
        fill="#c7c7c7"
    />
    <path
        d="M25.818 3.897a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
        fill="#dc3722"
    />
    <path
        d="M25.691 2.429l-.244.671a.62.62 0 001.164.423l.244-.671z"
        fill="#fff"
    />
    <path
        d="M25.961 3.902a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.624.624 0 01-.106-.03zM23.084 5.17a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.371z"
        fill="#c7c7c7"
    />
    <path
        d="M23.205 5.114a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
        fill="#dc3722"
    />
    <path
        d="M23.078 3.65l-.244.671a.62.62 0 001.164.423l.244-.671z"
        fill="#fff"
    />
    <path
        d="M23.348 5.123a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.627.627 0 01-.107-.03z"
        fill="#c7c7c7"
    />
    <g>
      <path
          d="M20.47 6.391a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#c7c7c7"
      />
      <path
          d="M20.591 6.335a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#dc3722"
      />
      <path
          d="M20.464 4.871l-.244.671a.62.62 0 101.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M20.734 6.344a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.617.617 0 01-.106-.03z"
          fill="#c7c7c7"
      />
    </g>
    <g>
      <path
          d="M17.857 7.612a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
          fill="#c7c7c7"
      />
      <path
          d="M17.978 7.556a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
          fill="#dc3722"
      />
      <path
          d="M17.849 6.092l-.244.671a.62.62 0 101.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M18.12 7.565a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.618.618 0 01-.107-.03z"
          fill="#c7c7c7"
      />
    </g>
    <g>
      <path
          d="M27.614 8.052a.619.619 0 01-.37-.793l.487-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#c7c7c7"
      />
      <path
          d="M27.735 7.993a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
          fill="#dc3722"
      />
      <path
          d="M27.608 6.532l-.244.671a.62.62 0 101.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M27.877 8.005a.619.619 0 01-.37-.793l.49-1.346a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.617.617 0 01-.109-.027z"
          fill="#c7c7c7"
      />
    </g>
    <g>
      <path
          d="M25.001 9.273a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#c7c7c7"
      />
      <path
          d="M25.122 9.217a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#dc3722"
      />
      <path
          d="M24.995 7.753l-.244.671a.62.62 0 001.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M25.264 9.226a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.627.627 0 01-.107-.03z"
          fill="#c7c7c7"
      />
    </g>
    <g>
      <path
          d="M22.387 10.494a.619.619 0 01-.37-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.793.37z"
          fill="#c7c7c7"
      />
      <path
          d="M22.508 10.438a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.37z"
          fill="#dc3722"
      />
      <path
          d="M22.381 8.974l-.244.671a.62.62 0 001.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M22.651 10.449a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.612.612 0 01-.106-.03z"
          fill="#c7c7c7"
      />
    </g>
    <g>
      <path
          d="M19.774 11.716a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.371.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.371z"
          fill="#c7c7c7"
      />
      <path
          d="M19.895 11.659a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.793-.37.619.619 0 01.37.793l-.488 1.343a.619.619 0 01-.792.37z"
          fill="#dc3722"
      />
      <path
          d="M19.768 10.195l-.244.671a.62.62 0 001.164.423l.244-.671z"
          fill="#fff"
      />
      <path
          d="M20.038 11.668a.619.619 0 01-.371-.793l.488-1.343a.619.619 0 01.543-.406.618.618 0 00-.686.4l-.488 1.343a.618.618 0 00.62.829.612.612 0 01-.106-.03z"
          fill="#c7c7c7"
      />
    </g>
  </g>
  <g>
    <path
        fill="#e0e0e0"
        d="M17.395 16.02l-2.345 1.559-.13-.195 2.345-1.559z"
    />
    <path
        d="M15.044 18.531l.129-.574-.259.172a.247.247 0 01-.273-.411l3.043-2.022a.247.247 0 01.273.411l-.259.172.58.1a3.787 3.787 0 012.492 1.633l4.435 6.672a.756.756 0 01-.211 1.049l-3.926 2.609a.757.757 0 01-1.049-.211l-4.435-6.673a3.787 3.787 0 01-.54-2.927z"
        fill="#e0e0e0"
    />
    <path
        fill="#dcb02d"
        d="M24.483 24.612l-4.257 2.831-4.236-6.368 4.258-2.831z"
    />
    <path
        d="M20.019 28.132l-4.435-6.672a3.787 3.787 0 01-.54-2.93l.129-.574-.259.172a.247.247 0 01-.273-.411l.159-.105h.067c-.077.332.344.121.717.075s-.4 2.308 1.11 3.624 2.635 5.55 3.717 5.99 4.535-1.28 4.886-1.968a.752.752 0 01-.3.406l-3.926 2.609a.757.757 0 01-1.052-.216z"
        fill="#c7c7c7"
    />
    <path
        d="M18.016 16.75s2.449-.2 6.676 8.2l-.828.55s-3.321-7.448-5.848-8.75z"
        fill="#d6d6d6"
    />
    <path d="M17.699 16.282l-2.526 1.678-.061.27z" fill="#c7c7c7" />
    <path
        d="M17.604 15.63l-2.998 1.993-.128-.192a1.8 1.8 0 01.503-2.495 1.8 1.8 0 012.496.502l.127.192z"
        fill="#dcb02d"
    />
    <path
        fill="#dcb02d"
        d="M16.128 14.603l-7.04 4.68-.198-.297 7.041-4.68z"
    />
    <path
        fill="#dcb02d"
        d="M10.54 18.544l-1.348.896-.301-.453 1.348-.896z"
    />
    <path
        d="M17.088 16.225c.468-.3.645-.569.8-.435s.125.25-.348.436-.452-.001-.452-.001z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <path
        d="M14.65 28.578a.962.962 0 01-1.358-.074l-1.481-1.653a.962.962 0 01.074-1.358.962.962 0 011.358.074l1.481 1.653a.962.962 0 01-.074 1.358z"
        fill="#dc3722"
    />
    <path
        d="M12.551 27.677l.741.827a.962.962 0 101.433-1.284l-.741-.827z"
        fill="#3bbaf7"
    />
    <path
        d="M14.767 28.389a.962.962 0 01-1.358-.074l-1.481-1.653a.961.961 0 01-.157-1.042.96.96 0 00.041 1.232l1.481 1.653a.961.961 0 001.59-.242.968.968 0 01-.116.126z"
        fill="#c7c7c7"
    />
    <path
        d="M14.541 27.613a.068.068 0 01-.05-.022l-1.77-1.941a.068.068 0 11.1-.091l1.77 1.941a.068.068 0 010 .1.067.067 0 01-.05.013z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <path
        d="M33.962 17.075a.962.962 0 01.228 1.341l-1.284 1.81a.962.962 0 01-1.341.228.962.962 0 01-.228-1.341l1.284-1.81a.962.962 0 011.341-.228z"
        fill="#dc3722"
    />
    <path
        d="M33.547 19.321l.642-.905a.962.962 0 00-1.569-1.113l-.642.905z"
        fill="#3bbaf7"
    />
    <path
        d="M33.752 17a.962.962 0 01.228 1.341l-1.284 1.81a.961.961 0 01-.982.384.961.961 0 001.192-.313l1.284-1.81a.96.96 0 00-.587-1.5.957.957 0 01.149.088z"
        fill="#c7c7c7"
    />
    <path
        d="M31.477 19.633a.068.068 0 01-.055-.106l1.5-2.155a.068.068 0 11.111.077l-1.5 2.155a.067.067 0 01-.056.029z"
        fill="#d6d6d6"
    />
  </g>
  <g>
    <circle
        cx={1.882}
        cy={1.882}
        r={1.882}
        transform="rotate(-61.171 25.426 -13.887)"
        fill="#dc3722"
    />
    <path
        d="M27.367 12.535a1.9 1.9 0 00-.353.142l1.027 3.542a1.9 1.9 0 00.727-.211l-1.027-3.542a1.9 1.9 0 00-.374.069z"
        fill="#dbdbdb"
    />
  </g>
  <g>
    <path
        d="M39.825 10.647c-.241 0-.383-.515-.541-.905s-4.047-1.7-4.49-.77c-.443.925 3.013 3.136 3.416 3.015s.892-.333 1.044-.147a4.089 4.089 0 001.931 2.539l3.575 1.711a1 1 0 001.327-.468l.485-1.013.485-1.013a1 1 0 00-.468-1.327l-3.575-1.711a4.09 4.09 0 00-3.189.089z"
        fill="#ccc"
    />
    <path
        d="M45.969 15.566a.865.865 0 01-1.152.406l-3.575-1.711a3.473 3.473 0 01-1.751-2.187 1.148 1.148 0 00-.136-.317c-.182-.223-.564-.1-.967.035-.073.024-.146.048-.216.069a7.86 7.86 0 01-3.057-2.186c-.141-.194-.29-.463-.2-.646s.392-.236.631-.248a7.825 7.825 0 013.622 1.014c.026.064.053.136.08.208.15.4.291.773.58.775a1.147 1.147 0 00.332-.093 3.472 3.472 0 012.8-.008l3.575 1.711a.865.865 0 01.406 1.152z"
        fill="#e6e6e6"
    />
    <path
        d="M41.242 14.261l3.575 1.711a.865.865 0 001.152-.406l.969-2.026a.865.865 0 00-.406-1.152l-3.575-1.711a3.041 3.041 0 00-.5-.187l-1.674 3.5a3.038 3.038 0 00.459.271z"
        fill="#dcb02d"
    />
    <path
        fill="#565656"
        d="M41.353 14.459l1.227-2.564.487.233-1.227 2.564zM43.229 15.357l1.227-2.564.487.233-1.227 2.564zM42.306 14.915l1.227-2.564.274.13-1.228 2.565z"
    />
    <path
        d="M46.589 12.269l-3.575-1.711a4.088 4.088 0 00-3.189.088c-.241 0-.383-.515-.541-.905s-3.792-1.609-4.434-.854c.546-.448 1.969.267 2.34.427a10.3 10.3 0 011.816.87c.172.209.255 1.115.7.808a2.967 2.967 0 013.064-.258c1.221.748 2.192 1.793 2.888 1.818s.7 2.1.385 3.149a.966.966 0 00.042-.078l.485-1.013.485-1.013a1 1 0 00-.466-1.328z"
        fill="#e3e3e3"
    />
    <path
        d="M34.969 9.44a8.808 8.808 0 002.791 2.361c.612.134.2-.465-.182-.8S34.969 9.44 34.969 9.44zM39.812 12.145c.126 1.167 4.576 3.859 5 3.885s.508-.91.508-.91-4.979-1.536-5.508-2.975z"
        fill="#d6d6d6"
    />
  </g>
</svg>)

export default SvgScrapping
