import React from 'react'
import { ReplenishmentDialogDataModel } from '../ReplenishmentModel'
import { REPLENISHMENT_DIALOG, REPLENISHMENT_STATUS } from '../../../../utils/constants'
import DialogReplenishmentRefuse from './dialogActions/DialogReplenishmentRefuse'
import DialogReplenishmentConfirm from './dialogActions/DialogReplenishmentConfirm'
import DialogReplenishmentCancel from './dialogActions/DialogReplenishmentCancel'
import DialogReplenishmentProcess from './dialogActions/DialogReplenishmentProcess'
import DialogReplenishmentCancelConfirmation from './dialogActions/DialogReplenishmentCancelConfirmation'
import DialogReplenishmentDeparture from './dialogActions/DialogReplenishmentDeparture'
import DialogReplenishmentMultipleConfirm from './dialogActions/DialogReplenishmentMultipleConfirm'

interface DialogActionsProps {
	dialogType?: string
	loading: boolean
	closeDialogAction: () => any
	replenishment: ReplenishmentDialogDataModel,
	onSubmit: (values: any) => any
	onSubmitMultiple: (values: any, status: string) => any
	onSubmitConfirm: () => void
}

const DialogReplenishmentActions: React.FC<DialogActionsProps> = (
	{
		dialogType,
		loading,
		closeDialogAction,
		replenishment,
		onSubmit,
		onSubmitMultiple,
		onSubmitConfirm
	}
) => {
	switch (dialogType) {
		case REPLENISHMENT_DIALOG.REFUSE:
			return <DialogReplenishmentRefuse
				isOpen
				handleClose={closeDialogAction}
				onSubmit={onSubmit}
				replenishment={replenishment}
				loading={loading}
			/>
		case REPLENISHMENT_DIALOG.CONFIRM:
			return <DialogReplenishmentConfirm
				isOpen
				handleClose={closeDialogAction}
				onSubmit={onSubmit}
				replenishment={replenishment}
				loading={loading}
			/>
		case REPLENISHMENT_DIALOG.CANCEL:
			return <DialogReplenishmentCancel
				isOpen
				handleClose={closeDialogAction}
				onSubmit={onSubmit}
				replenishment={replenishment}
			/>
		case REPLENISHMENT_DIALOG.PROCESS:
			return <DialogReplenishmentProcess
				isOpen
				handleClose={closeDialogAction}
				onSubmit={onSubmit}
				replenishment={replenishment}
				loading={loading}
			/>
		case REPLENISHMENT_DIALOG.CANCEL_CONFIRM:
			return <DialogReplenishmentCancelConfirmation
				isOpen
				handleClose={closeDialogAction}
				onSubmit={onSubmitConfirm} />
		case REPLENISHMENT_DIALOG.DEPART_MARCHANDISES:
			return <DialogReplenishmentDeparture
				isOpen
				handleClose={closeDialogAction}
				onSubmit={(values: any) => onSubmitMultiple(values, REPLENISHMENT_STATUS.DEPART_MARCHANDISES)}
				loading={loading}
			/>
		case REPLENISHMENT_DIALOG.MULTIPLE_CONFIRM:
			return <DialogReplenishmentMultipleConfirm
				isOpen
				handleClose={closeDialogAction}
				onSubmit={(values: any) => onSubmitMultiple(values, REPLENISHMENT_STATUS.CONFIRME)}
				loading={loading}
			/>
		default:
			return <></>
	}
}

export default DialogReplenishmentActions
