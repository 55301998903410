import createDecorator from 'final-form-calculate'
import moment from 'moment'

/* This decorator creates a datetime value in the form from two different inputs (date & time) */
export const dateAndTimeDecorator = (dateName: string, timeName: string, datetimeName: string) => {
    return createDecorator(
        {
            field: dateName, // when dateName value changes...
            updates: {
                // ...update datetimeName to the result of this function
                [datetimeName]: (dateValue, allValues) => {
                    // @ts-ignore
                    if (dateValue && allValues[timeName]) {
                        // @ts-ignore
                        const datetime = moment(new Date(`${dateValue} ${allValues[timeName]}`), 'DD/MM/YYYY HH:mm')

                        return datetime.local().format('YYYY-MM-DD[T]HH:mm:ss')
                    }

                    return undefined
                }
            }
        },
        {
            field: timeName, // when timeName value changes...
            updates: {
                // update datetimeName to the result of this function
                [datetimeName]: (timeValue, allValues) => {
                    // @ts-ignore
                    if (timeValue && allValues[dateName]) {
                        // @ts-ignore
                        const datetime = moment(new Date(`${allValues[dateName]} ${timeValue}`), 'DD/MM/YYYY HH:mm')

                        return datetime.local().format('YYYY-MM-DD[T]HH:mm:ss')
                    }

                    return undefined
                }
            }
        }
    )
}

/* This decorator creates a month type value in the form from two different inputs (month & year) */
export const monthAndYearDecorator = (monthName: string, yearName: string, monthYearName: string) => {
    return createDecorator(
        {
            field: monthName, // when monthName value changes...
            updates: {
                // ...update monthYearName to the result of this function
                [monthYearName]: (monthValue, allValues) => {
                    // @ts-ignore
                    if (monthValue !== undefined && allValues[yearName]) {
                        // @ts-ignore
                        const datetime = moment(new Date(Date.UTC(allValues[yearName], monthValue)))
                        return datetime.format()
                    }

                    return undefined
                }
            }
        },
        {
            field: yearName, // when yearName value changes...
            updates: {
                // update monthYearName to the result of this function
                [monthYearName]: (yearValue, allValues) => {
                    // @ts-ignore
                    if (yearValue && allValues[monthName] !== undefined) {
                        // @ts-ignore
                        const datetime = moment(new Date(Date.UTC(yearValue, allValues[monthName])))
                        return datetime.format()
                    }

                    return undefined
                }
            }
        }
    )
}
