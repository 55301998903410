import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateProductFormModel } from './productModel'

export const postProduct = (values: RequestCreateOrUpdateProductFormModel) => fetchFactory(`/referentiel/produit`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updateProduct = (id: string, values: RequestCreateOrUpdateProductFormModel) => fetchFactory(`/referentiel/produit`, {
    method: 'PUT',
    body: JSON.stringify({ ...values, id })
})

export const updateProductStatus = (id: string, status: boolean) => fetchFactory(`/referentiel/produit/status`, {
    method: 'PUT',
    body: JSON.stringify({ status, id })
})

export const getProductByID = (id: string) => fetchFactory(`/referentiel/produit/${id}`)
