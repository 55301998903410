import React from 'react'
import { OfflineModel } from '../offlineModel'
import { OFFLINE_DATA } from '../offlineConstants'
import { initPhidemContextData } from '../../phidemData/PhidemDataContext'

export const initOfflineData = {
	[OFFLINE_DATA.GLOBAL]: initPhidemContextData,
	[OFFLINE_DATA.BL]: [],
	[OFFLINE_DATA.STOCK]: []
}

const initOfflineMode = {
	// Handle offline mode
	offlineMode: false,
	setOfflineMode: () => {
	},
	backgroundSync: false,
	setBackgroundSync: () => {
	},

	// Handle stored data
	lastSynchronisation: undefined,
	offlineData: initOfflineData,
	loadOfflineData: () => {
	},
	storeAppContext: () => {
	},

	// Handle offline actions
	hasOfflineActionsToSend: false,
	hasErrorsToSend: false,
	offlineActionList: [],
	handleOfflineAction: () => {
		return Promise.resolve()
	},
	updateActionStatus: () => {
	},
	clearOfflineActionList: () => {
	}

}

export const OfflineContext = React.createContext<OfflineModel>(initOfflineMode)
