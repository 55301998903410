import { LOCAL_STORAGE_ITEM } from '../../../utils/constants'

export const SWITCH_CENTER = 'SWITCH_CENTER'

interface SessionCenter {
    id?: string,
    type?: string
}

export const getStorageCenter = () => localStorage.getItem(LOCAL_STORAGE_ITEM.CENTER)
export const setStorageCenter = (centerId?: string) => localStorage.setItem(LOCAL_STORAGE_ITEM.CENTER, centerId || '')
export const deleteStorageCenter = () => localStorage.removeItem(LOCAL_STORAGE_ITEM.CENTER)

export const switchCenter = (center?: SessionCenter) => (dispatch: any) => {
    setStorageCenter(center ? center.id : '')
    dispatch({
        type: SWITCH_CENTER,
        payload: center
    })
}
