import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { OnChange } from 'react-final-form-listeners'
import { Autocomplete, FormInput, FormSelect, FormSwitch, MultipleChoice } from 'isotope-client'
import { CenterDetailsDataModel, CenterUsersDataModel, EtiquetteCentreModel, RequestCreateOrUpdateCenterDetailsModel } from '../services/centerDetailsDataModel'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { ADMIN_MODE, CENTER_TYPE, MAIL, QTY_MODE, RECEIVER_MAIL, SCREEN_SIZE, SELECT_VALUES, TEMPLATE_TYPES } from '../../../../utils/constants'
import Loader from '../../../../components/layout/Loader'
import { getAvailableChildrenCenters } from '../services/centerApi'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import AddToList from '../../../../components/layout/AddToList'
import DialogAddCategory from './DialogAddCategory'
import { getUsersByQuery } from '../../../common/user/userApi'
import Alert from '@material-ui/lab/Alert'
import { CentreInfoModel } from '../../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import { TemplateRowModel } from '../../template/services/templateModels'
import { CarrierModel } from '../../carrier/services/carrierModel'
import AutocompleteMultipleCenter from './AutocompleteMultipleCenter'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		rowContainer: {
			margin: 0
		},
		tags: {
			marginBottom: 12
		},
		tag: {
			margin: theme.spacing(1)
		},
		itemsContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
		},
		missingCenterType: {
			marginBottom: 80
		},
		subSectionTitle: {
			textDecoration: 'underline'
		},
		templatePreferenceContainer: {
			justifyContent: 'space-between',
			alignItems: 'center'
		}
	})
)

interface CenterDetailsFormProps {
	isLoading?: boolean,
	centerUsers?: CenterUsersDataModel[],
	initialValues: RequestCreateOrUpdateCenterDetailsModel,
	onSubmit: (event: any) => void,
	handleUpdateCenterStatus?: () => void,
	isCreationForm?: boolean,
	isAdminDispatch?: boolean,
	canUpdateStatus?: boolean
}

const CenterDetailsForm: React.FC<CenterDetailsFormProps> = (
	{
		isLoading = false,
		centerUsers = [],
		initialValues,
		onSubmit,
		handleUpdateCenterStatus,
		isCreationForm = false,
		isAdminDispatch = false,
		canUpdateStatus = false
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [tags, setTags] = useState<EtiquetteCentreModel[]>([])
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const { templates, referentialData: { carriers }, user: { isAdmin } } = useContext(PhidemDataContext)

	const [fetchedChildrenCenters, setFetchedChildrenCenters] = useState<CenterDetailsDataModel[]>([])

	const openDialog = () => setIsDialogOpen(true)

	const closeDialog = () => setIsDialogOpen(false)

	const addTag = (tag: any) => {
		closeDialog()
		setTags([...tags, tag])
	}

	const deleteTag = (index: number) => {
		const newTags: any[] = [...tags]

		newTags.splice(index, 1)
		setTags(newTags)
	}

	const getAvailableChildren = useCallback((centerType?: string) => {
		const requestedType = centerType || initialValues.type
		const previousChildren = requestedType === initialValues.type ? initialValues.childrenCenters : []

		if (requestedType) {
			getAvailableChildrenCenters(requestedType, initialValues.id)
				.then((childrenCenters: CenterDetailsDataModel[]) =>
					setFetchedChildrenCenters([...previousChildren, ...childrenCenters]))
		}

	}, [initialValues.childrenCenters, initialValues.type, initialValues.id])


	useEffect(() => {
		if (initialValues.etiquettes && initialValues.etiquettes.length > 0) {
			setTags(initialValues.etiquettes)
		}
	}, [initialValues])

	useEffect(() => getAvailableChildren(), [getAvailableChildren, initialValues])

	const onValidate = (formValues: RequestCreateOrUpdateCenterDetailsModel) => {
		const errors: any = {}

		if (!formValues.nom) {
			errors.nom = <ErrorMessageRequired />
		}
		if (!formValues.type) {
			errors.type = <ErrorMessageRequired />
		}
		if (!formValues.adresse) {
			errors.adresse = <ErrorMessageRequired />
		}
		if (!formValues.codePostal) {
			errors.codePostal = <ErrorMessageRequired />
		}
		if (!formValues.ville) {
			errors.ville = <ErrorMessageRequired />
		}
		if (!formValues.destinataireEmail) {
			errors.destinataireEmail = <ErrorMessageRequired />
		}

		return errors
	}

	const formValuesToModel = (formValues: RequestCreateOrUpdateCenterDetailsModel) => ({
		nom: formValues.nom,
		service: formValues.service,
		type: formValues.type,
		capacite: formValues.capacite,
		numFiness: formValues.numFiness,
		siret: formValues.siret,
		ape: formValues.ape,
		complementDistrib: formValues.complementDistrib,
		adresse: formValues.adresse,
		codePostal: formValues.codePostal,
		ville: formValues.ville,
		libelleRoutage: formValues.libelleRoutage,
		telephone: formValues.telephone,
		email: formValues.email,
		gestionStock: formValues.gestionStock,
		desactivationSuivi: formValues.desactivationSuivi,
		users: formValues.userCenters.map((user) => user.id),
		idReferent: formValues.idReferent,
		idTransporteur: formValues.idTransporteur,
		childrenIds: formValues.childrenCenters.map(
			(childrenCenter) => childrenCenter.id),
		etiquettes: tags,
		destinataireEmail: formValues.destinataireEmail,
		emailAutorise: formValues.emailAutorise,
		modeQte: formValues.modeQte,
		modeAdministration: formValues.modeAdministration,
		modeOffline: formValues.modeOffline,
		templates: (formValues.type === CENTER_TYPE.DISPATCH ? Object.values(TEMPLATE_TYPES.DISPATCH) : Object.values(TEMPLATE_TYPES.VACCINATION))
			.map((templateType) => {
				// @ts-ignore
				return formValues[templateType]
			}).filter((item: string) => !!item && item !== SELECT_VALUES.DEFAULT),
		actif: formValues.actif,
		id: formValues.id
	})

	const handleOnSubmit = (formValues: RequestCreateOrUpdateCenterDetailsModel) => {
		return onSubmit(formValuesToModel(formValues))
	}

	// Gestion des utilisateurs
	const getSuggestions = (userCenters: CenterUsersDataModel[]) => (length: number, value: string) => {
		return getUsersByQuery(value)
			.then((result: CenterUsersDataModel[]) => {
				// On filtre les résultats en omettant les valeurs déjà renseignées
				return result
					.filter((user: CenterUsersDataModel) => userCenters.findIndex((item: CenterUsersDataModel) => item.id === user.id) === -1)
					.map((user: CenterUsersDataModel) => ({
						label: `${user.firstname} ${user.lastname}`,
						value: user
					}))
			})
			.catch(() => [])
	}
	const onSuggestionSelected = (form: any, userCenters: CenterUsersDataModel[]) => (event: any, { suggestion }: any) => {
		const newUsers = [...userCenters]
		newUsers.push({ ...suggestion.value })
		form.change('userCenters', newUsers)
		form.change('userCentersHidden', '')
	}
	const deleteUser = (index: number, form: any, userCenters: CenterUsersDataModel[]) => {
		const newUsers: CenterUsersDataModel[] = [...userCenters]

		newUsers.splice(index, 1)
		form.change('userCenters', newUsers)
	}

	const getInfoCentreParent = () => {
		if (isCreationForm) {
			return <></>
		}

		if (initialValues.parentCenters.length === 0) {
			// Dans le cas d'un référent ou dispatch, on n'alerte pas
			if (initialValues.type === CENTER_TYPE.REFERENT || initialValues.type === CENTER_TYPE.DISPATCH) {
				return <></>
			}

			return <Alert severity="error">
				<FormattedMessage
					id={`center.${initialValues.type === CENTER_TYPE.VACCINATION ? 'alertNoDispatch' : 'alertNoVaccination'}`}
					defaultMessage="Attention, ce centre n'est rattaché à aucun centre de dispatch"
					description="alert message"
				/>
			</Alert>
		}

		return <Alert variant="outlined" severity="info">
			<FormattedMessage
				id="center.alertRef"
				defaultMessage="Ce centre est rattaché à"
				description="alert message"
				values={{
					centerNames: initialValues.parentCenters.map((center: CentreInfoModel) =>
						`${center.nom}${center.service ? ` - ${center.service}` : ''}`
					).join(', ')
				}}
			/>
		</Alert>
	}

	const getCarriers = useMemo(() => {
		if (isAdmin) {
			if (initialValues.parentCenters && initialValues.parentCenters.length > 0) {
				const referentId = initialValues.parentCenters[0].id
				return carriers.filter((item: CarrierModel) => item.centreDispatch.id === referentId)
			}
			return []
		}
		return carriers
	}, [initialValues, carriers, isAdmin])

	const getTemplates = (centerType: string) => {
		switch (centerType) {
			case CENTER_TYPE.REFERENT:
				return Object.values(TEMPLATE_TYPES.REFERENT)
			case CENTER_TYPE.DISPATCH:
				return Object.values(TEMPLATE_TYPES.DISPATCH)
			case CENTER_TYPE.VACCINATION:
				return Object.values(TEMPLATE_TYPES.VACCINATION)
			default:
				return []
		}
	}

	const getMailChoices = (centerType: string) => {
		switch (centerType) {
			case CENTER_TYPE.REFERENT:
				return Object.values(MAIL.REFERENT)
			case CENTER_TYPE.DISPATCH:
				return Object.values(MAIL.DISPATCH)
			case CENTER_TYPE.VACCINATION:
				return Object.values(MAIL.VACCINATION)
			case CENTER_TYPE.VACCINATION_POINT:
				return Object.values(MAIL.VACCINATION_POINT)
			default:
				return []
		}
	}

	return (
		<>
			{(isLoading)
				?
				<Loader />
				:
				<Form
					initialValues={initialValues}
					onSubmit={handleOnSubmit}
					validate={onValidate}
					render={({ handleSubmit, form, submitting, values }) => {
						const { idReferent, type } = values

						return (
							<PageFormContainer onSubmit={handleSubmit}>
								{getInfoCentreParent()}
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="center.formSection.information"
											defaultMessage="Informations du centre"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={7} md={5}>
									<Field
										name="nom"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.nom"
												defaultMessage="Nom du centre"
												description="Center name"
											/>
										}
										required
									/>
								</Grid>
								<Grid item xs={7} md={5}>
									<Field
										name="service"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.service"
												defaultMessage="Service"
												description="Center service"
											/>
										}
									/>
								</Grid>
								<Grid container spacing={2} className={classes.rowContainer}>
									<Grid item xs={7} md={5}>
										<Field
											name="type"
											component={FormSelect}
											label={
												<FormattedMessage
													id="center.type"
													defaultMessage="Type"
													description="Center type"
												/>
											}
											required
											disabled={!isCreationForm}
										>
											{Object.values(CENTER_TYPE).filter((TYPE: string) => !(isAdminDispatch && CENTER_TYPE.DISPATCH === TYPE)).map((TYPE: string) => (
												<MenuItem key={TYPE} value={TYPE}>
													<FormattedMessage
														id={`enum.centerType.${TYPE}`}
														defaultMessage={TYPE}
														description="Center type"
													/>
												</MenuItem>
											))}
										</Field>
										<OnChange name="type">
											{
												(value) => {
													getAvailableChildren(value)
													form.change('emailAutorise', [])
													form.change('childrenCenters', [])
													form.change('gestionStock', false)
													form.change('desactivationSuivi', false)

													if (value === CENTER_TYPE.VACCINATION_POINT) {
														form.change('desactivationSuivi', true)
													}
												}
											}
										</OnChange>
									</Grid>
									{(type !== CENTER_TYPE.REFERENT && type !== CENTER_TYPE.DISPATCH) &&
										<Grid item xs={7} md={4}>
											<Field
												name="capacite"
												component={FormInput}
												label={
													<FormattedMessage
														id="center.capacite"
														defaultMessage="Capacité"
														description="Capacity"
													/>
												}
												type="number"
												inputProps={{ min: 0 }}
											/>
										</Grid>}
								</Grid>
								<Grid item xs={12}>
									{type === CENTER_TYPE.REFERENT ?
										<Field
											name="gestionStock"
											component={FormSwitch}
											label={
												<FormattedMessage
													id="center.gestionStock"
													defaultMessage="Gestion du stock"
													description="disable switch"
												/>
											}
										/>
										:
										<Field
											name="desactivationSuivi"
											component={FormSwitch}
											label={
												<FormattedMessage
													id="center.desactivationSuivi"
													defaultMessage="Désactivation du suivi par défaut"
													description="disable switch"
												/>
											}
											disabled={CENTER_TYPE.VACCINATION_POINT === values.type}
										/>
									}
								</Grid>
								<Grid item xs={12}>
									{type === CENTER_TYPE.VACCINATION &&
										<Field
											name="modeOffline"
											component={FormSwitch}
											label={
												<FormattedMessage
													id="center.modeOffline"
													defaultMessage="Mode déconnecté activé"
													description="disable switch"
												/>
											}
										/>
									}
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={7} md={4}>
										<Field
											name="numFiness"
											component={FormInput}
											label={
												<FormattedMessage
													id="center.numFiness"
													defaultMessage="Numéro FINESS"
													description="FINESS number"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="siret"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.siret"
												defaultMessage="Numéro SIRET"
												description="SIRET number"
											/>
										}
									/>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="ape"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.ape"
												defaultMessage="APE"
												description="APE Number"
											/>
										}
									/>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="complementDistrib"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.complementDistrib"
												defaultMessage="Complément de distribution"
												description="Complément de distribution form input"
											/>
										}
									/>
								</Grid>

								{initialValues.type === CENTER_TYPE.VACCINATION && !isCreationForm &&
									<Grid item xs={12}>
										<Grid item xs={4}>
											<Field
												name="idTransporteur"
												component={FormSelect}
												label={
													<FormattedMessage
														id="center.defaultCarrier"
														defaultMessage="Transporteur par défaut"
														description="Transporteur form select"
													/>
												}
											>

												<MenuItem key={0} value={undefined}>
													<FormattedMessage
														id="select.none"
														defaultMessage="Aucun"
														description="No option"
													/>
												</MenuItem>
												{getCarriers.map((carrier: CarrierModel) =>
													<MenuItem key={carrier.id} value={carrier.id}>{carrier.nom}</MenuItem>
												)}
											</Field>
										</Grid>
									</Grid>
								}

								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="center.formSection.adresse"
											defaultMessage="Adresse"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={7} md={4}>
										<Field
											name="adresse"
											component={FormInput}
											label={
												<FormattedMessage
													id="center.adresse"
													defaultMessage="Adresse"
													description="Address"
												/>
											}
											multiline
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="ville"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.ville"
												defaultMessage="Ville"
												description="City"
											/>
										}
										required
									/>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="codePostal"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.codePostal"
												defaultMessage="Code postal"
												description="Zip code"
											/>
										}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={7} md={4}>
										<Field
											name="libelleRoutage"
											component={FormInput}
											label={
												<FormattedMessage
													id="center.libelleRoutage"
													defaultMessage="Libellé routage"
													description="Routing label"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="telephone"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.telephone"
												defaultMessage="Télételephone"
												description="Phone number"
											/>
										}
									/>
								</Grid>
								<Grid item xs={7} md={4}>
									<Field
										name="email"
										component={FormInput}
										label={
											<FormattedMessage
												id="center.email"
												defaultMessage="Email"
												description="Email"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="center.formSection.users"
											defaultMessage="Utilisateurs"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={7} md={4}>
										<Field
											name="idReferent"
											component={FormSelect}
											label={
												<FormattedMessage
													id="center.referent"
													defaultMessage="Référent"
													description="Center referent"
												/>
											}
											disabled={centerUsers.length === 0}
										>
											{centerUsers.map((user: CenterUsersDataModel) => (
												<MenuItem key={user.id} value={user.id}>{`${user.firstname} ${user.lastname}`}</MenuItem>
											))}
										</Field>
									</Grid>
								</Grid>
								<Grid item xs={8} md={6}>
									<Field
										label={<FormattedMessage
											id="center.users"
											defaultMessage="Rechercher un utilisateur"
											description="Users"
										/>}
										name="userCentersHidden"
										component={Autocomplete}
										minLength={3}
										getSuggestion={getSuggestions(values.userCenters)}
										autosuggestProps={{ onSuggestionSelected: onSuggestionSelected(form, values.userCenters) }}
									/>
									<div className={classes.itemsContainer}>
										{(values.userCenters || []).map(((user: CenterUsersDataModel, index: number) =>
												<Chip
													className={classes.tag}
													key={`userCenter-${user.id}`}
													label={`${user.firstname} ${user.lastname}`}
													onDelete={() => deleteUser(index, form, values.userCenters)}
												/>
										))}
									</div>
								</Grid>
								<OnChange name="userCenters">{(changedUsers) => {
									// check that the referent is still in the users list
									if (idReferent && !changedUsers.some((user: CenterUsersDataModel) => (idReferent === user.id))) {
										form.change('idReferent', undefined)
									}
								}}</OnChange>
								{type !== CENTER_TYPE.VACCINATION_POINT &&
									<>
										<Grid item xs={12}>
											<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
												<FormattedMessage
													id="center.formSection.childrenCenters"
													defaultMessage="Centres associés"
													description="Form section title"
												/>
											</Typography>
										</Grid>
										<Grid item xs={8} md={6}>
											<Field name="childrenCenters">
												{field =>
													<AutocompleteMultipleCenter
														input={field.input}
														options={fetchedChildrenCenters}
													/>
												}
											</Field>
										</Grid>
									</>
								}
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="center.formSection.preferences"
											defaultMessage="Préférences"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{type ?
									<>
										<Grid container item xs={12} spacing={2} className={classes.tags}>
											<AddToList
												label={
													<Typography>
														<FormattedMessage
															id="center.tags"
															defaultMessage="Etiquettes"
															description="Label for tag input"
														/>
													</Typography>
												}
												onAdd={openDialog}
												items={tags}
												renderItem={(item: EtiquetteCentreModel, index) => {
													const label = item.categorieLabel ?
														<FormattedMessage
															id={`enum.tagCategoriesLabel.AUTRE`}
															defaultMessage={
																`Autre (${item.categorieLabel}): ${item.valeur}`
															}
															description="Field value"
															values={{ label: item.categorieLabel, value: item.valeur }}
														/>
														:
														<FormattedMessage
															id={`enum.tagCategoriesLabel.${item.categorieType}`}
															defaultMessage={
																`${item.categorieType[0].toUpperCase()}${item.categorieType.slice(1).toLowerCase()} : ${item.valeur}`
															}
															description="Field value"
															values={{ value: item.valeur }}
														/>

													return (
														<Chip
															className={classes.tag}
															key={`${item.categorieType}${item.categorieLabel}${item.valeur}`}
															label={label}
															onDelete={() => deleteTag(index)}
														/>
													)
												}}
											/>
										</Grid>
										<DialogAddCategory
											isOpen={isDialogOpen}
											handleClose={closeDialog}
											onSubmit={addTag}
											currentTags={tags}
										/>
										{(type === CENTER_TYPE.REFERENT || type === CENTER_TYPE.DISPATCH) && <Grid item xs={12}>
											<Grid item xs={7} md={4}>
												<Field
													name="modeQte"
													component={FormSelect}
													label={
														<FormattedMessage
															id="center.modeQte"
															defaultMessage="Mode de saisie des allocations"
															description="Quantity mode"
														/>
													}
													required
												>
													{Object.values(QTY_MODE).map((mode: string) => (
														<MenuItem key={mode} value={mode}>
															<FormattedMessage
																id={`enum.qtyMode.${mode}`}
																defaultMessage={mode}
																description="Qty mode"
															/>
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>}

										{(type !== CENTER_TYPE.REFERENT && type !== CENTER_TYPE.DISPATCH) && <Grid item xs={12}>
											<Grid item xs={7} md={4}>
												<Field
													name="modeAdministration"
													component={FormSelect}
													label={
														<FormattedMessage
															id="center.adminMode"
															defaultMessage="Mode de saisie d'administration"
															description="Administration mode"
														/>
													}
												>
													{Object.values(ADMIN_MODE).map((mode: string) => (
														<MenuItem key={mode} value={mode}>
															<FormattedMessage
																id={`enum.adminMode.${mode}`}
																defaultMessage={mode}
																description="Use"
															/>
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>}

										<Grid item xs={12}>
											<Typography gutterBottom variant="h4" className={classes.subSectionTitle}>
												<FormattedMessage
													id="center.subSections.mails"
													defaultMessage="Mails"
													description="Subsection title"
												/>
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Grid item xs={7} md={4}>
												<Field
													name="destinataireEmail"
													component={FormSelect}
													label={
														<FormattedMessage
															id="center.destinataireEmail"
															defaultMessage="Destinataire des mails"
															description="Email receiver"
														/>
													}
													required
												>
													{Object.values(RECEIVER_MAIL).map((receiver: string) => (
														<MenuItem key={receiver} value={receiver}>
															<FormattedMessage
																id={`enum.receiverMail.${receiver}`}
																defaultMessage={`${receiver[0].toUpperCase()}${receiver.slice(1).toLowerCase()}`}
																description="Email receiver"
															/>
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
										<Grid item xs={8} md={6}>
											<Field
												name="emailAutorise"
												component={MultipleChoice}
												fullWidth
												choices={getMailChoices(type)}
												getLabelElement={(reason: string) =>
													<FormattedMessage
														id={`enum.mailReasons.${reason}`}
														defaultMessage={`${reason[0].toUpperCase()}${reason.slice(1).toLowerCase()}`}
														description="Notification reason"
													/>
												}
												label={
													<FormattedMessage
														id="center.notifyReasons"
														defaultMessage="Notifier le(s) destinataire(s) pour"
														description="Notification reasons"
													/>
												}
											/>
										</Grid>
										{type !== CENTER_TYPE.VACCINATION_POINT && (<>
											<Grid item xs={12}>
												<Typography gutterBottom variant="h4" className={classes.subSectionTitle}>
													<FormattedMessage
														id="center.subSections.templates"
														defaultMessage="Templates"
														description="Subsection title"
													/>
												</Typography>
											</Grid>
											{getTemplates(type).map((templateType) => {
												return (<Grid key={templateType} container item xs={12} md={8} spacing={2} className={classes.templatePreferenceContainer}>
													<Typography gutterBottom>
														<FormattedMessage
															id={`enum.templateTypes.${templateType}`}
															defaultMessage={templateType}
															description="Template preference"
														/>
													</Typography>
													<Grid item xs={7} md={4}>
														<Field
															name={templateType}
															component={FormSelect}
															required
															defaultValue={SELECT_VALUES.DEFAULT}
														>
															<MenuItem key="default" value={SELECT_VALUES.DEFAULT}>
																<FormattedMessage
																	id="select.default"
																	defaultMessage="Par défaut"
																	description="Default"
																/>
															</MenuItem>
															{templates.filter((template: TemplateRowModel) => template.type === templateType)
																.map((template: TemplateRowModel) => (
																	<MenuItem key={template.id} value={template.id}>
																		{template.nom}
																	</MenuItem>
																))}
														</Field>
													</Grid>
												</Grid>)
											})
											}
										</>)}

										<div
											className={classes.buttonRoot}
											style={
												isLargeScreen
													?
													{ flexDirection: 'row' }
													:
													{ flexDirection: 'column' }
											}
										>
											{isCreationForm
												?
												<ButtonCreate
													type="submit"
													isLoading={submitting}
												/>
												:
												<ButtonSave
													type="submit"
													isLoading={submitting}
												/>
											}
											{canUpdateStatus && <ButtonActivate handleStatus={handleUpdateCenterStatus} actif={initialValues.actif} />}
										</div>
									</>
									:
									<Grid item xs={12} className={classes.missingCenterType}>
										<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
											<FormattedMessage
												id="center.missingType"
												defaultMessage="Sélectionnez le type de centre avant de pouvoir saisir les préférences."
												description="Missing center type"
											/>
										</Typography>
									</Grid>
								}

							</PageFormContainer>
						)
					}}
				/>
			}
		</>
	)
}

export default CenterDetailsForm
