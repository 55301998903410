import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import CenterDetailsForm from './components/CenterDetailsForm'
import { getCenterByID, updateCenterDetails, updateCenterStatus } from './services/centerApi'
import { CenterDetailsDataModel, CenterUsersDataModel, defaultRequestCenter, RequestCreateOrUpdateCenterDetailsModel } from './services/centerDetailsDataModel'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { TemplateRowModel } from '../template/services/templateModels'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface MatchParamsModel {
	id: string
}

interface CenterDetailsUpdateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const CenterDetailsUpdate: React.FC<CenterDetailsUpdateProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const { user: { selectedCenter, isAdminDispatch, isAdmin }, reloadReferentiel } = useContext(PhidemDataContext)
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const [fetchedCenterDetails, setFetchedCenterDetails] = useState<RequestCreateOrUpdateCenterDetailsModel>(defaultRequestCenter)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const handleUpdateCenterStatus = () => {
		updateCenterStatus(idInUrl, !fetchedCenterDetails?.actif)
			.then(() => {
				setFetchedCenterDetails({
					...fetchedCenterDetails,
					actif: !fetchedCenterDetails?.actif
				})
				snackSuccess({ id: 'center.updateStatusSuccess', defaultMessage: 'Le statut du centre a bien été modifié', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.CENTER)
			})
			.catch(() => {
				snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
			})
	}

	const fetchedDataToFormValues = (fetchedData: CenterDetailsDataModel) => ({
		...fetchedData,
		childrenIds: fetchedData.centreEnfants.map((vacc: CenterDetailsDataModel) => vacc.id),
		parentCenters: fetchedData.centreParents,
		users: fetchedData.users.map((user: CenterUsersDataModel) => user.id),
		childrenCenters: fetchedData.centreEnfants,
		userCenters: fetchedData.users,
		etiquettes: fetchedData.etiquettes,
		email: fetchedData.email,
		destinataireEmail: fetchedData.destinataireEmail,
		emailAutorise: fetchedData.emailAutorise,
		templates: fetchedData.templates.map((template: TemplateRowModel) => (template.id)),
		...(fetchedData.templates.reduce((acc: any, template: TemplateRowModel) => ({
			...acc,
			[template.type]: template.id
		}), {}))
	}) as RequestCreateOrUpdateCenterDetailsModel

	useEffect(() => {
		getCenterByID(idInUrl)
			.then((values: CenterDetailsDataModel) => setFetchedCenterDetails(fetchedDataToFormValues(values)))
			.finally(() => setIsLoading(false))
	}, [idInUrl])


	const onSubmit = async (formValuesModel: any) => {
		return updateCenterDetails(idInUrl, formValuesModel)
			.then((response: CenterDetailsDataModel) => {
				setFetchedCenterDetails(fetchedDataToFormValues(response))
				snackSuccess({ id: 'center.updateSuccess', defaultMessage: 'Le centre a bien été modifié', description: 'Success message' })
				reloadReferentiel(REFERENTIEL.CENTER)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'center.updateError', defaultMessage: 'Une erreur est survenue, le centre n\'a pas été modifié', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<CenterDetailsForm
			isLoading={isLoading}
			initialValues={fetchedCenterDetails}
			handleUpdateCenterStatus={handleUpdateCenterStatus}
			onSubmit={onSubmit}
			centerUsers={fetchedCenterDetails.userCenters}
			canUpdateStatus={isAdmin || (selectedCenter && isAdminDispatch && idInUrl !== selectedCenter.id)}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="center.pageTitle.update"
		defaultMessage="Modifier un centre"
		description="Page title"
	/>
})), injectSnackbarActions)(CenterDetailsUpdate)

