import React, { useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SCREEN_SIZE } from '../../../utils/constants'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../../components/layout/buttons/Button'
import EmptyResult from '../../../components/layout/EmptyResult'
import BackupIcon from '@material-ui/icons/Backup'
import OfflineActionCard from './components/OfflineActionCard'
import { OFFLINE_DATA } from '../../common/offline/offlineConstants'
import { OfflineContext } from '../../common/offline/context/OfflineContext'
import AccueilTitle from '../../accueil/AccueilTitle'
import Alert from '@material-ui/lab/Alert'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'
import moment from 'moment'
import { OfflineActionStatus } from '../../common/offline/offlineModel'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleContainer: {
			marginTop: theme.spacing(6),
			marginLeft: theme.spacing(4),
			marginBottom: theme.spacing(3),

			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(6),
				marginLeft: theme.spacing(0),
				marginBottom: theme.spacing(3)
			}
		},
		container: {
			display: 'flex',
			justifyContent: 'center',
			marginLeft: theme.spacing(4),
			marginRight: theme.spacing(4),
			[theme.breakpoints.down('md')]: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2)
			}
		},
		grid: {
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center'
			}
		},
		warning: {
			marginBottom: 16
		},
		title: {
			textAlign: 'left',
			[theme.breakpoints.down('md')]: {
				textAlign: 'center'
			}
		},
		errorBtn: {
			marginRight: 16
		},
		refreshButton: {
			marginTop: 16,
			marginBottom: 16
		}
	})
)

type SynchronisationPageProps = {
	snackSuccess: (value: any) => void
}

const SynchronisationPage: React.FC<SynchronisationPageProps> = ({ snackSuccess }) => {
	const classes = useStyles()
	const intl = useIntl()
	const globalContext = useContext(PhidemDataContext)
	const { offlineData, offlineActionList, lastSynchronisation, hasOfflineActionsToSend, hasErrorsToSend, storeAppContext, loadOfflineData, updateActionStatus } = useContext(OfflineContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [transferLoading, setTransferLoading] = useState<boolean>(false)

	useEffect(() => {
		storeAppContext(OFFLINE_DATA.GLOBAL, globalContext)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Actions handler
	const sendToServer = (status: OfflineActionStatus) => {
		setTransferLoading(true)

		const promises = offlineActionList
			.filter(action => action.status === status)
			.map(action => {
				// Send action to server
				return action.promise(action.values)
					.then(() => updateActionStatus(action.uid, 'SUCCESS'))
					.catch(() => updateActionStatus(action.uid, 'ERROR'))
			})

		Promise.all(promises)
			.finally(() => {
				setTransferLoading(false)
				loadOfflineData()
			})
	}

	return <div>
		<div className={classes.titleContainer}>
			<AccueilTitle text={intl.formatMessage({ id: 'menu.vaccinationCenter.offlineHome' })} />
		</div>
		<div className={classes.container}>
			<Grid container item xs={12} className={classes.grid}>
				<Grid item xs={12} className={classes.warning}>
					<Alert severity="error">
						<FormattedMessage
							id="common.offlineMode.warning"
							defaultMessage="Attention, vous êtes en mode déconnecté. Veuillez bien effectuer une synchronisation avant de démarrer votre journée."
							description="Offline warning"
						/>
					</Alert>
				</Grid>
				<Grid item xs={12} className={classes.title}>
					<Typography variant="h2">
						<FormattedMessage
							id={`vaccination.synchronisation.${lastSynchronisation ? 'received' : 'empty'}DataTitle`}
							defaultMessage="Données synchronisées"
							description="Data title"
						/>
					</Typography>
				</Grid>
				{(lastSynchronisation && offlineData) ? <>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
								<FormattedMessage
									id="vaccination.synchronisation.bl"
									defaultMessage="Bl disponibles"
									description="Bl count"
									values={{ bl: offlineData[OFFLINE_DATA.BL].length }}
								/>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
								<FormattedMessage
									id="vaccination.synchronisation.stock"
									defaultMessage="Stocks disponibles"
									description="Stock count"
									values={{ stock: offlineData[OFFLINE_DATA.STOCK].length }}
								/>
							</Typography>
						</Grid>
					</>
					: <EmptyResult message="vaccination.synchronisation.emptyData" />
				}
				<Grid item container xs={12} justify={isLargeScreen ? 'space-between' : 'center'} className={classes.refreshButton}>
					{lastSynchronisation && <Grid item>
						<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
							<FormattedMessage
								id="vaccination.synchronisation.lastRefresh"
								defaultMessage="Dernière synchronisation"
								description="Last synchronisation"
								values={{ datetime: displayDate(moment(moment(lastSynchronisation).locale(globalContext.user.selectedLang)), DATE_FORMAT.DATETIME, globalContext.user.isDateFormatEn) }}
							/>
						</Typography>
					</Grid>}
				</Grid>
				{offlineActionList.length > 0 && <>
					<Grid item xs={12} className={classes.title}>
						<Typography variant="h2">
							<FormattedMessage
								id="vaccination.synchronisation.waitingActionsTitle"
								defaultMessage="Actions en attente"
								description="Offline actions title"
							/>
						</Typography>
					</Grid>
					<Grid item container xs={12}>
						{offlineActionList.map((action, index) => <OfflineActionCard key={`action-${index}`} action={action} />)}
					</Grid>
					<Grid item container xs={12} justify={isLargeScreen ? 'flex-end' : 'center'} className={classes.refreshButton}>
						{hasErrorsToSend && <Grid item className={classes.errorBtn}>
							<Button
								variant="contained"
								startIcon={<BackupIcon />}
								onClick={() => sendToServer('ERROR')}
								isLoading={transferLoading}
							>
								<FormattedMessage
									id="button.sendError"
									defaultMessage="Erreurs"
									description="Send button label"
								/>
							</Button>
						</Grid>}
						<Grid item>
							<Button
								variant="contained"
								startIcon={<BackupIcon />}
								onClick={() => sendToServer('WAITING')}
								isLoading={transferLoading}
								disabled={!hasOfflineActionsToSend}
							>
								<FormattedMessage
									id="button.send"
									defaultMessage="Envoyer"
									description="Send button label"
								/>
							</Button>
						</Grid>
					</Grid>
				</>}
			</Grid>
		</div>
	</div>
}

export default compose(injectSnackbarActions)(SynchronisationPage)
