import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateCarrierFormModel } from './carrierModel'

export const postCarrier = (values: RequestCreateOrUpdateCarrierFormModel) => fetchFactory(`/referentiel/transporteur`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updateCarrier = (id: string, values: RequestCreateOrUpdateCarrierFormModel) => fetchFactory(`/referentiel/transporteur`, {
    method: 'PUT',
    body: JSON.stringify({ ...values, id })
})

export const updateCarrierStatus = (id: string, status: boolean) => fetchFactory(`/referentiel/transporteur/status`, {
    method: 'PUT',
    body: JSON.stringify({ status, id })
})

export const getCarrierByID = (id: string) => fetchFactory(`/referentiel/transporteur/${id}`)
