import React, { useContext } from 'react'
import { dataTableActions, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { ContainerModel } from './services/containerModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateContainerStatus } from './services/containerApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface ContainerListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ContainerList: React.FC<ContainerListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const history = useHistory()
	const { reloadReferentiel } = useContext(PhidemDataContext)

	const handleContainerStatus = (id: string, status: boolean) => {
		updateContainerStatus(id, status)
			.then(() => {
				refreshTable()
				reloadReferentiel(REFERENTIEL.CONTAINER)
				snackSuccess({ id: 'container.updateStatusSuccess', defaultMessage: 'Le statut du contenant a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({ id: 'container.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du contenant n\'a pas été modifiée', description: 'Error message' }))
	}

	return (
		<RedirectFAB redirectPath="/containers/add">
			<SpringDataTable
				apiUrl="/referentiel/contenant"
				nom="containerList"
				headers={[
					{
						key: 'nom',
						name: <FormattedMessage
							id="container.name"
							defaultMessage="Nom"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'fabricant',
						name: <FormattedMessage
							id="container.manufacturer"
							defaultMessage="Fabricant"
							description="Header column manufacturer"
						/>,
						sortable: true
					},
					{
						key: 'type',
						name: <FormattedMessage
							id="container.type1"
							defaultMessage="Type"
							description="Header column type"
						/>,
						sortable: true
					},
					{
						key: 'taille',
						name: <FormattedMessage
							id="container.size"
							defaultMessage="Taille"
							description="Header column size"
						/>,
						sortable: true
					},
					{
						key: 'poids',
						name: <FormattedMessage
							id="container.weight"
							defaultMessage="Poids"
							description="Header column weight"
						/>,
						sortable: true
					},
					{
						key: 'capacite',
						name: <FormattedMessage
							id="container.capacity"
							defaultMessage="Capacité"
							description="Header column capacity"
						/>,
						sortable: true
					},
					{
						key: 'actif',
						name: <FormattedMessage
							id="global.status.label"
							defaultMessage="Statut"
							description="Header column status"
						/>,
						sortable: true,
						render: (row: ContainerModel) => row.actif ? <FormattedMessage
							id="global.status.enabled"
							defaultMessage="Actif"
							description="Center status"
						/> : <FormattedMessage
							id="global.status.disabled"
							defaultMessage="Inactif"
							description="Center status"
						/>

					}
				]}
				makeActions={(row: ContainerModel) => [
					{
						label: <FormattedMessage
							id="global.update"
							defaultMessage="Modifier"
							description="Update action"
						/>, action: () => {
							history.push(`/containers/${row.id}`)
						}
					},
					{
						label: row.actif ? <FormattedMessage
							id="global.disable"
							defaultMessage="Désactiver"
							description="Disable action"
						/> : <FormattedMessage
							id="global.enable"
							defaultMessage="Activer"
							description="Enable action"
						/>, action: () => handleContainerStatus(row.id, !row.actif)
					}
				]}
				showActions
				noResultFragment={<NotFoundPage />}
			/>
		</RedirectFAB>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('containerList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ContainerList)
