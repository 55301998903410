import React, { useContext, useEffect, useState } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useParams } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { getProductByID, updateProduct, updateProductStatus } from './services/productApi'
import { RequestCreateOrUpdateProductFormModel } from './services/productModel'
import ProductForm from './components/ProductForm'
import errorsMapper from '../../../utils/errorsMapper'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { formatDecimal } from '../../../utils/formUtils'

interface MatchParamsModel {
    id: string
}

interface ProductCreateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const ProductCreate: React.FC<ProductCreateProps> = (
    {
        snackSuccess,
        snackError
    }) => {
    const { id: idInUrl } = useParams<MatchParamsModel>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [fetchedProduct, setFetchedProduct] = useState<RequestCreateOrUpdateProductFormModel>()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    useEffect(() => {
        getProductByID(idInUrl)
            .then((response: RequestCreateOrUpdateProductFormModel) => setFetchedProduct(response))
            .finally(() => setIsLoading(false))
    }, [idInUrl])

    const handleProductStatus = () => {
        updateProductStatus(idInUrl, !fetchedProduct?.actif)
            .then(() => {
                setFetchedProduct({
                    ...fetchedProduct as RequestCreateOrUpdateProductFormModel,
                    actif: !fetchedProduct?.actif
                })
                snackSuccess({ id: 'product.updateStatusSuccess', defaultMessage: 'Le statut du produit a bien été modifié', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.PRODUCT)
            })
            .catch(() => snackError({ id: 'product.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du produit n\'a pas été modifiée', description: 'Error message' }))
    }

    const onSubmit = async (formValues: any) => {
        formValues  = {
            ...formValues,
            nbDoses: formatDecimal(formValues.nbDoses)
        }

        return updateProduct(idInUrl, formValues)
            .then((response: RequestCreateOrUpdateProductFormModel) => {
                setFetchedProduct(response)
                snackSuccess({ id: 'product.updateSuccess', defaultMessage: 'Le produit a bien été créé', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.PRODUCT)
            })
            .catch((e: any) => {
                const { errors, displayFields } = errorsMapper(e)
                if (displayFields) {
                    snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
                } else {
                    snackError({ id: 'product.updateError', defaultMessage: 'Une erreur est survenue, le produit n\'a pas été modifié', description: 'Error message' })
                }
                return errors
            })
    }

    return (
        <ProductForm
            isLoading={isLoading}
            initialValues={fetchedProduct}
            onSubmit={onSubmit}
            isCreationForm={false}
            handleUpdateProductStatus={handleProductStatus}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="product.pageTitle.update"
        defaultMessage="Modifier un produit"
        description="Page title"
    />
})), injectSnackbarActions)(ProductCreate)
