import Button from './Button'
import React, { ReactElement } from 'react'
import { fetchDownload } from '../../../utils/fetchDownload'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Print } from '@material-ui/icons'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            padding: 12,
        },
        loader: {
            width: '20px !important',
            height: '20px !important',
            verticalAlign: 'middle',
            color: 'white'
        },
    }),
)

interface DownloadButton {
    snackError: (value: any) => void,
    className: string,
    label: ReactElement,
    url: string,
    fileName: string
}

const DownloadButton: React.FC<DownloadButton> = (
    {
        snackError,
        className,
        label,
        url,
        fileName
    }
) => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const classes = useStyles()

    const downloadDocument = () => {
        setLoading(true)
        fetchDownload(url)
            .then(
                (blob: any) => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = fileName
                    a.click()
                }
            )
            .catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
            .finally(() => setLoading(false))
    }

    return <Button
        className={`${classes.button} ${className}`}
        variant="contained"
        onClick={downloadDocument}
        startIcon={<Print />}
    >
        {loading ? <CircularProgress className={classes.loader} /> : label}
    </Button>
}

export default compose(injectSnackbarActions)(DownloadButton)
