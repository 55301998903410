import React from 'react'

const SvgCenter = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={93.337}
    height={88.756}
>
  <g
      transform="translate(-55.345 -53.7)"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  >
    <path fill="#fbfbfb" d="M64.979 83.14h73.408v58.941H64.979z" />
    <path fill="#fbfbfb" d="M76.883 99.336h24.8v42.745h-24.8z" />
    <path fill="#e8d654" d="M111.272 99.336h18.069v18.069h-18.069z" />
    <path d="M69.939 57.542h63.488l4.96 21.09H64.979z" fill="#4670eb" />
    <path fill="#4670eb" d="M55.72 75.843h92.587v7.297H55.72z" />
    <circle
        cx={16.572}
        cy={16.572}
        r={16.572}
        transform="rotate(-22.48 207.884 -167.704)"
        fill="#fbfbfb"
    />
    <circle
        cx={14.683}
        cy={14.683}
        r={14.683}
        transform="rotate(-22.5 211.917 -173.425)"
        fill="#fbfbfb"
    />
    <path
        d="M111.5 71.537h-5.51v-5.51h-8.61v5.51h-5.51v8.61h5.51v5.51h8.61v-5.51h5.51z"
        fill="#3dd380"
    />
  </g>
</svg>)

export default SvgCenter
