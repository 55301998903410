import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { FormCheckbox, FormInput } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { OnChange } from 'react-final-form-listeners'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import Button from '../../../../../components/layout/buttons/Button'
import PageFormContainer from '../../../../../components/layout/PageFormContainer'
import { isDateInputTypeSupported } from '../../../../../utils/form/inputTypes'
import { dateAndTimeDecorator, monthAndYearDecorator } from '../../../../../utils/form/decorators'
import FieldDatetime from '../../../../../components/fields/FieldDatetime'
import { ORIGIN, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../../../utils/constants'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../../../utils/dateUtils'
import { EnterStockFormValues } from '../services/enterStockModels'
import FieldRadio from '../../../../../components/fields/FieldRadio'
import StockFormExternal from './StockFormExternal'
import StockFormWithBl from './StockFormWithBl'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		unitQuantity: {
			fontSize: 12,
			marginTop: -22
		}
	})
)

interface EnterStockFormProps {
	hasReferent: boolean,
	canUseTransfertOption: boolean,
	onSubmit?: (values: EnterStockFormValues) => Promise<void>
}

const EnterStockForm: React.FC<EnterStockFormProps> =
	({
		 hasReferent,
		 canUseTransfertOption,
		 onSubmit = () => {
		 }
	 }) => {
		const classes = useStyles()
		const intl = useIntl()
		const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
		const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
		const isMonthSupported = isDateInputTypeSupported('month')
		const datetimeLocalDecorator = useMemo(() => isDatetimeSupported
				? []
				:
				[dateAndTimeDecorator('enterDate', 'enterTime', 'enterDatetime')]
			, [isDatetimeSupported])
		const dateMonthDecorator = useMemo(() => isMonthSupported
				? []
				:
				[monthAndYearDecorator('expirationMonth',
					'expirationYear', 'expirationDate')]
			, [isMonthSupported])
		const datetimeTransfertDecorator = useMemo(() => isDatetimeSupported
				? []
				:
				[dateAndTimeDecorator('transfertDate', 'transfertTime', 'transfertDatetime')]
			, [isDatetimeSupported])

		const onValidate = (values: EnterStockFormValues) => {
			const errors: any = {}

			// External
			if (values.origin === ORIGIN.EXTERNAL) {
				if (!values.product) {
					errors.product = <ErrorMessageRequired />
				}
				if (!values.quantity) {
					errors.quantity = <ErrorMessageRequired />
				} else if (values.quantity <= 0) {
					errors.quantity = <FormattedMessage
						id="global.error.validation.positive"
						defaultMessage="La valeur doit être positive"
						description="Quantity error message"
					/>
				}
				if (!values.batchNumber) {
					errors.batchNumber = <ErrorMessageRequired />
				}

				if (isMonthSupported) {
					if (!values.expirationDate) {
						errors.expirationDate = <ErrorMessageRequired />
					} else if (moment(values.expirationDate)
						.isBefore(moment().subtract(1, 'months').endOf('month'))) {
						// should be newer than last day of previous month
						errors.expirationDate = <FormattedMessage
							id="global.error.validation.datePriorToCurrentMonth"
							defaultMessage="La date ne peut pas être antérieure au mois actuel"
							description="Error message of date prior to last month"
						/>
					}
				} else {
					if (values.expirationMonth === undefined) {
						errors.expirationMonth = <ErrorMessageRequired />
					}
					if (!values.expirationYear) {
						errors.expirationYear = <ErrorMessageRequired />
					}
					if (values.expirationMonth !== undefined && values.expirationYear && values.expirationDate &&
						moment(values.expirationDate)
							.isBefore(moment().subtract(1, 'months').endOf('month'))) {
						// should be newer than last day of previous month
						errors.expirationMonth = <FormattedMessage
							id="global.error.validation.datePriorToCurrentMonth"
							defaultMessage="La date ne peut pas être antérieure au mois actuel"
							description="Error message of date prior to last month"
						/>
						errors.expirationYear = <FormattedMessage
							id="global.error.validation.datePriorToCurrentMonth"
							defaultMessage="La date ne peut pas être antérieure au mois actuel"
							description="Error message of date prior to last month"
						/>
					}
				}

				if (values.isTransfert) {
					if (!values.transfertType) {
						errors.transfertType = <ErrorMessageRequired />
					}
					if (values.transfertType !== TRANSFERT_TYPE.CONGELE) {
						if (isDatetimeSupported) {
							if (!values.transfertDatetime) {
								errors.transfertDatetime = <ErrorMessageRequired />
							}
						} else {
							if (!values.transfertDate) {
								errors.transfertDate = <ErrorMessageRequired />
							}
							if (!values.transfertTime) {
								errors.transfertTime = <ErrorMessageRequired />
							}
						}
					}
				}
			}

			// Referent
			if (values.origin === ORIGIN.REFERENT) {
				if (!values.referentBlId) {
					errors.referentBlId = <ErrorMessageRequired />
				}
			}

			// Common
			if (isDatetimeSupported) {
				if (!values.enterDatetime) {
					errors.enterDatetime = <ErrorMessageRequired />
				} else if (moment(values.enterDatetime)
					.isAfter(moment())) {
					// should be older than now
					errors.enterDatetime = <FormattedMessage
						id="global.error.validation.dateLaterToNow"
						defaultMessage="La date ne peut pas être postérieure à maintenant"
						description="Error message of date, prohibit future"
					/>
				}
			} else {
				if (!values.enterDate) {
					errors.enterDate = <ErrorMessageRequired />
				}
				if (!values.enterTime) {
					errors.enterTime = <ErrorMessageRequired />
				}
				if (values.enterDate && values.enterTime && values.enterDatetime &&
					moment(values.enterDatetime).isAfter(moment())) {
					// should be older than now
					errors.enterDate = <FormattedMessage
						id="global.error.validation.dateLaterToNow"
						defaultMessage="La date ne peut pas être postérieure à maintenant"
						description="Error message of date, prohibit future"
					/>
					errors.enterTime = <FormattedMessage
						id="global.error.validation.dateLaterToNow"
						defaultMessage="La date ne peut pas être postérieure à maintenant"
						description="Error message of date, prohibit future"
					/>
				}
			}

			return errors
		}

		const initValues = useMemo(() => ({
			origin: hasReferent ? ORIGIN.REFERENT : ORIGIN.EXTERNAL,
			enterDatetime: formatDateInForm(moment().local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
			enterDate: formatDateInForm(moment().local(), DATE_FORMAT_IN_FORM.DATE_DASH),
			enterTime: formatDateInForm(moment().local(), DATE_FORMAT_IN_FORM.HOUR),
			isTransfert: false,
			transfertType: TRANSFERT_TYPE.CONGELE,
			conforme: true
		}), [hasReferent])

		return (
			<Form
				initialValues={initValues}
				onSubmit={onSubmit}
				validate={onValidate}
				// @ts-ignore
				decorators={[...datetimeLocalDecorator, ...dateMonthDecorator, ...datetimeTransfertDecorator]}
				render={({ handleSubmit, form, submitting, values }) => {
					return (
						<PageFormContainer onSubmit={event => {
							// @ts-ignore
							handleSubmit(event)?.then(() => {
								// @ts-ignore
								form.restart()
								// Hack pour reset la vue
								if (hasReferent) {
									form.change('origin', ORIGIN.EXTERNAL)
									form.change('origin', ORIGIN.REFERENT)
								}
							})
						}}
						>
							{hasReferent && <>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="common.enterStock.formSection.origin"
											defaultMessage="Sélectionner la provenance du stock"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<FieldRadio
										name="origin"
										label={<FormattedMessage
											id="common.enterStock.form.origin"
											defaultMessage="Provenance"
											description="Origin selection"
										/>}
										choices={Object.keys(ORIGIN).map((mode: string) => ({
											label: intl.formatMessage({
												id: `enum.origin.${mode}`,
												defaultMessage: `Origin ${mode}`,
												description: 'origin option'
											}),
											value: mode
										}))}
									/>
								</Grid>
							</>}
							{values.origin === ORIGIN.EXTERNAL ?
								<StockFormExternal
									change={form.change}
									values={{
										product: values.product,
										quantity: values.quantity,
										isTransfert: values.isTransfert,
										transfertType: values.transfertType
									}}
									canUseTransfertOption={canUseTransfertOption} />
								: <StockFormWithBl
									change={form.change}
									selectedBl={values.referentBlId}
								/>}
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
									<FormattedMessage
										id="common.enterStock.formSection.confirm"
										defaultMessage="Confirmer la réception"
										description="Form section title"
									/>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FieldDatetime
									datetimeName="enterDatetime"
									dateName="enterDate"
									timeName="enterTime"
									datetimeLabel={
										<FormattedMessage
											id="common.enterStock.form.enterDatetime"
											defaultMessage="Date (jj/mm/aaaa hh:mm)"
											description="Received Date"
										/>
									}
									dateLabel={
										<FormattedMessage
											id="common.enterStock.form.enterDate"
											defaultMessage="Date (jj/mm/aaaa)"
											description="Received Date"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="common.enterStock.form.enterTime"
											defaultMessage="Heure (HH:MM)"
											description="Received Time"
										/>
									}
								/>
							</Grid>
							{values.origin === ORIGIN.REFERENT && <Grid item xs={12}>
								<Field
									name="compliantReception"
									component={FormCheckbox}
									label={
										<FormattedMessage
											id="common.enterStock.form.compliantReception"
											defaultMessage="Réception conforme"
											description="Checkbox description"
										/>
									}
								/>
								<OnChange name="compliantReception">
									{
										(value) => {
											if (value) {
												form.change('comment', undefined)
											}
										}
									}
								</OnChange>
							</Grid>}
							<Grid item xs={12} md={10}>
								<Field
									name="comment"
									component={FormInput}
									label={
										<FormattedMessage
											id="common.enterStock.form.comment"
											defaultMessage="Commentaire"
											description="Comment"
										/>
									}
								/>
							</Grid>

							<div
								className={classes.submitButtonRoot}
								style={{
									justifyContent: isLargeScreen ? 'flex-start' : 'center'
								}}
							>
								<Button
									type="submit"
									variant="contained"
									isLoading={submitting}
									disabled={(values.origin === ORIGIN.REFERENT && !values.referentBlId) || (values.origin === ORIGIN.EXTERNAL && !values.product)}
								>
									<FormattedMessage
										id="button.validate"
										defaultMessage="Valider"
										description="Message on form submission button"
									/>
								</Button>
							</div>
						</PageFormContainer>
					)
				}}
			/>
		)
	}

export default EnterStockForm
