import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './App'
import { LoginPage, ChangePasswordPage } from 'isotope-client'

export default () => (
	<Switch>
		<Route path="/login" component={LoginPage} />
		<Route path="/logout" component={LoginPage} />
		<Route path="/passwordForgotten" component={ChangePasswordPage} />
		<Route path="/" component={App} />
		<Route path="*" component={App} />
	</Switch>
)
