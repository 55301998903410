import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { Field, Form } from 'react-final-form'
import { Search } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { dataTableActions, FormSelect, FormSwitch, SpringDataTable } from 'isotope-client'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { VaccinModel } from '../../admin/product/services/productModel'
import { PhidemDataContext } from '../phidemData/PhidemDataContext'
import Button from '../../../components/layout/buttons/Button'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { CENTER_TYPE, DOWNLOAD_FILE_TYPE, QTY_MODE, SELECT_VALUES } from '../../../utils/constants'
import PageLayout from '../../../components/layout/PageLayout'
import RedirectFAB from '../../admin/RedirectFAB'
import { DuplicateProcurementModel, ProcurementListRequestModel, ProcurementRowModel } from './services/procurementModels'
import FieldWeekPicker from '../../../components/fields/FieldWeekPicker'
import { WeekRange } from '../../../components/weekPicker/WeekPicker'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { createReplenishmentFromProcurement, duplicateProcurement } from './services/procurementApi'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import FieldAutocomplete from '../../../components/fields/FieldAutocomplete'
import SplitButton from '../../../components/layout/buttons/SplitButton'
import { fetchDownload, getFormattedUrl } from '../../../utils/fetchDownload'
import DialogDuplicateProcurement, { DataDialogDuplicateProcurement } from './components/DialogDuplicateProcurement'
import errorsMapper from '../../../utils/errorsMapper'
import { OnChange } from 'react-final-form-listeners'
import { formatDecimal, getLocationOptions } from '../../../utils/formUtils'
import DialogImportProcurement from './components/DialogImportProcurement'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'

const START_OF_WEEK = moment().startOf('isoWeek').hours(12).toDate()
const END_OF_WEEK = moment().endOf('isoWeek').hours(12).toDate()
const DAYS: string[] = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
const NB_WEEKS_EXPORT = [1, 2, 5]
const PROCUREMENT_TABLE = 'procurementList'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		createButton: {
			marginBottom: 20
		},
		modeQteFieldLabel: {
			color: '#55555a',
			fontSize: 12,
			transform: 'scale(1)',
			fontWeight: 'normal',
			whiteSpace: 'nowrap'
		}
	})
)

interface Column {
	key: string,
	name: React.ReactElement,
	sortable?: boolean,
	render?: (row: any) => React.ReactElement
}

interface FormValues {
	idProduit?: string
	idCentre?: string
	weekRange: WeekRange
	modeQte: boolean
}

interface ProcurementListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void
}

const ProcurementList: React.FC<ProcurementListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const history = useHistory()
	const { vaccins, user: { selectedCenter, isAdminDispatch, isDateFormatEn, selectedLang } } = useContext(PhidemDataContext)
	const initialWeekRange = useMemo(() => ({
		from: START_OF_WEEK,
		to: END_OF_WEEK
	}), [])
	const [filters, setFilters] = useState<ProcurementListRequestModel>({
		idProduit: undefined,
		idCentre: selectedCenter && selectedCenter.type === CENTER_TYPE.VACCINATION_POINT ? selectedCenter.id : undefined,
		debutSemaine: initialWeekRange.from.toISOString(),
		modeQte: selectedCenter.modeQte || QTY_MODE.CONDITIONNEMENT
	})
	const [weekRangeToAdd, setWeekRangeToAdd] = useState<WeekRange>(initialWeekRange)
	const [loadingCreation, setLoadingCreation] = useState<boolean>(false)
	const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false)
	const [openDuplicationDialog, setOpenDuplicationDialog] = useState<boolean>(false)
	const [openImportDialog, setOpenImportDialog] = useState<boolean>(false)
	const [dataDialogProcurement, setDataDialogProcurement] = useState<DataDialogDuplicateProcurement>()

	const canManageProcurement = ((isAdminDispatch && selectedCenter && selectedCenter.type === CENTER_TYPE.DISPATCH)
		|| selectedCenter.type === CENTER_TYPE.REFERENT)
		&& moment(filters.debutSemaine).isSameOrAfter(START_OF_WEEK)

	const getHeaders = () => {
		const headers: Column[] = [
			{
				key: 'produit.id',
				name: <FormattedMessage
					id="global.functionnal.product"
					defaultMessage="Produit"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: ProcurementRowModel) => <span>{row.produit.nomCommercial}</span>
			}
		]

		if (selectedCenter.type !== CENTER_TYPE.VACCINATION_POINT) {
			headers.push(
				{
					key: 'centre.id',
					name: <FormattedMessage
						id="procurement.listLabels.vaccinationLocation"
						defaultMessage="Lieu de vaccination"
						description="Header column name"
					/>,
					sortable: true,
					render: (row: ProcurementRowModel) => <span>{`${row.centre.nom}${row.centre.service ? ` - ${row.centre.service}` : ''}`}</span>
				}
			)
		}

		DAYS.forEach((day: string, index: number) => {
			headers.push({
				key: day,
				name: <span>{displayDate(moment(filters?.debutSemaine).isoWeekday(index + 1), DATE_FORMAT.SHORT_DATE, isDateFormatEn)}</span>,
				sortable: true,
				render: (row: ProcurementRowModel) => filters.modeQte === QTY_MODE.CONDITIONNEMENT ?
					<span>{row[day as keyof ProcurementRowModel]}</span>
					:
					<>
						<span>{`D1 : ${row[`${day}1` as keyof ProcurementRowModel] || 0}`}</span>
						<br />
						<span>{`D2 : ${row[`${day}2` as keyof ProcurementRowModel] || 0}`}</span>
					</>
			})
		})

		headers.push({
			key: 'total',
			name: <FormattedMessage
				id="procurement.listLabels.total"
				defaultMessage="Soit en doses par semaine"
				description="Total header column"
			/>,
			sortable: false,
			render: (row: ProcurementRowModel) => {
				const sum = DAYS.reduce((acc: number, curr: string) => {
					let qtyToAdd = 0
					if (row.modeQte === QTY_MODE.CONDITIONNEMENT) {
						qtyToAdd = Number(row[curr as keyof ProcurementRowModel]) * row.produit.nbDoses
					} else {
						qtyToAdd = Number(row[`${curr}1` as keyof ProcurementRowModel]) + Number(row[`${curr}2` as keyof ProcurementRowModel])
					}

					return acc + qtyToAdd
				}, 0)

				return <span>{formatDecimal(sum)}</span>
			}
		})

		return headers
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.weekRange.from || !formValues.weekRange.to) {
			errors.weekRange = <ErrorMessageRequired />
		}

		return errors
	}

	const getProcurementPlan = (formValues: FormValues) => {
		let filterCenter

		if (selectedCenter && selectedCenter.type === CENTER_TYPE.VACCINATION_POINT) {
			filterCenter = selectedCenter.id
		} else {
			filterCenter = formValues.idCentre
		}

		setFilters({
			idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
			idCentre: filterCenter,
			debutSemaine: formValues?.weekRange?.from ? new Date(formValues.weekRange.from).toISOString() : '',
			modeQte: formValues.modeQte ? QTY_MODE.CONDITIONNEMENT : QTY_MODE.DOSE
		})
		setWeekRangeToAdd(formValues?.weekRange)
	}

	const createReplenishment = () => {
		setLoadingCreation(true)
		createReplenishmentFromProcurement(filters)
			.then(() => snackSuccess({ id: 'procurement.createReplenishmentSuccess', defaultMessage: 'La génération des demandes a été effectuée', description: 'Success message' }))
			.catch(() => snackError({ id: 'procurement.createReplenishmentError', defaultMessage: 'Une erreur est survenue, la génération n\' a pas été effectuée', description: 'Error message' }))
			.finally(() => setLoadingCreation(false))

	}

	const downloadDocument = (nbWeeks: number, isCumul: boolean) => {
		setIsLoadingDocument(true)
		fetchDownload(getFormattedUrl(`/vaccination/approvisionnement/export${isCumul ? '-cumul' : ''}/${nbWeeks}`, filters))
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = isCumul ? DOWNLOAD_FILE_TYPE.VACCINATION_APPROVISIONNEMENT_CUMULE.outputFile : DOWNLOAD_FILE_TYPE.VACCINATION_APPROVISIONNEMENT.outputFile
					a.click()
				}
			)
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	const onSubmitDialog = (values: DuplicateProcurementModel) => {
		return duplicateProcurement(values)
			.then(() => {
				snackSuccess({ id: 'procurement.duplicate.success', defaultMessage: 'L\'allocation a bien été dupliquée', description: 'Success message' })
				setOpenDuplicationDialog(false)
				setDataDialogProcurement(undefined)
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'procurement.duplicate.error', defaultMessage: 'Une erreur est survenue, l\'allocation n\'a pas été dupliquée', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						idProduit: SELECT_VALUES.ALL,
						idCentre: undefined,
						weekRange: initialWeekRange,
						modeQte: selectedCenter.modeQte || QTY_MODE.CONDITIONNEMENT
					}}
					// @ts-ignore
					validate={onValidate}
					onSubmit={getProcurementPlan}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item xs={12} md={5}>
									<Field
										name="idProduit"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functional.product"
												defaultMessage="Produit"
												description="Product"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{vaccins
											.map((vaccin: VaccinModel) => (
												<MenuItem
													key={vaccin.id}
													value={vaccin.id}
												>
													{vaccin.nomCommercial}
												</MenuItem>
											))}
									</Field>
								</Grid>
								{
									selectedCenter.type !== CENTER_TYPE.VACCINATION_POINT
									&&
									<Grid item xs={12} md={5}>
										<Field
											name="idCentre"
											label={<FormattedMessage
												id="procurement.fieldsLabel.vaccinationLocation"
												defaultMessage="Lieu de vaccination"
												description="input field"
											/>}
											placeholder={intl.formatMessage({
												id: 'select.all',
												defaultMessage: 'Tous',
												description: 'All'
											})}
											component={FieldAutocomplete}
											options={(() => getLocationOptions(selectedCenter, selectedCenter.type === CENTER_TYPE.VACCINATION))()}
										/>
									</Grid>
								}
								<FieldWeekPicker
									name="weekRange"
									label={
										<FormattedMessage
											id="procurement.fieldsLabel.date"
											defaultMessage="Date"
											description="form input"
										/>
									}
									isDateFormatEn={isDateFormatEn}
									selectedLang={selectedLang}
									required
								/>
								<Grid item xs={12} md={5}>
									<span className={classes.modeQteFieldLabel}>
										<FormattedMessage
											id="procurement.fieldsLabel.modeQteLabel"
											defaultMessage="Afficher en :"
											description="Product"
										/>
									</span>
									<Field
										name="modeQte"
										component={FormSwitch}
										label={
											<FormattedMessage
												id="procurement.fieldsLabel.modeQte"
												defaultMessage={`${QTY_MODE.DOSE} / ${QTY_MODE.CONDITIONNEMENT}`}
												description="Product"
											/>
										}
									/>
									<OnChange name="modeQte">
										{(value) => {
											setFilters((filters: ProcurementListRequestModel) => {
												return { ...filters, modeQte: value ? QTY_MODE.CONDITIONNEMENT : QTY_MODE.DOSE }
											})
										}}
									</OnChange>
								</Grid>

								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Paper>

			<RedirectFAB
				redirectPath="/procurements/add"
				redirectState={{ weekRange: weekRangeToAdd }}
				showButton={canManageProcurement}
			>
				<SpringDataTable
					apiUrl="/vaccination/approvisionnement"
					nom={PROCUREMENT_TABLE}
					headers={getHeaders()}
					filters={filters}
					noResultFragment={<NotFoundPage />}
					makeActions={(row: ProcurementRowModel) => {
						const actions = [{
							label: <FormattedMessage
								id="procurement.duplicate.btn"
								defaultMessage="Dupliquer"
								description="Duplicate action"
							/>, action: () => {
								setDataDialogProcurement({
									id: row.id,
									centerName: row.centre.nom,
									productName: row.produit.nomCommercial,
									conditioningType: row.produit.typeConditionnement,
									modeQte: filters.modeQte,
									debutSemaine: row.debutSemaine,
									lundi: row.lundi,
									lundi1: row.lundi1,
									lundi2: row.lundi2,
									mardi: row.mardi,
									mardi1: row.mardi1,
									mardi2: row.mardi2,
									mercredi: row.mercredi,
									mercredi1: row.mercredi1,
									mercredi2: row.mercredi2,
									jeudi: row.jeudi,
									jeudi1: row.jeudi1,
									jeudi2: row.jeudi2,
									vendredi: row.vendredi,
									vendredi1: row.vendredi1,
									vendredi2: row.vendredi2,
									samedi: row.samedi,
									samedi1: row.samedi1,
									samedi2: row.samedi2,
									dimanche: row.dimanche,
									dimanche1: row.dimanche1,
									dimanche2: row.dimanche2
								})
								setOpenDuplicationDialog(true)
							}
						}]

						actions.unshift({
							label: <FormattedMessage
								id="global.update"
								defaultMessage="Modifier"
								description="Update action"
							/>, action: () => {
								history.push(`/procurements/${row.id}`)
							}
						})

						return actions
					}}
					showActions={canManageProcurement}
				/>
				{canManageProcurement && <>
					<Grid container direction="column" alignItems="center">
						<Button
							color="primary"
							variant="contained"
							onClick={() => setOpenImportDialog(true)}
							className={classes.createButton}
						>
							<FormattedMessage
								id="procurement.import"
								defaultMessage="Importer les allocations"
								description="Import procurement button"
							/>
						</Button>
					</Grid>
					<Grid container direction="column" alignItems="center">
						<Button
							color="primary"
							variant="contained"
							isLoading={loadingCreation}
							onClick={createReplenishment}
							className={classes.createButton}
						>
							<FormattedMessage
								id="procurement.createReplenishment"
								defaultMessage="Générer les demandes"
								description="Create replenishment button"
							/>
						</Button>
					</Grid>
				</>}
				<SplitButton
					isLoading={isLoadingDocument}
					label="button.export"
					items={[
						...NB_WEEKS_EXPORT.map((nbWeeks: number) => ({
							label: intl.formatMessage({
								id: `procurement.export.${nbWeeks}`,
								defaultMessage: `Détaillé sur ${nbWeeks} semaines`,
								description: 'Export button'
							}),
							onClick: () => downloadDocument(nbWeeks, false)
						})),
						...NB_WEEKS_EXPORT.map((nbWeeks: number) => ({
							label: intl.formatMessage({
								id: `procurement.exportCumul.${nbWeeks}`,
								defaultMessage: `Cumulé sur ${nbWeeks} semaines`,
								description: 'Export button'
							}),
							onClick: () => downloadDocument(nbWeeks, true)
						}))
					]} />
			</RedirectFAB>
			<DialogDuplicateProcurement
				isOpen={openDuplicationDialog}
				handleClose={() => {
					setDataDialogProcurement(undefined)
					setOpenDuplicationDialog(false)
				}}
				data={dataDialogProcurement}
				onSubmit={onSubmitDialog}
			/>
			{openImportDialog &&
				<DialogImportProcurement
					isOpen={openImportDialog}
					handleClose={() => setOpenImportDialog(false)}
					onSuccess={() => {
						setOpenImportDialog(false)
						refreshTable()
					}}
					startWeek={weekRangeToAdd}
				/>}
		</PageLayout>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh(PROCUREMENT_TABLE)
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ProcurementList)
