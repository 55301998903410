import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'

interface DialogReplenishmentCancelConfirmationProps {
	isOpen: boolean
	handleClose: () => any
	onSubmit: () => void
}

const DialogReplenishmentCancelConfirmation: React.FC<DialogReplenishmentCancelConfirmationProps> = ({
     isOpen,
     handleClose,
     onSubmit
 }) => {
	return (
		<Form
			onSubmit={onSubmit}
			render={({ values, handleSubmit }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.replenishmentList.dialog.cancelConfirmation.title"
							defaultMessage="Annuler la confirmation de réapprovisionnement"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.return"
								defaultMessage="Retour"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => handleSubmit(values)}>
							<FormattedMessage
								id="button.confirm"
								defaultMessage="Confirmer"
								description="Button label"
							/>
						</Button>
					]}
				>
						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.cancelConfirmation.message"
								defaultMessage="Êtes-vous sûr de vouloir annuler la confirmation de cette demande de réapprovisionnement ?"
								description="Dialog content"
							/>
						</Typography>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentCancelConfirmation
