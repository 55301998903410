import React from 'react'

const SvgStockEntry = () => (<svg
  xmlns="http://www.w3.org/2000/svg"
  width={84.194}
  height={58.146}
>
  <g data-name="Groupe 335">
    <path
      data-name="Trac\xE9 1145"
      d="M28.939 53.392a4.751 4.751 0 11-4.75-4.752 4.751 4.751 0 014.75 4.752z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1146"
      d="M79.736 53.392a4.751 4.751 0 11-4.75-4.752 4.751 4.751 0 014.75 4.752z"
      fill="#414042"
    />
    <path
      data-name="Rectangle 483"
      fill="#787878"
      d="M9.484 46.62h74.711v2.724H9.484z"
    />
    <path
      data-name="Trac\xE9 1147"
      d="M13.308 48.636a.839.839 0 01-.838-.839V15.162a6.7 6.7 0 00-2.356-5.1L1.259 2.513a.838.838 0 011.088-1.276l8.854 7.548a8.372 8.372 0 012.946 6.38V47.8a.839.839 0 01-.839.836z"
      fill="#787878"
    />
    <path
      data-name="Trac\xE9 1148"
      d="M8.137 9.546a2.264 2.264 0 01-1.475-.543l-5.863-5A2.275 2.275 0 113.75.541l5.862 5a2.273 2.273 0 01-1.475 4.005z"
      fill="#0082aa"
    />
    <path
      data-name="Trac\xE9 1149"
      d="M24.189 49.344h-4.751l2.725 4.053h4.052l2.725-4.053z"
      fill="#b4b4b4"
    />
    <path
      data-name="Trac\xE9 1150"
      d="M26.214 53.392a2.026 2.026 0 11-2.025-2.028 2.025 2.025 0 012.025 2.028z"
      fill="#787878"
    />
    <path
      data-name="Trac\xE9 1151"
      d="M74.989 49.344h-4.751l2.725 4.053h4.052l2.725-4.053z"
      fill="#b4b4b4"
    />
    <path
      data-name="Trac\xE9 1152"
      d="M77.012 53.392a2.026 2.026 0 11-2.026-2.028 2.024 2.024 0 012.026 2.028z"
      fill="#787878"
    />
    <path
      data-name="Rectangle 484"
      fill="#ffdc00"
      d="M45.948 32.683h14.145v13.936H45.948z"
    />
    <g data-name="Groupe 334">
      <path
        data-name="Rectangle 485"
        fill="#ffc800"
        d="M26.278 25.898h21.547v20.621H26.278z"
      />
      <path
        data-name="Rectangle 486"
        fill="#ffdc00"
        d="M34.479 25.898h5.144v1.998h-5.144z"
      />
      <path
        data-name="Rectangle 487"
        fill="#ffc800"
        opacity={0.15}
        style={{
          mixBlendMode: 'screen',
          isolation: 'isolate'
        }}
        d="M37.053 25.896h10.774v20.625H37.053z"
      />
      <path
        data-name="Rectangle 488"
        fill="#f4f4f4"
        d="M38.799 40.062h7.607v3.82h-7.607z"
      />
      <path
        data-name="Rectangle 489"
        fill="#ffdc00"
        d="M17.895 35.275H34.52v11.246H17.895z"
      />
      <path
        data-name="Rectangle 490"
        fill="#ffc800"
        d="M24.811 35.275h2.794v1.537h-2.794z"
      />
      <path
        data-name="Rectangle 491"
        fill="#ffdc00"
        opacity={0.25}
        style={{
          mixBlendMode: 'screen',
          isolation: 'isolate'
        }}
        d="M26.208 35.275h8.312v11.246h-8.312z"
      />
      <path
        data-name="Rectangle 492"
        fill="#f4f4f4"
        d="M19.187 36.812h4.087v3.318h-4.087z"
      />
    </g>
    <path
      data-name="Rectangle 493"
      fill="#ffc800"
      d="M53.019 28.497H74.99V46.62H53.019z"
    />
    <path
      data-name="Rectangle 494"
      fill="#ffdc00"
      d="M62.047 28.497h3.915v1.827h-3.915z"
    />
    <path
      data-name="Rectangle 495"
      fill="#ffc800"
      opacity={0.15}
      style={{
        mixBlendMode: 'screen',
        isolation: 'isolate'
      }}
      d="M64.004 28.497h10.985V46.62H64.004z"
    />
    <path
      data-name="Rectangle 496"
      fill="#fff"
      d="M65.77 40.898h7.455v4.15H65.77z"
    />
    <path
      data-name="Rectangle 497"
      fill="#ffdc00"
      d="M54.613 19.831h8.749v8.666h-8.749z"
    />
    <path
      data-name="Rectangle 498"
      fill="#ffc800"
      d="M58.056 19.831h1.863v.768h-1.863z"
    />
    <path
      data-name="Rectangle 499"
      fill="#ffdc00"
      opacity={0.25}
      style={{
        mixBlendMode: 'screen',
        isolation: 'isolate'
      }}
      d="M58.987 19.831h4.375v8.666h-4.375z"
    />
  </g>
</svg>)

export default SvgStockEntry
