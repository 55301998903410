import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AccueilPage from '../modules/accueil/AccueilPage'
import { Page, ProfilePage, UsersPage, ValueListPage } from 'isotope-client'
import LegalNotice from '../modules/common/LegalNotice'
import EnterStock from '../modules/common/dispatchAndReferent/enterStock/EnterStock'
import { CENTER_TYPE, USER_ROLE } from '../utils/constants'
import ExitStockReferent from '../modules/common/dispatchAndReferent/exitStock/ExitStock'
import ExitStockPrintReferent from '../modules/common/dispatchAndReferent/exitStock/ExitStockPrint'
import Scrapping from '../modules/common/dispatchAndReferent/scrapping/Scrapping'
import ScrappingPrint from '../modules/common/dispatchAndReferent/scrapping/ScrappingPrint'
import Dashboard from '../modules/common/dashboard/Dashboard'
import StockReferentSelf from '../modules/referent/stock/StockReferentSelf'
import StockAdmin from '../modules/admin/stock/components/StockAdmin'
import ExitStock from '../modules/dispatch/exitStock/ExitStock'
import ExitStockPrint from '../modules/dispatch/exitStock/ExitStockPrint'
import ProductReturn from '../modules/dispatch/productReturn/ProductReturn'
import ProductReturnPrint from '../modules/dispatch/productReturn/ProductReturnPrint'
import StockDispatchSelf from '../modules/dispatch/stock/StockDispatchSelf'
import StockDispatchAffiliatedVaccinationCenters from '../modules/dispatch/stock/StockDispatchAffiliatedVaccinationCenters'
import EnterStockVaccination from '../modules/vaccination/enterStock/EnterStock'
import ExitFridge from '../modules/vaccination/exitFridge/ExitFridge'
import ExitFridgePrint from '../modules/vaccination/exitFridge/ExitFridgePrint'
import DeclarationOfUse from '../modules/vaccination/declarationOfUse/DeclarationOfUse'
import ScrappingVaccination from '../modules/vaccination/scrapping/Scrapping'
import ScrappingPrintVaccination from '../modules/vaccination/scrapping/ScrappingPrint'
import Replenishment from '../modules/vaccination/replenishment/Replenishment'
import ReplenishmentDemand from '../modules/pointVaccination/replenishment/ReplenishmentDemand'
import StockVaccination from '../modules/vaccination/stock/components/StockVaccination'
import OnlyDesktop from '../components/layout/OnlyDesktop'
import PageLayout from '../components/layout/PageLayout'
import ReplenishmentList from '../modules/common/replenishment/ReplenishmentList'
import MovementHistory from '../modules/common/dispatchAndReferent/movementHistory/MovementHistory'
import ProcurementList from '../modules/common/procurement/ProcurementList'
import ProcurementAdd from '../modules/common/procurement/ProcurementAdd'
import ProcurementUpdate from '../modules/common/procurement/ProcurementUpdate'
import VaccinPointReplenishmentList from '../modules/common/replenishment/VaccinPointReplenishmentList'
import MovementHistoryVaccination from '../modules/vaccination/movementHistory/MovementHistory'
import UsageControl from '../modules/common/dispatchAndReferent/declarationOfUse/UsageControl'
import CenterList from '../modules/admin/center/CenterList'
import CenterDetailsCreate from '../modules/admin/center/CenterDetailsCreate'
import CenterDetailsUpdate from '../modules/admin/center/CenterDetailsUpdate'
import ProductList from '../modules/admin/product/ProductList'
import ProductCreate from '../modules/admin/product/ProductCreate'
import ProductUpdate from '../modules/admin/product/ProductUpdate'
import TrackerList from '../modules/admin/tracker/TrackerList'
import TrackerCreate from '../modules/admin/tracker/TrackerCreate'
import TrackerUpdate from '../modules/admin/tracker/TrackerUpdate'
import ContainerList from '../modules/admin/container/ContainerList'
import ContainerCreate from '../modules/admin/container/ContainerCreate'
import ContainerUpdate from '../modules/admin/container/ContainerUpdate'
import CarrierList from '../modules/admin/carrier/CarrierList'
import CarrierCreate from '../modules/admin/carrier/CarrierCreate'
import CarrierUpdate from '../modules/admin/carrier/CarrierUpdate'
import MailForm from '../modules/admin/mail/MailForm'
import TemplateList from '../modules/admin/template/TemplateList'
import TemplateCreate from '../modules/admin/template/TemplateCreate'
import TemplateUpdate from '../modules/admin/template/TemplateUpdate'
import { OfflineContext } from '../modules/common/offline/context/OfflineContext'
import SynchronisationPage from '../modules/vaccination/synchronisation/SynchronisationPage'

type AppRoutesProps = {
	location: string
	session: any
}

const AppRoutes = ({ location, session }: AppRoutesProps) => {
	const { offlineMode, lastSynchronisation } = useContext(OfflineContext)
	return <Page
		location={location}
		menu={`centerId=${session.centerId || 'ADMIN'}&offlineMode=${offlineMode}${lastSynchronisation ? '-withData' : ''}`}
	>
		<Switch>
			<Route exact path="/" render={() => offlineMode ? <SynchronisationPage /> : <AccueilPage />} />
			<Route path="/me" component={ProfilePage} />
			<Route path="/notice" component={LegalNotice} />

			{/* REFERENT */}
			<Route path="/referent/stock-entries" render={() => <EnterStock centerType={CENTER_TYPE.REFERENT} />} />
			<Route exact path="/referent/exits" component={ExitStockReferent} />
			<Route exact path="/referent/exits/:id" component={ExitStockPrintReferent} />
			<Route exact path="/referent/scrappings" render={() => <Scrapping centerType={CENTER_TYPE.REFERENT} />} />
			<Route path="/referent/scrappings/:id" render={() => <ScrappingPrint centerType={CENTER_TYPE.REFERENT} />} />
			<Route exact path="/referent/dashboard" component={Dashboard} />
			<Route exact path="/referent/stocks" component={StockReferentSelf} />
			<Route exact path="/referent/affiliated-stocks" render={() => <StockAdmin fromReferentAffiliatedView />} />

			{/* DISPATCH */}
			<Route path="/dispatch/stock-entries" render={() => <EnterStock centerType={CENTER_TYPE.DISPATCH} />} />
			<Route exact path="/dispatch/exits" component={ExitStock} />
			<Route path="/dispatch/exits/:id" component={ExitStockPrint} />
			<Route exact path="/dispatch/scrappings" render={() => <Scrapping centerType={CENTER_TYPE.DISPATCH} />} />
			<Route path="/dispatch/scrappings/:id" render={() => <ScrappingPrint centerType={CENTER_TYPE.DISPATCH} />} />
			<Route exact path="/dispatch/dashboard" component={Dashboard} />
			<Route exact path="/dispatch/return" component={ProductReturn} />
			<Route exact path="/dispatch/return/:id" component={ProductReturnPrint} />
			<Route exact path="/dispatch/stocks" component={StockDispatchSelf} />
			<Route exact path="/dispatch/referent/stocks" component={StockDispatchAffiliatedVaccinationCenters} />

			{/* VACCINATION */}
			<Route path="/vaccination/stock-entries" component={EnterStockVaccination} />
			<Route exact path="/vaccination/exits" component={ExitFridge} />
			<Route path="/vaccination/exits/:id" component={ExitFridgePrint} />
			<Route path="/vaccination/declarations-of-use" component={DeclarationOfUse} />
			<Route exact path="/vaccination/scrappings" component={ScrappingVaccination} />
			<Route path="/vaccination/scrappings/:id" component={ScrappingPrintVaccination} />
			<Route path="/vaccination/replenishment-form" component={Replenishment} />
			<Route path="/vaccination-point/replenishment-form" component={ReplenishmentDemand} />
			<Route exact path="/vaccination/dashboard" component={Dashboard} />
			<Route exact path="/vaccination/stocks" component={StockVaccination} />

			{/* ADMIN */}
			<Route exact path="/stocks" component={StockAdmin} />

			{/* DESKTOP ONLY */}
			<OnlyDesktop>
				<PageLayout>
					<Switch>
						<Route exact path="/dispatch/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_DISPATCH_CENTER} />} />
						<Route exact path="/dispatch/movements" render={() => <MovementHistory centerType={CENTER_TYPE.DISPATCH} />} />
						<Route exact path="/procurements" component={ProcurementList} />
						<Route exact path="/procurements/add" component={ProcurementAdd} />
						<Route exact path="/procurements/:id" component={ProcurementUpdate} />
						<Route exact path="/vaccination/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_VACCINATION_CENTER} />} />
						<Route exact path="/vaccination/affiliated-replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_VACCINATION_CENTER} />} />
						<Route exact path="/vaccination-point/replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_VACCINATION_POINT} />} />
						<Route exact path="/vaccination/movements" component={MovementHistoryVaccination} />
						<Route exact path="/usage-control" component={UsageControl} />

						{/* REFERENT */}
						<Route exact path="/referent/movements" render={() => <MovementHistory centerType={CENTER_TYPE.REFERENT} />} />

						{/* ADMIN */}
						<Route path="/users" component={UsersPage} />
						<Route exact path="/centers" component={CenterList} />
						<Route exact path="/centers/add" component={CenterDetailsCreate} />
						<Route exact path="/centers/:id" component={CenterDetailsUpdate} />
						<Route exact path="/products" component={ProductList} />
						<Route exact path="/products/add" component={ProductCreate} />
						<Route exact path="/products/:id" component={ProductUpdate} />
						<Route exact path="/trackers" component={TrackerList} />
						<Route exact path="/trackers/add" component={TrackerCreate} />
						<Route exact path="/trackers/:id" component={TrackerUpdate} />
						<Route exact path="/containers" component={ContainerList} />
						<Route exact path="/containers/add" component={ContainerCreate} />
						<Route exact path="/containers/:id" component={ContainerUpdate} />
						<Route exact path="/carriers" component={CarrierList} />
						<Route exact path="/carriers/add" component={CarrierCreate} />
						<Route exact path="/carriers/:id" component={CarrierUpdate} />
						<Route exact path="/mail" component={MailForm} />
						<Route exact path="/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_ADMIN} />} />
						<Route exact path="/point-replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_ADMIN} />} />
						<Route exact path="/templates" component={TemplateList} />
						<Route exact path="/templates/add" component={TemplateCreate} />
						<Route exact path="/templates/:id" component={TemplateUpdate} />

						<Route exact path="/value-list" component={ValueListPage} />
						<Route exact path="/value-list/:id" component={ValueListPage} />
					</Switch>
				</PageLayout>
			</OnlyDesktop>
		</Switch>
	</Page>
}
export default AppRoutes
