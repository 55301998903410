import { fetchFactory } from 'isotope-client'
import { DechetFormModel } from './scrappingModels'

export const postDechetStock = (prefixApiUrl: string, values: DechetFormModel) => fetchFactory(`/${prefixApiUrl}/dechet`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getDechetById = (prefixApiUrl: string, idDechet: string) => fetchFactory(`/${prefixApiUrl}/dechet/${idDechet}`)

export const getStockForScrapping = (prefixApiUrl: string, idProduit: string) => fetchFactory(`/${prefixApiUrl}/preparation?idProduit=${idProduit}`)

