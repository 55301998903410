import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { createStyles, Grid, makeStyles, MenuItem, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { FormInput, FormSelect } from 'isotope-client'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import FieldProduct from '../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../components/fields/FieldUnitProduct'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { QTY_MODE, SCREEN_SIZE } from '../../../utils/constants'
import { postNeeds } from '../services/vaccinationApi'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import Dialog from '../../../components/layout/dialog/Dialog'
import ErrorPositiveValue from '../../../components/layout/errors/ErrorPositiveValue'
import errorsMapper from '../../../utils/errorsMapper'
import FieldDatetime from '../../../components/fields/FieldDatetime'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import FieldRadio from '../../../components/fields/FieldRadio'
import { getProcurement } from '../../dispatch/services/dispatchApi'
import { ProcurementRowModel } from '../../common/procurement/services/procurementModels'
import DialogReplenishmentProcurement, { DataDialogProcurement } from '../../common/replenishment/components/DialogReplenishmentProcurement'
import DialogQtyProcurementWarning from '../../dispatch/exitStock/components/DialogQtyProcurementWarning'
import { getAllCarriers } from '../../common/phidemData/phidemDataApi'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		coverage: {
			fontWeight: 'bold',
			textDecoration: 'underline',

			'& span:hover': {
				cursor: 'pointer'
			}
		}
	})
)

interface FormValues {
	product: string
	unitProduct: string
	modeQte: string
	nbDoses1?: number
	nbDoses2?: number
	nbDosesR?: number
	nbCond?: number
	datetimeSouhaitee: string
	datetimeCouverturDebut?: string
	datetimeCouvertureFin?: string
	dateSouhaitee: string
	timeSouhaitee: string
	dateCouvertureDebut?: string
	timeCouvertureDebut?: string
	dateCouvertureFin?: string
	timeCouvertureFin?: string
	idTransporteur?: string
	commentaire: string
}

interface ReplenishmentProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const Replenishment: React.FC<ReplenishmentProps> = ({
	                                                     snackSuccess,
	                                                     snackError
                                                     }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { vaccins, user: { selectedCenter, isDateFormatEn }, application: { configuration } } = useContext(PhidemDataContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [productTotal, setProductTotal] = useState<number>()
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const [formValuesToSubmit, setFormValuesToSubmit] = useState<FormValues>()
	const [selectedProduct, setSelectedProduct] = useState<any>(undefined)
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[
				dateAndTimeDecorator('dateSouhaitee', 'timeSouhaitee', 'datetimeSouhaitee'),
				dateAndTimeDecorator('dateCouvertureDebut', 'timeCouvertureDebut', 'datetimeCouverturDebut'),
				dateAndTimeDecorator('dateCouvertureFin', 'timeCouvertureFin', 'datetimeCouvertureFin')
			]
		, [isDatetimeSupported])
	const initValues = useMemo(() => ({ modeQte: QTY_MODE.CONDITIONNEMENT, idTransporteur: selectedCenter.idTransporteur }), [selectedCenter.idTransporteur])
	const [isOpenDialogProcurement, setIsOpenDialogProcurement] = useState<boolean>(false)
	const [dataDialogProcurement, setDataDialogProcurement] = useState<DataDialogProcurement>()
	const [isOpenDialogProcurementWarning, setIsOpenDialogProcurementWarning] = React.useState<boolean>(false)
	const [carriers, setCarriers] = useState([])
	const [submitLoader, setSubmitLoader] = useState<boolean>(false)

	useEffect(() => {
		getAllCarriers().then(setCarriers)
	}, [])

	const openDialog = (formValues: FormValues) => {
		setFormValuesToSubmit(formValues)
		setIsDialogOpen(true)
	}

	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	const dialogConfirm = async (form: any, forceValidation: boolean) => {
		if (formValuesToSubmit) {
			setSubmitLoader(true)
			const quantitySent = formValuesToSubmit.modeQte === QTY_MODE.DOSE ?
				{ nbDoses1: formValuesToSubmit.nbDoses1 || 0, nbDoses2: formValuesToSubmit.nbDoses2 || 0, nbDosesR: formValuesToSubmit.nbDosesR || 0 }
				: { nbCond: formValuesToSubmit.nbCond || 0 }

			return postNeeds({
				idProduit: formValuesToSubmit.product,
				modeQte: formValuesToSubmit.modeQte,
				...quantitySent,
				dateSouhaitee: new Date(formValuesToSubmit.datetimeSouhaitee).toISOString(),
				dateCouvertureDebut: formValuesToSubmit.datetimeCouverturDebut && new Date(formValuesToSubmit.datetimeCouverturDebut).toISOString(),
				dateCouvertureFin: formValuesToSubmit.datetimeCouvertureFin && new Date(formValuesToSubmit.datetimeCouvertureFin).toISOString(),
				idTransporteur: formValuesToSubmit.idTransporteur,
				commentaire: formValuesToSubmit.commentaire,
				forceValidation: forceValidation
			})
				.then(() => {
					closeDialog()
					setIsOpenDialogProcurementWarning(false)
					form.restart()
					snackSuccess({ id: 'vaccination.replenishment.success', defaultMessage: 'La demande de réapprovisionement a bien été enregistrée', description: 'Success message' })
				})
				.catch((e: any) => {
					// Gestion du retour de warning pour allocation
					if (e && e.bodyError) {
						if (e.bodyError.globalErrors && e.bodyError.globalErrors.length > 0 && e.bodyError.globalErrors[0].code === 'quantitePrevision') {
							setIsOpenDialogProcurementWarning(true)
							return
						}
					}

					const { errors, displayFields } = errorsMapper(e)
					if (displayFields) {
						snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
					} else {
						snackError({
							id: 'vaccination.replenishment.error',
							defaultMessage: 'Une erreur est survenue, la demande de réapprovisionement n\'a pas été enregistrée',
							description: 'Error message'
						})
					}
					return errors
				})
				.finally(() => setSubmitLoader(false))
		}
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.product) {
			errors.product = <ErrorMessageRequired />
		}

		if (formValues.modeQte === QTY_MODE.DOSE) {
			if ((!formValues.nbDoses1 && !formValues.nbDoses2 && !formValues.nbDosesR) ||
				(Number(formValues.nbDoses1) === 0 && Number(formValues.nbDoses2) === 0 && Number(formValues.nbDosesR) === 0)) {
				errors.nbDoses1 = <FormattedMessage
					id="global.error.validation.oneOfThree"
					defaultMessage="Veuillez renseigner au moins un des trois champs"
					description="Error message"
				/>
			} else if (formValues.nbDoses1 && formValues.nbDoses1 < 0) {
				errors.nbDoses1 = <ErrorPositiveValue />
			} else if (formValues.nbDoses2 && formValues.nbDoses2 < 0) {
				errors.nbDoses2 = <ErrorPositiveValue />
			} else if (formValues.nbDosesR && formValues.nbDosesR < 0) {
				errors.nbDosesR = <ErrorPositiveValue />
			} else if ((Number(formValues.nbDoses1) || 0) + (Number(formValues.nbDoses2) || 0) + (Number(formValues.nbDosesR) || 0) <= 0) {
				errors.nbDoses1 = <ErrorPositiveValue />
				errors.nbDoses2 = <ErrorPositiveValue />
				errors.nbDosesR = <ErrorPositiveValue />
			}
		} else {
			if (!formValues.nbCond) {
				errors.nbCond = <ErrorMessageRequired />
			} else if (formValues.nbCond < 1) {
				errors.nbCond = <ErrorPositiveValue />
			}
		}

		if (isDatetimeSupported) {
			if (!formValues.datetimeSouhaitee) {
				errors.datetimeSouhaitee = <ErrorMessageRequired />
			} else if (moment(formValues.datetimeSouhaitee)
				.isBefore(moment().startOf('day'))) {
				// should be after now
				errors.datetimeSouhaitee = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date prior to now"
				/>
			}

			if (!formValues.datetimeCouverturDebut && formValues.datetimeCouvertureFin) {
				errors.datetimeCouverturDebut = <ErrorMessageRequired />
			} else if (moment(formValues.datetimeCouverturDebut)
				.isBefore(moment().startOf('day'))) {
				// should be after now
				errors.datetimeCouverturDebut = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date prior to now"
				/>
			} else if (formValues.datetimeCouverturDebut && moment(formValues.datetimeCouverturDebut).isBefore(moment(formValues.datetimeSouhaitee))) {
				// should be the before datetimeSouhaitee
				errors.datetimeCouverturDebut = <FormattedMessage
					id="global.error.validation.datePriorToReceipt"
					defaultMessage="La date ne peut pas être antérieure à la date de réception"
					description="Error message of date prior to receipt date"
				/>
			} else if (formValues.datetimeCouvertureFin && moment(formValues.datetimeCouverturDebut)
				.isAfter(formValues.datetimeCouvertureFin)) {
				// should be before end
				errors.datetimeCouverturDebut = <FormattedMessage
					id="global.error.validation.startDateAfterEndDate"
					defaultMessage="La date de début ne peut pas être antérieure à celle de fin"
					description="Error message of start date prior to end date"
				/>
			} else if (formValues.datetimeCouvertureFin && moment(formValues.datetimeCouverturDebut).week() !== moment(formValues.datetimeCouvertureFin).week()) {
				// should be the same datetimeCouvertureFin
				errors.datetimeCouverturDebut = <FormattedMessage
					id="global.error.validation.weekDifferents"
					defaultMessage="La semaine ne peut pas être différente de la date de fin"
					description="Error message of week different than ending date"
				/>
			}

			if (!formValues.datetimeCouvertureFin && formValues.datetimeCouverturDebut) {
				errors.datetimeCouvertureFin = <ErrorMessageRequired />
			} else if (moment(formValues.datetimeCouvertureFin)
				.isBefore(moment().startOf('day'))) {
				// should be after now
				errors.datetimeCouvertureFin = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date prior to now"
				/>
			} else if (formValues.datetimeCouvertureFin && moment(formValues.datetimeCouvertureFin).isBefore(moment(formValues.datetimeSouhaitee))) {
				// should be the before datetimeSouhaitee
				errors.datetimeCouvertureFin = <FormattedMessage
					id="global.error.validation.datePriorToReceipt"
					defaultMessage="La date ne peut pas être antérieure à la date de réception"
					description="Error message of date prior to receipt date"
				/>
			} else if (formValues.datetimeCouverturDebut && moment(formValues.datetimeCouvertureFin)
				.isBefore(formValues.datetimeCouverturDebut)) {
				// should be after now
				errors.datetimeCouvertureFin = <FormattedMessage
					id="global.error.validation.endDateBeforeStartDate"
					defaultMessage="La date de fin ne peut pas être antérieure à celle de début"
					description="Error message of end date prior to start date"
				/>
			} else if (formValues.datetimeCouverturDebut && moment(formValues.datetimeCouvertureFin).week() !== moment(formValues.datetimeCouverturDebut).week()) {
				// should be the same week
				errors.datetimeCouvertureFin = <FormattedMessage
					id="global.error.validation.weekDifferents"
					defaultMessage="La semaine ne peut pas être différente de la date de début"
					description="Error message of week different than starting date"
				/>
			}


			if (selectedProduct && selectedProduct.stabilite28 && formValues.datetimeSouhaitee && formValues.datetimeCouvertureFin) {
				const daysBetweenEndAndReceipt = (Date.parse(formValues.datetimeCouvertureFin) - Date.parse(formValues.datetimeSouhaitee)) / 86400000
				const daysOfStability = selectedProduct.stabilite28 / 24

				if (daysBetweenEndAndReceipt > daysOfStability) {
					errors.datetimeCouvertureFin = <FormattedMessage
						id="global.error.validation.unstability"
						defaultMessage="L'intégrité du produit ne sera pas garantie de sa réception jusque cette date"
						description="Error message of date prior to receipt date"
					/>
				}
			}

		} else {
			if (!formValues.dateSouhaitee) {
				errors.dateSouhaitee = <ErrorMessageRequired />
			}
			if (!formValues.timeSouhaitee) {
				errors.timeSouhaitee = <ErrorMessageRequired />
			}
			if (formValues.dateSouhaitee && formValues.timeSouhaitee && formValues.datetimeSouhaitee &&
				moment(formValues.datetimeSouhaitee)
					.isBefore(moment().startOf('day'))) {
				// should be after now
				errors.dateSouhaitee = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date prior to now"
				/>
				errors.timeSouhaitee = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date prior to now"
				/>
			}

			if (!formValues.dateCouvertureDebut && formValues.dateCouvertureFin) {
				errors.dateCouvertureDebut = <ErrorMessageRequired />
			}
			if (!formValues.timeCouvertureDebut && formValues.dateCouvertureFin) {
				errors.timeCouvertureDebut = <ErrorMessageRequired />
			}

			if (formValues.dateCouvertureDebut && formValues.timeCouvertureDebut && formValues.datetimeCouverturDebut) {
				if (moment(formValues.datetimeCouverturDebut)
					.isBefore(moment().startOf('day'))) {
					// should be after now
					errors.dateCouvertureDebut = <FormattedMessage
						id="global.error.validation.datePriorToNow"
						defaultMessage="La date ne peut pas être antérieure à maintenant"
						description="Error message of date prior to now"
					/>
					errors.timeCouvertureDebut = <FormattedMessage
						id="global.error.validation.datePriorToNow"
						defaultMessage="La date ne peut pas être antérieure à maintenant"
						description="Error message of date prior to now"
					/>
				} else if (formValues.datetimeSouhaitee && formValues.datetimeSouhaitee && formValues.datetimeSouhaitee && moment(formValues.datetimeCouverturDebut)
					.isBefore(formValues.datetimeSouhaitee)) {
					// should be before end
					errors.dateCouvertureDebut = <FormattedMessage
						id="global.error.validation.datePriorToReceipt"
						defaultMessage="La date ne peut pas être antérieure à la date de réception"
						description="Error message of date prior to receipt date"
					/>
					errors.timeCouvertureDebut = <FormattedMessage
						id="global.error.validation.datePriorToReceipt"
						defaultMessage="La date ne peut pas être antérieure à la date de réception"
						description="Error message of date prior to receipt date"
					/>
				} else if (formValues.dateCouvertureFin && formValues.timeCouvertureFin && formValues.datetimeCouvertureFin && moment(formValues.datetimeCouverturDebut)
					.isAfter(formValues.datetimeCouvertureFin)) {
					// should be before end
					errors.dateCouvertureDebut = <FormattedMessage
						id="global.error.validation.startDateAfterEndDate"
						defaultMessage="La date de début ne peut pas être antérieure à celle de fin"
						description="Error message of start date prior to end date"
					/>
					errors.timeCouvertureDebut = <FormattedMessage
						id="global.error.validation.startDateAfterEndDate"
						defaultMessage="La date de début ne peut pas être antérieure à celle de fin"
						description="Error message of start date prior to end date"
					/>
				}
			}

			if (!formValues.dateCouvertureFin && formValues.dateCouvertureDebut) {
				errors.dateCouvertureFin = <ErrorMessageRequired />
			}
			if (!formValues.timeCouvertureFin && formValues.dateCouvertureDebut) {
				errors.timeCouvertureFin = <ErrorMessageRequired />
			}

			if (formValues.dateCouvertureFin && formValues.timeCouvertureFin && formValues.datetimeCouvertureFin) {
				if (moment(formValues.datetimeCouvertureFin)
					.isBefore(moment().startOf('day'))) {
					// should be after now
					errors.dateCouvertureFin = <FormattedMessage
						id="global.error.validation.datePriorToNow"
						defaultMessage="La date ne peut pas être antérieure à maintenant"
						description="Error message of date prior to now"
					/>
					errors.timeCouvertureFin = <FormattedMessage
						id="global.error.validation.datePriorToNow"
						defaultMessage="La date ne peut pas être antérieure à maintenant"
						description="Error message of date prior to now"
					/>
				} else if (formValues.datetimeSouhaitee && formValues.datetimeSouhaitee && formValues.datetimeSouhaitee && moment(formValues.datetimeCouvertureFin)
					.isBefore(formValues.datetimeSouhaitee)) {
					// should be before end
					errors.dateCouvertureFin = <FormattedMessage
						id="global.error.validation.datePriorToReceipt"
						defaultMessage="La date ne peut pas être antérieure à la date de réception"
						description="Error message of date prior to receipt date"
					/>
					errors.timeCouvertureFin = <FormattedMessage
						id="global.error.validation.datePriorToReceipt"
						defaultMessage="La date ne peut pas être antérieure à la date de réception"
						description="Error message of date prior to receipt date"
					/>
				} else if (formValues.dateCouvertureDebut && formValues.timeCouvertureDebut && formValues.datetimeCouverturDebut && moment(formValues.datetimeCouvertureFin)
					.isBefore(formValues.datetimeCouverturDebut)) {
					// should be after now
					errors.dateCouvertureFin = <FormattedMessage
						id="global.error.validation.endDateBeforeStartDate"
						defaultMessage="La date de fin ne peut pas être antérieure à celle de début"
						description="Error message of end date prior to start date"
					/>

					errors.timeCouvertureFin = <FormattedMessage
						id="global.error.validation.endDateBeforeStartDate"
						defaultMessage="La date de fin ne peut pas être antérieure à celle de début"
						description="Error message of end date prior to start date"
					/>
				}
			}

			if (selectedProduct
				&& formValues.dateSouhaitee && formValues.timeSouhaitee && formValues.datetimeSouhaitee
				&& formValues.dateCouvertureFin && formValues.timeCouvertureFin && formValues.datetimeCouvertureFin) {
				const daysBetweenEndAndReceipt = (Date.parse(formValues.datetimeCouvertureFin) - Date.parse(formValues.datetimeSouhaitee)) / 86400000
				const daysOfStability = selectedProduct.stabilite28 / 24

				if (daysBetweenEndAndReceipt > daysOfStability) {
					errors.dateCouvertureFin = <FormattedMessage
						id="global.error.validation.unstability"
						defaultMessage="L'intégrité du produit ne sera pas garantie de sa réception jusque cette date"
						description="Error message of date prior to receipt date"
					/>
					errors.timeCouvertureFin = <FormattedMessage
						id="global.error.validation.unstability"
						defaultMessage="L'intégrité du produit ne sera pas garantie de sa réception jusque cette date"
						description="Error message of date prior to receipt date"
					/>
				}
			}
		}

		return errors
	}

	const resetSecondPartFields = (form: any) => {
		form.change('modeQte', QTY_MODE.CONDITIONNEMENT)
		form.change('nbDoses1', undefined)
		form.change('nbDoses2', undefined)
		form.change('nbDosesR', undefined)
		form.change('nbCond', undefined)
		form.change('datetimeSouhaitee', undefined)
		form.change('dateSouhaitee', undefined)
		form.change('timeSouhaitee', undefined)
	}

	const resetQuantity = (form: any) => {
		form.change('nbDoses1', undefined)
		form.change('nbDoses2', undefined)
		form.change('nbDosesR', undefined)
		form.change('nbCond', undefined)
	}

	const onSubmit = async (formValues: FormValues) => {
		const errors = onValidate(formValues)

		// validation step
		if (Object.keys(errors).length > 0) {
			return errors
		}

		openDialog(formValues)
	}

	const openDialogProcurement = (data: DataDialogProcurement) => {
		setDataDialogProcurement(data)
		setIsOpenDialogProcurement(true)
	}

	const handleClickPrevisions = (date: string, dateEnd: string | undefined) => {
		const checkDate = date ? moment(date).startOf('isoWeek').hours(12) : undefined

		getProcurement({
			debutSemaine: checkDate ? checkDate.toISOString() : '',
			idProduit: selectedProduct ? selectedProduct.id : '',
			idCentre: selectedCenter.id,
			modeQte: ''
		})
			.then((response: ProcurementRowModel) => {
				openDialogProcurement({
					startDate: date,
					endDate: dateEnd && `${dateEnd}`,
					centerName: response.centre.nom,
					productName: response.produit.nomCommercial,
					conditioningType: response.produit.typeConditionnement,
					residualPrevision: response.residualPrevision,
					modeQte: response.modeQte,
					debutSemaine: response.debutSemaine,
					lundi: response.lundi,
					lundi1: response.lundi1,
					lundi2: response.lundi2,
					mardi: response.mardi,
					mardi1: response.mardi1,
					mardi2: response.mardi2,
					mercredi: response.mercredi,
					mercredi1: response.mercredi1,
					mercredi2: response.mercredi2,
					jeudi: response.jeudi,
					jeudi1: response.jeudi1,
					jeudi2: response.jeudi2,
					vendredi: response.vendredi,
					vendredi1: response.vendredi1,
					vendredi2: response.vendredi2,
					samedi: response.samedi,
					samedi1: response.samedi1,
					samedi2: response.samedi2,
					dimanche: response.dimanche,
					dimanche1: response.dimanche1,
					dimanche2: response.dimanche2
				})
			})
			.catch(() => snackError({
				id: `dispatch.replenishmentList.errors.${(selectedProduct && date) ? 'procurement' : 'missingField'}`,
				defaultMessage: (selectedProduct && date) ?
					'Ce centre ne possède pas de plan d\'approvisionnement pour la semaine indiquée'
					: 'Un produit et une date doivent être sélectionnées pour connaître les allocations',
				description: 'Error message'
			}))
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			initialValues={initValues}
			// @ts-ignore
			decorators={formDecorators}
			render={({ handleSubmit, submitting, values, form }) => {
				return (
					<PageFormContainer onSubmit={handleSubmit}>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="dispatch.stock.formSection.productID"
									defaultMessage="Identification du produit"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={7} md={4}>
							<FieldProduct required />
						</Grid>
						<OnChange name="product">
							{(productID) => {
								const foundProduct = vaccins.find(vaccin => productID === vaccin.id)

								setSelectedProduct(foundProduct)
								resetSecondPartFields(form)
								setProductTotal(0)
							}}
						</OnChange>
						<Grid item xs={5} md={4}>
							<FieldUnitProduct />
						</Grid>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="vaccination.replenishment.formSection.info"
									defaultMessage="2. Renseigner le besoin"
									description="Form section"
								/>
							</Typography>
						</Grid>
						{configuration.replenishmentDose && <Grid item xs={12}>
							<FieldRadio
								name="modeQte"
								label={<FormattedMessage
									id="vaccination.replenishment.modeQte"
									defaultMessage="Mode de saisie"
									description="Quantity mode selection"
								/>}
								choices={Object.keys(QTY_MODE).map((mode: string) => ({
									label: intl.formatMessage({
										id: `enum.qtyMode.${mode}`,
										defaultMessage: `Quantity mode ${mode}`,
										description: 'Quantity mode option'
									}),
									value: mode
								}))}
							/>
							<OnChange name="modeQte">
								{() => {
									resetQuantity(form)
								}}
							</OnChange>
						</Grid>}
						{values.modeQte === QTY_MODE.DOSE ?
							<>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDoses1"
										component={FormInput}
										label={
											<FormattedMessage
												id="vaccination.replenishment.quantity1"
												defaultMessage="1ère injection"
												description="Comment label input"
											/>
										}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDoses1">
										{(value) => {
											const wantedDoseTotal = Number(value || 0) + Number(values.nbDoses2 || 0) + Number(values.nbDosesR || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDoses2"
										component={FormInput}
										label={
											<FormattedMessage
												id="vaccination.replenishment.quantity2"
												defaultMessage="2ème injection"
												description="Comment label input"
											/>
										}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDoses2">
										{(value) => {
											const wantedDoseTotal = Number(values.nbDoses1 || 0) + Number(value || 0) + Number(values.nbDosesR || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDosesR"
										component={FormInput}
										label={
											<FormattedMessage
												id="vaccination.replenishment.quantityR"
												defaultMessage="injection rappel"
												description="Comment label input"
											/>
										}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDosesR">
										{(value) => {
											const wantedDoseTotal = Number(values.nbDoses1 || 0) + Number(values.nbDoses2 || 0) + Number(value || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<TotalCountCaption
									count={productTotal}
									type={selectedProduct?.typeConditionnement}
								/>
							</>
							:
							<Grid item xs={4} md={2}>
								<Field
									name="nbCond"
									component={FormInput}
									label={
										<FormattedMessage
											id="global.functionnal.quantity"
											defaultMessage="Quantité"
											description="Comment label input"
										/>
									}
									type="number"
									inputProps={{ min: 0 }}
								/>
							</Grid>
						}

						<Grid item xs={12}>
							<FieldDatetime
								datetimeName="datetimeSouhaitee"
								dateName="dateSouhaitee"
								timeName="timeSouhaitee"
								datetimeLabel={
									<FormattedMessage
										id="vaccination.replenishment.datetimeWanted"
										defaultMessage="Date de réception souhaitée"
										description="Datetime input"
									/>
								}
								dateLabel={
									<FormattedMessage
										id="vaccination.replenishment.dateWanted"
										defaultMessage="Date de réception souhaitée"
										description="Date input"
									/>
								}
								timeLabel={
									<FormattedMessage
										id="vaccination.replenishment.timeWanted"
										defaultMessage="Heure de réception souhaitée"
										description="Time input"
									/>
								}
							/>
						</Grid>
						{configuration.replenishmentCoverage && <>
							<Grid item xs={12}>
								<Typography gutterBottom variant="h3">
									<FormattedMessage
										id="vaccination.replenishment.coveragePeriod"
										defaultMessage="Heure de début de couverture souhaitée"
										description="Time input"
									/>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FieldDatetime
									datetimeName="datetimeCouverturDebut"
									dateName="dateCouvertureDebut"
									timeName="timeCouvertureDebut"
									datetimeLabel={
										<FormattedMessage
											id="vaccination.replenishment.datetimeCoverageStart"
											defaultMessage="Date de début de couverture souhaitée"
											description="Datetime input"
										/>
									}
									dateLabel={
										<FormattedMessage
											id="vaccination.replenishment.dateCoverageStart"
											defaultMessage="Date de début de couverture souhaitée"
											description="Date input"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="vaccination.replenishment.timeCoverageStart"
											defaultMessage="Heure de début de couverture souhaitée"
											description="Time input"
										/>
									}
								/>

								<FieldDatetime
									datetimeName="datetimeCouvertureFin"
									dateName="dateCouvertureFin"
									timeName="timeCouvertureFin"
									datetimeLabel={
										<FormattedMessage
											id="vaccination.replenishment.datetimeCoverageEnd"
											defaultMessage="Date de fin de couverture souhaitée"
											description="Datetime input"
										/>
									}
									dateLabel={
										<FormattedMessage
											id="vaccination.replenishment.dateCoverageEnd"
											defaultMessage="Date de fin de couverture souhaitée"
											description="Date input"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="vaccination.replenishment.timeCoverageEnd"
											defaultMessage="Heure de fin de couverture souhaitée"
											description="Time input"
										/>
									}
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography className={classes.coverage} variant="caption">
                                <span onClick={() => handleClickPrevisions(values.datetimeCouverturDebut || values.datetimeSouhaitee, values.datetimeCouvertureFin)}>
                                    <FormattedMessage
	                                    id="vaccination.replenishment.showPrevision"
	                                    defaultMessage="Voir les allocations de la semaine"
	                                    description="Show previsions"
                                    />
                                </span>
								</Typography>
							</Grid>
						</>}

						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h3' : 'h5'}>
								<FormattedMessage
									id="vaccination.replenishment.formSection.carrier"
									defaultMessage="Sélectionner un transporteur"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={4}>
								<Field
									name="idTransporteur"
									component={FormSelect}
									label={
										<FormattedMessage
											id="global.functionnal.carrier"
											defaultMessage="Transporteur"
											description="Carrier label select"
										/>
									}
									type="number"
								>
									<MenuItem key={0} value={undefined}><FormattedMessage
										id="select.none"
										defaultMessage="Aucun"
										description="No option"
									/></MenuItem>
									{carriers.map((carrier: CarrierModel) =>
										<MenuItem key={carrier.id} value={carrier.id}>
											{carrier.nom}
										</MenuItem>
									)}
								</Field>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="vaccination.replenishment.formSection.comment"
									defaultMessage="3. Ajouter un commentaire"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Field
								name="commentaire"
								component={FormInput}
								label={
									<FormattedMessage
										id="vaccination.replenishment.comment"
										defaultMessage="Commentaire"
										description="Comment label input"
									/>
								}
							/>
						</Grid>

						<div
							className={classes.submitButtonRoot}
							style={{
								justifyContent: isLargeScreen ? 'flex-start' : 'center'
							}}
						>
							<Button type="submit" variant="contained" isLoading={submitting}>
								<FormattedMessage
									id="button.validate"
									defaultMessage="Valider"
									description="Message on form submission button"
								/>
							</Button>
						</div>
						<Dialog
							title={
								<FormattedMessage
									id="vaccination.replenishment.dialog.title"
									defaultMessage="Confirmation de réapprovisionement"
									description="Dialog title"
								/>
							}
							isOpen={isDialogOpen}
							handleClose={closeDialog}
							buttons={[
								<Button onClick={closeDialog} color="primary" key={0} variant="outlined">
									<FormattedMessage
										id="vaccination.replenishment.dialog.actionNo"
										defaultMessage="Non"
										description="Button label"
									/>
								</Button>,
								<Button
									autoFocus
									onClick={() => dialogConfirm(form, false)}
									color="primary"
									key={1}
									variant="contained"
									isLoading={submitLoader}
								>
									<FormattedMessage
										id="vaccination.replenishment.dialog.actionYes"
										defaultMessage="Oui"
										description="Button label"
									/>
								</Button>
							]}
						>
							<Typography gutterBottom>
								<FormattedMessage
									id="vaccination.replenishment.dialog.summary"
									defaultMessage="Vous souhaitez faire une demande de réapprovisionement"
									description="Dialog content"
									values={{
										productCount: values.modeQte === QTY_MODE.DOSE ? productTotal : values.nbCond,
										conditioningType: selectedProduct?.typeConditionnement,
										date: displayDate(moment(values.datetimeSouhaitee).local(), DATE_FORMAT.DATETIME, isDateFormatEn)
									}}
								/>
							</Typography>
							{values.commentaire
								&&
								<Typography gutterBottom>
									{`"${values.commentaire}"`}
								</Typography>
							}
							<Typography gutterBottom>
								<FormattedMessage
									id="vaccination.replenishment.dialog.doYouConfirm"
									defaultMessage="Confirmez-vous cette demande ?"
									description="Dialog content"
								/>
							</Typography>
						</Dialog>

						<DialogQtyProcurementWarning
							title="vaccination.replenishment.warningTitle"
							description="vaccination.replenishment.warningDesc"
							isOpen={isOpenDialogProcurementWarning}
							handleClose={() => {
								setIsOpenDialogProcurementWarning(false)
								setIsDialogOpen(false)
								setSubmitLoader(false)
							}}
							onConfirm={() => dialogConfirm(form, true)}
							loading={submitLoader}
						/>

						<DialogReplenishmentProcurement
							isOpen={isOpenDialogProcurement}
							handleClose={() => setIsOpenDialogProcurement(false)}
							data={dataDialogProcurement}
						/>
					</PageFormContainer>
				)
			}}
		/>
	)
}

export default compose(injectSnackbarActions)(Replenishment)
