import React from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from './DialogTitle'
import DialogContent from './DialogContent'
import { SCREEN_SIZE } from '../../../utils/constants'

const useStyles = (isLargeScreen: boolean) => makeStyles(() =>
    createStyles({
        container: {
            ...(isLargeScreen && { minWidth: 400 }),
        }
    }),
)

interface DialogProps {
    isOpen: boolean
    title: string | React.ReactElement
    buttons?: React.ReactElement[]
    handleClose: () => void
    containerClass?: string
}

const Dialog: React.FC<DialogProps> = (
    {
        isOpen,
        title,
        handleClose,
        buttons = [],
        containerClass,
        children
    }) => {
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
    const classes = useStyles(isLargeScreen)()

    return (
        <MuiDialog
            onClose={handleClose}
            open={isOpen}
            classes={{ paper: containerClass || classes.container }}
            className={classes.container}
            fullScreen={!isLargeScreen}
        >
            <DialogTitle handleClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {buttons.length > 0 &&
			<DialogActions>
                {buttons}
			</DialogActions>
            }
        </MuiDialog>
    )
}

export default Dialog
