import React from 'react'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuTileIllustration from './MenuTileIllustration'
import MenuTileText from './MenuTileText'
import { HOME_MENU_TILE } from '../../../utils/home-menus/homeMenus'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			padding: '30px 20px',

			[theme.breakpoints.down('md')]: {
				flexDirection: 'column'
			}
		},
		divider: {
			width: 20,
			height: 20
		}
	})
)

const MenuTile: React.FC<HOME_MENU_TILE> = (
	{
		title,
		content,
		linkText,
		linkHref,
		icon
	}
) => {
	const classes = useStyles()

	return (
		<Paper className={classes.container}>
			<MenuTileIllustration icon={icon} />
			<div className={classes.divider} />
			<MenuTileText
				title={title}
				content={content}
				linkText={linkText}
				linkHref={linkHref}
			/>
		</Paper>
	)
}

export default MenuTile
