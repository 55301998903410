import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import DisplayLineData from '../../../../components/layout/DisplayLineData'
import { useParams } from 'react-router-dom'
import NotFoundPage from '../../../../components/layout/NotFoundPage'
import { injectToolbarData } from 'isotope-client'
import { CENTER_TYPE, CENTER_TYPE_LOWERCASE, DOWNLOAD_FILE_TYPE, SCRAPPING_OTHER_OPTION, SCREEN_SIZE, VALUE_LIST_SHORTCUT } from '../../../../utils/constants'
import DownloadButton from '../../../../components/layout/buttons/DownloadButton'
import TotalCountCaption from '../../../../components/fields/TotalCountCaption'
import { getDechetById } from './services/scrappingApi'
import { ScrappingMouvementModel } from './services/scrappingModels'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(3),
			display: 'flex',
			flexDirection: 'column'
		},
		circularProgressRoot: {
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		firstTitle: {
			marginTop: 0
		},
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		button: {
			margin: theme.spacing(2)
		},
		dosageContainer: {
			margin: '-9px 0 6px 12px'
		}
	})
)

interface Values {
	product: string
	unitProduct: string
	quantity: number
	doseTotal: number
	batchNumber: string
	scrappingCause: string
	scrappingComment?: string
}

interface MatchParamsModel {
	id: string
}

interface ScrappingPrintProps {
	centerType: string,
	theme: Theme
}

const ScrappingPrint: React.FC<ScrappingPrintProps> = ({ centerType, theme }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { valueList } = useContext(PhidemDataContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const [fetchedValues, setFetchedValues] = useState<Values | undefined>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const prefixApiUrl = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]
	const scrappingCauseOptions = valueList[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE] || {}

	useEffect(() => {
		getDechetById(prefixApiUrl, idInUrl)
			.then((values: ScrappingMouvementModel) => {
				return setFetchedValues({
					product: values.stock.produit.nomCommercial,
					quantity: values.quantite,
					unitProduct: values.stock.produit.typeConditionnement,
					doseTotal: values.quantite * values.stock.produit.nbDoses,
					batchNumber: values.stock.lot,
					scrappingCause: values.cause || '',
					scrappingComment: values.commentaireMouvement
				})
			})
			.finally(() => setIsLoading(false))
	}, [prefixApiUrl, idInUrl])

	if (isLoading) {
		return <div
			className={classes.container}
			style={{
				alignItems: isLargeScreen ? 'flex-start' : 'center',
				marginLeft: isLargeScreen ? '24px' : 0
			}}
		>
			<div className={classes.circularProgressRoot}>
				<CircularProgress color="primary" size={100} />
			</div>
		</div>
	}

	return (
		<div
			className={classes.container}
			style={{
				alignItems: isLargeScreen ? 'flex-start' : 'center',
				margin: isLargeScreen ?
					`${theme.spacing(3)}px ${theme.spacing(3)}px 64px 13vw` :
					`${theme.spacing(1)} ${theme.spacing(1)} 64px ${theme.spacing(1)}`,
				width: isLargeScreen ? 600 : 300
			}}
		>
			{!fetchedValues
				?
				<NotFoundPage />
				:
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					xs={10} md={12}
					spacing={2}
				>
					<Grid item xs={12}>
						<Typography
							className={classes.firstTitle}
							variant={isLargeScreen ? 'h1' : 'h3'}
						>
							<FormattedMessage
								id="common.scrapping.formSection.product"
								defaultMessage="1. Sélectionner le produit"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={10} md={4}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="global.functionnal.product"
									defaultMessage="Produit"
									description="Product to select"
								/>
							}
							data={fetchedValues.product}
						/>
					</Grid>
					<Grid item xs={6} md={4}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="common.scrapping.form.unitProduct"
									defaultMessage="Conditionnement"
									description="Conditioning"
								/>
							}
							data={fetchedValues.unitProduct}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="common.scrapping.formSection.preparation"
								defaultMessage="Préparer la mise en déchet"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid item xs={12} md={4}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="global.functionnal.batch"
										defaultMessage="Lot"
										description="Batch number"
									/>
								}
								data={fetchedValues.batchNumber}
							/>
						</Grid>
					</Grid>
					<Grid item xs={6} md={3}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="global.functionnal.quantity"
									defaultMessage="Quantité"
									description="Quantity of selected product"
								/>
							}
							data={fetchedValues.quantity}
						/>
					</Grid>
					<TotalCountCaption count={fetchedValues.doseTotal} isNextToInput={false} disabled />
					<Grid item xs={12}>
						<Grid item xs={12} md={8}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="common.scrapping.scrappingCause"
										defaultMessage="Cause de la mise en déchet"
										description="Scrapping cause"
									/>}
								data={fetchedValues.scrappingCause === SCRAPPING_OTHER_OPTION ?
									intl.formatMessage({ id: 'common.scrapping.scrappingOtherOption' })
									: scrappingCauseOptions[fetchedValues.scrappingCause]}
							/>
						</Grid>
						{fetchedValues.scrappingCause === SCRAPPING_OTHER_OPTION && <Grid item xs={12} md={8}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="common.scrapping.scrappingComment"
										defaultMessage="Commentaire"
										description="Scrapping comment"
									/>}
								data={fetchedValues.scrappingComment}
							/>
						</Grid>}
					</Grid>
					<div
						className={classes.buttonRoot}
						style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
					>
						<DownloadButton
							className={classes.button}
							label={<FormattedMessage
								id="button.print.labelScrapping"
								defaultMessage="Etiquette mise en déchet"
								description="Message on print label button"
							/>}
							url={`/${prefixApiUrl}/dechet/${idInUrl}/document?documentType=${centerType === CENTER_TYPE.DISPATCH ? DOWNLOAD_FILE_TYPE.DISPATCH_DECHET.type : DOWNLOAD_FILE_TYPE.REFERENT_DECHET.type}`}
							fileName={centerType === CENTER_TYPE.DISPATCH ? DOWNLOAD_FILE_TYPE.DISPATCH_DECHET.outputFile : DOWNLOAD_FILE_TYPE.REFERENT_DECHET.outputFile}
						/>
					</div>
				</Grid>
			}
		</div>
	)
}

export default injectToolbarData(() => ({
	title: <FormattedMessage
		id="menu.dispatchCenter.dechet"
		defaultMessage="Mise en déchet"
		description="Menu title"
	/>
}))(withTheme(ScrappingPrint))
