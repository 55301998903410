import React, { useContext, useMemo } from 'react'
import { dataTableActions, FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { Field, Form } from 'react-final-form'
import Button from '../../../components/layout/buttons/Button'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import Dialog from '../../../components/layout/dialog/Dialog'
import { CENTER_TYPE, CENTER_TYPE_LOWERCASE, PRODUCT_EXPIRATION_TYPE } from '../../../utils/constants'
import { updateStock } from './services/stockApi'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { ResponseStockModel } from './services/stockModel'
import Typography from '@material-ui/core/Typography'
import { monthAndYearDecorator } from '../../../utils/form/decorators'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import FieldMonth from '../../../components/fields/FieldMonth'
import moment from 'moment'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'
import { usePhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { OFFLINE_ACTION } from '../../common/offline/offlineConstants'
import { OfflineContext } from '../../common/offline/context/OfflineContext'

interface StockModel {
	id: string
	productName: string
	conditioningType: string
	currentStock: number
	doseCountPerUnit: number
	// @ts-ignore
	centerType: CENTER_TYPE.DISPATCH | CENTER_TYPE.VACCINATION
}

interface FormValues {
	quantity?: number
	expirationDate?: string
	expirationMonth?: string
	expirationYear?: string
	comment: string
}

interface DialogEditStockProps {
	isOpen: boolean
	handleClose: () => any
	stock: StockModel,
	selectedRow: ResponseStockModel
	refreshTable: (type: string) => void
	snackSuccess: (value: any) => void
	snackError: (value: any) => void
}

const atLeastOneField = <FormattedMessage
	id="global.error.validation.oneOfTwo"
	defaultMessage="Veuillez renseigner au moins un des deux champs"
	description="Error message"
/>

const DialogEditStock: React.FC<DialogEditStockProps> = (
	{
		isOpen,
		handleClose,
		selectedRow,
		refreshTable,
		snackSuccess,
		snackError
	}) => {
	const { user: { selectedLang, isDateFormatEn } } = usePhidemDataContext()
	const { handleOfflineAction } = useContext(OfflineContext)
	const stock = {
		id: selectedRow.id,
		currentStock: selectedRow.quantite,
		doseCountPerUnit: selectedRow.produit.nbDoses,
		productName: selectedRow.produit.nomCommercial,
		conditioningType: selectedRow.produit.typeConditionnement,
		centerType: selectedRow.centerType
	}
	const isReferentStock = selectedRow.centerType === CENTER_TYPE.REFERENT
	const isDispatchStock = selectedRow.centerType === CENTER_TYPE.DISPATCH
	const isDispatchOrReferentStock = isReferentStock || isDispatchStock
	const isExpirationDay = selectedRow.produit.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR

	const isMonthSupported = isDateInputTypeSupported('month')
	const dateMonthDecorator = useMemo(() => isMonthSupported
			? []
			:
			[monthAndYearDecorator('expirationMonth',
				'expirationYear', 'expirationDate')]
		, [isMonthSupported])

	const onSubmit = (formValues: FormValues) => {
		const errors = onValidate(formValues)
		const payload = {
			idStock: stock.id,
			quantite: formValues.quantity,
			peremption: formValues.expirationDate ? new Date(formValues.expirationDate || '').toISOString() : undefined,
			commentaire: formValues.comment
		}
		// validation step
		if (Object.keys(errors).length > 0) {
			return errors
		}

		return handleOfflineAction(OFFLINE_ACTION.UPDATE_STOCK, updateStock, {
			centerType: CENTER_TYPE_LOWERCASE[selectedRow.centerType as keyof typeof CENTER_TYPE_LOWERCASE],
			...payload
		})
			.then(() => {
				handleClose()
				snackSuccess({ id: 'stockEdit.dialog.updateSuccess', defaultMessage: 'Le stock a bien été modifié', description: 'Success message' })
				refreshTable(selectedRow.centerType.toLowerCase())
			})
			.catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		onValidateQuantity(formValues, errors, isDispatchOrReferentStock)

		if (isDispatchOrReferentStock) {
			// On vérifie qu'un champ est rempli
			if (!formValues.quantity &&
				(((isExpirationDay || isMonthSupported) && !formValues.expirationDate) ||
					((!isExpirationDay && !isMonthSupported) && formValues.expirationMonth === undefined && !formValues.expirationYear)
				)
			) {
				errors.quantity = atLeastOneField
				if (isExpirationDay || isMonthSupported) {
					errors.expirationDate = atLeastOneField
				} else {
					errors.expirationMonth = atLeastOneField
					errors.expirationYear = atLeastOneField
				}
			}

			// On vérifie ensuite la péremption saisie
			if (isExpirationDay) {
				if (formValues.expirationDate && moment(formValues.expirationDate).isBefore(moment())) {
					// should be newer than last day of actual month
					errors.expirationDate = <FormattedMessage
						id="global.error.validation.datePriorToNow"
						defaultMessage="La date ne peut pas être antérieure à maintenan"
						description="Error message of date prior to today"
					/>
				}
			} else {
				if (isMonthSupported) {
					if (formValues.expirationDate && moment(formValues.expirationDate)
						.isBefore(moment().subtract(1, 'months').endOf('month'))) {
						// should be newer than last day of actual month
						errors.expirationDate = <FormattedMessage
							id="global.error.validation.datePriorToCurrentMonth"
							defaultMessage="La date ne peut pas être antérieure au mois actuel"
							description="Error message of date prior to last month"
						/>
					}
				} else if (formValues.expirationMonth !== undefined && formValues.expirationYear && formValues.expirationDate &&
					moment(formValues.expirationDate)
						.isBefore(moment().subtract(1, 'months').endOf('month'))) {
					// should be newer than last day of actual month
					errors.expirationMonth = <FormattedMessage
						id="global.error.validation.datePriorToCurrentMonth"
						defaultMessage="La date ne peut pas être antérieure au mois actuel"
						description="Error message of date prior to last month"
					/>
					errors.expirationYear = <FormattedMessage
						id="global.error.validation.datePriorToCurrentMonth"
						defaultMessage="La date ne peut pas être antérieure au mois actuel"
						description="Error message of date prior to last month"
					/>
				}
			}
		}

		if (!formValues.comment) {
			errors.comment = <ErrorMessageRequired />
		}

		return errors
	}

	const onValidateQuantity = (formValues: FormValues, errors: any, isDispatchOrReferentStock: boolean) => {
		if (!formValues.quantity) {
			// On affichera une erreur globale sur les deux champs
			if (!isDispatchOrReferentStock) {
				errors.quantity = <ErrorMessageRequired />
			}
		} else if (Number(formValues.quantity) === 0) {
			errors.quantity = (
				<FormattedMessage
					id="global.error.validation.invalidQuantity"
					defaultMessage="La quantité renseignée est invalide"
					description="Error message"
				/>
			)
		} else if (formValues.quantity < -stock.currentStock) {
			errors.quantity = (
				<FormattedMessage
					id="global.error.validation.moreThanCurrentStock"
					defaultMessage="La quantité renseignée excède le stock actuel"
					description="Error message"
				/>
			)
		}
	}

	return (
		<Dialog
			title={
				<FormattedMessage
					id="stockEdit.dialog.title"
					defaultMessage="Modification de stock"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
			buttons={[
				<Button onClick={handleClose} color="primary" key={0} variant="outlined">
					<FormattedMessage
						id="button.cancel"
						defaultMessage="Annuler"
						description="Button label"
					/>
				</Button>,
				<Button autoFocus form="dialogEditStockForm" type="submit" color="primary" key={1} variant="contained">
					<FormattedMessage
						id="button.validate"
						defaultMessage="Valider"
						description="Button label"
					/>
				</Button>
			]}
		>
			<Form
				onSubmit={onSubmit}
				validate={onValidate}
				// @ts-ignore
				decorators={[...dateMonthDecorator]}
				render={({ handleSubmit, values }) => (
					<form id="dialogEditStockForm" onSubmit={handleSubmit}>
						<Grid
							container
							direction="row"
							justify="flex-start"
							spacing={2}
						>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={10} md={4}>
									<DisplayLineData
										label={
											<FormattedMessage
												id="global.functionnal.product"
												defaultMessage="Produit"
												description="Display element"
											/>
										}
										data={stock.productName}
									/>
								</Grid>
								<Grid item xs={6} md={4}>
									<DisplayLineData
										label={
											<FormattedMessage
												id="dispatch.stock.unitProduct"
												defaultMessage="Conditionnement"
												description="Conditioning"
											/>
										}
										data={stock.conditioningType}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h3">
									<FormattedMessage
										id="stockEdit.dialog.formSection.quantity"
										defaultMessage="Gestion de la quantité"
										description="Form section title"
									/>
								</Typography>
							</Grid>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={10} md={4}>
									<DisplayLineData
										label={
											<FormattedMessage
												id="stockEdit.dialog.stockCurrent"
												defaultMessage="Stock actuel"
												description="Display element"
											/>
										}
										data={stock.currentStock}
									/>
								</Grid>
								<TotalCountCaption count={stock.currentStock * stock.doseCountPerUnit} isNextToInput={false} disabled />
							</Grid>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={4} md={2}>
									<Field
										name="quantity"
										component={FormInput}
										label={
											<FormattedMessage
												id="stockEdit.dialog.quantity"
												defaultMessage="Quantité (+/-)"
												description="label input"
											/>
										}
										type="number"
										inputProps={{ min: -stock.currentStock }}
										required={!isDispatchOrReferentStock}
									/>
								</Grid>
								<TotalCountCaption count={values.quantity ? values.quantity * stock.doseCountPerUnit : undefined} />
							</Grid>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={10} md={4}>
									<DisplayLineData
										label={
											<FormattedMessage
												id="stockEdit.dialog.quantityAfterward"
												defaultMessage="Stock après opération"
												description="Display element"
											/>
										}
										data={Number(stock.currentStock) + Number(values.quantity || 0)}
									/>
								</Grid>
								<TotalCountCaption
									count={(Number(stock.currentStock) + Number(values.quantity || 0)) * stock.doseCountPerUnit}
									isNextToInput={false}
									disabled
								/>
							</Grid>
							{isDispatchOrReferentStock && <>
								<Grid item xs={12}>
									<Typography variant="h3" gutterBottom>
										<FormattedMessage
											id="stockEdit.dialog.formSection.expiration"
											defaultMessage="Gestion de la péremption"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography>
										<FormattedMessage
											id="stockEdit.dialog.expiration"
											defaultMessage="Péremption actuelle :"
											description="Actual expiration"
											values={{ expiration: isExpirationDay ? displayDate(moment(selectedRow.peremption), DATE_FORMAT.DATE, isDateFormatEn) : displayDate(moment(selectedRow.peremption).locale(selectedLang), DATE_FORMAT.MONTH_YEAR, isDateFormatEn) }}
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{isExpirationDay ?
										<Grid item xs={12} md={5}>
											<Field
												name="expirationDate"
												component={FormInput}
												label={<FormattedMessage
													id="global.functionnal.expiration"
													defaultMessage="Péremption"
													description="Expiration date"
												/>}
												type="date"
											/>
										</Grid>
										:
										<FieldMonth
											dateName="expirationDate"
											monthName="expirationMonth"
											yearName="expirationYear"
											required={false}
											dateLabel={
												<FormattedMessage
													id="global.functionnal.expiration"
													defaultMessage="Péremption"
													description="Expiration date"
												/>
											}
											monthLabel={
												<FormattedMessage
													id="stockEdit.dialog.expirationMonth"
													defaultMessage="Mois de péremption"
													description="Expiration date"
												/>
											}
											yearLabel={
												<FormattedMessage
													id="stockEdit.dialog.expirationYear"
													defaultMessage="Année de péremption"
													description="Expiration date"
												/>
											}
										/>
									}
								</Grid>
							</>}

							<Grid item xs={12}>
								<Typography variant="h3">
									<FormattedMessage
										id="stockEdit.dialog.formSection.comment"
										defaultMessage="Traçage de la modification"
										description="Form section title"
									/>
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<Field
									name="comment"
									component={FormInput}
									label={
										<FormattedMessage
											id="stockEdit.dialog.comment"
											defaultMessage="Commentaire"
											description="Comment label input"
										/>
									}
									multiline
									required
								/>
							</Grid>
						</Grid>
					</form>
				)}
			/>
		</Dialog>
	)
}

const actions = {
	refreshTable: (type: string) => dataTableActions.refresh(`${type}StockListDesktop`)
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(DialogEditStock)
