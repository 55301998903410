import React, { useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { FormInput } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import { getSpecificAdministration, postDeclarationOfUse } from '../services/vaccinationApi'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import FieldProduct from '../../../components/fields/FieldProduct'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { ADMIN_MODE, SCREEN_SIZE } from '../../../utils/constants'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import ErrorPositiveValue from '../../../components/layout/errors/ErrorPositiveValue'
import errorsMapper from '../../../utils/errorsMapper'
import FieldWeekPicker from '../../../components/fields/FieldWeekPicker'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import moment from 'moment'
import Loader from '../../../components/layout/Loader'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme: Theme) => createStyles({
	submitButtonRoot: {
		width: '100%',
		margin: theme.spacing(1),
		display: 'flex'
	},
	longLabel: {
		whiteSpace: 'nowrap'
	}
}))

interface FormValues {
	product: string
	week?: any
	date?: string
	doseCount1?: number
	doseCount2?: number
	doseCount3?: number
	doseCountSingle?: number
}

interface DeclarationOfUseProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

interface UseByDayProps {
	d1?: number
	d2?: number
	d3?: number
	du?: number
}

const DeclarationOfUse: React.FC<DeclarationOfUseProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { user: { selectedCenter, isDateFormatEn, selectedLang } } = useContext(PhidemDataContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [loaded, setLoaded] = useState<boolean>(false)

	const clearFields = (form: any) => {
		form.change('doseCount1', undefined)
		form.change('doseCount2', undefined)
		form.change('doseCountSingle', undefined)
	}

	const getUse = (form: any) => {
		clearFields(form)
		const formValues = form.getState().values
		setLoaded(false)
		if (formValues.product && (formValues.date || formValues.week)) {
			setLoading(true)
			getSpecificAdministration({
				product: formValues.product,
				modeAdministration: selectedCenter.modeAdministration,
				endOfWeek: formValues.date ? moment(formValues.date).endOf('week').toISOString() : formValues.week.to.toISOString(),
				dayOfWeek: formValues.date ? moment(formValues.date).weekday() + 1 : 7
			})
				.then((use: UseByDayProps) => {
					form.change('doseCount1', use.d1)
					form.change('doseCount2', use.d2)
					form.change('doseCount3', use.d3)
					form.change('doseCountSingle', use.du)
				})
				.finally(() => {
					setLoading(false)
					setLoaded(true)
				})
		}

	}

	const isOld = (form: any) => {
		const formValues = form.getState().values
		const now = formatDateTime(moment())
		if (formValues.date) {
			return now.isAfter(moment(formValues.date))
		} else if (formValues.week) {
			return now.endOf('week').isAfter(formatDateTime(moment(formValues.week.to)))
		}
		return false
	}

	const formatDateTime = (date: any) => date.hours(12).minutes(30)

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.product) {
			errors.product = <ErrorMessageRequired />
		}

		if (!!formValues.doseCount1 && formValues.doseCount1 < 0) {
			errors.doseCount1 = <ErrorPositiveValue />
		}

		if (!!formValues.doseCount2 && formValues.doseCount2 < 0) {
			errors.doseCount2 = <ErrorPositiveValue />
		}

		if (!!formValues.doseCount3 && formValues.doseCount3 < 0) {
			errors.doseCount3 = <ErrorPositiveValue />
		}

		if (!!formValues.doseCountSingle && formValues.doseCountSingle < 0) {
			errors.doseCountSingle = <ErrorPositiveValue />
		}

		if (selectedCenter.modeAdministration === ADMIN_MODE.SEMAINE) {
			if (!formValues.week) {
				errors.week = <ErrorMessageRequired />
			}
		} else if (!formValues.date) {
			errors.date = <ErrorMessageRequired />
		}

		return errors
	}

	const onSubmit = async (formValues: FormValues) => {
		return postDeclarationOfUse({
			idProduit: formValues.product,
			doseCount1: formValues.doseCount1,
			doseCount2: formValues.doseCount2,
			doseCount3: formValues.doseCount3,
			doseCountSingle: formValues.doseCountSingle,
			datetime: formValues.date ? moment(formValues.date).toISOString() : formValues.week?.to.toISOString(),
			endOfWeek: formValues.date ? moment(formValues.date).endOf('week').toISOString() : formValues.week?.to.toISOString(),
			dayOfWeek: formValues.date ? moment(formValues.date).weekday() + 1 : 7,
			modeAdministration: selectedCenter.modeAdministration || ADMIN_MODE.SEMAINE
		})
			.then(() => snackSuccess({ id: 'vaccination.declarationOfUse.success', defaultMessage: 'L\'entrée en stock a bien été enregistrée', description: 'Success message' }))
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'vaccination.declarationOfUse.error', defaultMessage: 'Une erreur est survenue, la déclaration d\'utilisation n\'a pas été enregistrée', description: 'Error message' })
				}
				return errors
			})
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, hasValidationErrors, form, submitting }) => (
				<PageFormContainer onSubmit={async event => {
					const error = await handleSubmit(event)
					if (error) {
						return error
					}
					if (!hasValidationErrors) {
						// @ts-ignore
						form.restart()
					}
				}}
				>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="vaccination.declarationOfUse.form.sections.product"
								defaultMessage="1. Sélectionner le produit"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={7} md={4}>
						<FieldProduct />
					</Grid>
					<OnChange name="product">{() => getUse(form)}</OnChange>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id={`vaccination.declarationOfUse.form.sections.${selectedCenter.modeAdministration === ADMIN_MODE.SEMAINE ? 'week' : 'day'}`}
								defaultMessage="2. Sélectionner la période"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{
							selectedCenter.modeAdministration === ADMIN_MODE.SEMAINE ?
								<FieldWeekPicker
									name="week"
									label={
										<FormattedMessage
											id="vaccination.declarationOfUse.form.fields.week"
											defaultMessage="Date"
											description="form input"
										/>
									}
									required
									isDateFormatEn={isDateFormatEn}
									selectedLang={selectedLang}
								/>
								: selectedCenter.modeAdministration === ADMIN_MODE.JOUR ?
									<Grid item xs={12} md={4}>
										<Field
											name="date"
											component={FormInput}
											label={<FormattedMessage
												id="vaccination.declarationOfUse.form.fields.date"
												defaultMessage="Date de réception souhaitée"
												description="Date input"
											/>}
											type="date"
											required
										/>
									</Grid>
									: <></>
						}
					</Grid>
					<OnChange name="week">{() => getUse(form)}</OnChange>
					<OnChange name="date">{() => getUse(form)}</OnChange>
					{loading && <Loader />}
					{loaded && <>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id={`vaccination.declarationOfUse.form.sections.${isOld(form) ? 'old' : 'future'}Quantity`}
									defaultMessage="3. Saisir la quantité"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={10}>
							<Alert severity="info" variant="outlined">
								<FormattedMessage
									id={`vaccination.declarationOfUse.form.${isOld(form) ? 'old' : 'future'}Warning`}
									defaultMessage="Attention, au vu de la période saisie, vous vous apprêtez à saisir des quantités réalisées"
									description="Warning message"
								/>
							</Alert>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} md={4}>
								<Field
									className={classes.longLabel}
									name="doseCount1"
									component={FormInput}
									label={
										<FormattedMessage
											id="vaccination.declarationOfUse.form.fields.doseCount1"
											defaultMessage="Nombre de doses 1re injection"
											description="Quantity of 1st dose injection"
										/>
									}
									type="number"
									inputProps={{ min: 0, endAdornment: 'test' }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} md={4}>
								<Field
									className={classes.longLabel}
									name="doseCount2"
									component={FormInput}
									label={
										<FormattedMessage
											id="vaccination.declarationOfUse.form.fields.doseCount2"
											defaultMessage="Nombre de doses 2e injection"
											description="Quantity of 2nd dose injection"
										/>
									}
									type="number"
									inputProps={{ min: 0 }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} md={4}>
								<Field
									className={classes.longLabel}
									name="doseCount3"
									component={FormInput}
									label={
										<FormattedMessage
											id="vaccination.declarationOfUse.form.fields.doseCount3"
											defaultMessage="Nombre de doses 3e injection"
											description="Quantity of 3rd dose injection"
										/>
									}
									type="number"
									inputProps={{ min: 0 }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} md={4}>
								<Field
									className={classes.longLabel}
									name="doseCountSingle"
									component={FormInput}
									label={
										<FormattedMessage
											id="vaccination.declarationOfUse.form.fields.singleDoseCount"
											defaultMessage="Nombre de doses uniques"
											description="Quantity of single dose"
										/>
									}
									type="number"
									inputProps={{ min: 0 }}
								/>
							</Grid>
						</Grid>
						<div
							className={classes.submitButtonRoot}
							style={{
								justifyContent: isLargeScreen ? 'flex-start' : 'center'
							}}
						>
							<Button type="submit" variant="contained" isLoading={submitting}>
								<FormattedMessage
									id="button.validate"
									defaultMessage="Valider"
									description="Message on form submission button"
								/>
							</Button>
						</div>
					</>}
				</PageFormContainer>
			)}
		/>
	)
}

export default compose(injectSnackbarActions)(DeclarationOfUse)
