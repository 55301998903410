import React, { useContext } from 'react'
import { initApplication, initCentre, PhidemDataModel } from './phidemDataModel'
import { OfflineContext } from '../offline/context/OfflineContext'
import { getStorageOfflineGlobalContext } from '../offline/offlineStorage'

export const initPhidemContextData: PhidemDataModel = {
	user: {
		centerList: [],
		selectedCenter: initCentre,
		isAdmin: false,
		isAdminDispatch: false,
		selectedLang: '',
		isDateFormatEn: false
	},
	vaccins: [],
	products: [],
	templates: [],
	referentialData: {
		trackers: [],
		containers: [],
		carriers: []
	},
	application: initApplication,
	valueList: {},
	reloadReferentiel: () => {
	}
}

export const PhidemDataContext = React.createContext(initPhidemContextData)

// Used on offline screens
export const usePhidemDataContext = () => {
	const { offlineMode } = useContext(OfflineContext)
	const localContext = useContext(PhidemDataContext)

	return offlineMode ? getStorageOfflineGlobalContext() : localContext
}
