import React from 'react'

const SvgDashboard = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={64.473}
    height={53.816}
>
  <g data-name="Groupe 313">
    <path
        data-name="Rectangle 375"
        fill="#414042"
        d="M27.662 42.375h9.149v5.029h-9.149z"
    />
    <path
        data-name="Rectangle 376"
        fill="#787878"
        d="M0 0h64.473v43.129H0z"
    />
    <path
        data-name="Rectangle 377"
        fill="#fff"
        d="M2.264 2.263H62.21v37.722H2.264z"
    />
    <path
        data-name="Rectangle 378"
        fill="#505050"
        d="M23.891 47.403h16.692v2.389H23.891z"
    />
    <path
        data-name="Rectangle 379"
        fill="#783c00"
        d="M0 49.792h64.473v4.024H0z"
    />
    <path
        data-name="Rectangle 380"
        fill="#0082aa"
        d="M2.264 2.263H62.21V5.03H2.264z"
    />
    <path
        data-name="Rectangle 381"
        fill="#dcdcdc"
        d="M2.264 5.03H62.21v12.354H2.264z"
    />
    <path
        data-name="Rectangle 382"
        fill="#bebebe"
        d="M3.018 5.784h5.815v2.138H3.018z"
    />
    <path
        data-name="Rectangle 383"
        fill="#bebebe"
        d="M11.732 5.784h7.136v2.138h-7.136z"
    />
    <path
        data-name="Rectangle 384"
        fill="#bebebe"
        d="M21.954 5.784h6.243v2.138h-6.243z"
    />
    <path
        data-name="Rectangle 385"
        fill="#bebebe"
        d="M31.473 5.784h8.544v2.138h-8.544z"
    />
    <path
        data-name="Rectangle 386"
        fill="#bebebe"
        d="M42.4 5.784h6.23v2.138H42.4z"
    />
    <path
        data-name="Rectangle 387"
        fill="#bebebe"
        d="M54.131 5.784h7.136v2.138h-7.136z"
    />
    <path
        data-name="Rectangle 388"
        fill="#fff"
        d="M3.018 9.068h15.85v2.138H3.018z"
    />
    <path
        data-name="Rectangle 389"
        fill="#fff"
        d="M20.193 9.069h6.243v2.138h-6.243z"
    />
    <path
        data-name="Rectangle 390"
        fill="#fff"
        d="M27.662 9.069h6.243v2.138h-6.243z"
    />
    <path
        data-name="Rectangle 391"
        fill="#fff"
        d="M47.555 9.069h6.243v2.138h-6.243z"
    />
    <path
        data-name="Rectangle 392"
        fill="#fff"
        d="M55.025 9.069h6.242v2.138h-6.242z"
    />
    <path
        data-name="Rectangle 393"
        fill="#fff"
        d="M47.555 12.276h6.243v2.137h-6.243z"
    />
    <path
        data-name="Rectangle 394"
        fill="#fff"
        d="M55.025 12.276h6.242v2.137h-6.242z"
    />
    <path
        data-name="Rectangle 395"
        fill="#bebebe"
        d="M3.018 12.276h10.971v2.436H3.018z"
    />
    <path
        data-name="Rectangle 396"
        fill="#bebebe"
        d="M26.437 12.276h7.469v2.436h-7.469z"
    />
    <path
        data-name="Rectangle 397"
        fill="#bebebe"
        d="M2.264 17.383H62.21v2.766H2.264z"
    />
    <path
        data-name="Rectangle 398"
        fill="#bebebe"
        d="M5.548 20.149h.755v19.836h-.755z"
    />
    <path
        data-name="Rectangle 399"
        fill="#bebebe"
        d="M26.06 20.149h.754v19.836h-.754z"
    />
    <path
        data-name="Rectangle 400"
        fill="#bebebe"
        d="M33.528 20.149h.754v19.836h-.754z"
    />
    <path
        data-name="Rectangle 401"
        fill="#bebebe"
        d="M41.588 20.149h.755v19.836h-.755z"
    />
    <path
        data-name="Rectangle 402"
        fill="#bebebe"
        d="M54.647 20.149h.755v19.836h-.755z"
    />
    <path
        data-name="Rectangle 403"
        fill="#bebebe"
        d="M2.264 24.362H62.21v.755H2.264z"
    />
    <path
        data-name="Rectangle 404"
        fill="#bebebe"
        d="M7.072 21.124h14.882v2.138H7.072z"
    />
    <path
        data-name="Rectangle 405"
        fill="#bebebe"
        d="M27.662 21.124h4.574v2.138h-4.574z"
    />
    <path
        data-name="Rectangle 406"
        fill="#bebebe"
        d="M43.228 21.124h7.449v2.138h-7.449z"
    />
    <path
        data-name="Rectangle 407"
        fill="#bebebe"
        d="M56.478 21.124h4.537v2.138h-4.537z"
    />
    <path
        data-name="Rectangle 408"
        fill="#bebebe"
        d="M3.018 21.124h1.697v2.138H3.018z"
    />
    <path
        data-name="Rectangle 409"
        fill="#bebebe"
        d="M2.264 29.69H62.21v.754H2.264z"
    />
    <path
        data-name="Rectangle 410"
        fill="#bebebe"
        d="M7.072 26.453h9.431v2.138H7.072z"
    />
    <path
        data-name="Rectangle 411"
        fill="#bebebe"
        d="M35.442 26.453h4.574v2.138h-4.574z"
    />
    <path
        data-name="Rectangle 412"
        fill="#bebebe"
        d="M43.228 26.453h9.913v2.138h-9.913z"
    />
    <path
        data-name="Rectangle 413"
        fill="#bebebe"
        d="M3.018 26.453h1.697v2.138H3.018z"
    />
    <path
        data-name="Rectangle 414"
        fill="#bebebe"
        d="M17.588 26.453h7.487v2.138h-7.487z"
    />
    <path
        data-name="Rectangle 415"
        fill="#bebebe"
        d="M2.264 34.924H62.21v.754H2.264z"
    />
    <path
        data-name="Rectangle 416"
        fill="#bebebe"
        d="M7.072 31.686h11.065v2.138H7.072z"
    />
    <path
        data-name="Rectangle 417"
        fill="#bebebe"
        d="M27.662 31.686h4.574v2.138h-4.574z"
    />
    <path
        data-name="Rectangle 418"
        fill="#bebebe"
        d="M43.228 31.686h4.327v2.138h-4.327z"
    />
    <path
        data-name="Rectangle 419"
        fill="#bebebe"
        d="M56.478 31.686h3.155v2.138h-3.155z"
    />
    <path
        data-name="Rectangle 420"
        fill="#bebebe"
        d="M3.018 31.686h1.697v2.138H3.018z"
    />
    <path
        data-name="Trac\xE9 814"
        d="M24.289 2.263L2.267 24.275v-12.2l9.815-9.815z"
        fill="#fff"
        opacity={0.2}
        style={{
          mixBlendMode: "screen",
          isolation: "isolate",
        }}
    />
    <path
        data-name="Trac\xE9 815"
        d="M62.207 15.72v15.8l-8.463 8.463h-15.8z"
        fill="#fff"
        opacity={0.2}
        style={{
          mixBlendMode: "screen",
          isolation: "isolate",
        }}
    />
  </g>
</svg>)

export default SvgDashboard
