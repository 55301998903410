export enum OFFLINE_ACTION {
	GET_BL_LIST,
	POST_ENTRY_STOCK,
	GET_BL_BY_PRODUCT,
	POST_EXIT_FRIDGE,
	POST_SCRAPPING,
	GET_STOCK_PAGE,
	UPDATE_STOCK
}

export enum OFFLINE_DATA {
	GLOBAL = 'GLOBAL',
	BL = 'BL',
	STOCK = 'STOCK'
}
