import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateTrackerFormModel } from './trackerModel'

export const postTracker = (values: RequestCreateOrUpdateTrackerFormModel) => fetchFactory(`/referentiel/tracker`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updateTracker = (id: string, values: RequestCreateOrUpdateTrackerFormModel) => fetchFactory(`/referentiel/tracker`, {
    method: 'PUT',
    body: JSON.stringify({ ...values, id })
})

export const updateTrackerStatus = (id: string, status: boolean) => fetchFactory(`/referentiel/tracker/status`, {
    method: 'PUT',
    body: JSON.stringify({ status, id })
})

export const getTrackerByID = (id: string) => fetchFactory(`/referentiel/tracker/${id}`)
