import React from 'react'

const SvgVaccinationExit = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={57.587}
    height={94.679}
>
  <g fill="#787878">
    <path d="M43.79 30.681a1.711 1.711 0 00-1.711 1.711v3.837H3.421V13.906a1.456 1.456 0 011.454-1.454h17.54a1.711 1.711 0 000-3.421H4.875A4.881 4.881 0 000 13.906v70.646a4.881 4.881 0 004.875 4.875h1.148v1.871a3.385 3.385 0 003.381 3.381h4.324a3.385 3.385 0 003.381-3.381v-1.871h12.039v1.871a3.385 3.385 0 003.381 3.381h4.324a3.385 3.385 0 003.381-3.381v-1.871h.853l.4-.233c2.7-1.574 4.014-3.093 4.014-4.642v-52.16a1.711 1.711 0 00-1.711-1.711zm-30.1 60.577H9.446v-1.831h4.244zm23.125 0H32.57v-1.831h4.244zm3.339-5.252H4.875a1.456 1.456 0 01-1.454-1.454v-44.9H42.08v44.759a5.9 5.9 0 01-1.928 1.595z" />
    <path d="M6.329 21.475v9.3a1.711 1.711 0 003.421 0v-9.3a1.711 1.711 0 10-3.421 0zM8.237 42.081a1.711 1.711 0 00-1.711 1.711v29.611a1.711 1.711 0 103.421 0V43.792a1.711 1.711 0 00-1.71-1.711zM55.883 13.001l-2.484-.01.917-.917a1.711 1.711 0 00-2.419-2.419l-3.316 3.316-4.54-.019 2.193-3.748 4.471-1.19a1.711 1.711 0 10-.88-3.306l-1.137.3 1.425-2.436A1.71 1.71 0 1047.16.844l-1.283 2.192-.295-1.107a1.711 1.711 0 00-3.306.88l1.167 4.386-2.3 3.925-2.142-3.636L40.138 3a1.711 1.711 0 10-3.316-.841L36.533 3.3 35.099.869a1.711 1.711 0 00-2.947 1.738l1.291 2.188-1.11-.282a1.711 1.711 0 10-.841 3.316l4.4 1.115 2.35 3.984-4.322-.018-3.271-3.271a1.711 1.711 0 10-2.419 2.419l.832.832-2.823-.011h-.007a1.711 1.711 0 00-.007 3.421l2.54.01-.81.81a1.711 1.711 0 002.419 2.419l3.209-3.209 4.508.019-2.159 3.689-4.323 1.151a1.711 1.711 0 00.88 3.306l.99-.264-1.289 2.2a1.71 1.71 0 102.953 1.727l1.255-2.144.334 1.253a1.711 1.711 0 103.306-.88l-1.206-4.532 2.33-3.982 2.289 3.881-1.1 4.337a1.711 1.711 0 103.316.841l.252-.993 1.3 2.2a1.711 1.711 0 002.947-1.738l-1.262-2.14 1.257.319a1.71 1.71 0 00.841-3.316l-4.546-1.153-2.205-3.738 4.213.017 3.163 3.163a1.711 1.711 0 002.419-2.419l-.724-.724 2.553.011h.007A1.711 1.711 0 0055.889 13z" />
  </g>
</svg>)

export default SvgVaccinationExit
