import React from 'react'
import { Grid } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { FormInput } from 'isotope-client'
import { FormattedMessage, useIntl } from 'react-intl'
import { DispatchReferentLigneBlModel } from '../../stock/stockModels'

const useStyles = makeStyles((theme) => createStyles({
	container: {
		marginBottom: theme.spacing(2)
	},
	details: {
		paddingLeft: theme.spacing(5)
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	}
}))

interface LabelSelectionLineProps {
	data: DispatchReferentLigneBlModel
	push: (...args: any[]) => any
	values: any
	vaccineIndex: number
}

const LabelSelectionLine = ({ data, push, values, vaccineIndex }: LabelSelectionLineProps) => {
	const intl = useIntl()
	const classes = useStyles()
	const [isOpen, setIsOpen] = React.useState(false)
	const remainingQuantity = React.useMemo(() => {
		if (values && values.length) {
			const totalBottles = values.map((division: any) => (division.boxes || 0) * (division.bottles || 0))
				.reduce((p: number, c: number) => p + c)
			return data.quantite - totalBottles
		}

		return data.quantite
	}, [values, data.quantite])


	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} className={classes.row}>
				<FormattedMessage
					id="common.exitStock.dialogLabelSelection.lineDetails"
					defaultMessage={`${data.produit.nomCommercial} - ${data.stock?.lot ? `Lot ${data.stock?.lot} -` : ''} ${data.quantite} ${data.produit.typeConditionnement}(s)`}
					description="Line details"
					values={{
						nom: data.produit.nomCommercial,
						lot: data.stock?.lot ?
							intl.formatMessage({ id: 'common.exitStock.dialogLabelSelection.lot' }, { lot: data.stock?.lot })
							: '',
						quantite: data.quantite,
						modeQte: data.produit.typeConditionnement
					}}
				/>
				{isOpen ? <ArrowDropUpIcon onClick={() => setIsOpen(false)}/> : <ArrowDropDownIcon onClick={() => setIsOpen(true)}/>}
			</Grid>
			<Grid container item xs={12} className={classes.details}>
				{isOpen &&
					<>
						<FieldArray name={`#${vaccineIndex}${data.produit.nomCommercial}`}>
							{({ fields }) =>
								fields.map((name, index) =>
									<Grid key={`${name}`} container item xs={12} spacing={2} className={classes.row}>
										<Grid item xs={2}>
											<Field
												name={`${name}.boxes`}
												component={FormInput}
												type="number"
												inputProps={{ min: 0 }}
												defaultValue="0"
												label={
													<FormattedMessage
														id="common.exitStock.dialogLabelSelection.fields.boxes"
														defaultMessage="Boîtes"
														description="Input label"
													/>
												}
											/>
										</Grid>
										<Grid item xs={2}>
											<Field
												name={`${name}.bottles`}
												component={FormInput}
												type="number"
												inputProps={{ min: 0 }}
												defaultValue="0"
												label={
													<FormattedMessage
														id="common.exitStock.dialogLabelSelection.fields.bottles"
														defaultMessage="Flacons"
														description="Input label"
													/>
												}
											/>
										</Grid>
										<DeleteIcon onClick={() => fields.remove(index)} />
									</Grid>
								)
							}
						</FieldArray>
						<Grid item xs={12} className={classes.row}>
							<FormattedMessage
								id="common.exitStock.dialogLabelSelection.remaining"
								defaultMessage={`${remainingQuantity} unité(s) restante(s)`}
								description="Remaining quantity"
								values={{
									count: remainingQuantity
								}}
							/>
							&nbsp;
							<AddCircleOutlineIcon onClick={() => push(`#${vaccineIndex}${data.produit.nomCommercial}`, { boxes: 0, bottles: 0})}/>
						</Grid>
					</>
				}
			</Grid>
		</Grid>
	)
}

export default LabelSelectionLine
