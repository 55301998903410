import React from 'react'
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuTile from "./MenuTile/MenuTile"
import { HOME_MENU_TILE } from '../../utils/home-menus/homeMenus'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        grid: {
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center'
            },
        }
    })
)

interface AccueilMenuTilesProps {
    tiles: HOME_MENU_TILE[]
}

const AccueilMenuTiles: React.FC<AccueilMenuTilesProps> = ({ tiles }) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Grid container item xs={12} spacing={6} className={classes.grid}>
                {tiles.map((
                    {
                        title,
                        content,
                        linkText,
                        linkHref,
                        icon
                    }
                ) => (
                    <Grid item xs={10} md={4} key={linkHref}>
                        <MenuTile
                            title={title}
                            content={content}
                            linkText={linkText}
                            linkHref={linkHref}
                            icon={icon}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default AccueilMenuTiles
