import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { colors } from '../../utils/constants'
import { formatDecimal } from '../../utils/formUtils'

const useStyles = ({ isNextToInput, disabled }: TotalCountCaptionProps) =>
    makeStyles(() =>
        createStyles({
            totalContainer: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
            },
            text: {
                fontSize: '0.9rem',
                ...(isNextToInput && { marginBottom: 10 }),
                ...(disabled && { color: colors.displayText })
            }
        }),
    )

interface TotalCountCaptionProps {
    count: number | undefined
    type?: string
    isNextToInput?: boolean // Need it to adjust margin, because isotope FieldInputs have extra bottom margin
    disabled?: boolean,
    labelId?: string,
    fullRow?: boolean
}

const TotalCountCaption: React.FC<TotalCountCaptionProps> = (
    {
        count,
        isNextToInput = true,
        disabled = false,
        labelId = 'global.functionnal.totalCount',
        type = (
            <FormattedMessage
                id="global.functionnal.dose"
                defaultMessage="dose"
                description="dose"
            />
        ),
        fullRow = false
    }) => {
    const classes = useStyles({ count, isNextToInput, disabled })()
    const displayedCount  = count ? formatDecimal(Math.round(count * 10) / 10) : undefined

    return (
        Boolean(displayedCount) ?
            <Grid item xs={fullRow ? 12 : 5}>
                <div className={classes.totalContainer}>
                    <FormattedMessage
                        id={labelId}
                        defaultMessage={`Soit ${displayedCount} ${type}s`}
                        description="Count total"
                        values={{ count: displayedCount, type }}
                    >
                        {msg => <Typography variant='caption' className={classes.text}>{msg}</Typography>}
                    </FormattedMessage>
                </div>
            </Grid>
            :
            null
    )
}

export default TotalCountCaption
