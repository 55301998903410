import React from 'react'
import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core'
import { QTY_MODE } from '../../../../utils/constants'
import { Field } from 'react-final-form'
import { FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import TotalCountCaption from '../../../../components/fields/TotalCountCaption'
import { DayModel } from './ProcurementForm'
import { ProcurementFormValues } from '../services/procurementModels'

export interface DayTileProps {
	title: any
	day: DayModel
	type: string,
	nbDoses: number
	values: ProcurementFormValues
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	paper: {
		padding: theme.spacing(2)
	},
	title: {
		fontSize: '0.75rem',
		marginBottom: theme.spacing(2)
	}
}))

const DayTile: React.FC<DayTileProps> = (
	{
		title,
		day,
		type,
		nbDoses,
		values
	}
) => {
	const classes = useStyles()

	return (
		<Paper className={classes.paper}>
			<div className={classes.title}>
				{title}
			</div>
			{values.modeQte === QTY_MODE.DOSE ?
				<Grid container spacing={2}>
					<Grid item xs={5}>
						<Field
							name={`${day.key}1`}
							component={FormInput}
							label={
								<FormattedMessage
									id="procurement.quantity.D1"
									defaultMessage="D1"
									description="Comment label input"
								/>
							}
							type="number"
							inputProps={{ min: 0 }}
						/>
					</Grid>
					<Grid item xs={5}>
						<Field
							name={`${day.key}2`}
							component={FormInput}
							label={
								<FormattedMessage
									id="procurement.quantity.D2"
									defaultMessage="D2"
									description="Comment label input"
								/>
							}
							type="number"
							inputProps={{ min: 0 }}
						/>
					</Grid>
				</Grid>
				:
				<Grid item xs={12} md={6}>
					<Field
						name={day.key}
						component={FormInput}
						type="number"
						inputProps={{ min: 0 }}
					/>
				</Grid>
			}
			<Grid container>
				<TotalCountCaption
					count={values.modeQte === QTY_MODE.DOSE ? Math.ceil((Number(values[`${day.key}1` as keyof ProcurementFormValues] || 0) + Number(values[`${day.key}2` as keyof ProcurementFormValues] || 0)) / nbDoses) : Number(values[day.key as keyof ProcurementFormValues] || 0) * nbDoses}
					type={values.modeQte === QTY_MODE.DOSE ? type : 'dose'}
					isNextToInput={false}
					fullRow
				/>
			</Grid>
		</Paper>
	)
}

export default DayTile
