import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SCREEN_SIZE } from '../../utils/constants'
import TooSmallPage from './TooSmallPage'

const OnlyDesktop: React.FC = ({ children }) => {
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)

    return (
        <>
            {isLargeScreen ? children : <TooSmallPage />}
        </>
    )
}

export default OnlyDesktop
