import React, { useContext, useEffect, useState } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useParams } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { getContainerByID, updateContainer, updateContainerStatus } from './services/containerApi'
import { RequestCreateOrUpdateContainerFormModel } from './services/containerModel'
import ContainerForm from './components/ContainerForm'
import errorsMapper from '../../../utils/errorsMapper'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

interface MatchParamsModel {
    id: string
}

interface ContainerCreateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const ContainerCreate: React.FC<ContainerCreateProps> = (
    {
        snackSuccess,
        snackError
    }) => {
    const { id: idInUrl } = useParams<MatchParamsModel>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [fetchedContainer, setFetchedContainer] = useState<RequestCreateOrUpdateContainerFormModel>()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    useEffect(() => {
        getContainerByID(idInUrl)
            .then((response: RequestCreateOrUpdateContainerFormModel) => setFetchedContainer(response))
            .finally(() => setIsLoading(false))
    }, [idInUrl])

    const handleContainerStatus = () => {
        updateContainerStatus(idInUrl, !fetchedContainer?.actif)
            .then(() => {
                setFetchedContainer({
                    ...fetchedContainer as RequestCreateOrUpdateContainerFormModel,
                    actif: !fetchedContainer?.actif
                })
                snackSuccess({ id: 'container.updateStatusSuccess', defaultMessage: 'Le statut du contenant a bien été modifié', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.CONTAINER)
            })
            .catch(() => snackError({ id: 'container.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du contenant n\'a pas été modifiée', description: 'Error message' }))
    }

    const onSubmit = async (formValues: any) => {
        return updateContainer(idInUrl, formValues)
            .then((response: RequestCreateOrUpdateContainerFormModel) => {
                setFetchedContainer(response)
                snackSuccess({ id: 'container.updateSuccess', defaultMessage: 'Le contenant a bien été créé', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.CONTAINER)
            })
            .catch((e: any) => {
                const { errors, displayFields } = errorsMapper(e)
                if (displayFields) {
                    snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
                } else {
                    snackError({ id: 'container.createError', defaultMessage: 'Une erreur est survenue, le contenant n\'a pas été créé', description: 'Error message' })
                }
                return errors
            })
    }

    return (
        <ContainerForm
            isLoading={isLoading}
            initialValues={fetchedContainer}
            onSubmit={onSubmit}
            isCreationForm={false}
            updateContainerStatus={handleContainerStatus}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="container.pageTitle.update"
        defaultMessage="Modifier un contenant"
        description="Page title"
    />
})), injectSnackbarActions)(ContainerCreate)
