import React, { useContext } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import AccueilTitle from './AccueilTitle'
import AccueilMenuTiles from './AccueilMenuTiles'
import { HOME_ADMIN_DATA, HOME_DISPATCH_DATA, HOME_REFERENT_WITH_STOCK_DATA, HOME_REFERENT_WITHOUT_STOCK_DATA, HOME_VACCINATION_DATA, HOME_VACCINATION_POINT_DATA } from '../../utils/home-menus/homeMenus'
import { CENTER_TYPE } from '../../utils/constants'
import { PhidemDataContext } from '../common/phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleContainer: {
			marginTop: theme.spacing(6),
			marginLeft: theme.spacing(4),
			marginBottom: theme.spacing(3),

			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(6),
				marginLeft: theme.spacing(0),
				marginBottom: theme.spacing(3)
			}
		}
	})
)

const AccueilPage: React.FC = () => {
	const classes = useStyles()
	const { user: { selectedCenter, isAdmin } } = useContext(PhidemDataContext)
	let menuData
	let title

	if (isAdmin) {
		menuData = HOME_ADMIN_DATA
		title = (
			<FormattedMessage
				id="menu.admin.home.title"
				defaultMessage="Administration Promedeo"
				description="Home title"
			/>
		)
	} else {
		switch (selectedCenter.type) {
			case CENTER_TYPE.REFERENT:
				menuData = selectedCenter.gestionStock ? HOME_REFERENT_WITH_STOCK_DATA : HOME_REFERENT_WITHOUT_STOCK_DATA
				title = (
					<FormattedMessage
						id="menu.referentCenter.home.title"
						defaultMessage={`Centre référent ${selectedCenter.nom}`}
						description="Home title"
						values={{
							centerName: selectedCenter.nom
						}}
					/>
				)
				break
			case CENTER_TYPE.DISPATCH:
				menuData = HOME_DISPATCH_DATA
				title = (
					<FormattedMessage
						id="menu.dispatchCenter.home.title"
						defaultMessage={`Centre de dispatch ${selectedCenter.nom}`}
						description="Home title"
						values={{
							centerName: selectedCenter.nom
						}}
					/>
				)
				break
			case CENTER_TYPE.VACCINATION:
				menuData = HOME_VACCINATION_DATA
				title = (
					<FormattedMessage
						id="menu.vaccinationCenter.home.title"
						defaultMessage={`Centre de vaccination ${selectedCenter.nom}`}
						description="Home title"
						values={{
							centerName: selectedCenter.nom
						}}
					/>
				)
				break
			case CENTER_TYPE.VACCINATION_POINT:
				menuData = HOME_VACCINATION_POINT_DATA
				title = (
					<FormattedMessage
						id="menu.vaccinationPoint.home.title"
						defaultMessage={`Point de vaccination ${selectedCenter.nom}`}
						description="Home title"
						values={{
							centerName: selectedCenter.nom
						}}
					/>
				)
				break
			default:
				menuData = { tiles: [] }
				title = ''
				break
		}
	}

	return (
		<div>
			<div className={classes.titleContainer}>
				<AccueilTitle text={title} />
			</div>
			<AccueilMenuTiles tiles={menuData.tiles} />
		</div>
	)
}

export default AccueilPage
