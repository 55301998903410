import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SvgPictoLogo from '../../components/icons/SvgPictoLogo'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            }
        },
        logo: {
            height: 80,
            marginRight: 30,

            [theme.breakpoints.down('md')]: {
                marginRight: 0,
                marginBottom: 20,
            }
        },
        title: {
            margin: 0,
            padding: 0,
            textAlign: 'center'
        }
    }),
)

interface AccueilTitleProps {
    text: string | React.ReactElement
}

const AccueilTitle: React.FC<AccueilTitleProps> = ({ text }) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <SvgPictoLogo className={classes.logo} />
            <Typography variant="h1" className={classes.title}>
                {text}
            </Typography>
        </div>
    )
}

export default AccueilTitle
