import React from 'react'
import { Field, Form } from 'react-final-form'
import Paper from '@material-ui/core/Paper'
import Search from '@material-ui/icons/Search'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormInput, FormSelect, FormSwitch } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import { VaccinModel } from '../../admin/product/services/productModel'
import Button from '../../../components/layout/buttons/Button'
import { CENTER_TYPE, SCREEN_SIZE, SELECT_VALUES } from '../../../utils/constants'
import { usePhidemDataContext } from '../phidemData/PhidemDataContext'
import { getAvailableDispatchCenters } from '../../admin/center/services/centerApi'
import { CentreModel } from '../phidemData/phidemDataModel'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		mobilePaperForm: {
			width: '90%',
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		titleContainer: {
			marginTop: 32,
			width: 'fit-content',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		},
		expand: {
			transform: 'rotate(0deg)',
			marginLeft: 'auto',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest
			})
		},
		expandOpen: {
			transform: 'rotate(180deg)'
		}
	})
)

export interface StockListFormValues {
	idProduit: string
	idCentre: string
	batchNumber: string
	isVisibleOutOfStock: boolean
	onlyRetour: boolean
}

interface StockListFormProps {
	hideCenterFilter?: boolean,
	showEmptyStock?: boolean,
	showRetourFilter?: boolean,
	onSubmit: (formValues: StockListFormValues) => any
}

const StockForm: React.FC<StockListFormProps> = (
	{
		hideCenterFilter = false,
		showEmptyStock = true,
		showRetourFilter = false,
		onSubmit
	}
) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { vaccins, user: { isAdmin, selectedCenter } } = usePhidemDataContext()
	const [centers, setCenters] = React.useState<CentreModel[]>([])

	React.useEffect(() => {
		if (isAdmin || selectedCenter.type === CENTER_TYPE.REFERENT) {
			getAvailableDispatchCenters()
				.then((response: CentreModel[]) => setCenters(response))
		}
	}, [isAdmin, selectedCenter.type])


	return (
		<Paper className={isLargeScreen ? classes.paperForm : classes.mobilePaperForm}>
			<Form
				initialValues={{
					idProduit: SELECT_VALUES.ALL,
					idCentre: SELECT_VALUES.ALL,
					isVisibleOutOfStock: false,
					onlyRetour: false
				}}
				onSubmit={onSubmit}
				// @ts-ignore
				render={({ handleSubmit, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Grid
							container
							direction="row"
							justify="flex-start"
							spacing={2}
						>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={12} md={5}>
									<Field
										name="idProduit"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functionnal.product"
												defaultMessage="Produit"
												description="Product"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{vaccins.map((vaccin: VaccinModel) => (
											<MenuItem
												key={vaccin.id}
												value={vaccin.id}
											>
												{vaccin.nomCommercial}
											</MenuItem>
										))}
									</Field>
								</Grid>
								{((isAdmin || selectedCenter.type === CENTER_TYPE.REFERENT) && !hideCenterFilter) && <Grid item xs={12} md={5}>
									<Field
										name="idCentre"
										component={FormSelect}
										label={
											<FormattedMessage
												id="dispatch.movementHistory.search.center"
												defaultMessage="Centre de dispatch"
												description="Dispatch center"
											/>
										}
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{centers.map((center: CentreModel) =>
											<MenuItem key={center.id} value={center.id}>
												{center.nom}
											</MenuItem>
										)}
									</Field>
								</Grid>}
							</Grid>
							<Grid container item xs={12} spacing={2} alignItems="center">
								<Grid item xs={12} md={4}>
									<Field
										name="batchNumber"
										component={FormInput}
										label={
											<FormattedMessage
												id="global.functionnal.batchNumber"
												defaultMessage="Lot"
												description="Batch number"
											/>
										}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{showEmptyStock && <Grid item xs={12} md={4}>
									<Field
										name="isVisibleOutOfStock"
										component={FormSwitch}
										label={
											<FormattedMessage
												id="stockEdit.isVisibleOutOfStock"
												defaultMessage="Voir les stocks épuisés"
												description="form input"
											/>
										}
										type="checkbox"
									/>
								</Grid>}
								{showRetourFilter && <Grid item xs={12} md={4}>
									<Field
										name="onlyRetour"
										component={FormSwitch}
										label={
											<FormattedMessage
												id="stockEdit.onlyRetour"
												defaultMessage="Voir uniquement les retours"
												description="form input"
											/>
										}
										type="checkbox"
									/>
								</Grid>}
							</Grid>

							<Grid item xs={12} md={12}>
								<div className={classes.submitButtonRoot}>
									<Button
										variant="contained"
										startIcon={<Search />}
										type="submit"
										isLoading={submitting}
									>
										<FormattedMessage
											id="button.search"
											defaultMessage="Rechercher"
											description="Search button label"
										/>
									</Button>
								</div>
							</Grid>
						</Grid>
					</form>
				)} />
		</Paper>
	)
}

export default StockForm
