import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/layout/buttons/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '../../../../components/layout/dialog/Dialog'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
	createStyles({
		description: {
			fontSize: 14,
			fontWeight: 'bold',
			whiteSpace: 'break-spaces'
		}
	})
)

interface DialogProductReturnQtyWarningProps {
	title?: string
	description?: string
	isOpen: boolean,
	loading?: boolean,
	handleClose: () => any
	onConfirm: () => Promise<any>
}

const DialogQtyProcurementWarning: React.FC<DialogProductReturnQtyWarningProps> = (
	{
		title = 'dispatch.exitStock.dialogProcurementWarning.title',
		description = 'dispatch.exitStock.dialogProcurementWarning.content',
		isOpen,
		handleClose,
		onConfirm,
		loading = false
	}
) => {
	const classes = useStyles()

	return (
		<Dialog
			title={
				<FormattedMessage
					id={title}
					defaultMessage="Confirmation de la préparation"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
			buttons={[
				<Button onClick={handleClose} color="primary" key={0} variant="outlined">
					<FormattedMessage
						id="button.cancel"
						defaultMessage="ANNULER"
						description="Button label"
					/>
				</Button>,
				<Button color="primary" key={1} variant="contained" onClick={onConfirm} isLoading={loading}>
					<FormattedMessage
						id="button.confirm"
						defaultMessage="CONFIRMER"
						description="Button label"
					/>
				</Button>
			]}
		>
			<Typography gutterBottom className={classes.description}>
				<FormattedMessage
					id={description}
					defaultMessage="La quantité saisie est supérieure à la quantité en allocation pour ce centre. Confirmez-vous la préparation ?"
					description="Dialog content"
				/>
			</Typography>
		</Dialog>
	)
}

export default DialogQtyProcurementWarning
