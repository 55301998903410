import React from 'react'

const SvgMovement = () => (<svg
  xmlns="http://www.w3.org/2000/svg"
  width={62.62}
  height={68.248}
>
  <g data-name="Groupe 341">
    <path
      data-name="Trac\xE9 1162"
      d="M12.883 65.839a2.41 2.41 0 11-2.41-2.41 2.409 2.409 0 012.41 2.41z"
      fill="#0082aa"
    />
    <g data-name="Groupe 339">
      <path
        data-name="Rectangle 518"
        fill="#0082aa"
        d="M10.473 65.419h.42v.839h-.42z"
      />
      <path
        data-name="Trac\xE9 1163"
        d="M16.461 66.258h-.929v-.839h.929zm-1.857 0h-.927v-.839h.927zm-1.856 0h-.93v-.839h.928z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 519"
        fill="#0082aa"
        d="M17.389 65.419h.839v.839h-.839z"
      />
      <path
        data-name="Trac\xE9 1164"
        d="M18.227 64.568h-.838v-.851h.838zm0-1.7h-.838v-.851h.838zm0-1.7h-.838v-.85h.838zm0-1.7h-.838v-.851h.838zm0-1.7h-.838v-.852h.838zm0-1.7h-.838v-.852h.838zm0-1.7h-.838v-.852h.838zm0-1.7h-.838v-.85h.838zm0-1.7h-.838v-.851h.838z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 520"
        fill="#0082aa"
        d="M17.389 48.41h.839v.838h-.839z"
      />
      <path
        data-name="Trac\xE9 1165"
        d="M35.093 49.246h-.844v-.838h.844zm-1.688 0h-.843v-.838h.843zm-1.685 0h-.844v-.838h.842zm-1.688 0h-.843v-.838h.843zm-1.686 0h-.843v-.838h.843zm-1.686 0h-.842v-.838h.844zm-1.687 0h-.843v-.838h.843zm-1.687 0h-.843v-.838h.843zm-1.686 0h-.843v-.838h.843zm-1.687 0h-.843v-.838h.843z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 521"
        fill="#0082aa"
        d="M35.935 48.41h.838v.838h-.838z"
      />
      <path
        data-name="Trac\xE9 1166"
        d="M36.773 47.505h-.838v-.9h.838zm0-1.808h-.838v-.9h.838zm0-1.809h-.838v-.9h.838zm0-1.809h-.838v-.9h.838z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 522"
        fill="#0082aa"
        d="M35.935 39.433h.838v.839h-.838z"
      />
      <path
        data-name="Trac\xE9 1167"
        d="M35.118 40.272h-.82v-.839h.82zm-1.638 0h-.819v-.839h.819zm-1.638 0h-.824v-.839h.82zm-1.639 0h-.819v-.839h.819zm-1.639 0h-.819v-.839h.819z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 523"
        fill="#0082aa"
        d="M26.086 39.433h.838v.839h-.838z"
      />
      <path
        data-name="Trac\xE9 1168"
        d="M26.924 38.565h-.838v-.869h.838zm0-1.737h-.838v-.868h.838zm0-1.736h-.838v-.868h.838z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 524"
        fill="#0082aa"
        d="M26.086 32.518h.838v.838h-.838z"
      />
      <path
        data-name="Trac\xE9 1169"
        d="M54.358 33.356h-.857v-.838h.857zm-1.714 0h-.858v-.838h.858zm-1.714 0h-.858v-.838h.858zm-1.715 0h-.858v-.838h.861zm-1.715 0h-.858v-.838h.858zm-1.715 0h-.857v-.838h.857zm-1.715 0h-.852v-.838h.856zm-1.714 0h-.857v-.838h.857zm-1.715 0h-.857v-.838h.857zm-1.714 0h-.858v-.838h.858zm-1.715 0h-.858v-.838h.858zm-1.714 0h-.858v-.838h.858zm-1.715 0h-.857v-.838h.857zm-1.715 0h-.857v-.838h.857zm-1.715 0h-.857v-.838h.857zm-1.713 0h-.859v-.838h.859z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 525"
        fill="#0082aa"
        d="M55.216 32.518h.838v.838h-.838z"
      />
      <path
        data-name="Trac\xE9 1170"
        d="M56.054 31.698h-.836v-.82h.838zm0-1.638h-.836v-.82h.838zm0-1.639h-.836v-.819h.838zm0-1.638h-.836v-.82h.838zm0-1.639h-.836v-.82h.838z"
        fill="#0082aa"
      />
      <path
        data-name="Rectangle 526"
        fill="#0082aa"
        d="M55.216 23.087h.838v.419h-.838z"
      />
      <g data-name="Groupe 338">
        <path
          data-name="Trac\xE9 1171"
          d="M55.635 19.546a17.732 17.732 0 002.791 5.017l-2.791-1.01-2.79 1.01a17.754 17.754 0 002.79-5.017z"
          fill="#0082aa"
        />
      </g>
    </g>
    <path
      data-name="Trac\xE9 1172"
      d="M20.946 46.734c0 5.784-10.473 14.665-10.473 14.665S0 52.518 0 46.734a10.473 10.473 0 0120.946 0z"
      fill="#f06400"
    />
    <path
      data-name="Trac\xE9 1173"
      d="M16.934 45.646a6.461 6.461 0 11-6.461-6.46 6.461 6.461 0 016.461 6.46z"
      fill="#fff"
    />
    <path
      data-name="Trac\xE9 1174"
      d="M62.618 6.985c0 3.858-6.985 9.782-6.985 9.782s-6.986-5.924-6.986-9.782a6.986 6.986 0 0113.971 0z"
      fill="#00b4c8"
    />
    <path
      data-name="Trac\xE9 1175"
      d="M59.944 6.261a4.309 4.309 0 11-4.309-4.309 4.309 4.309 0 014.309 4.309z"
      fill="#fff"
    />
    <path
      data-name="Rectangle 527"
      d="M11.976 16.904h23.611a.838.838 0 01.838.838V28.43a.838.838 0 01-.838.838H11.976a.839.839 0 01-.839-.839V17.743a.839.839 0 01.839-.839z"
      fill="#ffdc00"
    />
    <path
      data-name="Trac\xE9 1176"
      d="M34.05 19.796H13.512a.419.419 0 010-.838H34.05a.419.419 0 010 .838z"
      fill="#787878"
    />
    <path
      data-name="Trac\xE9 1177"
      d="M34.05 22.27H13.512a.419.419 0 010-.838H34.05a.419.419 0 010 .838z"
      fill="#787878"
    />
    <path
      data-name="Trac\xE9 1178"
      d="M34.05 24.746H13.512a.42.42 0 010-.839H34.05a.42.42 0 010 .839z"
      fill="#787878"
    />
    <path
      data-name="Trac\xE9 1179"
      d="M34.05 27.215H13.512a.419.419 0 010-.838H34.05a.419.419 0 010 .838z"
      fill="#787878"
    />
    <path
      data-name="Rectangle 528"
      d="M31.436 51.989h20.426a.838.838 0 01.836.838v10.687a.839.839 0 01-.839.839H31.437a.839.839 0 01-.839-.839V52.827a.838.838 0 01.838-.838z"
      fill="#ffdc00"
    />
    <g data-name="Groupe 340" fill="#787878">
      <path
        data-name="Trac\xE9 1180"
        d="M50.533 54.88H32.764a.423.423 0 010-.838h17.769a.423.423 0 010 .838z"
      />
      <path
        data-name="Trac\xE9 1181"
        d="M50.533 57.355H32.764a.424.424 0 010-.839h17.769a.424.424 0 010 .839z"
      />
      <path
        data-name="Trac\xE9 1182"
        d="M50.533 59.826H32.764a.423.423 0 010-.838h17.769a.423.423 0 010 .838z"
      />
      <path
        data-name="Trac\xE9 1183"
        d="M50.533 62.3H32.764a.424.424 0 010-.839h17.769a.424.424 0 010 .839z"
      />
    </g>
    <path
      data-name="Rectangle 529"
      fill="#c8c8c8"
      d="M41.07 22.689h9.114v7.419H41.07z"
    />
    <path
      data-name="Rectangle 530"
      fill="#c8c8c8"
      d="M38.939 36.918h4.96v10.587h-4.96z"
    />
    <path
      data-name="Rectangle 531"
      fill="#c8c8c8"
      d="M46.57 36.918h9.065v3.353H46.57z"
    />
    <path
      data-name="Rectangle 532"
      fill="#c8c8c8"
      d="M21.265 53.055h5.364v9.664h-5.364z"
    />
  </g>
</svg>)

export default SvgMovement
