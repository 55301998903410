import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../components/layout/buttons/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '../../../components/layout/dialog/Dialog'

interface DialogProductReturnQtyWarningProps {
    isOpen: boolean
    handleClose: () => any
    onConfirm: () => Promise<any>
}

const DialogProductReturnQtyWarning: React.FC<DialogProductReturnQtyWarningProps> = (
    {
        isOpen,
        handleClose,
        onConfirm
    }
) => {
    return (
        <Dialog
            title={
                <FormattedMessage
                    id='dispatch.productReturn.dialog.title'
                    defaultMessage="Confirmation du retour de produit"
                    description='Dialog title'
                />
            }
            isOpen={isOpen}
            handleClose={handleClose}
            buttons={[
                <Button onClick={handleClose} color="primary" key={0} variant="outlined">
                    <FormattedMessage
                        id="button.cancel"
                        defaultMessage="ANNULER"
                        description="Button label"
                    />
                </Button>,
                <Button color="primary" key={1} variant="contained" onClick={onConfirm}>
                    <FormattedMessage
                        id="button.confirm"
                        defaultMessage="CONFIRMER"
                        description="Button label"
                    />
                </Button>
            ]}
        >
            <Typography gutterBottom>
                <FormattedMessage
                    id="dispatch.productReturn.dialog.content"
                    defaultMessage="La quantité saisie est supérieure à la quantité résiduelle présente dans le centre de vaccination. Confirmez-vous la quantité en retour ?"
                    description="Dialog content"
                />
            </Typography>
        </Dialog>
    )
}

export default DialogProductReturnQtyWarning
