import React, { useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment, { Moment } from 'moment'
import { FormattedMessage } from 'react-intl'
import { FormInput, FormSelect } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import { compose } from 'redux'
import { useHistory } from 'react-router-dom'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import FieldProduct from '../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../components/fields/FieldUnitProduct'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { SCRAPPING_OTHER_OPTION, SCREEN_SIZE, VALUE_LIST_SHORTCUT } from '../../../utils/constants'
import { getAvailableBatchNumberListByProductID, postScrapping } from '../services/vaccinationApi'
import { ResponseBatchNumberModelItem } from '../services/vaccinationModels'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import FieldQuantity from '../../../components/fields/FieldQuantity'
import { VaccinModel } from '../../admin/product/services/productModel'
import { usePhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import errorsMapper from '../../../utils/errorsMapper'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import { compareLimitDate } from '../../../utils/formUtils'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../utils/dateUtils'
import { OFFLINE_ACTION } from '../../common/offline/offlineConstants'
import { OfflineContext } from '../../common/offline/context/OfflineContext'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		longLabel: {
			whiteSpace: 'nowrap'
		}
	})
)

interface FormValues {
	product: string
	unitProduct: string
	batchNumberID: string
	limitDate: string
	quantite: number
	causeDechet: string
	commentaireDechet: string
}

interface ScrappingProps {
	snackInfo: (value: any) => void
	snackSuccess: (value: any) => void
	snackError: (value: any) => void
}

const Scrapping: React.FC<ScrappingProps> = (
	{
		snackInfo,
		snackSuccess,
		snackError
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { vaccins, valueList } = usePhidemDataContext()
	const { handleOfflineAction } = useContext(OfflineContext)
	const history = useHistory()
	const [batchNumberList, setBatchNumberList] = useState<ResponseBatchNumberModelItem[]>([])
	const [dosesCount, setDosesCount] = useState<number>(0)
	const [showBatchList, setShowBatchList] = useState<boolean>(false)
	const [showLimitDate, setShowLimitDate] = useState<boolean>(false)
	const scrappingCauseOptions = valueList[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE] || {}

	const fetchBatchNumberList = (productId: string) => {
		setBatchNumberList([]) // clear
		setDosesCount(0) // clear
		if (productId) {
			handleOfflineAction(OFFLINE_ACTION.GET_BL_BY_PRODUCT, getAvailableBatchNumberListByProductID, productId)
				.then((fetchedBatchNumberList: ResponseBatchNumberModelItem[]) => setBatchNumberList(fetchedBatchNumberList))
				.finally(() => setShowBatchList(true))
		}
	}

	const resetSecondPartFields = (form: any) => {
		form.change('batchNumberID', undefined)
		form.change('quantite', undefined)
		form.change('limitDate', undefined)
		setShowBatchList(false)
		setShowLimitDate(false)
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.product) {
			errors.product = <ErrorMessageRequired />
		}
		if (!formValues.batchNumberID) {
			errors.batchNumberID = <ErrorMessageRequired />
		}
		if (!formValues.quantite) {
			errors.quantite = <ErrorMessageRequired />
		}

		if (!formValues.causeDechet) {
			errors.causeDechet = <ErrorMessageRequired />
		} else if (formValues.causeDechet === SCRAPPING_OTHER_OPTION && !formValues.commentaireDechet) {
			errors.commentaireDechet = <ErrorMessageRequired />
		}

		return errors
	}

	const onSubmit = async (formValues: FormValues) => {
		const batchNumberItem = batchNumberList.find(
			(batchNumberItem: ResponseBatchNumberModelItem) => batchNumberItem.id === formValues.batchNumberID
		)

		if (batchNumberItem) {
			return handleOfflineAction(OFFLINE_ACTION.POST_SCRAPPING, postScrapping, {
				idLigneBl: batchNumberItem.ligneBl.id,
				quantite: formValues.quantite,
				causeDechet: formValues.causeDechet,
				commentaireDechet: formValues.commentaireDechet
			})
				.then((response: any) => {
					if (response.offline) {
						snackInfo({ id: 'vaccination.scrapping.successOffline', defaultMessage: 'La mise en déchet a bien été enregistrée', description: 'Success message' })
					} else {
						snackSuccess({ id: 'vaccination.scrapping.success', defaultMessage: 'La mise en déchet a bien été enregistrée', description: 'Success message' })
						history.push(`/vaccination/scrappings/${response.id}`)
					}
				})
				.catch((e: any) => {
					const { errors, displayFields } = errorsMapper(e)
					if (displayFields) {
						snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
					} else {
						snackError({ id: 'vaccination.scrapping.error', defaultMessage: 'Une erreur est survenue, la mise en déchet n\'a pas été enregistrée', description: 'Error message' })
					}
					return errors
				})
		}
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, form, submitting, hasValidationErrors, values }) => (
				<PageFormContainer onSubmit={async event => {
					const error = await handleSubmit(event)
					if (error) {
						return error
					}
					if (!hasValidationErrors) {
						// @ts-ignore
						form.restart()
					}
				}}
				>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.stock.formSection.productID"
								defaultMessage="Identification du produit"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={7} md={4}>
						<FieldProduct />
					</Grid>
					<OnChange name="product">
						{(value) => {
							resetSecondPartFields(form)
							fetchBatchNumberList(value)
						}}
					</OnChange>
					<Grid item xs={5} md={4}>
						<FieldUnitProduct />
					</Grid>
					{showBatchList && <>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="vaccination.scrapping.formSection.batchNumber"
									defaultMessage="2. Sélectionner le lot"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						{batchNumberList.length > 0 ? <>
								<Grid item xs={12} md={6}>
									<Field
										name="batchNumberID"
										component={FormSelect}
										label={
											<FormattedMessage
												id="vaccination.exitFridge.batchNumber"
												defaultMessage="Numéro de lot / bon de livraison"
												description="Batch number"
											/>
										}
									>
										{batchNumberList.map((batchNumber: ResponseBatchNumberModelItem) => (
											<MenuItem key={batchNumber.id} value={batchNumber.id}>{`${batchNumber.ligneBl.stock ? batchNumber.ligneBl.stock.lot : ''} / ${batchNumber.ligneBl.numeroBl}`}</MenuItem>
										))}
									</Field>
									<OnChange name="batchNumberID">
										{(value) => {
											const batchNumberItem = batchNumberList.find(
												(batchNumberItem: ResponseBatchNumberModelItem) => batchNumberItem.id === value
											)

											if (batchNumberItem && batchNumberItem.ligneBl.dateSortie) {
												setShowLimitDate(true)
												let expirationDate: Moment | undefined = moment(batchNumberItem.ligneBl.dateSortie)
													.add(batchNumberItem.ligneBl.produit.stabilite28, 'hours')

												// On compare la date limite d'utilisation à la date de péremption du stock
												expirationDate = compareLimitDate(expirationDate, batchNumberItem.ligneBl?.stock?.peremption, batchNumberItem.ligneBl?.produit.peremptionType)

												form.change('limitDate', expirationDate ? formatDateInForm(expirationDate.local(), DATE_FORMAT_IN_FORM.DATETIME) : undefined)
											}
										}}
									</OnChange>
								</Grid>
								{showLimitDate &&
									<Grid item xs={12} md={4}>
										<Field
											className={classes.longLabel}
											name="limitDate"
											component={FormInput}
											label={
												<FormattedMessage
													id="global.functionnal.usageLimitDate"
													defaultMessage="Limite d'utilisation"
													description="Input for limit date"
												/>
											}
											disabled
										/>
									</Grid>}
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="vaccination.scrapping.formSection.usingProduct"
											defaultMessage="3. Saisir la quantité"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={4} md={2}>
									<FieldQuantity name="quantite" required />
								</Grid>
								<TotalCountCaption count={dosesCount} />
								<OnChange name="quantite">
									{(value) => {
										const vaccin = vaccins.find((vaccin: VaccinModel) => vaccin.id === values.product)
										const dosesTotal = value * (vaccin?.nbDoses || 0)

										setDosesCount(dosesTotal)
									}}
								</OnChange>
								<Grid item xs={12}>
									<Grid item xs={12} md={8}>
										<Field
											name="causeDechet"
											component={FormSelect}
											label={
												<FormattedMessage
													id="common.scrapping.scrappingCause"
													defaultMessage="Cause du déchet"
													description="Scrapping cause"
												/>
											}
											required
										>
											{Object.keys(scrappingCauseOptions).map((optionKey: string) => (
												<MenuItem key={optionKey} value={optionKey}>{scrappingCauseOptions[optionKey]}</MenuItem>
											))}
											<MenuItem key={SCRAPPING_OTHER_OPTION} value={SCRAPPING_OTHER_OPTION}>
												<FormattedMessage
													id="common.scrapping.scrappingOtherOption"
													defaultMessage="Autre"
													description="Scrapping other option"
												/>
											</MenuItem>
										</Field>
										<OnChange name="causeDechet">{() => form.change('commentaireDechet', '')}</OnChange>
										{values.causeDechet === SCRAPPING_OTHER_OPTION && <Field
											name="commentaireDechet"
											component={FormInput}
											label={
												<FormattedMessage
													id="common.scrapping.scrappingComment"
													defaultMessage="Commentaire"
													description="Scrapping comment"
												/>
											}
											required
										/>}
									</Grid>
								</Grid>
								<div
									className={classes.submitButtonRoot}
									style={{
										justifyContent: isLargeScreen ? 'flex-start' : 'center'
									}}
								>
									<Button type="submit" variant="contained" isLoading={submitting}>
										<FormattedMessage
											id="button.validate"
											defaultMessage="Valider"
											description="Message on form submission button"
										/>
									</Button>
								</div>
							</> :
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
									<FormattedMessage
										id="vaccination.exitFridge.batchNotFound"
										defaultMessage="Aucun lot ne possède ce produit"
										description="No batch found"
									/>
								</Typography>
							</Grid>
						}
					</>
					}

				</PageFormContainer>
			)}
		/>
	)
}

export default compose(injectSnackbarActions)(Scrapping)
