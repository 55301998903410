import { LOCAL_STORAGE_ITEM } from './constants'
import { CancelablePromise } from './fetchTypes'

const transformConfig = (config: any) => {
    const centerId = localStorage.getItem(LOCAL_STORAGE_ITEM.CENTER)
    if (!!centerId) {
        return {
            ...config,
            headers: {
                ...config.headers,
                'ID_CENTRE': centerId
            }
        }
    }

    return config
}

export const fetchDownload = (path: string, config: any = {}, apiUrl: string = '/api/admin') => {
    const {
        headers,
        ...others
    } = transformConfig(config)
    const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN) || null
    const defaultHeaders: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8'
    }
    if (token) {
        defaultHeaders.Authorization = token
    }
    const newConfig = {
        headers: {
            ...defaultHeaders,
            ...headers
        },
        ...others
    }
    return fetch(`${process.env.REACT_APP_SERVER_URL}${apiUrl}${path}`, newConfig)
        .then(handleResponse)
}

const handleResponse = (response: any) => {
    if (!response.ok && response.status !== 400) {
        return Promise.reject(response)
    }
    return response.blob()
        .then((blob: any) => Promise.resolve(blob))
}

const getUrlParams = (filters: any): string => {
    const params = Object.keys(filters).reduce((acc: string, curr: string) => {
        if (filters[curr] !== undefined && filters[curr] !== null) {
            if (Array.isArray(filters[curr])) {
                return `${acc}${filters[curr].map((value: string) => `${curr}=${value}&`).join('')}`
            }
            return `${acc}${curr}=${filters[curr]}&`
        }
        return acc
    }, '')

    return params.substring(0, params.length - 1)
}

export const getFormattedUrl = (url: string, filters: any): string => {
    const params = getUrlParams(filters)

    if (params.length > 0) {
        return `${url}?${params}`
    }

    return url
}

export const makeCancellablePromise = <T>(promise: Promise<T>): CancelablePromise<T> => {
    let isCancelled = false
    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise.then((...args) => !isCancelled && resolve(...args)).catch((error) => !isCancelled && reject(error))
    })

    return {
        cancel() {
            isCancelled = true
        },
        promise: wrappedPromise,
    }
}
