import React from 'react'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DownloadButton from './DownloadButton'
import { getFormattedUrl } from '../../../utils/fetchDownload'

const useStyles = makeStyles(() =>
    createStyles({
        exportBtn: {
            marginBottom: 20
        }
    })
)

interface ExportExcelButtonProps {
    url: string,
    fileName: string,
    filters?: any
}

const ExportExcelButton: React.FC<ExportExcelButtonProps> = (
    {
        url,
        fileName,
        filters = {}
    }
) => {
    const classes = useStyles()

    return (
        <Grid container item xs={12} direction="row" justify="flex-end">
            <DownloadButton
                className={classes.exportBtn}
                label={<FormattedMessage
                    id="button.export"
                    defaultMessage="Exporter les résultats"
                    description="Message on print label button"
                />}
                url={getFormattedUrl(url, filters)}
                fileName={fileName}
            />
        </Grid>
    )
}

export default ExportExcelButton
