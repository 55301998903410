import React, { useContext } from 'react'
import PageLayout from '../../../components/layout/PageLayout'
import Paper from '@material-ui/core/Paper'
import { Field, Form } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import Button from '../../../components/layout/buttons/Button'
import SendIcon from '@material-ui/icons/Send'
import { sendMailToVaccination } from '../center/services/centerApi'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) => createStyles({
	paperForm: {
		width: '70%',
		minWidth: 300,
		maxWidth: 880,
		padding: theme.spacing(2),
		marginBottom: 50
	},
	body: {
		height: 500
	},
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	}
}))

interface MailFormValues {
	title: string
	body: string
}

interface MailProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const MailForm: React.FC<MailProps> = (
	{
		snackSuccess,
		snackError
	}
) => {
	const classes = useStyles()
	const { user: { selectedCenter } } = useContext(PhidemDataContext)

	const validate = (values: MailFormValues) => {
		const errors: any = {}

		if (!values.title) {
			errors.title = <ErrorMessageRequired />
		}

		if (!values.body) {
			errors.body = <ErrorMessageRequired />
		}

		return errors
	}

	const submit = (values: MailFormValues) => {
		return sendMailToVaccination(values.title, values.body, selectedCenter.id)
			.then(() => snackSuccess({ id: 'mail.form.success' }))
			.catch(() => snackError({ id: 'mail.form.error' }))
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					validate={validate}
					onSubmit={submit}
					render={({ handleSubmit, hasValidationErrors, submitting, form }) => (
						<form onSubmit={async event => {
							const error = await handleSubmit(event)
							if (error) {
								return error
							}
							if (!hasValidationErrors) {
								// @ts-ignore
								form.restart()
							}
						}}
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										name="title"
										component={FormInput}
										label={
											<FormattedMessage
												id="mail.form.fields.title.label"
												defaultMessage="Objet"
												description="Object"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="body"
										component={FormInput}
										label={
											<FormattedMessage
												id="mail.form.fields.body.label"
												defaultMessage="Message"
												description="Message"
											/>
										}
										multiline
									/>
								</Grid>
								<Grid item xs={12} className={classes.button}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SendIcon />}
										isLoading={submitting}
									>
										<FormattedMessage
											id="button.send"
											defaultMessage="Envoyer"
											description="Submit button label"
										/>
									</Button>
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Paper>
		</PageLayout>
	)
}

export default injectSnackbarActions(MailForm)
