import React, {useContext, useEffect, useState} from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useParams } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { getCarrierByID, updateCarrier, updateCarrierStatus } from './services/carrierApi'
import { CarrierModel, RequestCreateOrUpdateCarrierFormModel } from './services/carrierModel'
import CarrierForm from './components/CarrierForm'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface MatchParamsModel {
    id: string
}

interface CarrierCreateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const CarrierCreate: React.FC<CarrierCreateProps> = (
    {
        snackSuccess,
        snackError
    }) => {
    const { id: idInUrl } = useParams<MatchParamsModel>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [fetchedCarrier, setFetchedCarrier] = useState<RequestCreateOrUpdateCarrierFormModel>()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    useEffect(() => {
        getCarrierByID(idInUrl)
            .then((response: CarrierModel) => setFetchedCarrier({
                ...response,
                idCentre: response.centreDispatch.id
            }))
            .finally(() => setIsLoading(false))
    }, [idInUrl])

    const handleCarrierStatus = () => {
        updateCarrierStatus(idInUrl, !fetchedCarrier?.actif)
            .then(() => {
                setFetchedCarrier({
                    ...fetchedCarrier as RequestCreateOrUpdateCarrierFormModel,
                    actif: !fetchedCarrier?.actif
                })
                snackSuccess({ id: 'container.updateStatusSuccess', defaultMessage: 'Le statut du contenant a bien été modifié', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.CARRIER)
                reloadReferentiel(REFERENTIEL.CENTER)
            })
            .catch(() => snackError({ id: 'container.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du contenant n\'a pas été modifiée', description: 'Error message' }))
    }

    const onSubmit = async (formValues: any) =>  {
        return updateCarrier(idInUrl, formValues)
            .then((response: RequestCreateOrUpdateCarrierFormModel) => {
                setFetchedCarrier(response)
                snackSuccess({ id: 'carrier.updateSuccess', defaultMessage: 'Le transporteur a bien été créé', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.CARRIER)
            })
            .catch((e: any) => {
                const { errors, displayFields } = errorsMapper(e)
                if (displayFields) {
                    snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
                } else {
                    snackError({ id: 'carrier.updateError', defaultMessage: 'Une erreur est survenue, le transporteur n\'a pas été modifié', description: 'Error message' })
                }
                return errors
            })
    }

    return (
        <CarrierForm
            isLoading={isLoading}
            initialValues={fetchedCarrier}
            onSubmit={onSubmit}
            isCreationForm={false}
            updateCarrierStatus={handleCarrierStatus}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="carrier.pageTitle.update"
        defaultMessage="Modifier un transporteur"
        description="Page title"
    />
})), injectSnackbarActions)(CarrierCreate)
