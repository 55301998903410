import React, { useContext } from 'react'
import { dataTableActions, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { TrackerModel } from './services/trackerModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateTrackerStatus } from './services/trackerApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { ProductModel } from '../product/services/productModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface TrackerListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const TrackerList: React.FC<TrackerListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const history = useHistory()
	const { reloadReferentiel } = useContext(PhidemDataContext)

	const handleTrackerStatus = (id: string, status: boolean) => {
		updateTrackerStatus(id, status)
			.then(() => {
				refreshTable()
				reloadReferentiel(REFERENTIEL.TRACKER)
				snackSuccess({ id: 'tracker.updateStatusSuccess', defaultMessage: 'Le statut du tracker a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({ id: 'tracker.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du tracker n\'a pas été modifiée', description: 'Error message' }))
	}

	return (
		<RedirectFAB redirectPath="/trackers/add">
			<SpringDataTable
				apiUrl="/referentiel/tracker"
				nom="trackerList"
				headers={[
					{
						key: 'nom',
						name: <FormattedMessage
							id="tracker.name"
							defaultMessage="Nom"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'fabricant',
						name: <FormattedMessage
							id="tracker.manufacturer"
							defaultMessage="Fabricant"
							description="Header column manufacturer"
						/>,
						sortable: true
					},
					{
						key: 'type1',
						name: <FormattedMessage
							id="tracker.type1"
							defaultMessage="Type 1"
							description="Header column type 1"
						/>,
						sortable: true
					},
					{
						key: 'type2',
						name: <FormattedMessage
							id="tracker.type2"
							defaultMessage="Type 2"
							description="Header column type 2"
						/>,
						sortable: true
					},
					{
						key: 'minTemperature',
						name: <FormattedMessage
							id="tracker.temperatureInterval"
							defaultMessage="Plage de température gérée"
							description="Header column temperature interval"
						/>,
						sortable: true,
						render: (row: TrackerModel) => <span>{row.minTemperature} - {row.maxTemperature}</span>

					},
					{
						key: 'actif',
						name: <FormattedMessage
							id="global.status.label"
							defaultMessage="Statut"
							description="Header column status"
						/>,
						sortable: true,
						render: (row: ProductModel) => row.actif ? <FormattedMessage
							id="global.status.enabled"
							defaultMessage="Actif"
							description="Center status"
						/> : <FormattedMessage
							id="global.status.disabled"
							defaultMessage="Inactif"
							description="Center status"
						/>

					}
				]}
				makeActions={(row: TrackerModel) => [
					{
						label: <FormattedMessage
							id="global.update"
							defaultMessage="Modifier"
							description="Update action"
						/>, action: () => {
							history.push(`/trackers/${row.id}`)
						}
					},
					{
						label: row.actif ? <FormattedMessage
							id="global.disable"
							defaultMessage="Désactiver"
							description="Disable action"
						/> : <FormattedMessage
							id="global.enable"
							defaultMessage="Activer"
							description="Enable action"
						/>, action: () => handleTrackerStatus(row.id, !row.actif)
					}
				]}
				showActions
				noResultFragment={<NotFoundPage />}
			/>
		</RedirectFAB>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('trackerList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(TrackerList)
