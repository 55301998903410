import React from 'react'
import { FormattedMessage } from 'react-intl'

const ErrorPositiveValue: React.FC = () => {
    return (
        <FormattedMessage
            id="global.error.validation.positive"
            defaultMessage='La valeur doit être positive'
            description='Error message of a value that needs to be positive'
        />
    )
}

export default ErrorPositiveValue
