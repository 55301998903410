import moment from 'moment'
import { PRODUCT_EXPIRATION_TYPE } from './constants'

type DateFormatByLang = {
	FR: string
	EN: string
}
type DateFormat = {
	[key: string]: DateFormatByLang
}
type DateFormatInForm = {
	[key: string]: string
}

export const DATE_FORMAT: DateFormat = {
	DATE: {
		FR: 'DD/MM/YYYY',
		EN: 'MM/DD/YYYY'
	},
	DATETIME: {
		FR: 'DD/MM/yyyy HH:mm',
		EN: 'MM/DD/yyyy HH:mm'
	},
	MONTH_YEAR: {
		FR: 'MMMM YYYY',
		EN: 'MMMM YYYY'
	},
	SHORT_MONTH_YEAR: {
		FR: 'MM/yyyy',
		EN: 'MM/yyyy'
	},
	SHORT_DATE: {
		FR: 'DD/MM',
		EN: 'MM/DD'
	},
	HOUR: {
		FR: 'HH:mm',
		EN: 'HH:mm'
	}
}
export const DATE_FORMAT_IN_FORM: DateFormatInForm = {
	DATETIME: 'DD/MM/yyyy HH:mm',
	DATETIME_TIMEZONE: 'yyyy-MM-DDTHH:mm',
	DATE_DASH: 'YYYY-MM-DD',
	HOUR: 'HH:MM'
}


/**
 * Centralisation de l'affichage des dates (gestion format EN)
 * @param date
 * @param format
 * @param isDateFormatEn
 */
export const displayDate = (date: moment.Moment, format: DateFormatByLang, isDateFormatEn: boolean) => {
	const formatToUse = isDateFormatEn ? format.EN : format.FR
	return date.format(formatToUse)
}

/**
 * Centralisation du formattage des dates pour les formulaires
 * @param date
 * @param format
 */
export const formatDateInForm = (date: moment.Moment, format: string) => {
	return date.format(format)
}

/**
 * Récupération de la date de péremption
 * @param isDateFormatEn
 * @param peremption
 * @param peremptionType peremption en jour ou mois ?
 */
export const getPeremptionDate = (isDateFormatEn: boolean, peremption?: string, peremptionType?: string) => {
	const peremptionWithDay = peremptionType && peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR
	return peremptionWithDay ?
		displayDate(moment(peremption), DATE_FORMAT.DATE, isDateFormatEn)
		: displayDate(moment(peremption).endOf('month'), DATE_FORMAT.SHORT_MONTH_YEAR, isDateFormatEn)
}
