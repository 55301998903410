import React from 'react'
import { connect } from 'react-redux'
import { dataTableValueSelectors } from 'isotope-client'
import { ReplenishmentModel } from '../ReplenishmentModel'
import { REPLENISHMENT_STATUS } from '../../../../utils/constants'
import SplitButton from '../../../../components/layout/buttons/SplitButton'
import { useIntl } from 'react-intl'
import moment from 'moment'

export interface MultipleSelectionButtonProps {
	rows: ReplenishmentModel[],
	openConfirmation: (data: ReplenishmentModel[]) => void
	openPreparation: (data: ReplenishmentModel[]) => void
	openDeparture: (data: ReplenishmentModel[]) => void
	snackError: (message: any) => void
}

const MultipleSelectionButton: React.FC<MultipleSelectionButtonProps> = (
	{
		rows,
		openConfirmation,
		openPreparation,
		openDeparture,
		snackError
	}
) => {
	const intl = useIntl()

	const handleClickConfirmation = () => {
		const statuses = rows.map(row => row.statut)
		const receptionDates = rows.map(row => row.dateReception)

		if (statuses.find(status => status !== REPLENISHMENT_STATUS.EN_ATTENTE)) {
			snackError({ id: 'dispatch.replenishmentList.dialog.confirm.errors.status' })
		} else if (receptionDates.find(date => moment().isAfter(date))) {
			snackError({ id: 'dispatch.replenishmentList.dialog.confirm.errors.date' })
		} else {
			openConfirmation(rows)
		}
	}

	const handleClickPrepare = () => {
		const products = new Set(rows.map(row => row.produit.id))
		const dealtOrRefused = rows.filter(row => row.statut === REPLENISHMENT_STATUS.TRAITE || row.statut === REPLENISHMENT_STATUS.REFUSE)

		if (products.size > 1) {
			snackError({ id: 'dispatch.replenishmentList.dialog.prepare.error.oneProduct' })
		} else if (dealtOrRefused.length) {
			snackError({ id: 'dispatch.replenishmentList.dialog.prepare.error.status' })
		} else {
			openPreparation(rows)
		}
	}

	const handleClickDeparture = () => {
		if (rows.some((row: ReplenishmentModel) => row.statut !== REPLENISHMENT_STATUS.TRAITE)) {
			snackError({ id: 'dispatch.replenishmentList.dialog.departure.errorStatus' })
		} else {
			openDeparture(rows)
		}

	}

	return <SplitButton
		label="dispatch.replenishmentList.dialog.multipleBtn"
		items={[
			{
				label: intl.formatMessage({
					id: 'dispatch.replenishmentList.dialog.confirm.button',
					defaultMessage: 'Confirmer les demandes',
					description: 'Confirmation button'
				}),
				onClick: handleClickConfirmation
			},
			{
				label: intl.formatMessage({
					id: 'dispatch.replenishmentList.dialog.prepare.button',
					defaultMessage: 'Préparer les demandes',
					description: 'Prepare button'
				}),
				onClick: handleClickPrepare
			},
			{
				label: intl.formatMessage({
					id: 'dispatch.replenishmentList.dialog.departure.button',
					defaultMessage: 'Départ marchandises',
					description: 'Departure button'
				}),
				onClick: handleClickDeparture
			}
		]}
		disabled={rows.length < 2}
		showIcon={false}
	/>
}

const mapStateToProps = (state: any) => ({
	rows: dataTableValueSelectors.getDataTableSelectedRows(state)('replenishmentList') || []
})

export default connect(mapStateToProps)(MultipleSelectionButton)
