import { CentreModel } from '../modules/common/phidemData/phidemDataModel'
import moment, { Moment } from 'moment'
import { LANGUAGES_CODE, LANGUAGES_ID, PEREMPTION_HOUR, PRODUCT_EXPIRATION_TYPE, TRANSFERT_TYPE } from './constants'
import { VaccinModel } from '../modules/admin/product/services/productModel'

export const isEmailOk = (value: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)

export const formatDecimal = (value: number) => value.toString().replace('.', ',')

/**
 * Liste des options de centre
 * @param selectedCenter centre connecté
 * @param showMe centre connecté en option
 */
export const getLocationOptions = (selectedCenter: CentreModel, showMe: boolean = false) => {
	const options = selectedCenter.centreEnfantsByType.vaccinations.map((centre: CentreModel) => ({
		label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
		value: centre.id
	}))
	if (showMe) {
		options.push({
			label: `${selectedCenter.nom}${selectedCenter.service ? ` - ${selectedCenter.service}` : ''}`,
			value: selectedCenter.id
		})

		// Sort du centre connecté
		options.sort((a, b) => {
			if (a.label < b.label) {
				return -1
			}
			if (a.label > b.label) {
				return 1
			}
			return 0
		})
	}
	return options
}

/**
 * Comparaison entre date limite et péremption
 * On récupère la date antérieure
 * @param peremptionType peremption en jour ou mois ?
 * @param usageLimit
 * @param peremption
 */
export const compareLimitDate = (usageLimit?: Moment, peremption?: string | Moment, peremptionType?: string) => {
	const peremptionWithDay = peremptionType && peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR
	if (usageLimit && peremption) {
		const peremptionDate = peremptionWithDay ? moment(peremption) : moment(peremption).endOf('month')
		const peremptionLimit = peremptionDate.hours(PEREMPTION_HOUR).minutes(0)
		if (peremptionLimit.isBefore(usageLimit)) {
			return peremptionLimit
		}
	}
	return usageLimit
}

/**
 * Récupération de l'id langue via son code
 * @param languageCode
 */
export const getLanguageIdByCode = (languageCode: string) => {
	const key = Object.keys(LANGUAGES_CODE).find((key: string) => LANGUAGES_CODE[key as keyof typeof LANGUAGES_CODE] === languageCode)

	return key ? LANGUAGES_ID[key as keyof typeof LANGUAGES_ID] : LANGUAGES_ID.EN
}

/**
 * Calcul de la date avec stabilité selon le produit et le type de stock
 * @param vaccin
 * @param initDate
 * @param stockTransfertType
 */
export const getLimitDateWithStability = (vaccin: VaccinModel, initDate?: string, stockTransfertType?: string) => {
	// On calcule la durée de stabilité selon le stock
	// - Stock classique = stabilite28
	// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
	const stability = (stockTransfertType === TRANSFERT_TYPE.MOINS_20 ? vaccin.stabilite28TransfertMoins20 : vaccin.stabilite28 || 0)

	return moment(initDate).add(stability, 'hours')
}
