import React from 'react'

const SvgProduct = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={66.292}
    height={91.406}
>
  <g
      transform="translate(-68.867 -206.014)"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  >
    <rect
        width={65.542}
        height={90.656}
        rx={15.1}
        transform="translate(69.242 206.389)"
        fill="#fbfbfb"
    />
    <rect
        width={59.412}
        height={82.177}
        rx={15.1}
        transform="translate(72.308 210.629)"
        fill="#fbfbfb"
    />
    <path fill="none" d="M102.013 219.559v64.317" />
    <path
        d="M122.638 222.813a7.961 7.961 0 010 11.253l-11.259-11.26a7.962 7.962 0 0111.259.007z"
        fill="#e8d654"
    />
    <path
        d="M111.379 222.806l11.259 11.26a7.962 7.962 0 11-11.259-11.26z"
        fill="#4670eb"
    />
    <path
        d="M122.638 246.091a7.961 7.961 0 010 11.253l-11.259-11.26a7.962 7.962 0 0111.259.007z"
        fill="#e8d654"
    />
    <path
        d="M111.379 246.084l11.259 11.26a7.962 7.962 0 11-11.259-11.26z"
        fill="#4670eb"
    />
    <path
        d="M122.638 269.369a7.961 7.961 0 010 11.253l-11.259-11.259a7.961 7.961 0 0111.259.006z"
        fill="#e8d654"
    />
    <path
        d="M111.379 269.363l11.259 11.259a7.961 7.961 0 11-11.259-11.259z"
        fill="#4670eb"
    />
    <path
        d="M93.688 222.813a7.96 7.96 0 010 11.253l-11.259-11.26a7.962 7.962 0 0111.259.007z"
        fill="#e8d654"
    />
    <path
        d="M82.429 222.806l11.259 11.26a7.962 7.962 0 11-11.259-11.26z"
        fill="#4670eb"
    />
    <path
        d="M93.688 246.091a7.96 7.96 0 010 11.253l-11.259-11.26a7.962 7.962 0 0111.259.007z"
        fill="#e8d654"
    />
    <path
        d="M82.429 246.084l11.259 11.26a7.962 7.962 0 11-11.259-11.26z"
        fill="#4670eb"
    />
    <path
        d="M93.688 269.369a7.96 7.96 0 010 11.253l-11.259-11.259a7.961 7.961 0 0111.259.006z"
        fill="#e8d654"
    />
    <path
        d="M82.429 269.363l11.259 11.259a7.961 7.961 0 11-11.259-11.259z"
        fill="#4670eb"
    />
  </g>
</svg>)

export default SvgProduct
