import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../../../../components/layout/buttons/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '../../../../components/layout/dialog/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Field, Form } from 'react-final-form'
import { PRODUCT_TYPES, QTY_MODE, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../../utils/constants'
import { FormCheckbox, FormInput, FormSelect } from 'isotope-client'
import moment from 'moment'
import FieldProduct from '../../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../../components/fields/FieldUnitProduct'
import { isDateInputTypeSupported } from '../../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../../utils/form/decorators'
import { ReplenishmentModel } from '../ReplenishmentModel'
import MenuItem from '@material-ui/core/MenuItem'
import FieldDatetime from '../../../../components/fields/FieldDatetime'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { getStockByProduit } from '../../../dispatch/services/dispatchApi'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../../utils/dateUtils'
import { DispatchReferentStockModel } from '../../../common/dispatchAndReferent/stock/stockModels'

const getInitialDates = () => {
	const currentDateTime = moment()
	return {
		sortie: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		exitDate: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		exitTime: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.HOUR)
	}
}

const useStyles = (isLargeScreen: boolean) => makeStyles((theme) => createStyles({
	submitButtonRoot: {
		width: '100%',
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'flex-end'
	},
	marginComment: {
		marginBottom: 30
	},
	table: {
		width: '100%'
	},
	title: {
		marginTop: 5
	},
	dialogContainer: {
		...(isLargeScreen && { minWidth: 500 }),
		minHeight: 525
	}
}))

interface DialogReplenishmentPrepareProps {
	isOpen: boolean
	handleClose: () => any
	rows: ReplenishmentModel[] | undefined
	onSubmit: (values: DispatchPreparationMultipleFormModel) => void
}

export type ReplenishmentPrepareValues = {
	produits?: ReplenishmentModel
	exitDate: string
	exitTime: string
	forceValidation: boolean
	idProduit: string
	idStock: string
	quantite: number | undefined
	replenishmentID: string | undefined
	sortie: string
	typeProduit: string
	unitProduct: string
	refExterne?: string
	suiviDesactive?: boolean
}

export type DispatchPreparationMultipleFormModel = {
	idDemandesList: string[],
	idStock: string,
	refExterne: string,
	sortie?: string,
	suiviDesactive: boolean
}

const DialogReplenishmentPrepare: React.FC<DialogReplenishmentPrepareProps> = (
	{
		isOpen,
		handleClose,
		rows,
		onSubmit
	}
) => {

	const selectedProduit = rows ? rows[0].produit : null
	const [stocks, setStocks] = useState<DispatchReferentStockModel[]>([])
	const [flacons, setFlacons] = useState(0)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const classes = useStyles(isLargeScreen)()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('exitDate', 'exitTime', 'sortie'),
				dateAndTimeDecorator('usageLimitDate', 'usageLimitTime', 'usageLimitDatetime')]
		, [isDatetimeSupported])

	useEffect(() => {
		getStockByProduit(selectedProduit ? selectedProduit.id : '')
			.then((stocks: DispatchReferentStockModel[]) => {
				setStocks(stocks.filter((stock: DispatchReferentStockModel) => !stock.retourProduit && stock.transfertType !== TRANSFERT_TYPE.DECONGELE))

				rows?.forEach(row => {
					if (row.quantiteConfirmee) {
						setFlacons(previous => row.quantiteConfirmee ? previous + row.quantiteConfirmee : previous)
					} else if (row.modeQte === QTY_MODE.DOSE) {
						setFlacons(previous => previous + computeQuantityFromDoses(row))
					} else if (row.modeQte === QTY_MODE.CONDITIONNEMENT) {
						setFlacons(previous => row.nbCond ? previous + row.nbCond : previous)
					}
				})
			})
	}, [selectedProduit, rows])

	const computeQuantityFromDoses = (row: ReplenishmentModel) => {
		const dosesSum = Number(row.nbDoses1 || 0) + Number(row.nbDoses2 || 0) + Number(row.nbDosesR || 0)
		return Math.ceil(dosesSum / (row.produit.nbDoses || 1))
	}

	const submit = async (values: ReplenishmentPrepareValues) => {
		const valuesToSend: DispatchPreparationMultipleFormModel = {
			idDemandesList: rows ? rows.map(row => row.id) : [],
			idStock: values.idStock,
			refExterne: values.refExterne ? values.refExterne : '',
			sortie: selectedProduit && selectedProduit.stabilite28 && values.sortie ? new Date(values.sortie || '').toISOString() : undefined,
			suiviDesactive: values.suiviDesactive ? values.suiviDesactive : false
		}
		onSubmit(valuesToSend)
	}

	const onValidate = (values: ReplenishmentPrepareValues) => {
		const errors: any = {}

		if (!values.idStock) {
			errors.idStock = <ErrorMessageRequired />
		} else if (flacons > stocks.filter(stock => stock.id === values.idStock)[0].quantite) {
			errors.idStock = <FormattedMessage
				id="global.error.quantityMax"
				defaultMessage="La quantité saisie est supérieure au stock"
				description="Dialog title"
			/>
		}

		return errors
	}

	const initValues = useMemo(() => ({
		idProduit: selectedProduit?.id,
		typeProduit: PRODUCT_TYPES.VACCIN,
		// @ts-ignore
		unitProduct: selectedProduit?.typeConditionnement,
		...getInitialDates()
	}), [selectedProduit])

	return (
		<Dialog
			title={
				<FormattedMessage
					id="dispatch.replenishmentList.dialog.prepare.title"
					defaultMessage="Préparation des demandes"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
			containerClass={classes.dialogContainer}
		>
			<Form
				initialValues={initValues}
				onSubmit={submit}
				validate={onValidate}
				// @ts-ignore
				decorators={formDecorators}
				render={({ handleSubmit, submitting, values }) => {
					return (
						<Grid container>
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'} className={classes.title}>
									<FormattedMessage
										id="dispatch.replenishmentList.dialog.prepare.form.product"
										defaultMessage="1. Produit sélectionné"
										description="Form section title"
									/>
								</Typography>
							</Grid>

							<Grid container item xs={12} spacing={2}>
								<Grid item xs={10} md={6}>
									<FieldProduct name="idProduit" required showOtherThanVaccin disabled />
								</Grid>
								<Grid item xs={6} md={4}>
									<FieldUnitProduct />
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
									<FormattedMessage
										id="dispatch.replenishmentList.dialog.prepare.form.preparation"
										defaultMessage="2. Information sur la préparation"
										description="Form section title"
									/>
								</Typography>
							</Grid>

							<Grid container item xs={12} spacing={2}>
								<Grid item xs={12} md={4}>
									<Field
										name="idStock"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functionnal.batchNumber"
												defaultMessage="Lot"
												description="Batch number"
											/>
										}
										required
									>
										{stocks.map((stock: any) =>
											<MenuItem key={stock.id} value={stock.id}>
												{stock.lot}
											</MenuItem>
										)}
									</Field>
								</Grid>
								<Grid item xs={12} md={4}>
									<Field
										name="refExterne"
										component={FormInput}
										label={
											<FormattedMessage
												id="common.exitStock.dialogAddProduct.form.refExterne"
												defaultMessage="Référence externe"
												description="Extern reference"
											/>
										}
									/>
								</Grid>
							</Grid>

							{selectedProduit && selectedProduit.stabilite28 ?
								<Grid item xs={12}>
									<FieldDatetime
										gridMD={6}
										datetimeName="sortie"
										dateName="exitDate"
										timeName="exitTime"
										datetimeLabel={
											<FormattedMessage
												id="global.functionnal.exitTime"
												defaultMessage="Heure de sortie"
												description="Exit time of the product"
											/>
										}
										dateLabel={
											<FormattedMessage
												id="common.exitStock.dialogAddProduct.form.exitDate"
												defaultMessage="Date de sortie du congélateur (jj/mm/aaaa)"
												description="Exit date"
											/>
										}
										timeLabel={
											<FormattedMessage
												id="global.functionnal.exitTime"
												defaultMessage="Heure de sortie du congélateur (jj/mm/aaaa)"
												description="Exit time"
											/>
										}
									/>
								</Grid>
								:
								null
							}

							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
									<FormattedMessage
										id="dispatch.replenishmentList.dialog.prepare.form.following"
										defaultMessage="3. Suivi du produit"
										description="Form section title"
									/>
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Field
									name="suiviDesactive"
									component={FormCheckbox}
									label={
										<FormattedMessage
											id="dispatch.exitStock.disableFollowUp"
											defaultMessage="Désactiver le suivi"
											description="Checkbox description"
										/>
									}
								/>
							</Grid>

							<Grid item xs={12}>
								<div className={classes.submitButtonRoot}>
									<Button variant="contained" isLoading={submitting} onClick={handleSubmit}>
										<FormattedMessage
											id="button.validate"
											defaultMessage="Valider"
											description="Message on form submission button"
										/>
									</Button>
								</div>
							</Grid>
						</Grid>
					)
				}}
			/>
		</Dialog>
	)
}

export default DialogReplenishmentPrepare
