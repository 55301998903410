import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { QTY_MODE } from '../../../../utils/constants'
import { ReplenishmentDialogDataModel } from '../ReplenishmentModel'
import moment from 'moment'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'

interface DialogReplenishmentBodyProps {
	replenishment: ReplenishmentDialogDataModel
}

const DialogReplenishmentBody: React.FC<DialogReplenishmentBodyProps> = (
	{
		replenishment
	}
) => {
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const {
		productName,
		productModeQte,
		productQuantity1,
		productQuantity2,
		productQuantityR,
		productQuantityCond,
		productTotalQuantity,
		productCond,
		destinationCenter,
		dateCouvertureDebut,
		dateCouvertureFin,
		transporteur
	} = replenishment

	return <>
		<Typography gutterBottom>
			<FormattedMessage
				id="dispatch.processing.dialog.contentProduct"
				defaultMessage={`Produit : ${productName}`}
				description="Dialog content"
				values={{ productName }}
			/>
		</Typography>
		{productModeQte === QTY_MODE.CONDITIONNEMENT ?
			<Typography gutterBottom>
				<FormattedMessage
					id="dispatch.processing.dialog.contentQuantity"
					defaultMessage={`Quantité : ${productQuantityCond}`}
					description="Dialog content"
					values={{
						quantity: productQuantityCond,
						typeCond: productCond
					}}
				/>
			</Typography>
			:
			<>
				<Typography gutterBottom>
					<FormattedMessage
						id="dispatch.processing.dialog.contentQuantity1"
						defaultMessage={`Quantité : ${productQuantity1}`}
						description="Dialog content"
						values={{
							quantity: productQuantity1
						}}
					/>
				</Typography>
				<Typography gutterBottom>
					<FormattedMessage
						id="dispatch.processing.dialog.contentQuantity2"
						defaultMessage={`Quantité : ${productQuantity2}`}
						description="Dialog content"
						values={{
							quantity: productQuantity2
						}}
					/>
				</Typography>
				<Typography gutterBottom>
					<FormattedMessage
						id="dispatch.processing.dialog.contentQuantityR"
						defaultMessage={`Quantité : ${productQuantityR}`}
						description="Dialog content"
						values={{
							quantity: productQuantityR
						}}
					/>
				</Typography>
				<Typography gutterBottom>
					<FormattedMessage
						id="dispatch.processing.dialog.contentTotalQuantity"
						defaultMessage={`Soit au total : ${productTotalQuantity}`}
						description="Dialog content"
						values={{
							quantity: productTotalQuantity,
							typeCond: productCond
						}}
					/>
				</Typography>
			</>
		}
		<Typography gutterBottom>
			<FormattedMessage
				id="dispatch.processing.dialog.contentDestinationCenter"
				defaultMessage={`Centre de destination : ${destinationCenter}`}
				description="Dialog content"
				values={{
					destinationCenter
				}}
			/>
		</Typography>
		{transporteur && <Typography gutterBottom>
			<FormattedMessage
				id="dispatch.processing.dialog.carrier"
				defaultMessage={`Trasnporteur : ${transporteur}`}
				description="Dialog content"
				values={{
					carrier: transporteur
				}}
			/>
		</Typography>}
		{dateCouvertureDebut && dateCouvertureDebut && (
			<Typography gutterBottom>
				<FormattedMessage
					id="dispatch.processing.dialog.contentCoveragePeriod"
					defaultMessage="Période de couverture"
					description="Dialog content"
					values={{
						dateCouvertureDebut: displayDate(moment(dateCouvertureDebut), DATE_FORMAT.DATE, isDateFormatEn),
						dateCouvertureFin: displayDate(moment(dateCouvertureFin), DATE_FORMAT.DATE, isDateFormatEn),
						heureCouvertureDebut: displayDate(moment(dateCouvertureDebut), DATE_FORMAT.HOUR, isDateFormatEn),
						heureCouvertureFin: displayDate(moment(dateCouvertureFin), DATE_FORMAT.HOUR, isDateFormatEn)
					}}
				/>
			</Typography>
		)}
	</>

}

export default DialogReplenishmentBody
