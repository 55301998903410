import React, { useContext } from 'react'
import { dataTableActions, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { ProductModel } from './services/productModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateProductStatus } from './services/productApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { formatDecimal } from '../../../utils/formUtils'
import { VALUE_LIST_SHORTCUT } from '../../../utils/constants'

interface ProductListProps {
	refreshTable: () => void,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ProductList: React.FC<ProductListProps> = (
	{
		refreshTable,
		snackSuccess,
		snackError
	}
) => {
	const history = useHistory()
	const { reloadReferentiel, valueList } = useContext(PhidemDataContext)

	const handleProduitStatus = (id: string, status: boolean) => {
		updateProductStatus(id, status)
			.then(() => {
				refreshTable()
				reloadReferentiel(REFERENTIEL.PRODUCT)
				snackSuccess({ id: 'product.updateStatusSuccess', defaultMessage: 'Le statut du produit a bien été modifié', description: 'Success message' })
			})
			.catch(() => snackError({ id: 'product.updateStatusError', defaultMessage: 'Une erreur est survenue, le statut du produit n\'a pas été modifiée', description: 'Error message' }))
	}

	return (
		<RedirectFAB redirectPath="/products/add">
			<SpringDataTable
				apiUrl="/referentiel/produit"
				nom="productList"
				headers={[
					{
						key: 'type',
						name: <FormattedMessage
							id="product.type"
							defaultMessage="Nom"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: ProductModel) => <span>
							{(valueList[VALUE_LIST_SHORTCUT.PRODUCT_TYPE] || {})[row.type] || ''}
						</span>
					},
					{
						key: 'nomCommercial',
						name: <FormattedMessage
							id="product.name"
							defaultMessage="Nom"
							description="Header column name"
						/>,
						sortable: true
					},
					{
						key: 'codeCip',
						name: <FormattedMessage
							id="product.codeCip"
							defaultMessage="Code CIP"
							description="Header column cip code"
						/>,
						sortable: true
					},
					{
						key: 'formePharma',
						name: <FormattedMessage
							id="product.formePharma"
							defaultMessage="Forme pharmaceutique"
							description="Header column pharmacetic format"
						/>,
						sortable: true
					},
					{
						key: 'conservation',
						name: <FormattedMessage
							id="product.preservation"
							defaultMessage="Conservation"
							description="Header column preservation"
						/>,
						sortable: true
					},
					{
						key: 'typeConditionnement',
						name: <FormattedMessage
							id="product.typeCond"
							defaultMessage="Type de conditionnement"
							description="Header column packaging type"
						/>,
						sortable: true
					},
					{
						key: 'nbUnitesBoite',
						name: <FormattedMessage
							id="product.nbunitBoite"
							defaultMessage="Nombre d'unités par boîte"
							description="Header column unit box count"
						/>,
						sortable: true
					},
					{
						key: 'nbDoses',
						name: <FormattedMessage
							id="product.nbDoses"
							defaultMessage="Nombre de doses"
							description="Header column dose count"
						/>,
						sortable: true,
						render: (row: ProductModel) => <span>{row.nbDoses ? formatDecimal(row.nbDoses) : ''}</span>
					},
					{
						key: 'actif',
						name: <FormattedMessage
							id="global.status.label"
							defaultMessage="Statut"
							description="Header column status"
						/>,
						sortable: true,
						render: (row: ProductModel) => row.actif ? <FormattedMessage
							id="global.status.enabled"
							defaultMessage="Actif"
							description="Center status"
						/> : <FormattedMessage
							id="global.status.disabled"
							defaultMessage="Inactif"
							description="Center status"
						/>

					}
				]}
				makeActions={(row: ProductModel) => [
					{
						label: <FormattedMessage
							id="global.update"
							defaultMessage="Modifier"
							description="Update action"
						/>, action: () => {
							history.push(`/products/${row.id}`)
						}
					},
					{
						label: row.actif ? <FormattedMessage
							id="global.disable"
							defaultMessage="Désactiver"
							description="Disable action"
						/> : <FormattedMessage
							id="global.enable"
							defaultMessage="Activer"
							description="Enable action"
						/>, action: () => handleProduitStatus(row.id, !row.actif)
					}
				]}
				showActions
				noResultFragment={<NotFoundPage />}
			/>
		</RedirectFAB>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('productList')
}

export default compose<any>(
	connect(null, actions),
	injectSnackbarActions
)(ProductList)
