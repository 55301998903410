import React from 'react'
import { Field } from 'react-final-form'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { FormInput } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import WeekPicker, { WeekRange } from '../weekPicker/WeekPicker'
import { DATE_FORMAT, displayDate } from '../../utils/dateUtils'

interface StyleProps {
	label?: string | React.ReactElement
}

const useStyles = ({ label }: StyleProps) => makeStyles(() =>
	createStyles({
		container: {
			position: 'relative'
		},
		calendarIcon: {
			position: 'absolute',
			right: 24,
			width: 14,
			height: 14,
			top: label ? 'calc(50% - 5px)' : 'calc(50% - 14px)'
		}
	})
)


interface FieldWeekPickerProps {
	name: string
	label?: string | React.ReactElement
	required?: boolean
	disabled?: boolean
	selectedLang: string
	isDateFormatEn: boolean
}

/* This is a fully CONTROLLED INPUT field returning a `WeekRange` value */
const FieldWeekPicker: React.FC<FieldWeekPickerProps> = (
	{
		name,
		required = false,
		label,
		disabled,
		selectedLang,
		isDateFormatEn
	}
) => {
	const [anchorEl, setAnchorEl] = React.useState<any | null>(null)
	const isOpen = Boolean(anchorEl)
	const intl = useIntl()
	const classes = useStyles({ label })()

	const openPicker = (event: React.MouseEvent<any>) => {
		setAnchorEl(event.currentTarget)
	}

	const closePicker = () => {
		setAnchorEl(null)
	}

	const weekRangeToText = (weekRange: WeekRange) => {
		const { from, to } = weekRange

		if (!from || !to) {
			return ''
		}

		const fromFormatted = displayDate(moment(from), DATE_FORMAT.DATE, isDateFormatEn)
		const toFormatted = displayDate(moment(to), DATE_FORMAT.DATE, isDateFormatEn)

		return intl.formatMessage({
				id: 'global.functional.fromDateToDate',
				defaultMessage: `Du ${fromFormatted} au ${toFormatted}`,
				description: 'Week range value'
			}, {
				from: fromFormatted,
				to: toFormatted
			}
		)
	}

	return (
		<Field name={name}>
			{props => {
				const { input: { name, value }, meta, meta: { initial }, ...rest } = props

				return (
					<>
						<Grid item xs={12} md={5} className={classes.container} onClick={(event: any) => !disabled && openPicker(event)}>
							<FormInput
								{...rest}
								meta={meta}
								name={name}
								label={label}
								value={weekRangeToText(value)}
								type="text"
								readOnly
								required={required}
								disabled={disabled}
							/>
							<CalendarTodayIcon className={classes.calendarIcon} />
						</Grid>
						<Popover
							open={isOpen}
							anchorEl={anchorEl}
							onClose={closePicker}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center'
							}}
							keepMounted
						>
							<WeekPicker
								onChange={props.input.onChange}
								initialWeekRange={initial}
								locale={selectedLang}
							/>
						</Popover>
					</>
				)
			}}
		</Field>
	)
}

export default FieldWeekPicker
