import { fetchFactory } from 'isotope-client'
import { RequestCreateOrUpdateContainerFormModel } from './containerModel'

export const postContainer = (values: RequestCreateOrUpdateContainerFormModel) => fetchFactory(`/referentiel/contenant`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updateContainer = (id: string, values: RequestCreateOrUpdateContainerFormModel) => fetchFactory(`/referentiel/contenant`, {
    method: 'PUT',
    body: JSON.stringify({ ...values, id })
})

export const updateContainerStatus = (id: string, status: boolean) => fetchFactory(`/referentiel/contenant/status`, {
    method: 'PUT',
    body: JSON.stringify({ status, id })
})

export const getContainerByID = (id: string) => fetchFactory(`/referentiel/contenant/${id}`)
