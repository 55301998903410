import React from 'react'
import { default as MUIButton, ButtonProps as MUIButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        actionButton: {
            fontSize: 14,
            padding: '8px 25px'
        },
        circularProgress: {
            position: 'absolute'
        },
        loading: {
            visibility: 'hidden'
        }
    })
)

interface ButtonProps extends MUIButtonProps {
    color?: 'primary' | 'secondary' | 'inherit' | undefined
    isLoading?: boolean
}

const Button: React.FC<ButtonProps> = (
    { onClick, color = 'primary', disabled = false, children, isLoading = false, ...otherProps }
) => {
    const classes = useStyles()

    return (
        <MUIButton
            color={color}
            className={classes.actionButton}
            onClick={(event) => !isLoading && onClick && onClick(event)}
            disabled={disabled || isLoading}
            {...otherProps}
        >
            <div className={isLoading ? classes.loading : ''}>
                {children}
            </div>
            {isLoading && <CircularProgress color={color} className={classes.circularProgress} size={20} />}
        </MUIButton>
    )
}


export default Button
