import React from 'react'
import _ from 'lodash'
import { FormSelect } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { Field, FormSpy } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { usePhidemDataContext } from '../../modules/common/phidemData/PhidemDataContext'
import { ProductModel, VaccinModel } from '../../modules/admin/product/services/productModel'
import { OnChange } from 'react-final-form-listeners'
import { makeStyles } from '@material-ui/core/styles'
import { PRODUCT_TYPES, VALUE_LIST_SHORTCUT } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
	subHeader: {
		color: theme.palette.primary.main
	}
}))

interface FieldProductProps {
	disabled?: boolean,
	required?: boolean,
	name?: string,
	showOtherThanVaccin?: boolean
}

interface ProductProps {
	[key: string]: ProductModel[]
}

const FieldProduct: React.FC<FieldProductProps> = (
	{
		disabled = false,
		required = false,
		name = 'product',
		showOtherThanVaccin = false
	}) => {
	const { vaccins, products, valueList } = usePhidemDataContext()
	const classes = useStyles()

	// Double check temporaire pour fix problème cache
	const normalizedVaccins = vaccins.filter((vaccin: VaccinModel) => vaccin.type === PRODUCT_TYPES.VACCIN)

	// Groupage des autres produits
	let otherProducts: ProductProps = {}
	if (showOtherThanVaccin) {
		otherProducts = _.groupBy(products, 'type')
	}

	return (
		<FormSpy subscription={{}}>
			{({ form }) =>
				<>
					<Field
						name={name}
						component={FormSelect}
						label={
							<FormattedMessage
								id="global.functional.product"
								defaultMessage="Produit"
								description="Product to select"
							/>
						}
						disabled={disabled}
						required={required}
					>
						{showOtherThanVaccin && normalizedVaccins.length > 0 && <ListSubheader className={classes.subHeader}><FormattedMessage
							id="enum.productTypes.VACCIN"
							defaultMessage="Vaccin"
							description="Vaccin category"
						/></ListSubheader>}
						{normalizedVaccins.map((vaccin: VaccinModel) => (
							<MenuItem key={vaccin.id} value={vaccin.id}>{vaccin.nomCommercial}</MenuItem>
						))}
						{showOtherThanVaccin && Object.keys(otherProducts).map((key: string) => <>
							<ListSubheader className={classes.subHeader}>{(valueList[VALUE_LIST_SHORTCUT.PRODUCT_TYPE] || {})[key] || ''}</ListSubheader>
							{otherProducts[key].map((product: ProductModel) => <MenuItem key={product.id} value={product.id}>{product.nomCommercial}</MenuItem>)}
						</>)}

					</Field>
					<OnChange name={name}>{(value) => {
						const product = [...vaccins, ...products].find((product: ProductModel) => product.id === value)
						form.change('unitProduct', product ? product.typeConditionnement : '')
						form.change('typeProduit', product ? product.type : '')
					}}</OnChange>
				</>
			}

		</FormSpy>
	)
}

export default FieldProduct
