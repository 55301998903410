import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '../../../../components/layout/dialog/Dialog'
import DisplayLineData from '../../../../components/layout/DisplayLineData'
import { DAYS, QTY_MODE } from '../../../../utils/constants'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'

const useStyles = makeStyles(() =>
	createStyles({
		dayQuantity: {
			marginTop: 16
		},
		totalLine: {
			marginTop: 24,
			marginBottom: 12
		}
	})
)

export interface DataDialogProcurement {
	startDate: string
	endDate?: string
	centerName: string
	productName: string
	conditioningType: string
	residualPrevision: number
	modeQte: string
	debutSemaine: string
	lundi: number
	lundi1: number
	lundi2: number
	mardi: number
	mardi1: number
	mardi2: number
	mercredi: number
	mercredi1: number
	mercredi2: number
	jeudi: number
	jeudi1: number
	jeudi2: number
	vendredi: number
	vendredi1: number
	vendredi2: number
	samedi: number
	samedi1: number
	samedi2: number
	dimanche: number
	dimanche1: number
	dimanche2: number
}

interface DialogReplenishmentProcurementProps {
	isOpen: boolean
	residualLabel?: string
	handleClose: () => any
	data: DataDialogProcurement | undefined
}

const DialogReplenishmentProcurement: React.FC<DialogReplenishmentProcurementProps> = (
	{
		isOpen,
		residualLabel = 'dispatch.replenishmentList.dialog.procurement.residualPrevision',
		handleClose,
		data
	}) => {
	const classes = useStyles()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)

	if (!data) {
		return null
	}

	const {
		startDate,
		endDate,
		centerName,
		productName,
		conditioningType,
		residualPrevision,
		modeQte,
		debutSemaine,
		lundi,
		mardi,
		mercredi,
		jeudi,
		vendredi,
		samedi,
		dimanche
	} = data

	const startInterval = moment(startDate)
	const endInterval = endDate ? moment(endDate) : moment(startDate).endOf('isoWeek')
	const startWeek = displayDate(moment(debutSemaine), DATE_FORMAT.DATE, isDateFormatEn)
	const endWeek = displayDate(moment(debutSemaine).endOf('isoWeek'), DATE_FORMAT.DATE, isDateFormatEn)
	const total = lundi + mardi + mercredi + jeudi + vendredi + samedi + dimanche
	const residual = total - residualPrevision < 0 ? 0 : (total - residualPrevision)

	return (
		<Dialog
			title={
				<FormattedMessage
					id="dispatch.replenishmentList.dialog.procurement.title"
					defaultMessage="Plan d'approvisionnement"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
		>
			<Typography gutterBottom>
				<FormattedMessage
					id="dispatch.replenishmentList.dialog.procurement.center"
					defaultMessage={`Centre : ${centerName}`}
					description="Dialog content"
					values={{ centerName }}
				/>
			</Typography>
			<Typography gutterBottom>
				<FormattedMessage
					id="dispatch.replenishmentList.dialog.procurement.product"
					defaultMessage={`Produit : ${productName}`}
					description="Dialog content"
					values={{ productName, conditioningType }}
				/>
			</Typography>
			<Typography gutterBottom>
				<FormattedMessage
					id="global.functional.weekFromDateToDate"
					defaultMessage={`Semaine du ${startWeek} au ${endWeek}`}
					description="Dialog content"
					values={{ from: startWeek, to: endWeek }}
				/>
			</Typography>

			{/* DAYS QUANTITY */}
			<Grid container spacing={2}>
				{Object.values(DAYS).map((day, i) => {
						const date = moment(data.debutSemaine).add(i, 'days')

						return (
							<Grid item xs={3} className={classes.dayQuantity} key={day}>
								<DisplayLineData
									isPrimary={endDate ? date.isSameOrAfter(startInterval, 'day') && date.isSameOrBefore(endInterval, 'day') : date.isSame(startInterval, 'day')}
									label={
										<FormattedMessage
											id={`dispatch.replenishmentList.dialog.procurement.${day.toLowerCase()}`}
											defaultMessage={`${day[0].toUpperCase()}${day.slice(1).toLowerCase()}`}
											description="Dialog content"
											values={{ date: displayDate(date, DATE_FORMAT.SHORT_DATE, isDateFormatEn) }}
										/>}
									// @ts-ignore
									data={modeQte === QTY_MODE.CONDITIONNEMENT ? data[day.toLowerCase()] : `D1 : ${data[day.toLowerCase() + 1] ? data[day.toLowerCase() + 1] : 0} - D2 : ${data[day.toLowerCase() + 2] ? data[day.toLowerCase() + 2] : 0}`}
								/>
							</Grid>)
					}
				)}
			</Grid>
			<Typography gutterBottom className={classes.totalLine}>
				<FormattedMessage
					id="dispatch.replenishmentList.dialog.procurement.total"
					defaultMessage={`Soit ${total} ${conditioningType}(s) au total`}
					description="Dialog content"
					values={{ quantity: total, conditioningType }}
				/>
			</Typography>
			<Typography gutterBottom className={classes.totalLine}>
				<FormattedMessage
					id={residualLabel}
					defaultMessage={`Allocation résiduelle : ${residual} ${conditioningType}(s)`}
					description="Dialog content"
					values={{ quantity: residual, conditioningType }}
				/>
			</Typography>
		</Dialog>
	)
}

export default DialogReplenishmentProcurement
