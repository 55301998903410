import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { OnChange } from 'react-final-form-listeners'
import { FormattedMessage } from 'react-intl'
import { FormCheckbox, FormInput, FormSelect } from 'isotope-client'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import { SCREEN_SIZE } from '../../../utils/constants'
import { DispatchBlModel, DispatchLigneBlModel } from '../../dispatch/services/dispatchModels'
import { getBlList, postEntryStock } from '../services/vaccinationApi'
import errorsMapper from '../../../utils/errorsMapper'
import ProductsTable from '../../../components/layout/productsTable/ProductsTable'
import { OfflineContext } from '../../common/offline/context/OfflineContext'
import { OFFLINE_ACTION } from '../../common/offline/offlineConstants'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		longLabel: {
			whiteSpace: 'nowrap'
		}
	})
)

interface FormValues {
	deliverySlipNumberID: string
	deliverySlipNumber: string
	compliantReception: boolean
	comment: string
}

interface EnterStockProps {
	snackSuccess: (value: any) => void,
	snackInfo: (value: any) => void,
	snackError: (value: any) => void,
}

const EnterStock: React.FC<EnterStockProps> = ({
	                                               snackSuccess,
	                                               snackInfo,
	                                               snackError
                                               }) => {
	const classes = useStyles()
	const { handleOfflineAction } = useContext(OfflineContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [blList, setBlList] = useState<DispatchBlModel[]>([])
	const [showSecondPart, setShowSecondPart] = useState<boolean>(false)

	const getList = useCallback(() => {
		handleOfflineAction(OFFLINE_ACTION.GET_BL_LIST, getBlList, undefined)
			.then((fetchedBlList: DispatchBlModel[]) => {
				setBlList(fetchedBlList)
				setShowSecondPart(false)
			})
	}, [handleOfflineAction])

	useEffect(() => {
		getList()
	}, [getList])

	const resetSecondPartFields = (form: any) => {
		setShowSecondPart(true)
		form.change('compliantReception', true)
		form.change('comment', undefined)
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.deliverySlipNumberID) {
			errors.deliverySlipNumberID = <ErrorMessageRequired />
		}

		return errors
	}

	const onSubmit = async (formValues: FormValues) => {
		return handleOfflineAction(OFFLINE_ACTION.POST_ENTRY_STOCK, postEntryStock, {
			idBl: formValues.deliverySlipNumberID,
			numeroBl: formValues.deliverySlipNumber,
			conforme: formValues.compliantReception,
			commentaire: formValues.comment
		})
			.then((status) => {
				if (status.offline) {
					snackInfo({ id: 'vaccination.enterStock.successOffline', defaultMessage: 'L\'entrée en stock a bien été enregistrée', description: 'Success message' })
				} else {
					snackSuccess({ id: 'vaccination.enterStock.success', defaultMessage: 'L\'entrée en stock a bien été enregistrée', description: 'Success message' })
				}
				getList()
			})
			.catch((e: any) => {
				const { errors, displayFields } = errorsMapper(e)
				if (displayFields) {
					snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
				} else {
					snackError({ id: 'vaccination.enterStock.error', defaultMessage: 'Une erreur est survenue, l\' entrée en stock n\'a pas été créée', description: 'Error message' })
				}
				return errors
			})
	}

	const convertProductToTable = (dispatchLignes: DispatchLigneBlModel[]) => {
		return dispatchLignes.map((dispatchLigne: DispatchLigneBlModel) => ({
			nom: dispatchLigne.produit.nomCommercial,
			unit: dispatchLigne.produit.typeConditionnement,
			lot: dispatchLigne.stock && dispatchLigne.stock.lot,
			reference: dispatchLigne.refExterne,
			peremption: dispatchLigne.stock && dispatchLigne.stock.peremption,
			peremptionType: dispatchLigne.produit.peremptionType,
			quantite: dispatchLigne.quantite,
			sortie: dispatchLigne.dateSortie,
			isRetour: dispatchLigne.stock && dispatchLigne.stock.retourProduit,
		}))
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			render={({ handleSubmit, hasValidationErrors, submitting, values, form }) => {
				const selectedBL = blList.find((bl: DispatchBlModel) => bl.id === values.deliverySlipNumberID)

				return (
					<PageFormContainer onSubmit={async event => {
						const error = await handleSubmit(event)
						if (error) {
							return error
						}
						if (!hasValidationErrors) {
							// @ts-ignore
							form.restart()
						}
					}}>

						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="vaccination.enterStock.formSection.slipNumber"
								defaultMessage="1. Sélectionner le numéro de bon de livraison"
								description="Form section title"
							/>
						</Typography>
						{(blList.length > 0) ?
							<>
								<Grid item xs={12}>
									<Grid item xs={12} md={4}>
										<Field
											name="deliverySlipNumberID"
											component={FormSelect}
											label={
												<FormattedMessage
													id="vaccination.enterStock.deliverySlipNumber"
													defaultMessage="Numéro de bon de livraison"
													description="Input for delivery slip"
												/>
											}
											required
										>
											{blList.map((bl: DispatchBlModel) => (
												<MenuItem key={bl.id} value={bl.id}>{bl.numeroBl}</MenuItem>
											))}
										</Field>
									</Grid>
								</Grid>
								<OnChange name="deliverySlipNumberID">
									{() => {
										if (selectedBL) {
											form.change('deliverySlipNumber', selectedBL.numeroBl)
											resetSecondPartFields(form)
										}
									}}
								</OnChange>

								{showSecondPart &&
								<>
									<ProductsTable
										title="vaccination.enterStock.formSection.identifyBatch"
										produits={convertProductToTable(selectedBL?.lignes || [])}
									/>
									<Grid item xs={12}>
										<Typography variant='caption'>
											<FormattedMessage
												id="vaccination.enterStock.warningIncoherentQuantity"
												defaultMessage="Si la quantité reçue diffère de la quantité affichée, veuillez procéder à une mise en déchet"
												description="Warning message"
											/>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
											<FormattedMessage
												id="vaccination.enterStock.formSection.receptionConfirmation"
												defaultMessage="3. Confirmer la réception"
												description="Form section title"
											/>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="compliantReception"
											component={FormCheckbox}
											label={
												<FormattedMessage
													id="vaccination.enterStock.compliantReception"
													defaultMessage="Réception conforme"
													description="Checkbox description"
												/>
											}
										/>
										{/* clear comment field */}
										<OnChange name="compliantReception">
											{
												(value) => {
													if (value) {
														form.change('comment', undefined)
													}
												}
											}
										</OnChange>
									</Grid>
									<Grid item xs={8}>
										<Field
											name="comment"
											component={FormInput}
											label={
												<FormattedMessage
													id="vaccination.enterStock.comment"
													defaultMessage="Commentaire"
													description="Comment label input"
												/>
											}
											multiline
											required={!values.compliantReception}
											disabled={values.compliantReception}
										/>
									</Grid>
									<div
										className={classes.submitButtonRoot}
										style={{
											justifyContent: isLargeScreen ? 'flex-start' : 'center'
										}}
									>
										<Button type="submit" variant="contained" isLoading={submitting}>
											<FormattedMessage
												id="button.validate"
												defaultMessage="Valider"
												description="Message on form submission button"
											/>
										</Button>
									</div>
								</>}
							</> :
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
									<FormattedMessage
										id="vaccination.enterStock.blNotFound"
										defaultMessage="Aucun bon de livraison n'a été trouvé'"
										description="No bl found"
									/>
								</Typography>
							</Grid>
						}
					</PageFormContainer>
				)
			}}
		/>
	)
}

export default compose(injectSnackbarActions)(EnterStock)
