import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ProcurementForm from './components/ProcurementForm'
import { compose } from 'redux'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { deleteProcurementByID, getProcurementByID, updateProcurementForm } from './services/procurementApi'
import { ProcurementFormValues, ProcurementRowModel } from './services/procurementModels'
import { CENTER_TYPE } from '../../../utils/constants'
import { PhidemDataContext } from '../phidemData/PhidemDataContext'

interface MatchParamsModel {
	id: string
}

interface ProcurementUpdateProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void
}

const ProcurementUpdate: React.FC<ProcurementUpdateProps> = (
	{
		snackSuccess,
		snackError
	}
) => {
	const { user: { selectedCenter } } = useContext(PhidemDataContext)
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)
	const [fetchedProcurement, setFetchedProcurement] = useState<ProcurementRowModel>()
	const history = useHistory()
	const isVaccination = selectedCenter && selectedCenter.type === CENTER_TYPE.VACCINATION

	const handleDeleteProcurement = async () => {
		setIsLoadingDelete(true)
		deleteProcurementByID(idInUrl)
			.then(() => history.push('/procurements'))
			.catch(() => {
				snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
			})
			.finally(setIsLoadingDelete(false))
	}

	const onSubmit = async (formValues: ProcurementFormValues) => {
		updateProcurementForm({
			id: idInUrl,
			debutSemaine: formValues.weekRange.from?.toISOString() || '',
			idCentre: formValues.idCentre || '',
			idProduit: formValues.idProduit || '',
			modeQte: formValues.modeQte,
			lundi: formValues.lundi,
			mardi: formValues.mardi,
			mercredi: formValues.mercredi,
			jeudi: formValues.jeudi,
			vendredi: formValues.vendredi,
			samedi: formValues.samedi,
			dimanche: formValues.dimanche,
			lundi1: formValues.lundi1,
			lundi2: formValues.lundi2,
			mardi1: formValues.mardi1,
			mardi2: formValues.mardi2,
			mercredi1: formValues.mercredi1,
			mercredi2: formValues.mercredi2,
			jeudi1: formValues.jeudi1,
			jeudi2: formValues.jeudi2,
			vendredi1: formValues.vendredi1,
			vendredi2: formValues.vendredi2,
			samedi1: formValues.samedi1,
			samedi2: formValues.samedi2,
			dimanche1: formValues.dimanche1,
			dimanche2: formValues.dimanche2
		})
			.then((result: ProcurementRowModel) => {
				setFetchedProcurement(result)
				snackSuccess({ id: 'procurement.updateSuccess', defaultMessage: 'L\'allocation a bien été modifiée', description: 'Success message' })
			})
			.catch(() => snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' }))
	}

	useEffect(() => {
		getProcurementByID(idInUrl)
			.then((response: ProcurementRowModel) => {
				setFetchedProcurement(response)
				setIsLoading(false)
			})
			.catch(() => history.push('/procurements'))
	}, [idInUrl, history])

	return (
		<ProcurementForm
			initialValues={
				fetchedProcurement ?
					{
						weekRange: {
							from: new Date(fetchedProcurement.debutSemaine),
							to: moment(fetchedProcurement.debutSemaine).endOf('isoWeek').toDate()
						},
						idProduit: fetchedProcurement.produit.id,
						idCentre: fetchedProcurement.centre.id,
						modeQte: fetchedProcurement.modeQte,
						lundi: fetchedProcurement.lundi,
						mardi: fetchedProcurement.mardi,
						mercredi: fetchedProcurement.mercredi,
						jeudi: fetchedProcurement.jeudi,
						vendredi: fetchedProcurement.vendredi,
						samedi: fetchedProcurement.samedi,
						dimanche: fetchedProcurement.dimanche,
						lundi1: fetchedProcurement.lundi1,
						lundi2: fetchedProcurement.lundi2,
						mardi1: fetchedProcurement.mardi1,
						mardi2: fetchedProcurement.mardi2,
						mercredi1: fetchedProcurement.mercredi1,
						mercredi2: fetchedProcurement.mercredi2,
						jeudi1: fetchedProcurement.jeudi1,
						jeudi2: fetchedProcurement.jeudi2,
						vendredi1: fetchedProcurement.vendredi1,
						vendredi2: fetchedProcurement.vendredi2,
						samedi1: fetchedProcurement.samedi1,
						samedi2: fetchedProcurement.samedi2,
						dimanche1: fetchedProcurement.dimanche1,
						dimanche2: fetchedProcurement.dimanche2
					}
					: undefined
			}
			isCreationForm={false}
			snackError={snackError}
			isLoading={isLoading}
			isLoadingDelete={isLoadingDelete}
			onSubmit={onSubmit}
			nbDoses={fetchedProcurement?.produit.nbDoses}
			handleDeleteProcurement={handleDeleteProcurement}
			centerList={isVaccination ? [selectedCenter] : selectedCenter.centreEnfantsByType.vaccinations}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="procurement.title"
		defaultMessage="Plan d'approvisionnement"
		description="Page title"
	/>
})), injectSnackbarActions)(ProcurementUpdate)
