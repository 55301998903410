import { fetchFactory } from 'isotope-client'
import { ExitStockFormModel } from './exitStockModels'

export const postExitStock = (values: ExitStockFormModel) => fetchFactory('/referent/preparation', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getBlById = (idBl: string) => fetchFactory(`/referent/preparation/${idBl}`)

export const cancelPreparation = (blID: string) => fetchFactory(`/referent/preparation/${blID}`, {
	method: 'PUT'
})
