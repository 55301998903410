import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface DialogReplenishmentMultipleConfirmProps {
	isOpen: boolean
	loading: boolean
	handleClose: () => any
	onSubmit: (value: any) => any
}

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		}
	})
)

const DialogReplenishmentMultipleConfirm: React.FC<DialogReplenishmentMultipleConfirmProps> = (
	{
		isOpen,
		loading,
		handleClose,
		onSubmit
	}
) => {
	const classes = useStyles()

	return (
		<Form
			onSubmit={onSubmit}
			render={({ values }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.replenishmentList.dialog.confirm.title"
							defaultMessage="Départ marchandises"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.cancel"
								defaultMessage="Annuler"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => onSubmit(values)} isLoading={loading}>
							<FormattedMessage
								id="button.validate"
								defaultMessage="Valider"
								description="Button label"
							/>
						</Button>
					]}
				>
					<Typography gutterBottom>
						<FormattedMessage
							id="dispatch.replenishmentList.dialog.confirm.body"
							defaultMessage="Via la confirmation des demandes multiples, vous vous apprêtez à confirmer l'ensemble des demandes sélectionnées avec les quantités et dates de réception préalablement renseignées."
							description="Dialog disclaimer"
						/>
					</Typography>
					<Grid container item xs={12} spacing={2} className={classes.inputContainer}>
						<Grid item xs={12}>
							<Field
								name="comment"
								component={FormInput}
								label={
									<FormattedMessage
										id="dispatch.processing.dialog.comment"
										defaultMessage="Commentaire"
										description="Comment label input"
									/>
								}
							/>
						</Grid>
					</Grid>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentMultipleConfirm
