import { DashboardProductModel, DashboardProductStatModel } from './dashboardProductModel'
import { ProductModel } from '../../admin/product/services/productModel'

const INIT_COUNT = {
    countStock: 0,
    countExit: 0,
    countDechet: 0
}

export const generateProductStatFromProductContextID = (
    productID: string,
    fetchedProducts: DashboardProductModel[],
    products: ProductModel[]
): DashboardProductStatModel => {
    const fetchedProduct = getMatchingFetchedProduct(productID, fetchedProducts)
    return fetchedProduct ? generateExistingProductStat(productID, fetchedProduct, products) : generateNotFoundProductStat(productID, products)
}

const getMatchingFetchedProduct = (productID: string, fetchedProducts: DashboardProductModel[]) => {
    return fetchedProducts.find(
        (fetchedProduct) => productID === fetchedProduct.idProduit)
}

const getMatchingContextProduct = (productID: string, products: ProductModel[]) => {
    return products.find((product) => productID === product.id)
}

const generateNotFoundProductStat = (productID: string, products: ProductModel[]): DashboardProductStatModel => {
    const product = getMatchingContextProduct(productID, products)

    return {
        idProduit: productID,
        nomCommercial: product?.nomCommercial,
        typeConditionnement: product?.typeConditionnement,
        day: INIT_COUNT,
        week: INIT_COUNT,
        month: INIT_COUNT,
        countStockRestant: 0
    }
}

const generateExistingProductStat =
    (productID: string, fetchedProduct: DashboardProductModel, products: ProductModel[]): DashboardProductStatModel => {
        const product = getMatchingContextProduct(productID, products)

        return {
            idProduit: productID,
            nomCommercial: product?.nomCommercial,
            typeConditionnement: product?.typeConditionnement,
            day: fetchedProduct?.day,
            week: fetchedProduct?.week,
            month: fetchedProduct?.month,
            countStockRestant: fetchedProduct?.countStockRestant
        }
    }
