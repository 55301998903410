import { fetchFactory } from 'isotope-client'
import { loadEntries } from 'isotope-client/modules/valueList/services/valueListApi'
import { ApplicationConfiguration } from '../../../modules/common/phidemData/phidemDataModel'

export const getAllProducts = () => fetchFactory('/referentiel/liste/produit')
export const getAllCarriers = () => fetchFactory('/referentiel/liste/transporteur')
export const getAllContainers = () => fetchFactory('/referentiel/liste/contenant')
export const getAllTrackers = () => fetchFactory('/referentiel/liste/tracker')
export const getAvailableTemplates = () => fetchFactory('/template/available')

export const getMyCenter = () => fetchFactory('/centre/me')

export const getMyCenterList = () => fetchFactory('/centre/me/list')

export const getValueListByShortcut = (shortcut: string, languageId: string) => loadEntries(shortcut, false, languageId)

export const getApplicationConfiguration = (): Promise<ApplicationConfiguration> => fetchFactory('/configuration', {}, '/api')
