import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { ReplenishmentDialogDataModel } from '../../ReplenishmentModel'
import FieldDatetime from '../../../../../components/fields/FieldDatetime'
import { dateAndTimeDecorator } from '../../../../../utils/form/decorators'
import { isDateInputTypeSupported } from '../../../../../utils/form/inputTypes'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import TotalCountCaption from '../../../../../components/fields/TotalCountCaption'
import ErrorPositiveValue from '../../../../../components/layout/errors/ErrorPositiveValue'
import DialogReplenishmentBody from '../DialogReplenishmentBody'
import { QTY_MODE } from '../../../../../utils/constants'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../../../utils/dateUtils'

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		},
		sectionTitle: {
			fontWeight: 500,
			marginBottom: 16
		}
	})
)

interface FormValues {
	quantity: number;
	receptionDatetime: string;
	comment?: string;
}

interface DialogReplenishmentConfirmProps {
	isOpen: boolean
	loading: boolean
	handleClose: () => any
	onSubmit: (value: any) => any,
	replenishment: ReplenishmentDialogDataModel
}

const DialogReplenishmentConfirm: React.FC<DialogReplenishmentConfirmProps> = (
	{
		isOpen,
		handleClose,
		onSubmit,
		replenishment,
		loading
	}
) => {
	const classes = useStyles()
	const {
		productModeQte,
		productQuantityCond,
		productTotalQuantity,
		nbDoses,
		receptionDate
	} = replenishment
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('receptionDate', 'receptionTime', 'receptionDatetime')]
		, [isDatetimeSupported])

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (!formValues.quantity) {
			errors.quantity = <ErrorMessageRequired />
		} else if (Number(formValues.quantity) <= 0) {
			errors.quantity = <ErrorPositiveValue />
		}
		if (isDatetimeSupported) {
			if (!formValues.receptionDatetime) {
				errors.receptionDatetime = <ErrorMessageRequired />
			} else if (moment(formValues.receptionDatetime)
				.isBefore(moment())) {
				// should be older than now
				errors.receptionDatetime = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
			}
		} else {
			if (!formValues.receptionDate) {
				errors.receptionDate = <ErrorMessageRequired />
			}
			if (!formValues.receptionTime) {
				errors.receptionTime = <ErrorMessageRequired />
			}
			if (formValues.receptionDate && formValues.receptionTime && formValues.receptionDatetime &&
				moment(formValues.receptionDatetime).isBefore(moment())) {
				// should be older than now
				errors.receptionDate = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
				errors.receptionTime = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
			}
		}

		return errors
	}

	const handleOnSubmit = (formValues: FormValues) => {
		onSubmit({
			nbFlacons: formValues.quantity,
			dateReception: formValues.receptionDatetime,
			comment: formValues.comment
		})
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={handleOnSubmit}
			// @ts-ignore
			decorators={formDecorators}
			initialValues={{
				quantity: productModeQte === QTY_MODE.DOSE ? productTotalQuantity : productQuantityCond,
				receptionDatetime: formatDateInForm(moment(`${receptionDate}`).local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE)
			}}
			render={({ values, handleSubmit }) => (
				<form id="formDialogReplenishmentConfirm" onSubmit={handleSubmit}>
					<Dialog
						title={
							<FormattedMessage
								id="dispatch.confirm.dialog.title"
								defaultMessage="Traitement de la demande"
								description="Dialog title"
							/>
						}
						isOpen={isOpen}
						handleClose={handleClose}
						buttons={[
							<Button onClick={handleClose} color="primary" key={0} variant="outlined">
								<FormattedMessage
									id="button.cancel"
									defaultMessage="Annuler"
									description="Button label"
								/>
							</Button>,
							<Button type="submit" autoFocus color="primary" key={1} variant="contained" form="formDialogReplenishmentConfirm" isLoading={loading}>
								<FormattedMessage
									id="button.validate"
									defaultMessage="Valider"
									description="Button label"
								/>
							</Button>
						]}
					>
						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.confirm.dialog.disclaimer"
								defaultMessage="Via la confirmation d'une demande, vous attestez de la bonne prise en compte de la demande. Lors de la validation, un mail notifiera le centre de vaccination."
								description="Dialog disclaimer"
							/>
						</Typography>

						<Typography gutterBottom variant="h5" className={`${classes.inputContainer} ${classes.sectionTitle}`}>
							<FormattedMessage
								id="dispatch.confirm.dialog.sections.initial"
								defaultMessage="Demande initiale"
								description="Dialog content"
							/>
						</Typography>

						<DialogReplenishmentBody replenishment={replenishment} />
						{/* FORM FIELDS */}
						<Typography gutterBottom variant="h5" className={`${classes.inputContainer} ${classes.sectionTitle}`}>
							<FormattedMessage
								id="dispatch.confirm.dialog.sections.confirmed"
								defaultMessage="Demande confirmée"
								description="Dialog content"
							/>
						</Typography>
						<Grid container item xs={12} spacing={2}>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={4} md={2}>
									<Field
										name="quantity"
										component={FormInput}
										label={
											<FormattedMessage
												id="global.functionnal.quantity"
												defaultMessage="Quantité"
												description="label input"
											/>
										}
										type="number"
										inputProps={{ min: 0 }}
										required
									/>
								</Grid>
								{/* @ts-ignore */}
								<TotalCountCaption count={(Number(values.quantity) || 0) * nbDoses} />
							</Grid>
							<FieldDatetime
								datetimeName="receptionDatetime"
								dateName="receptionDate"
								timeName="receptionTime"
								datetimeLabel={
									<FormattedMessage
										id="dispatch.confirm.dialog.receptionDatetime"
										defaultMessage="Date de réception (jj:mm:aaaa hh:mm)"
										description="input label"
									/>
								}
								dateLabel={
									<FormattedMessage
										id="dispatch.confirm.dialog.receptionDate"
										defaultMessage="Date de réception (jj:mm:aaaa)"
										description="input label"
									/>
								}
								timeLabel={
									<FormattedMessage
										id="dispatch.confirm.dialog.receptionTime"
										defaultMessage="Heure de réception (hh:mm)"
										description="input label"
									/>
								}
							/>
							<Grid item xs={12}>
								<Field
									name="comment"
									component={FormInput}
									label={
										<FormattedMessage
											id="dispatch.confirm.dialog.comment"
											defaultMessage="Commentaire"
											description="Comment label input"
										/>
									}
								/>
							</Grid>
						</Grid>
					</Dialog>
				</form>
			)}
		/>
	)
}

export default DialogReplenishmentConfirm
