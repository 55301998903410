import React from 'react'
import { createStyles, makeStyles, withTheme } from '@material-ui/core/styles'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => createStyles({
		text: {
			margin: '50px 150px',
			paddingBottom: 25,

			'& h3': {
				marginTop: 50,
			}
		}
}))

const LegalNotice: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.text}>
			<p>
				Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y
				parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserve les présentes
				modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la
				confiance dans l’économie numérique, les responsables du présent site internet&nbsp;
				<a href="http://www.tlms.promedeo.com">www.tlms.promedeo.com</a> sont :
			</p>
			<ul>
				<li><b>Editeur du Site :</b> Promedeo</li>
				<li><b>Numéro de SIRET :</b> 822 979 951 00016</li>
				<li><b>Responsable éditorial :</b> Promedeo – 57 Avenue de Bretagne 76100 Rouen</li>
				<li><b>Email :</b> contact@promedeo.com</li>
				<li><b>Site Web :</b> www.tlms.promedeo.com</li>
				<li><b>Hébergement :</b> IpsoSenso</li>
				<li><b>Crédit :</b> Fond vecteur créé par pikisuperstar - <a href="http://fr.freepik.com">fr.freepik.com</a></li>
			</ul>
			<h3>Conditions d’utilisation :</h3>
			<p>
				Pour un meilleur confort d’utilisation et un graphisme plus agréable, nous vous
				recommandons de recourir à des navigateurs mis à jour.
				Promedeo met en œuvre tous les moyens dont elle dispose, pour assurer une mise à jour
				fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir.
				L’utilisateur devra donc s’assurer de l’exactitude des informations auprès de l’entreprise et
				proposer toutes modifications du site qu’il jugerait utile. Promedeo n’est en aucun cas
				responsable de l’utilisation faite de ces informations, et de tout préjudice direct ou indirect
				pouvant en découler.
			</p>
			<h3>Cookies :</h3>
			<p>
				Le site <a href="http://www.tlms.promedeo.com">www.tlms.promedeo.com</a> peut être amené à vous demander l’acceptation des
				cookies pour des besoins de statistiques et d’affichage. Un cookie est une information
				déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs
				données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un
				serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne
				peuvent être fonctionnelles sans l’acceptation de cookies.
			</p>
			<h3>Liens hypertextes :</h3>
			<p>
				Les sites internet peuvent offrir des liens vers d’autres sites internet ou d’autres ressources
				disponibles sur Internet. Promedeo ne dispose d’aucun moyen pour contrôler les sites en
				connexion avec ses sites internet. Promedeo ne répond pas de la disponibilité de tels sites et
				sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout
				dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources
				externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout
				usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent
				pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation. Les
				utilisateurs, les abonnés et les visiteurs des sites internet de Promedeo ne peuvent mettre
				en place un hyperlien en direction de ce site sans l’autorisation expresse et préalable de
				Promedeo. Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un
				hyperlien en direction d’un des sites internet de Promedeo, il lui appartiendra d’adresser un
				email accessible sur le site afin de formuler sa demande de mise en place d’un hyperlien.
				Promedeo se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier
				sa décision.
			</p>
			<h3>Services fournis :</h3>
			<p>
				Notre site <a href="http://www.tlms.promedeo.com">www.tlms.promedeo.com</a> permet le suivi logistique
				et la traçabilité des médicaments dit ‘sensibles’. Notre site permet de suivre les stocks de produits,
				d’éditer des documents de suivi, de faire des réapprovisionnements de produits et de consulter des
				rapports de suivi d’activité. Tous les documents générés par notre site sont sécurisés.
				Promedeo n’est en aucun cas responsable des informations saisies par les utilisateurs sur le
				site <a href="http://www.tlms.promedeo.com">www.tlms.promedeo.com</a>.
			</p>
			<h3>Limitations contractuelles sur les données :</h3>
			<p>
				Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour
				à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des
				omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement,
				merci de bien vouloir le signaler par email, à l’adresse « contact » mentionné ci-dessus, en
				décrivant le problème de la manière la plus précise possible (page posant problème, type
				d’ordinateur et de navigateur utilisé, …). De plus, l’utilisateur du site s’engage à accéder au
				site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de
				dernière génération mis-à-jour. Les liens hypertextes mis en place dans le cadre du présent
				site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient
				engager la responsabilité de Promedeo.
			</p>
			<h3>Loi informatique et libertés et protection de la vie privée :</h3>
			<p>
				Compte tenu de notre activité qui nous impose de collecter, traiter et conserver vos données
				personnelles, le nouveau règlement européen RGPD (RÈGLEMENT (UE) 2016/679 DU
				PARLEMENT EUROPÉEN ET DU CONSEIL du 27 avril 2016 relatif à la protection des personnes
				physiques à l'égard du traitement des données à caractère personnel et à la libre circulation
				de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des
				données) vient renforcer la politique exigeante que nous appliquions déjà au niveau de nos
				systèmes d’information, notamment par nos obligations de garantie de sécurité imposées
				par la Loi Informatique et Libertés (N°78-17 du 6 janvier 1978) modifiée.<br/>
				Vos données personnelles sont exclusivement destinées à notre usage professionnel. Nous
				ne traitons vos données que dans la mesure où elles nous sont nécessaires pour exécuter
				nos missions contractuelles, pour vous contacter, vous informer de nos actualités et
				événements et réaliser des statistiques exclusivement à usage interne.
			</p>
			<h3>Evolutions de la plateforme :</h3>
			<p>
				Les éléments présentés dans cette plateforme sont susceptibles de modification sans préavis
				et sont mis à disposition sans aucune garantie, expresse ou implicite, d’aucune sorte et ne
				peuvent donner lieu à un quelconque droit à dédommagement.
			</p>
			<h3>Litiges :</h3>
			<p>
				Les présentes conditions du site www.tlms.promedeo.com sont régies par les lois françaises
				et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de
				celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la
				société. La langue de référence, pour le règlement de contentieux éventuels, est le français.
			</p>
		</div>
	)
}


export default injectToolbarData(() => (
	{
		title: <FormattedMessage
			id="menu.noticeMenu"
			defaultMessage="Mentions légales"
			description="Menu title"
		/>
	}
))(withTheme(LegalNotice))
