import React from 'react'
import { Field } from 'react-final-form'
import { FormInput } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import { isDateInputTypeSupported } from '../../utils/form/inputTypes'

interface FieldDatetimeProps {
    datetimeName: string
    dateName: string
    timeName: string
    datetimeLabel?: string | React.ReactElement
    dateLabel?: string | React.ReactElement
    timeLabel?: string | React.ReactElement
    gridXS?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
    gridMD?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
    disabled?: boolean
    required?: boolean
}

const FieldDatetime: React.FC<FieldDatetimeProps> = (
    {
        datetimeName, dateName, timeName,
        datetimeLabel, dateLabel, timeLabel,
        gridXS = 12,
        gridMD = 5,
        disabled = false,
        required = false
    }) => {
    const isDatetimeSupported = isDateInputTypeSupported('datetime-local')

    return (
        isDatetimeSupported // using alternative inputs if not
            ?
            <Grid item xs={gridXS} md={gridMD}>
                <Field
                    name={datetimeName}
                    component={FormInput}
                    label={datetimeLabel}
                    type="datetime-local"
                    required={required}
                    disabled={disabled}
                />
            </Grid>
            :
            <>
                <Grid item xs={gridXS} md={gridMD}>
                    <Field
                        name={dateName}
                        component={FormInput}
                        label={dateLabel}
                        type="date"
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={gridXS} md={gridMD}>
                    <Field
                        name={timeName}
                        component={FormInput}
                        label={timeLabel}
                        type="time"
                        required={required}
                        disabled={disabled}
                    />
                </Grid>
            </>
    )
}

export default FieldDatetime
