import React, { useContext, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { injectToolbarData } from 'isotope-client'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { getRetourPrint } from '../services/dispatchApi'
import { DispatchMouvementModel } from '../services/dispatchModels'
import Loader from '../../../components/layout/Loader'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import { DOWNLOAD_FILE_TYPE, SCREEN_SIZE } from '../../../utils/constants'
import DownloadButton from '../../../components/layout/buttons/DownloadButton'
import { compareLimitDate, getLimitDateWithStability } from '../../../utils/formUtils'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { DispatchReferentStockModel } from '../../common/dispatchAndReferent/stock/stockModels'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(3),
			display: 'flex',
			flexDirection: 'column'
		},
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		button: {
			margin: theme.spacing(4)
		},
		buttonContainer: {
			margin: '40px 40px 100px 40px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
)

interface MatchParamsModel {
	id: string
}

interface ProductReturnPrintProps {
	theme: Theme,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ProductReturnPrint: React.FC<ProductReturnPrintProps> = (
	{
		theme
	}
) => {
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const history = useHistory()
	const { id: idFromUrlParam } = useParams<MatchParamsModel>()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const classes = useStyles()
	const [fetchedValues, setFetchedValues] = useState<DispatchMouvementModel>()

	useEffect(() => {
		getRetourPrint(idFromUrlParam)
			.then((response: DispatchMouvementModel) => {
				setFetchedValues(response)
			})
			.catch(() => history.push('/dispatch/stocks'))
	}, [idFromUrlParam, history])

	const stock = useMemo(() => fetchedValues?.stock, [fetchedValues])
	const produit = useMemo(() => fetchedValues?.stock.produit, [fetchedValues])

	const getUsageLimit = (stock: DispatchReferentStockModel) => {
		// Dans le cas d'un retour, on calcule la durée de stabilité selon le stock initial
		// - Stock classique = stabilite28
		// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
		const limitUsage = getLimitDateWithStability(produit!!, stock.retourBl?.dateSortie, stock.retourBl?.stockTransfertType)
		// On compare la date limite d'utilisation à la date de péremption du stock
		return displayDate((compareLimitDate(limitUsage, stock.peremption, produit?.peremptionType) || moment()), DATE_FORMAT.DATETIME, isDateFormatEn)
	}

	return (
		<div
			className={classes.container}
			style={{
				alignItems: isLargeScreen ? 'flex-start' : 'center',
				margin: isLargeScreen ?
					`${theme.spacing(3)}px ${theme.spacing(3)}px 64px 13vw` :
					`${theme.spacing(1)} ${theme.spacing(1)} 64px ${theme.spacing(1)}`,
				width: isLargeScreen ? 600 : 300
			}}
		>
			{(!fetchedValues || !stock || !produit)
				?
				<Loader />
				:
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					xs={10} md={12}
					spacing={2}
				>
					{/* SECTION 1 */}
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.productReturn.form.sections.selectVaccinationCenter"
								defaultMessage="1. Sélectionner le centre de vaccination"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={12} md={5}>
						<DisplayLineData
							label={<FormattedMessage
								id="global.functionnal.vaccinationCenter"
								defaultMessage="Centre de vaccination"
								description="Center to select"
							/>}
							data={stock.centreRetour?.nom}
						/>
					</Grid>

					{/* SECTION 2 */}
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.productReturn.form.sections.selectProduct"
								defaultMessage="2. Sélectionner le produit"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={10} md={4}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="global.functional.product"
									defaultMessage="Produit"
									description="Product to select"
								/>
							}
							data={produit.nomCommercial}
						/>
					</Grid>
					<Grid item xs={6} md={4}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="dispatch.stock.unitProduct"
									defaultMessage="Conditionnement"
									description="Conditioning"
								/>
							}
							data={produit.typeConditionnement}
						/>
					</Grid>

					{/* SECTION 3 */}
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.productReturn.form.sections.selectBl"
								defaultMessage="3. Sélectionner le numéro de bon de livraison"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid item xs={12} md={4}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="dispatch.productReturn.form.inputs.deliverySlipNumber"
										defaultMessage="Numéro de bon de livraison"
										description="Input for delivery slip"
									/>
								}
								data={stock.retourBl?.numeroBl}
							/>
						</Grid>
					</Grid>

					{/* SECTION 4 */}
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.productReturn.form.sections.identifyBatch"
								defaultMessage="4. Identifier le lot"
								description="Form section title"
							/>
						</Typography>
					</Grid>

					<Grid item xs={4} md={2}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="dispatch.stock.quantity"
									defaultMessage="Quantité"
									description="Quantity of selected product"
								/>
							}
							data={fetchedValues.quantite}
						/>
					</Grid>
					<TotalCountCaption count={fetchedValues.quantite * produit.nbDoses} isNextToInput={false} disabled />
					<Grid item xs={12}>
						<Grid item xs={12} md={4}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="global.functionnal.batchNumber"
										defaultMessage="Lot"
										description="Batch number"
									/>
								}
								data={stock.lot}
							/>
						</Grid>
					</Grid>
					{stock.retourBl?.dateSortie &&
						<>
							<Grid item xs={12} md={5}>
								<DisplayLineData
									label={
										<FormattedMessage
											id="dispatch.global.functionnal.exitTime"
											defaultMessage="Heure de sortie du congélateur"
											description="Exit fridge datetime"
										/>
									}
									data={displayDate(moment(stock.retourBl?.dateSortie), DATE_FORMAT.DATETIME, isDateFormatEn)}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<DisplayLineData
									label={
										<FormattedMessage
											id="global.functionnal.usageLimitDate"
											defaultMessage="Date limite d'utilisation"
											description="Usage limit datetime"
										/>
									}
									data={getUsageLimit(stock)}
								/>
							</Grid>
							{fetchedValues.cause && <Grid item xs={12} md={10}>
								<DisplayLineData
									label={
										<FormattedMessage
											id="dispatch.productReturn.form.inputs.returnComment"
											defaultMessage="Commentaire de retour"
											description="Comment"
										/>
									}
									data={fetchedValues.cause}
								/>
							</Grid>}
						</>
					}

					<div
						className={classes.buttonRoot}
						style={
							isLargeScreen
								?
								{ flexDirection: 'row' }
								:
								{
									justifyContent: 'center',
									alignItems: 'center'
								}
						}
					>
						<div className={classes.buttonContainer}>
							<DownloadButton
								className={classes.button}
								label={<FormattedMessage
									id="button.print.return"
									defaultMessage="Fiche de retour"
									description="Message on print label button"
								/>}
								url={`/dispatch/retour/${idFromUrlParam}/document`}
								fileName={DOWNLOAD_FILE_TYPE.DISPATCH_RETOUR.outputFile}
							/>
						</div>
					</div>
				</Grid>
			}
		</div>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="menu.productReturn"
		defaultMessage="Retour de produit"
		description="Page title"
	/>
})), withTheme)(ProductReturnPrint)
