import React from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Truncate from 'react-truncate'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import { colors } from '../../../utils/constants'

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        title: {
            fontSize: 15,
            marginBottom: 14
        },
        content: {
            width: '100%',
            color: colors.displayText
        },
        linkContainer: {
            marginTop: 14,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: colors.primary
        },
        linkText: {
            color: colors.primary,
            fontWeight: 500
        }
    })
)

interface MenuTileTextProps {
    title: string | React.ReactElement
    content: string | React.ReactElement
    linkText: string | React.ReactElement
    linkHref: string
}

const MenuTileText: React.FC<MenuTileTextProps> = (
    {
        title,
        content,
        linkText,
        linkHref
    }
) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.title} variant="h6">
                {title}
            </Typography>
            <Typography className={classes.content}>
                <Truncate lines={3} ellipsis={
                    <Tooltip title={content}>
                        <span>
                            <FormattedMessage
                                id="global.ellipsis"
                                defaultMessage="..."
                                description="ellipsis"
                            />
                        </span>
                    </Tooltip>
                }>{content}</Truncate>
            </Typography>
            <Link className={classes.linkContainer} to={linkHref}>
                <Typography className={classes.linkText}>
                    {linkText}
                </Typography>
                <ArrowForwardIcon />
            </Link>
        </div>
    )
}

export default MenuTileText
