import React, { ReactElement } from 'react'
import classnames from 'classnames'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormattedMessage } from 'react-intl'
import { FieldRenderProps } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textFieldInput: {
            marginTop: 15,
            height: 35,
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.body1.fontSize,
            padding: '0 !important',
            border: '1px solid',
            borderColor: '#919191',
            borderRadius: 0
        },
        errorBorder: {
            borderColor: '#B92D00'
        },
        label: {
            fontSize: 12,
            transform: 'scale(1) !important',
            fontWeight: 'normal',
        },
        error: {
            marginLeft: 0,
            color: theme.palette.error.main,
            fontSize: 10
        }
    })
)

interface AutocompleteOption {
    label: string,
    value: string
}

interface FieldAutocompleteProps {
    label: ReactElement,
    options: AutocompleteOption[],
    defaultValue?: AutocompleteOption,
    disabled?: boolean
}

const FieldAutocomplete: React.FC<FieldAutocompleteProps & FieldRenderProps<string>> = (
    {
        label,
        options,
        input: { onChange, ...inputProps },
        meta: { error, ...otherMeta },
        defaultValue,
        disabled = false,
        ...otherProps
    }
) => {
    const classes = useStyles()

    const initialValue = options.find((option: AutocompleteOption) => option.value === otherMeta.initial)

    return <Autocomplete
        options={options}
        disabled={disabled}
        getOptionLabel={(option: AutocompleteOption) => option.label}
        {...(onChange ? { onChange: (event, value) => onChange(value ? value.value : null) } : {})}
        getOptionSelected={(option, value) => option.value === value.value}
        defaultValue={!!initialValue ? initialValue : defaultValue}
        noOptionsText={<FormattedMessage id="global.noOptions" />}
        classes={{
            inputRoot: classnames(classes.textFieldInput, {[classes.errorBorder]: (!otherMeta || otherMeta.touched) && !!error })
        }}
        renderInput={params => {
            return (
                <TextField
                    label={label}
                    {...otherProps}
                    {...inputProps}
                    {...params}
                    onBlur={() => {
                    }}
                    variant="filled"
                    helperText={(!otherMeta || otherMeta.touched) && error}
                    error={(!otherMeta || otherMeta.touched) && !!error}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.label }
                    }}
                    FormHelperTextProps={{
                        classes: { root: classes.error }
                    }}
                />
            )
        }}
    />
}

export default FieldAutocomplete
