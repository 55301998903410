import React from 'react'
import { FormInput } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-final-form'

interface FieldBatchNumberProps {
    disabled?: boolean,
    required?: boolean
}

const FieldBatchNumber: React.FC<FieldBatchNumberProps> = (
    {
        disabled = false,
        required = false
    }) => {
    return (
        <Field
            name="batchNumber"
            component={FormInput}
            label={
                <FormattedMessage
                    id="global.functionnal.batchNumber"
                    defaultMessage="Lot"
                    description="Batch number"
                />
            }
            disabled={disabled}
            required={required}
        />
    )
}

export default FieldBatchNumber
