import React, { useContext, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { FormInput } from 'isotope-client'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import FieldProduct from '../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../components/fields/FieldUnitProduct'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { QTY_MODE, SCREEN_SIZE } from '../../../utils/constants'
import { postPointNeeds } from '../services/pointVaccinationApi'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import Dialog from '../../../components/layout/dialog/Dialog'
import ErrorPositiveValue from '../../../components/layout/errors/ErrorPositiveValue'
import errorsMapper from '../../../utils/errorsMapper'
import FieldDatetime from '../../../components/fields/FieldDatetime'
import { dateAndTimeDecorator } from '../../../utils/form/decorators'
import { isDateInputTypeSupported } from '../../../utils/form/inputTypes'
import FieldRadio from '../../../components/fields/FieldRadio'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		coverage: {
			fontWeight: 'bold',
			textDecoration: 'underline',

			'& span:hover': {
				cursor: 'pointer'
			}
		}
	})
)

interface FormValues {
	product: string
	unitProduct: string
	modeQte: string
	nbDoses1?: number
	nbDoses2?: number
	nbDosesR?: number
	nbCond?: number
	datetimeSouhaitee: string
	dateSouhaitee: string
	timeSouhaitee: string
	commentaire: string
}

interface ReplenishmentProps {
	snackSuccess: (value: any) => void
	snackError: (value: any) => void
}

const ReplenishmentDemand: React.FC<ReplenishmentProps> = ({ snackSuccess, snackError }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { vaccins, application: { configuration }, user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [productTotal, setProductTotal] = useState<number>()
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const [formValuesToSubmit, setFormValuesToSubmit] = useState<FormValues>()
	const [selectedProduct, setSelectedProduct] = useState<any>(undefined)
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => (isDatetimeSupported ? [] : [dateAndTimeDecorator('dateSouhaitee', 'timeSouhaitee', 'datetimeSouhaitee')]), [isDatetimeSupported])
	const initValues = { modeQte: QTY_MODE.CONDITIONNEMENT }
	const [submitLoader, setSubmitLoader] = useState<boolean>(false)

	const openDialog = (formValues: FormValues) => {
		setFormValuesToSubmit(formValues)
		setIsDialogOpen(true)
	}

	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	const dialogConfirm = async (form: any, forceValidation: boolean) => {
		if (formValuesToSubmit) {
			setSubmitLoader(true)
			const quantitySent =
				formValuesToSubmit.modeQte === QTY_MODE.DOSE ? { nbDoses1: formValuesToSubmit.nbDoses1 || 0, nbDoses2: formValuesToSubmit.nbDoses2 || 0, nbDosesR: formValuesToSubmit.nbDosesR || 0 } : { nbCond: formValuesToSubmit.nbCond || 0 }

			return postPointNeeds({
				idProduit: formValuesToSubmit.product,
				modeQte: formValuesToSubmit.modeQte,
				...quantitySent,
				dateSouhaitee: new Date(formValuesToSubmit.datetimeSouhaitee).toISOString(),
				commentaire: formValuesToSubmit.commentaire
			})
				.then(() => {
					closeDialog()
					form.restart()
					snackSuccess({ id: 'vaccination.replenishment.success', defaultMessage: 'La demande de réapprovisionement a bien été enregistrée', description: 'Success message' })
				})
				.catch((e: any) => {
					const { errors, displayFields } = errorsMapper(e)
					if (displayFields) {
						snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
					} else {
						snackError({
							id: 'vaccination.replenishment.error',
							defaultMessage: 'Une erreur est survenue, la demande de réapprovisionement n\'a pas été enregistrée',
							description: 'Error message'
						})
					}
					return errors
				})
				.finally(() => setSubmitLoader(false))
		}
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.product) {
			errors.product = <ErrorMessageRequired />
		}

		if (formValues.modeQte === QTY_MODE.DOSE) {
			if ((!formValues.nbDoses1 && !formValues.nbDoses2 && !formValues.nbDosesR) || (Number(formValues.nbDoses1) === 0 && Number(formValues.nbDoses2) === 0 && Number(formValues.nbDosesR) === 0)) {
				errors.nbDoses1 = <FormattedMessage id="global.error.validation.oneOfThree" defaultMessage="Veuillez renseigner au moins un des trois champs" description="Error message" />
			} else if (formValues.nbDoses1 && formValues.nbDoses1 < 0) {
				errors.nbDoses1 = <ErrorPositiveValue />
			} else if (formValues.nbDoses2 && formValues.nbDoses2 < 0) {
				errors.nbDoses2 = <ErrorPositiveValue />
			} else if (formValues.nbDosesR && formValues.nbDosesR < 0) {
				errors.nbDosesR = <ErrorPositiveValue />
			} else if ((Number(formValues.nbDoses1) || 0) + (Number(formValues.nbDoses2) || 0) + (Number(formValues.nbDosesR) || 0) <= 0) {
				errors.nbDoses1 = <ErrorPositiveValue />
				errors.nbDoses2 = <ErrorPositiveValue />
				errors.nbDosesR = <ErrorPositiveValue />
			}
		} else {
			if (!formValues.nbCond) {
				errors.nbCond = <ErrorMessageRequired />
			} else if (formValues.nbCond < 1) {
				errors.nbCond = <ErrorPositiveValue />
			}
		}

		if (isDatetimeSupported) {
			if (!formValues.datetimeSouhaitee) {
				errors.datetimeSouhaitee = <ErrorMessageRequired />
			} else if (moment(formValues.datetimeSouhaitee).isBefore(moment().startOf('day'))) {
				// should be after now
				errors.datetimeSouhaitee = (
					<FormattedMessage id="global.error.validation.datePriorToNow" defaultMessage="La date ne peut pas être antérieure à maintenant" description="Error message of date prior to now" />
				)
			}
		} else {
			if (!formValues.dateSouhaitee) {
				errors.dateSouhaitee = <ErrorMessageRequired />
			}
			if (!formValues.timeSouhaitee) {
				errors.timeSouhaitee = <ErrorMessageRequired />
			}
			if (formValues.dateSouhaitee && formValues.timeSouhaitee && formValues.datetimeSouhaitee && moment(formValues.datetimeSouhaitee).isBefore(moment().startOf('day'))) {
				// should be after now
				errors.dateSouhaitee = (
					<FormattedMessage id="global.error.validation.datePriorToNow" defaultMessage="La date ne peut pas être antérieure à maintenant" description="Error message of date prior to now" />
				)
				errors.timeSouhaitee = (
					<FormattedMessage id="global.error.validation.datePriorToNow" defaultMessage="La date ne peut pas être antérieure à maintenant" description="Error message of date prior to now" />
				)
			}
		}

		return errors
	}

	const resetSecondPartFields = (form: any) => {
		form.change('modeQte', QTY_MODE.CONDITIONNEMENT)
		form.change('nbDoses1', undefined)
		form.change('nbDoses2', undefined)
		form.change('nbDosesR', undefined)
		form.change('nbCond', undefined)
		form.change('datetimeSouhaitee', undefined)
		form.change('dateSouhaitee', undefined)
		form.change('timeSouhaitee', undefined)
	}

	const resetQuantity = (form: any) => {
		form.change('nbDoses1', undefined)
		form.change('nbDoses2', undefined)
		form.change('nbDosesR', undefined)
		form.change('nbCond', undefined)
	}

	const onSubmit = async (formValues: FormValues) => {
		const errors = onValidate(formValues)

		// validation step
		if (Object.keys(errors).length > 0) {
			return errors
		}

		openDialog(formValues)
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			initialValues={initValues}
			// @ts-ignore
			decorators={formDecorators}
			render={({ handleSubmit, submitting, values, form }) => {
				return (
					<PageFormContainer onSubmit={handleSubmit}>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage id="dispatch.stock.formSection.productID" defaultMessage="Identification du produit" description="Form section title" />
							</Typography>
						</Grid>
						<Grid item xs={7} md={4}>
							<FieldProduct required />
						</Grid>
						<OnChange name="product">
							{(productID) => {
								const foundProduct = vaccins.find((vaccin) => productID === vaccin.id)

								setSelectedProduct(foundProduct)
								resetSecondPartFields(form)
								setProductTotal(0)
							}}
						</OnChange>
						<Grid item xs={5} md={4}>
							<FieldUnitProduct />
						</Grid>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage id="vaccination.replenishment.formSection.info" defaultMessage="2. Renseigner le besoin" description="Form section" />
							</Typography>
						</Grid>
						{configuration.replenishmentDose && <Grid item xs={12}>
							<FieldRadio
								name="modeQte"
								label={<FormattedMessage id="vaccination.replenishment.modeQte" defaultMessage="Mode de saisie" description="Quantity mode selection" />}
								choices={Object.keys(QTY_MODE).map((mode: string) => ({
									label: intl.formatMessage({
										id: `enum.qtyMode.${mode}`,
										defaultMessage: `Quantity mode ${mode}`,
										description: 'Quantity mode option'
									}),
									value: mode
								}))}
							/>
							<OnChange name="modeQte">
								{() => {
									resetQuantity(form)
								}}
							</OnChange>
						</Grid>}
						{values.modeQte === QTY_MODE.DOSE ? (
							<>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDoses1"
										component={FormInput}
										label={<FormattedMessage id="vaccination.replenishment.quantity1" defaultMessage="1ère injection" description="Comment label input" />}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDoses1">
										{(value) => {
											const wantedDoseTotal = Number(value || 0) + Number(values.nbDoses2 || 0) + Number(values.nbDosesR || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDoses2"
										component={FormInput}
										label={<FormattedMessage id="vaccination.replenishment.quantity2" defaultMessage="2ème injection" description="Comment label input" />}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDoses2">
										{(value) => {
											const wantedDoseTotal = Number(values.nbDoses1 || 0) + Number(value || 0) + Number(values.nbDosesR || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<Grid item xs={4} md={2}>
									<Field
										name="nbDosesR"
										component={FormInput}
										label={<FormattedMessage id="vaccination.replenishment.quantityR" defaultMessage="injection rappel" description="Comment label input" />}
										type="number"
										inputProps={{ min: 0 }}
									/>
									<OnChange name="nbDosesR">
										{(value) => {
											const wantedDoseTotal = Number(values.nbDoses1 || 0) + Number(values.nbDoses2 || 0) + Number(value || 0)

											setProductTotal(selectedProduct ? Math.ceil(wantedDoseTotal / (selectedProduct.nbDoses || 1)) : 0)
										}}
									</OnChange>
								</Grid>
								<TotalCountCaption count={productTotal} type={selectedProduct?.typeConditionnement} />
							</>
						) : (
							<Grid item xs={4} md={2}>
								<Field
									name="nbCond"
									component={FormInput}
									label={<FormattedMessage id="global.functionnal.quantity" defaultMessage="Quantité" description="Comment label input" />}
									type="number"
									inputProps={{ min: 0 }}
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							<FieldDatetime
								datetimeName="datetimeSouhaitee"
								dateName="dateSouhaitee"
								timeName="timeSouhaitee"
								datetimeLabel={<FormattedMessage id="vaccination.replenishment.datetimeWanted" defaultMessage="Date de réception souhaitée" description="Datetime input" />}
								dateLabel={<FormattedMessage id="vaccination.replenishment.dateWanted" defaultMessage="Date de réception souhaitée" description="Date input" />}
								timeLabel={<FormattedMessage id="vaccination.replenishment.timeWanted" defaultMessage="Heure de réception souhaitée" description="Time input" />}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage id="vaccination.replenishment.formSection.comment" defaultMessage="3. Ajouter un commentaire" description="Form section title" />
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Field
								name="commentaire"
								component={FormInput}
								label={<FormattedMessage id="vaccination.replenishment.comment" defaultMessage="Commentaire" description="Comment label input" />}
							/>
						</Grid>

						<div
							className={classes.submitButtonRoot}
							style={{
								justifyContent: isLargeScreen ? 'flex-start' : 'center'
							}}
						>
							<Button type="submit" variant="contained" isLoading={submitting}>
								<FormattedMessage id="button.validate" defaultMessage="Valider" description="Message on form submission button" />
							</Button>
						</div>
						<Dialog
							title={<FormattedMessage id="vaccination.replenishment.dialog.title" defaultMessage="Confirmation de réapprovisionement" description="Dialog title" />}
							isOpen={isDialogOpen}
							handleClose={closeDialog}
							buttons={[
								<Button onClick={closeDialog} color="primary" key={0} variant="outlined">
									<FormattedMessage id="vaccination.replenishment.dialog.actionNo" defaultMessage="Non" description="Button label" />
								</Button>,
								<Button autoFocus onClick={() => dialogConfirm(form, false)} color="primary" key={1} variant="contained" isLoading={submitLoader}>
									<FormattedMessage id="vaccination.replenishment.dialog.actionYes" defaultMessage="Oui" description="Button label" />
								</Button>
							]}
						>
							<Typography gutterBottom>
								<FormattedMessage
									id="vaccination.replenishment.dialog.summary"
									defaultMessage="Vous souhaitez faire une demande de réapprovisionement"
									description="Dialog content"
									values={{
										productCount: values.modeQte === QTY_MODE.DOSE ? productTotal : values.nbCond,
										conditioningType: selectedProduct?.typeConditionnement,
										date: displayDate(moment(values.datetimeSouhaitee).local(), DATE_FORMAT.DATETIME, isDateFormatEn)
									}}
								/>
							</Typography>
							{values.commentaire && <Typography gutterBottom>{`"${values.commentaire}"`}</Typography>}
							<Typography gutterBottom>
								<FormattedMessage id="vaccination.replenishment.dialog.doYouConfirm" defaultMessage="Confirmez-vous cette demande ?" description="Dialog content" />
							</Typography>
						</Dialog>
					</PageFormContainer>
				)
			}}
		/>
	)
}

export default compose(injectSnackbarActions)(ReplenishmentDemand)
