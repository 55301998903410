import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Dialog from './dialog/Dialog'
import Button from './buttons/Button'

interface DialogOfflineModeProps {
	isOpen: boolean
	disabled: boolean
	offlineMode: boolean
	handleClose: () => any
	onConfirm: () => any
}

const DialogOfflineMode: React.FC<DialogOfflineModeProps> = (
	{
		isOpen,
		disabled,
		offlineMode,
		handleClose,
		onConfirm
	}
) => {
	return (
		<Dialog
			title={
				<FormattedMessage
					id={`menuHeader.offlineDialog.${offlineMode ? 'online' : 'offline'}Title`}
					defaultMessage="Passer en mode déconnecté"
					description="Dialog title"
				/>
			}
			isOpen={isOpen}
			handleClose={handleClose}
			buttons={disabled ?
				[
					<Button color="primary" key={0} variant="contained" onClick={onConfirm}>
						<FormattedMessage
							id="button.close"
							defaultMessage="FERMER"
							description="Button label"
						/>
					</Button>
				]
				:
				[
					<Button onClick={handleClose} color="primary" key={0} variant="outlined">
						<FormattedMessage
							id="button.cancel"
							defaultMessage="ANNULER"
							description="Button label"
						/>
					</Button>,
					<Button color="primary" key={1} variant="contained" onClick={onConfirm}>
						<FormattedMessage
							id="button.confirm"
							defaultMessage="CONFIRMER"
							description="Button label"
						/>
					</Button>
				]}
		>
			<Typography gutterBottom>
				{disabled ?
					<FormattedMessage
						id="menuHeader.offlineDialog.disabledDesc"
						defaultMessage="Impossible de changer de mode"
						description="Dialog content"
					/>
					:
					<FormattedMessage
						id={`menuHeader.offlineDialog.${offlineMode ? 'online' : 'offline'}Desc`}
						defaultMessage="Confirmation de modification du mode"
						description="Dialog content"
					/>}

			</Typography>
		</Dialog>
	)
}

export default DialogOfflineMode
