import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
	radio: {
		margin: '-5px 0'
	},
	label: {
		fontSize: 13,
		transform: 'scale(1)'
	},
	radioGroup: {
		marginBottom: 20
	}
}))

interface RadioOption {
	label: string,
	value: string,
	disabled?: boolean
}

interface FieldRadioProps {
	name: string
	label: string | React.ReactElement,
	choices: RadioOption[]
}

const FieldRadio: React.FC<FieldRadioProps> = ({
	                                               name = 'radio',
	                                               label,
	                                               choices
                                               }) => {
	const classes = useStyles()

	return (
		<FormControl>
			{label && <FormLabel component="legend" className={classes.label}>{label}</FormLabel>}
			<RadioGroup className={classes.radioGroup}>
				{choices.map((choice: RadioOption) => <Field key={`radioField-${choice.value}`} name={name} type="radio" value={choice.value}>
					{({ input }) => <FormControlLabel
						{...input}
						control={<Radio className={classes.radio} />}
						key={choice.value}
						label={choice.label}
						value={choice.value}
						disabled={!!choice.disabled}
					/>
					}
				</Field>)}
			</RadioGroup>
		</FormControl>
	)
}

export default FieldRadio
