import React from 'react'
import {default as MuiDialogContent, DialogContentProps as MuiDialogContentProps } from '@material-ui/core/DialogContent'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        }
    }),
)

const DialogContent: React.FC<MuiDialogContentProps> = ({ children, ...rest }) => {
    const classes = useStyles()

    return (
        <MuiDialogContent className={classes.root} {...rest}>
            {children}
        </MuiDialogContent>
    )
}

export default DialogContent
