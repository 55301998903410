import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { useParams } from 'react-router-dom'
import { FormCheckbox, injectToolbarData } from 'isotope-client'
import Button from '../../../components/layout/buttons/Button'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import { cancelPreparation, getBlByID } from '../services/dispatchApi'
import { DispatchBlModel, DispatchLigneBlModel } from '../services/dispatchModels'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { DOWNLOAD_FILE_TYPE, PRODUCT_TYPES, SCREEN_SIZE, STATUT_LIGNE_BL, TRANSFERT_TYPE } from '../../../utils/constants'
import SplitButton from '../../../components/layout/buttons/SplitButton'
import { fetchDownload } from '../../../utils/fetchDownload'
import DialogCancelPreparationConfirm from '../../common/dispatchAndReferent/exitStock/components/DialogCancelPreparationConfirm'
import ProductsTable from '../../../components/layout/productsTable/ProductsTable'
import DialogLabelSelection from '../../common/dispatchAndReferent/exitStock/components/DialogLabelSelection'
import moment from 'moment'
import { getLimitDateWithStability } from '../../../utils/formUtils'
import { ExitStockPrintModel } from '../../common/dispatchAndReferent/exitStock/services/exitStockModels'
import { DispatchReferentLigneBlModel } from '../../common/dispatchAndReferent/stock/stockModels'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(3),
			display: 'flex',
			flexDirection: 'column'
		},
		circularProgressRoot: {
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		firstTitle: {
			marginTop: 0
		},
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		button: {
			margin: theme.spacing(4)
		},
		dosageContainer: {
			margin: '-9px 0 6px 12px'
		},
		buttonContainer: {
			margin: '40px 40px 100px 40px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
)

interface MatchParamsModel {
	id: string
}

interface ExitStockPrintProps {
	theme: Theme,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ExitStockPrint: React.FC<ExitStockPrintProps> = ({ theme, snackSuccess, snackError }) => {
	const classes = useStyles()
	const { referentialData } = useContext(PhidemDataContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const [fetchedValues, setFetchedValues] = useState<ExitStockPrintModel | undefined>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false)
	const [isOpenDialogCancelPreparationConfirm, setIsOpenDialogCancelPreparationConfirm] = useState<boolean>(false)
	const [isOpenDialogLabelSelection, setIsOpenDialogLabelSelection] = useState<boolean>(false)

	const isCancelPreparationDisabled = fetchedValues?.annule ||
		(!fetchedValues?.disabledFollowUp &&
			fetchedValues?.produits &&
			fetchedValues?.produits.length > 0 &&
			fetchedValues?.produits[0].statut !== STATUT_LIGNE_BL.PREPARE)

	const openDialogCancelPreparationConfirm = () => setIsOpenDialogCancelPreparationConfirm(true)

	const closeDialogCancelPreparationConfirm = () => setIsOpenDialogCancelPreparationConfirm(false)

	const openDialogLabelSelection = () => setIsOpenDialogLabelSelection(true)

	const closeDialogLabelSelection = () => setIsOpenDialogLabelSelection(false)

	const saveBlModelToValues = (blModel: DispatchBlModel) => {
		if (blModel.lignes && blModel.lignes.length > 0) {

			setFetchedValues({
				id: blModel.id,
				produits: (blModel.lignes as DispatchReferentLigneBlModel[]),
				isTransfert: blModel.transfert,
				destination: !!blModel.destination ? `${blModel.destination.nom}${blModel.destination.service ?
					`-${blModel.destination.service}` :
					''}` : undefined,
				destinationComment: blModel.commentaireDestination,
				replenishmentComment: blModel.commentaireDemande,
				trackerID: blModel.idTracker,
				trackerNumber: blModel.numTracker,
				carrierID: blModel.idTransporteur,
				containerID: blModel.idContenant,
				numContenant: blModel.numContenant,
				disabledFollowUp: blModel.desactivationSuivi,
				annule: blModel.annule,
				statut: blModel.lignes[0].statut,
				decongele: true
			})
		}
	}

	const handleCancelPreparation = () => {
		if (fetchedValues?.id) {
			cancelPreparation(fetchedValues.id)
				.then((response: DispatchBlModel) => {
					saveBlModelToValues(response)
					closeDialogCancelPreparationConfirm()
					snackSuccess({ id: 'common.exitStock.print.cancelSuccess', defaultMessage: 'L\'annulation de la préparation a bien été effectuée', description: 'Success message' })
				})
				.catch(() => snackError({ id: 'common.exitStock.print.cancelError', defaultMessage: 'Une erreur est survenue, l\'annulation a échoué', description: 'Error message' }))
		}
	}

	const downloadDocument = (url: string, fileName: string) => {
		setIsLoadingDocument(true)
		fetchDownload(url)
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = fileName
					a.click()
				}
			)
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	const downloadEtiquettes = (formValues: any) => {
		setIsLoadingDocument(true)
		fetchDownload(`/dispatch/preparation/${idInUrl}/etiquette`, {
			method: 'POST',
			body: JSON.stringify(formValues)
		})
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE.outputFile
					a.click()
				}
			)
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	const getDownloadOptions = () => {
		const items = [
			{
				label: <FormattedMessage
					id="button.print.BL"
					defaultMessage="Bon de livraison"
					description="Message on print BL button"
				/>,
				onClick: () => downloadDocument(
					`/dispatch/preparation/${idInUrl}/document?documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_BL.type}`,
					DOWNLOAD_FILE_TYPE.DISPATCH_BL.outputFile)
			},
			{
				label: <FormattedMessage
					id="button.print.label"
					defaultMessage="Etiquette"
					description="Message on print label button"
				/>,
				onClick: openDialogLabelSelection
			}]

		if (fetchedValues?.produits.some((product: DispatchReferentLigneBlModel) => product.stock?.produit.type === PRODUCT_TYPES.VACCIN)) {
			items.push(
				{
					label: <FormattedMessage
						id="button.print.bottle36"
						defaultMessage="Etiquettes de flacon 36"
						description="Message on bottle 36 button"
					/>,
					onClick: () => downloadDocument(
						`/dispatch/preparation/${idInUrl}/document?documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_FLACON_36.type}`,
						DOWNLOAD_FILE_TYPE.DISPATCH_FLACON_36.outputFile)
				},
				{
					label: <FormattedMessage
						id="button.print.contentLabel"
						defaultMessage="Etiquette contenant"
						description="Message on content label button"
					/>,
					onClick: () => downloadDocument(
						`/dispatch/preparation/${idInUrl}/document?documentType=${DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE_CONTENANT.type}`,
						DOWNLOAD_FILE_TYPE.DISPATCH_ETIQUETTE_CONTENANT.outputFile)
				})
		}

		return items
	}

	const convertProductToTable = (dispatchLignes: DispatchLigneBlModel[]) => {
		return dispatchLignes.map((dispatchLigne: DispatchLigneBlModel) => {
			let stockData = {}
			if (dispatchLigne.stock) {
				let usageLimit = undefined
				if (dispatchLigne.stock.retourProduit) {
					usageLimit = getLimitDateWithStability(dispatchLigne.stock.produit, dispatchLigne.dateSortie, dispatchLigne.stockTransfertType)
					usageLimit = moment(dispatchLigne.dateSortie).add(dispatchLigne.stock.produit.stabilite28, 'hours')
				} else if (dispatchLigne.stock.transfertType === TRANSFERT_TYPE.DECONGELE) {
					usageLimit = dispatchLigne.stock.transfertDate
				}

				stockData = {
					lot: dispatchLigne.stock.lot,
					peremption: dispatchLigne.stock.peremption,
					peremptionType: dispatchLigne.produit.peremptionType,
					isRetour: dispatchLigne.stock.retourProduit,
					transfertType: dispatchLigne.stock.transfertType,
					transfertDate: dispatchLigne.stock.transfertDate,
					usageLimit
				}
			}

			return ({
				nom: dispatchLigne.produit.nomCommercial,
				unit: dispatchLigne.produit.typeConditionnement,
				reference: dispatchLigne.refExterne,
				quantite: dispatchLigne.quantite,
				sortie: dispatchLigne.dateSortie,
				...stockData
			})
		})
	}

	useEffect(() => {
		getBlByID(idInUrl)
			.then(saveBlModelToValues)
			.finally(() => setIsLoading(false))
	}, [idInUrl])

	if (isLoading) {
		return <div
			className={classes.container}
			style={{
				alignItems: isLargeScreen ? 'flex-start' : 'center',
				marginLeft: isLargeScreen ? '24px' : 0
			}}
		>
			<div className={classes.circularProgressRoot}>
				<CircularProgress color="primary" size={100} />
			</div>
		</div>
	}

	const carrier = referentialData.carriers.find((carrier) => String(fetchedValues?.carrierID) === carrier.id)?.nom
	const container = referentialData.containers.find((container) => String(fetchedValues?.containerID) === container.id)?.nom
	const tracker = referentialData.trackers.find((tracker) => String(fetchedValues?.trackerID) === tracker.id)?.nom

	return (
		<div
			className={classes.container}
			style={{
				alignItems: isLargeScreen ? 'flex-start' : 'center',
				margin: isLargeScreen ?
					`${theme.spacing(3)}px ${theme.spacing(3)}px 64px 13vw` :
					`${theme.spacing(1)} ${theme.spacing(1)} 64px ${theme.spacing(1)}`,
				width: isLargeScreen ? 600 : 300
			}}
		>
			{!fetchedValues
				?
				<NotFoundPage />
				:
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					xs={10} md={12}
					spacing={2}
				>
					{fetchedValues.annule &&
						<Grid item xs={12}>
							<Alert severity="error">
								<FormattedMessage
									id="common.exitStock.print.cancelledPreparationWarning"
									defaultMessage="Cette préparation a été annulée"
									description="alert message"
								/>
							</Alert>
						</Grid>
					}
					{fetchedValues.disabledFollowUp &&
						<Grid item xs={12}>
							<Alert severity="error">
								<FormattedMessage
									id="common.exitStock.print.disabledFollowUp"
									defaultMessage="Le suivi a été désactivé."
									description="Info about disabled follow up"
								/>
							</Alert>
						</Grid>
					}
					{fetchedValues.replenishmentComment && <>
						<Grid item xs={12}>
							<Typography
								className={classes.firstTitle}
								variant={isLargeScreen ? 'h1' : 'h3'}
							>
								<FormattedMessage
									id="dispatch.exitStock.formSection.replenishment"
									defaultMessage="Informations sur la demande"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12} md={10}>
							<DisplayLineData
								label={
									<FormattedMessage
										id="dispatch.exitStock.replenishmentComment"
										defaultMessage="Commentaire du centre de vaccination"
										description="Replenishment comment"
									/>}
								data={fetchedValues.replenishmentComment}
								multiline
							/>
						</Grid>
					</>}

					<ProductsTable
						produits={convertProductToTable(fetchedValues.produits)}
					/>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="common.exitStock.formSection.shipment"
								defaultMessage="Expédition"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					{fetchedValues.isTransfert && <Grid item container>
						<Grid item xs={12} md={5}>
							<FormCheckbox
								name="isTransfert"
								value={fetchedValues.isTransfert}
								meta={{}}
								label={
									<FormattedMessage
										id="dispatch.enterStock.isTranfert"
										defaultMessage="Transfert de stock"
										description="Checkbox description"
									/>
								}
							/>
						</Grid>
					</Grid>}
					{fetchedValues.destination && <Grid item xs={12} md={5}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="global.functionnal.destination"
									defaultMessage="Destination"
									description="Destination to select"
								/>}
							data={fetchedValues.destination}
						/>
					</Grid>}

					{fetchedValues.destinationComment && <Grid item xs={12} md={10}>
						<DisplayLineData
							label={
								<FormattedMessage
									id="common.exitStock.form.destinationComment"
									defaultMessage="Informations complémentaires"
									description="Comment destination"
								/>}
							data={fetchedValues.destinationComment}
							multiline
						/>
					</Grid>}

					<Grid item xs={12} md={5}>
						{carrier && <DisplayLineData
							label={
								<FormattedMessage
									id="global.functionnal.carrier"
									defaultMessage="Transporteur"
									description="Product carrier"
								/>}
							data={carrier}
						/>}
					</Grid>
					{container &&
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={4}>
								<DisplayLineData
									label={
										<FormattedMessage
											id="common.exitStock.form.container"
											defaultMessage="Contenant"
											description="Product container"
										/>}
									data={container}
								/>
							</Grid>
							{
								fetchedValues.numContenant
								&&
								<Grid item xs={12} md={4}>
									<DisplayLineData
										label={
											<FormattedMessage
												id="common.exitStock.form.containerNumber"
												defaultMessage="Nᵒ contenant"
												description="Container number"
											/>
										}
										data={fetchedValues.numContenant}
									/>
								</Grid>
							}
						</Grid>
					}
					<Grid container item xs={12} spacing={2}>
						<Grid item xs={12} md={4}>
							{tracker && <DisplayLineData
								label={
									<FormattedMessage
										id="common.exitStock.form.tracker"
										defaultMessage="Trackeur température"
										description="Tracking temperature system"
									/>}
								data={referentialData.trackers.find(
									(tracker) => String(fetchedValues?.trackerID) === tracker.id)?.nom}
							/>}
						</Grid>
						<Grid item xs={12} md={4}>
							{fetchedValues.trackerNumber && <DisplayLineData
								label={
									<FormattedMessage
										id="common.exitStock.form.trackerNumber"
										defaultMessage="Trackeur №"
										description="Tracking temperature system ID"
									/>}
								data={fetchedValues.trackerNumber}
							/>}
						</Grid>
					</Grid>
					<div
						className={classes.buttonRoot}
						style={
							isLargeScreen
								?
								{ flexDirection: 'row' }
								:
								{
									justifyContent: 'center',
									alignItems: 'center'
								}
						}
					>
						<div className={classes.buttonContainer}>
							<SplitButton
								disabled={fetchedValues.annule}
								isLoading={isLoadingDocument}
								items={getDownloadOptions()} />
							<Button
								variant="contained"
								className={classes.button}
								onClick={openDialogCancelPreparationConfirm}
								disabled={isCancelPreparationDisabled}
							>
								<FormattedMessage
									id="dispatch.exitStock.cancelPreparationButtonLabel"
									defaultMessage="Annuler la préparation"
									description="Cancel button label"
								/>
							</Button>
						</div>
					</div>
				</Grid>
			}
			<DialogCancelPreparationConfirm
				isOpen={isOpenDialogCancelPreparationConfirm}
				handleClose={closeDialogCancelPreparationConfirm}
				onConfirm={handleCancelPreparation}
			/>
			{isOpenDialogLabelSelection && <DialogLabelSelection
				handleClose={closeDialogLabelSelection}
				downloadDocument={downloadEtiquettes}
				data={fetchedValues}
				isLoadingDocument={isLoadingDocument}
			/>}
		</div>
	)
}

export default compose<any>(injectSnackbarActions, injectToolbarData(() => (
	{
		title: <FormattedMessage
			id="menu.dispatchCenter.preparation"
			defaultMessage="Préparation / Expédition"
			description="Menu title"
		/>
	}
)))(withTheme(ExitStockPrint))
