import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../components/layout/buttons/Button'
import Dialog from '../../../../components/layout/dialog/Dialog'
import { ExitStockValues } from '../../../dispatch/exitStock/ExitStock'

const useStyles = makeStyles(() =>
    createStyles({
        inputContainer: {
            marginTop: 32
        }
    })
)

interface DialogExitStockReplenishmentProcessProps {
    isOpen: boolean
    handleClose: () => any
    onSubmit: (value: any) => any,
    handleComment: (comment: undefined | string) => any,
    data: ExitStockValues,
    replenishmentID: string,
    isLoading: boolean,
}

const DialogExitStockReplenishmentProcess: React.FC<DialogExitStockReplenishmentProcessProps> = (
    {
        isOpen,
        handleClose,
        onSubmit,
        handleComment,
        data,
        replenishmentID,
        isLoading
    }) => {
    const classes = useStyles()

    const handleSubmit = (values: any, wantToProcess: boolean) => {
        handleComment(values.comment)
        onSubmit({
            ...data,
            ...(wantToProcess && {
                replenishmentID,
                comment: values.comment
            })
        })
    }

    return (
        <Form
            onSubmit={onSubmit}
            render={({ values }) => (
                <Dialog
                    title={
                        <FormattedMessage
                            id="dispatch.processing.dialog.title"
                            defaultMessage="Confirmation de traitement"
                            description="Dialog title"
                        />
                    }
                    isOpen={isOpen}
                    handleClose={handleClose}
                    buttons={[
                        <Button color="primary" key={0} variant="outlined" onClick={() => handleSubmit(values, false)}>
                            <FormattedMessage
                                id="button.no"
                                defaultMessage="Non"
                                description="Button label"
                            />
                        </Button>,
                        <Button
                            autoFocus
                            color="primary"
                            key={1}
                            variant="contained"
                            isLoading={isLoading}
                            onClick={() => handleSubmit(values, true)}
                        >
                            <FormattedMessage
                                id="button.yes"
                                defaultMessage="Oui"
                                description="Button label"
                            />
                        </Button>
                    ]}
                >
                    <Typography>
                        <FormattedMessage
                            id="dispatch.processing.dialog.exitStockContent"
                            defaultMessage={`Vous avez procédé à une préparation suite à une demande de réapprovisionnement. Souhaitez-vous passer le statut de la demande à "Traité" ?`}
                            description="Dialog content"
                        />
                    </Typography>
                    <Typography>
                        <FormattedMessage
                            id="dispatch.processing.dialog.exitStockContentNotify"
                            defaultMessage="Le centre de vaccination sera notifié par mail."
                            description="Dialog content"
                        />
                    </Typography>
                    <Field
                        name="comment"
                        component={FormInput}
                        className={classes.inputContainer}
                        label={
                            <FormattedMessage
                                id="dispatch.processing.dialog.comment"
                                defaultMessage="Commentaire"
                                description="Comment label input"
                            />
                        }
                    />
                </Dialog>
            )}
        />
    )
}

export default DialogExitStockReplenishmentProcess
