import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { colors } from '../../utils/constants'
import classnames from 'classnames'

const useStyles = (isPrimary: boolean) => makeStyles(() =>
    createStyles({
        label: {
            fontSize: 12,
            lineHeight: 1,
            color: isPrimary ? colors.primary : colors.disabled
        },
        dataContainer: {
            marginTop: "3.5px",
            height: 17,
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            padding: '8px 8px',
            border: '1px solid',
            borderColor: isPrimary ? colors.primary : colors.notFound
        },
        multilineContainer: {
            height: 'auto'
        },
        multilineData: {
            whiteSpace: 'pre-line'
        },
        data: {
            color: isPrimary ? colors.primary : colors.displayText
        }
    }),
)


interface DisplayLineDataProps {
    label: string | React.ReactElement
    data: string | number | undefined,
    multiline?: boolean,
    isPrimary?: boolean
}

const DisplayLineData: React.FC<DisplayLineDataProps> = (
    {
        label,
        data,
        multiline = false,
        isPrimary = false
    }
) => {
    const classes = useStyles(isPrimary)()

    return (
        <>
            <Typography className={classes.label}>{label}</Typography>
            <div className={classnames(classes.dataContainer, { [classes.multilineContainer]: multiline })}>
                <Typography className={classnames(classes.data, { [classes.multilineData]: multiline })} noWrap>{data}</Typography>
            </div>
        </>
    )
}

export default DisplayLineData
