import React, { useContext, useState } from 'react'
import { RequestStockFiltersModel, ResponseStockModel } from '../../admin/stock/services/stockModel'
import StockForm, { StockListFormValues } from '../../common/stock/StockForm'
import { CENTER_TYPE, SELECT_VALUES } from '../../../utils/constants'
import PageLayout from '../../../components/layout/PageLayout'
import DialogEditStock from '../../admin/stock/DialogEditStock'
import StockList from '../../common/dispatchAndReferent/stock/StockList'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

const StockReferentSelf = () => {
	const { user: { selectedCenter } } = useContext(PhidemDataContext)
	const [filters, setFilters] = useState<RequestStockFiltersModel>({
		idProduit: undefined,
		quantiteNonNulle: true,
		onlyRetour: false,
		idCentre: selectedCenter.id
	})
	const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
	const [selectedRow, setSelectedRow] = useState<ResponseStockModel | undefined>(undefined)
	const openDialog = (row: ResponseStockModel) => {
		setSelectedRow(row)
		setIsOpenDialog(true)
	}
	const closeDialog = () => setIsOpenDialog(false)


	const getStocks = (formValues: StockListFormValues) => {
		setFilters({
			idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
			lot: formValues.batchNumber,
			quantiteNonNulle: !formValues.isVisibleOutOfStock,
			onlyRetour: false,
			idCentre: selectedCenter.id
		})
	}

	return (
		<PageLayout>
			<StockForm onSubmit={getStocks} hideCenterFilter />
			<StockList
				centerType={CENTER_TYPE.REFERENT}
				filters={filters}
				openDialog={openDialog}
				isOpenDialog={isOpenDialog}
				showActions
			/>
			{selectedRow && <DialogEditStock
				isOpen={isOpenDialog}
				handleClose={closeDialog}
				selectedRow={selectedRow}
			/>}
		</PageLayout>
	)
}

export default StockReferentSelf
