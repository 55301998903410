import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/layout/buttons/Button'
import Grid from '@material-ui/core/Grid'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import { FormSelect, FormInput } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import Dialog from '../../../../components/layout/dialog/Dialog'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { EtiquetteCentreModel } from '../services/centerDetailsDataModel'
import { TAG_CATEGORIES } from '../../../../utils/constants'

interface DialogAddCategoryProps {
    isOpen: boolean,
    handleClose: () => void,
    onSubmit: (formValues: EtiquetteCentreModel) => void,
    currentTags: EtiquetteCentreModel[]
}

const DialogAddCategory: React.FC<DialogAddCategoryProps> = (
    {
        isOpen,
        handleClose,
        onSubmit,
        currentTags
    }
) => {
    const onValidate = (formValues: EtiquetteCentreModel) => {
        const errors: any = {}

        if (!formValues.categorieType) {
            errors.categorieType = <ErrorMessageRequired />
        } else if (formValues.categorieType !== TAG_CATEGORIES.AUTRE && currentTags.some(tag => tag.categorieType === formValues.categorieType)) {
            errors.categorieType = <FormattedMessage
                id="center.dialog.tagAlreadyExists"
                defaultMessage="Cette catégorie est déjà utilisée"
                description="Error message"
            />
        } else if (formValues.categorieType === TAG_CATEGORIES.AUTRE) {
            if (!formValues.categorieLabel) {
                errors.categorieLabel = <ErrorMessageRequired />
            } else if (currentTags.some(tag => tag.categorieLabel === formValues.categorieLabel)) {
                errors.categorieLabel = <FormattedMessage
                    id="center.dialog.tagAlreadyExists"
                    defaultMessage="Cette catégorie est déjà utilisée"
                    description="Error message"
                />
            }
        }
        if (!formValues.valeur) {
            errors.valeur = <ErrorMessageRequired />
        }

        return errors
    }

    return (
        <Dialog
            isOpen={isOpen}
            handleClose={handleClose}
            title={
                <FormattedMessage
                    id="center.dialog.title"
                    defaultMessage="Ajouter une catégorie"
                    description="Dialog title"
                />
            }
            buttons={[
                <Button onClick={handleClose} color="primary" key={0} variant="outlined">
                    <FormattedMessage
                        id="center.dialog.actions.cancel"
                        defaultMessage="ANNULER"
                        description="Button label"
                    />
                </Button>,
                <Button autoFocus color="primary" key={1} variant="contained" type="submit" form="dialogAddCategory">
                    <FormattedMessage
                        id="center.dialog.actions.confirm"
                        defaultMessage="AJOUTER"
                        description="Button label"
                    />
                </Button>
            ]}
        >
            <Form
                onSubmit={onSubmit}
                validate={onValidate}
                render={({ handleSubmit, values, form }) => (
                    <form id="dialogAddCategory" onSubmit={handleSubmit}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={8}>
                                <Field
                                    name="categorieType"
                                    component={FormSelect}
                                    label={
                                        <FormattedMessage
                                            id="center.dialog.category"
                                            defaultMessage="Catégorie"
                                            description="Field label"
                                        />
                                    }
                                    required
                                >
                                    {Object.values(TAG_CATEGORIES).map((category: string) => (
                                            <MenuItem key={category} value={category}>
                                                <FormattedMessage
                                                    id={`enum.tagCategories.${category}`}
                                                    defaultMessage={`${category[0].toUpperCase()}${category.slice(1).toLowerCase()}`}
                                                    description="Field value"
                                                />
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <OnChange name="categorieType">
                                    {
                                        value => {
                                            if (value !== TAG_CATEGORIES.AUTRE) {
                                                form.change('categorieLabel', undefined)
                                            }
                                        }
                                    }
                                </OnChange>
                            </Grid>
                            {values.categorieType === TAG_CATEGORIES.AUTRE &&
							<Grid item xs={8}>
								<Field
									name="categorieLabel"
									component={FormInput}
									label={
                                        <FormattedMessage
                                            id="center.dialog.categoryLabel"
                                            defaultMessage="Nom de la catégorie"
                                            description="Field label"
                                        />
                                    }
									required={values.categorieType === TAG_CATEGORIES.AUTRE}
								/>
							</Grid>
                            }
                            <Grid item xs={8}>
                                <Field
                                    name="valeur"
                                    component={FormInput}
                                    label={
                                        <FormattedMessage
                                            id="center.dialog.value"
                                            defaultMessage="Valeur"
                                            description="Field label"
                                        />
                                    }
                                    required
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default DialogAddCategory
