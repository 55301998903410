import { flatten } from 'flat'
import { ModelTranslations } from './messagesModel'

const en = {
    accueil: 'Home ',
    admin: 'Administrator',
    dispatch_center: 'Dispatch center',
    vaccination_center: 'Vaccination center',
    vaccination_point: 'Vaccination point',
    admin_dispatch: 'Dispatch center administrator',
    referent: 'Stock center',
    menuHeader: {
        offlineMode: 'Disconnected mode',
        onlineMode: 'Connected mode',
        offlineDialog: {
            offlineTitle: 'Disconnected mode',
            onlineTitle: 'Connected mode',
            offlineDesc: 'Do you really want to go to disconnected mode ?',
            onlineDesc: 'Do you really want to go to connected mode ?',
            disabledDesc: 'You cannot leave disconnected mode while actions are in waiting queue.'
        }
    },
    menu: {
        accueilMenu: 'Home',
        noticeMenu: 'Legal notice',
        referentCenter: {
            affiliatedStock: 'My affiliate centers',
            home: {
                title: 'Stock center {centerName}',
                tiles: {
                    withStock: {
                        enterStock: {
                            title: 'Inbound reception',
                            body:  'Enter information for inbound reception',
                            link: 'Stock booking'
                        },
                        exitStock: {
                            title: 'Pick & Pack / Shipping',
                            body: 'Dispatch to affiliate centers',
                            link: 'Outbound delivery'
                        },
                        scrapping: {
                            title: 'Scrapping',
                            body: 'Stock adjustment : broken vials, outdated vials, ...',
                            link: 'Scrap movement'
                        },
                        replenishment: {
                            title: 'Replenishment list',
                            body: 'Manage replenishment orders from affiliate centers',
                            link: 'Display replenishment list'
                        },
                        dashboard: {
                            title: 'Dashboard',
                            body: 'Display real time inventory',
                            link: 'Display dashboard'
                        },
                        movementHistory: {
                            title: 'Stock movement history',
                            body: 'Display stock movement for each product',
                            link: 'Display stock movement'
                        }
                    },
                    withoutStock: {
                        affiliatedCenters: {
                            title: 'My affiliate centers',
                            body: 'Stocks in my affiliate centers',
                            link: 'Display stock'
                        },
                        procurement: {
                            title: 'Allocation',
                            body: 'Allocations in my affiliate centers',
                            link: 'Display allocations'
                        },
                        useDeclaration: {
                            title: 'Jab reporting',
                            body: 'Jab reporting in my affiliate centers',
                            link: 'Display number of jabs'
                        }
                    }
                }
            }
        },
        dispatchCenter: {
            stockReturn: 'Product return',
            affiliatedStock: 'My vaccination centers',
            productReturn: 'Product return',
            home: {
                title: 'Dispatch center {centerName}',
                tiles: {
                    enterStock: {
                        title: 'Inbound reception',
                        body: 'Enter information for inbound reception',
                        link: 'Stock booking'
                    },
                    exitStock: {
                        title: 'Pick & Pack / Shipping',
                        body: 'Dispatch to vaccination centers',
                        link: 'Outbound delivery'
                    },
                    scrapping: {
                        title: 'Scrapping',
                        body: 'Stock adjustment : broken vials, outdated vials, ...',
                        link: 'Scrap movement'
                    },
                    replenishment: {
                        title: 'Replenishment list',
                        body: 'Manage replenishment orders from vaccination centers',
                        link: 'Display replenishment list'
                    },
                    dashboard: {
                        title: 'Dashboard',
                        body: 'Display real time inventory',
                        link: 'Display dashboard'
                    },
                    movementHistory: {
                        title: 'Stock movement history',
                        body: 'Display stock movement for each product',
                        link: 'Display stock movement'
                    }
                }
            }
        },
        vaccinationCenter: {
            offlineHome: 'Home disconnected mode',
            offlineActions: 'Offline actions',
            exit: 'Consumption',
            reapprovisionnement: 'Replenishment',
            replenishmentList: 'Replenishment list',
            home: {
                title: 'Vaccination center {centerName}',
                tiles: {
                    enterStock: {
                        title: 'Stock entry',
                        body: 'Enter information about the products you receive',
                        link: 'Register stock entry'
                    },
                    exitFridge: {
                        title: 'Consumption',
                        body: 'Manage the use of cold chain jabs',
                        link: 'declare consumption'
                    },
                    declarationOfUse: {
                        title: 'Jab reporting',
                        body: 'Report the number of jabs performed for statistical purpose',
                        link: 'Register number of jabs performed'
                    },
                    replenishment: {
                        title: 'Replenishment request',
                        body: 'Send your replenishment requests to your dispatch center',
                        link: 'Replenishment request'
                    },
                    dashboard: {
                        title: 'Dashboard',
                        body: 'Check in real time the summary of stocks, movements, ...',
                        link: 'View dasboard'
                    },
                    movementHistory: {
                        title: 'Movement history',
                        body: 'View stock movements by product',
                        link: 'View stock movements'
                    }
                }
            }
        },
        vaccinationPoint: {
            home: {
                title: 'Vaccination point {centerName}',
                tiles: {
                    replenishment: {
                        title: 'Replenishment request',
                        body: 'Send your replenishment request to your dispatch center',
                        linki: 'Make a replenishement request'
                    },
                    replenishmentList: {
                        title: 'Replenishment request list',
                        body: 'View your replenishment requests',
                        link: 'View my requests'
                    }
                }
            },
            replenishment: 'Replenishment request',
            replenishmentList: 'Request history'
        },
        admin: {
            users: 'Users',
            centres: 'Centers',
            produits: 'Products',
            trackers: 'Trackers',
            contenants: 'Containers',
            transporteurs: 'Shipping partners',
            stocks: 'Global inventory',
            replenishmentList: 'Replenishment list from vaccination centers',
            vaccinPointReplenishmentList: 'Replenishment list form points',
            template: 'Template list',
            valueList: 'Value list',
            mail: 'Mail sending',
            home: {
                title: 'Administration Promedeo',
                tiles: {
                    users: {
                        title: 'Utilisateurs',
                        body: 'Saisissez les utilisateurs, leurs droits, profils, ...',
                        link: 'Gérer les utilisateurs'
                    },
                    centers: {
                        title: 'Centres',
                        body: 'Gérez les centres de dispatch, centre de vaccinations, ...',
                        link: 'Gérer les centres'
                    },
                    products: {
                        title: 'Produits',
                        body: 'Gérez les fiches produits disponibles dans l’application.',
                        link: 'Gérer les produits'
                    },
                    stocks: {
                        title: 'Stocks',
                        body: 'Consultez les stocks dans les centres, effectuer des corrections.',
                        link: 'Voir les stocks'
                    },
                    replenishments: {
                        title: 'Demandes',
                        body: 'Consultez les demandes de réapprovisionnement des centres.',
                        link: 'Voir les demandes'
                    },
                }
            }
        },
        section: {
            referentCenter: 'Stock center',
            dispatchCenter: 'Dispatch center',
            vaccinationCenter: 'Vaccination center',
            vaccinationPoint: 'Vaccination point',
            admin: 'Administration',
            stock: 'Stock'
        },
        common: {
            stockEntry: 'Stock entry',
            preparation: 'Pick & Pack',
            scrapping: 'Scrapping',
            replenishmentList: 'Replenishment list',
            tdb: 'Dashboard',
            movementHistory: 'Movement history',
            myStocks: 'My stock',
            procurement: 'Allocation',
            usage: 'Jab reporting',
            useDeclaration: 'Usage report',
        }
    },
    loginPage: {
        title: 'Promedeo',
        description: 'Please login',
        stayConnected: 'Remain logged in',
        forgottenPassword: 'Forgotten password?',
        connectBtn: 'Log in'
    },
    common: {
        dashboard: {
            productStatLabel: {
                enterStock: 'Stock entry',
                preparation: 'Pick & pack',
                exitFridge: 'Consumption',
                scrapping: 'Scrap',
                availableStock: 'Available stock :',
                day: 'Rolling 24 h',
                week: 'Rolling 7 days',
                month: 'Rolling 30 days'
            }
        },
        movementHistory: {
            search: {
                bl: 'Delivery Note number',
                destination: 'Destination',
                movementDate: 'Movement date',
                startDate: 'Start date (mm/dd/yyyy)',
                startTime: 'Start hour (hh:mm ',
                endDate: 'End date (mm/dd/yyyy)',
                endTime: 'End hour (hh:mm)'
            },
            table: {
                creationDate: 'Date',
                movementType: 'Movement type',
                numBl: 'Delivery Note number',
            }
        },
        scrapping: {
            scrappingCause: 'Scrapping root cause',
            scrappingComment: 'Comment',
            scrappingOtherOption: 'Other',
            formSection: {
                scrapping: 'Scrapping',
                product: '1. Sélect product',
                receiving: '2. Select batch',
                preparation: '2. Prepare scrapping'
            },
            form: {
                unitProduct: 'Unit of measure',
            },
            stockNotFound: 'No stock available for this product',
            success: 'Scrapping has been registered',
        },
        enterStock: {
            pageTitle: 'New stock entry',
            success: 'Stock entry has been registeres',
            warningIncoherentQuantity: 'If quantity received is different from quantity displayed, you need to scrap some quantities',
            blNotFound: 'No Delivery Note found',
            formSection: {
                origin: 'Select origin of stock',
                confirm: 'Confirm reception booking',
                referent: {
                    bl: 'Select Delivery Note',
                    product: 'Product information'
                },
                external : {
                    product: 'Select product',
                    receiving: 'enter batch',
                    quantity: 'enter quantity'
                },
            },
            form: {
                origin: 'Origin',
                compliantReception: 'Goods receipt OK',
                comment: 'Comment',
                containerPhrasing: '(Containing : 1 box of {nbUnitesBoite} {typeConditionnement}{nbUnitesBoite, plural, one {} other {s}})',
                enterDatetime: 'Date and time',
                enterDate: 'Date',
                enterTime: 'Time (hh:mm)',
                expirationMonth: 'Month of expiry',
                expirationYear: 'Year of expiry',
                isTransfert: 'Stock tranfer',
                transfertType: 'Transfer type',
                limit20Datetime: 'New expiry date -20°C',
                limit20Date: 'New expiry date -20°C',
                limit20Time: 'Time of new expiry date -20°C',
                limitDatetime: 'New expiry date',
                limitTime: 'Expiry date',
                exitTime: 'Time of new expiry date',
                referentBl: 'Delivery Note number',
            }
        },
        exitStock: {
            pageTitle: 'New Pick&Pack',
            formSection: {
                preparation: '1. Product Information',
                shipment: '2. Shipment'
            },
            form: {
                isDefrosted: 'With defrosting',
                destinationComment: 'Additionnal Information ',
                container: 'Container',
                containerNumber: 'Container Number',
                tracker: 'Temperature trackeur',
                trackerNumber: 'Trackeur number'
            },
            dialogAddProduct: {
                title: 'Add a product',
                formSection: {
                    product: '1. Sélect product',
                    preparation: '2. Product information'
                },
                form: {
                    defrosted: 'Defrost product',
                    refExterne: 'External reference',
                    exitDate: 'Defrosting time',
                },
                stockNotFound: 'No stock available for this product'
            },
            dialogDefrostedProduct: {
                title: 'Defrosted product are available (product return, transfer and/or coming from a stock center)',
                content: 'Defrost products (return, transfer and/or coming from a stock center) are available , do you want to use them ?'
            },
            dialogLabelSelection: {
                title: 'Label creation',
                lineDetails: '{nom} - {lot} {quantite} {modeQte}{modeQte, plural, one {} other {s}}',
                lot: 'batch {lot} -',
                fields: {
                    boxes: 'Boxes',
                    bottles: 'Units'
                },
                remaining: '{count} {count, plural, one {unité restante} other {unités restantes}}'
            },
            print: {
                cancelPreparationButtonLabel: 'Cancel Pick&Pack',
                cancelledPreparationWarning: 'The Pick&Pack has been cancelled',
                disabledFollowUp: ' Tracking has been disabled ',
                dialog: {
                    title: 'Confirmation of Pick&Pack cancellation',
                    content: 'Are you sure you want to cancel this PickPack? Pick&Pack cancellation automatically update the stock with the cancelled quantitites',
                },
                cancelSuccess: 'Pick&Pack cancellation has been processed',
                cancelError: 'An error occured, cancellation has not been processed',
            },
        },
        offlineMode: {
            warning: 'Warning, you are in disconnected mode, you need to synchronize before going offline.',
        }
    },
    global: {
        error: {
            validation: {
                required: 'Mandatory field',
                triggered: 'Some fields are incorrect ',
                startDateAfterEndDate: 'Start date cannot be later than end date',
                endDateBeforeStartDate: 'End date cannot cannot be earlier than start date',
                dateLaterToNow: 'Date cannot be later than today',
                datePriorToNow: 'Date cannot be earlier than today',
                datePriorToCurrentMonth: 'Date cannot be earlier than current month',
                weekDifferents: 'Weeks cannot be different',
                datePriorToReceipt: 'Date cannot be earlier than reception date',
                unstability: 'Product integrity cannot be garantied from the reception up until this date',
                positive: 'Value must be positive',
                tooLong: 'cannot exceed 250 character',
                emailFormat: 'email format is not compliant',
                oneOfTwo: 'Please complete at least one of the two fields',
                oneOfThree: 'Please complete at least one of the three fields',
                moreThanCurrentStock: 'Quantity is greater than current stock',
                invalidQuantity: 'Quantity is invalid',
            },
            occured: 'An error has occured',
            formError: 'The form contains errors',
            notFound: 'No result found',
            tooSmall: 'This page is not available for your screen resolution',
            quantityMax: 'Quantity is greater than curent stock',
            quantityMaxRows: 'Quantity is greater than current stock:',
            downloadFailed: 'Download failure',
            batchNotFound: 'Batch not found'
        },
        no_results: 'No result for your search',
        status: {
            label: 'Status',
            enabled: 'enabled',
            disabled: 'disabled',
            indifferent: 'Indifferent',
            confirme: 'Confirmed',
            traite: 'Processed',
            refuse: 'Refused',
            en_attente: 'On hold'
        },
        functionnal: {
            product: 'Product',
            dose: 'dose',
            batch: 'Batch',
            batchNumber: 'Batch number',
            quantity: 'Quantity',
            expiration: 'Expiry date',
            totalCount: 'representing {count} {type}{count, plural, one {} other {s}}',
            totalCountExplicit: 'representing {count} {type}{count, plural, one {} other {s}} in total',
            confirmCount: '(confirmed : {count} {type}{count, plural, one {} other {s}})',
            conditioningType: ' {conditioningType}{count, plural, one {} other {s}}',
            fromDateToDate: 'From {from} To {to}',
            weekFromDateToDate: 'Week of {from} au {to}',
            exitTime: 'Defrost time',
            usageLimitDate: 'Expiry date',
            usageLimitTime: 'Hour of expiry date',
            vaccinationCenter: 'Vaccination center',
            destination: 'Destination',
            carrier: 'Carrier',
        },
        space: ' ',
        noOptions: 'No résult',
        ellipsis: '...',
    },
    dispatch: {
        stock: {
            unitProduct: 'Unit',
            quantity: 'Quantity',
            productReturn: 'Product return',
            formSection: {
                productID: '1. Sélect product'
            },
            dialogReturnProduct: {
                title: ' Stock of returned products and unfrozen products available',
                content: 'Do you want to prepare products form returned or unfrozen stock?'
            }
        },
        enterStock: {
            isTransfert: 'Stock transfer'
        },
        exitStock: {
            replenishmentComment: 'Vaccination center comment',
            doseTotal: 'representing {count} doses',
            disableFollowUp: ' Disable tracking ',
            formSection: {
                replenishment: 'Information on replenishment request'
            },
            dialogTitle: 'Tracking disabled',
            dialogDescription: 'When tracking is disabled, the stock is not tracked in the destination vaccination centre: the stock is considered consumed when sent',
            dialogButtonLabel: 'Understood',
            dialogProcurementWarning: {
                title: 'Pick & Pack confirmation',
                content: 'The quantity entered (taking into account the quantities in demand) is greater than the quantity in allocation for this center over the week. Do you confirm the Pick & Pack?'
            },
            table: {
                header: {
                    exitDatetime: 'Defrost'
                },
                action: {
                    addProduct: '+ Add product'
                }
            },
        },
        replenishmentList: {
            search: {
                receptionDate: 'Reception date',
                creationDate: 'Creation date',
                startDate: 'Start date',
                startTime: 'Hour of start date',
                endDate: 'End date',
                endTime: 'Hour of end date',
                dateRole: 'Date type',
                labelType: 'Label type of vaccination center',
                labelText: 'label value'
            },
            wantedDate: 'Required reception date',
            coveragePeriod: 'Coverage period',
            confirmDate: '(confirmed: {date})',
            creationDate: 'Creation date',
            status: 'Status',
            quantity1: '1st jab : ',
            quantity2: '2nd jab : ',
            quantityR: 'booster jab : ',
            conditioningType: ' jab{count, plural, one {} other {s}}',
            dispatchComment: 'Dispatch comment',
            destinationComment: 'Vaccination comment',
            pointComment: 'Point comment',
            destination: 'Destination',
            point: 'Point',
            expedition: 'Shipping point',
            showFinished: 'View completed requests',
            cancelSuccess: 'Request has been canceled',
            updateStatusSuccess: 'The request status has been modified',
            updateMultipleStatusSuccess: 'status has been updated',
            updateStatusError: 'An error has occured, the status has not been updated',
            updateMultipleStatusError: 'An error has occured, status has not been updated',
            preparationSuccess: 'Pick & Pack have been completed',
            preparationError: 'An error has occured, Pick & Pack have not been completed',
            actions: {
                process: 'Process',
                processPoint: 'Processed',
                prepareReplenishment: 'Pick & Pack',
                confirm: 'Confirm',
                refuse: 'Reject'
            },
            dialog: {
                multipleBtn: 'Manage multiple requests',
                refuse: {
                    title: 'Reject replenishment request',
                    comment: 'cause of rejection'
                },
                cancel: {
                    title: 'Cancel replenishment request',
                    comment: 'Comment for cancellation',
                },
                process: {
                    quantiteConfirmee: 'Confirmed quantities : {quantity} {conditioningType}{quantity, plural, one {} other {s}}',
                    dateConfirmee: 'Confirmed date : {date}'
                },
                procurement: {
                    action: 'Allocation',
                    title: 'Allocation',
                    center: 'Center : {centerName}',
                    product: 'Product : {productName} ({conditioningType})',
                    lundi: 'Monday {date}',
                    mardi: 'Tuesday {date}',
                    mercredi: 'Wednasday {date}',
                    jeudi: 'Thursday {date}',
                    vendredi: 'Friday {date}',
                    samedi: 'Saturday {date}',
                    dimanche: 'Sunday {date}',
                    total: 'representing {quantity} {conditioningType}{quantity, plural, one {} other {s}} in the week',
                    residualPrevision: 'Remaining allocation :  {quantity} {conditioningType}{quantity, plural, one {} other {s}} in the week',
                    residualPrevisionExcept: 'Remaining allocation :  {quantity} {conditioningType}{quantity, plural, one {} other {s}} in the week. (except selected request)'
                },
                prepare: {
                    title: 'Pick & Pack',
                    form: {
                        product: '1. Selected product',
                        preparation: '2. Information on Pick & Pack ',
                        following: '3. Product tracking '
                    },
                    button: 'Pick & pack replenishment requests',
                    error: {
                        oneProduct: 'Replenishment requests should be for the same product',
                        status: 'Requests should not be processed or rejected'
                    }
                },
                cancelConfirmation: {
                    title: 'Cancel confirmation of replenishment request ',
                    message: 'Are you sure you want to cancel the confirmation of the replenishment request ?'
                },
                departure: {
                    button: 'departure of the goods',
                    errorStatus: 'Replenishment requests should be processed'
                },
                confirm: {
                    title: 'Replenishment request confirmation ',
                    body: 'Through the confirmation f multiple requests, you are about to confirm all the selected requests with their date and quantity',
                    button: 'Confirm replenishment request',
                    errors: {
                        status: 'Replenishment requests should be on hold',
                        date: 'Replenishment requests should have a reception date in the future'
                    }
                }
            },
            download: {
                title: 'Download you Pick & pack',
                description: 'You have performed multiple Pick & Pack. You can download the associated documents by clicking on the below button'
            },
            errors: {
                status: 'Update to this statu sis not possible',
                missingField: 'A product and a date must be selected to dispalay allocations',
                procurement: 'This center does not have a replenishment plan for the mentionned week',
            }
        },
        processing: {
            dialog: {
                title: 'Process request',
                exitStockContent: 'Replenishment request has been picked 1 packed, do you want to update the status to «processed»?',
                exitStockContentNotify: 'Vaccination center will be notified by mail',
                contentProduct: 'Product : {productName}',
                contentQuantity: 'Quantity : {quantity} {typeCond}{quantity, plural, one {} other {s}}',
                contentQuantity1: 'Quantity 1st jab : {quantity} dose{quantity, plural, one {} other {s}}',
                contentQuantity2: 'Quantity 2nd jab : {quantity} dose{quantity, plural, one {} other {s}}',
                contentQuantityR: 'Quantity boosterjab : {quantity} dose{quantity, plural, one {} other {s}}',
                contentTotalQuantity: 'Total : {quantity} {typeCond}{quantity, plural, one {} other {s}}',
                contentDestinationCenter: 'Destination : {destinationCenter}',
                contentCoveragePeriod: 'Coverage period : from {dateCouvertureDebut}  {heureCouvertureDebut} to {dateCouvertureFin}  {heureCouvertureFin}',
                carrier: 'Carrier : {carrier}',
                comment: 'Comment',
            }
        },
        departure: {
            dialog: {
                title: 'Departure of goods',
                disclaimer: ' Through goods departure, you confirm goods shipment',
                departureDatetime: "Departure date",
                departureDate: "Departure date",
                departureTime: "Hour of departure",
            }
        },
        confirm: {
            dialog: {
                disclaimer: 'With the request confirmation, a notification mail will be sent to the vaccination center',
                title: 'Request confirmation',
                receptionDatetime: "Reception date",
                receptionDate: "Reception date",
                receptionTime: "Hour of réception",
                comment: 'Comment',
                sections: {
                    initial: 'Initial request',
                    confirmed: ' Confirmed request'
                },
                warningTitle: 'Request confirmation',
                warningDesc: 'The quantity is grater to the quantity in allocation for this week. Do you confirm the quantity ?'
            }
        },
        productReturn: {
            form: {
                sections: {
                    selectVaccinationCenter: '1. Sélect vaccination center',
                    selectProduct: '2. Select product',
                    selectBl: '3. Select delivery note',
                    identifyBatch: '4. Select batch',
                },
                inputs: {
                    blNumber: 'Delivery Note number',
                    deliverySlipNumber: 'Delivery note number',
                    returnComment: 'Comment on return'
                },
                warning: 'The pre-filled quantity is the quantity remaining in the vaccination center',
                blListNotFound: 'No Delivery Note available for this product and center'
            },
            dialog: {
                title: 'Confirmation of product return',
                content: 'The quantity is greater than the residual quantity in the vaccination center. Do you confirm the return ?'
            }
        }
    },
    center: {
        name: 'Name',
        type: 'Type',
        status: 'Status',
        numFiness: 'Finess number',
        service: 'Departùent',
        gestionStock: 'Inventory management',
        desactivationSuivi: 'Tracking disabled by default',
        modeOffline: 'Disconnected mode',
        siret: 'Siret',
        ape: 'APE',
        complementDistrib: 'Aditionnal adress information',
        libelleRoutage: 'Routing',
        telephone: 'Téléphone',
        email: 'Email',
        capacite: 'Capacity',
        adresse: 'Address',
        codePostal: 'Post code',
        ville: 'City',
        referent: 'Stock center',
        users: 'Search user',
        childrenCenters: 'Child centers',
        tags: 'Tags',
        notifyReasons: 'Notify recipient for :',
        destinataireEmail: ' Mail destination ',
        modeQte: 'Allocation type of entry',
        alertRef: 'This center is linked to {centerNames}',
        alertNoDispatch: 'Make sure this center is linked to a dispatch center',
        alertNoVaccination: 'Make sure this center is linked to a vaccination center',
        missingType: 'Select type of center before selecting preferences',
        defaultCarrier: 'Default carrier',
        modeAdmin: 'Mode of jab reporting',
        dialog: {
            title: 'Add a category',
            category: 'Catégory',
            categoryLabel: 'Catégory name',
            value: 'Value',
            tagAlreadyExists: 'Category already exists',
            actions: {
                cancel: 'CANCEL',
                confirm: 'ADD'
            }
        },
        formSection: {
            information: 'Center information',
            adresse: 'Address',
            users: 'Users',
            childrenCenters: 'Child centers',
            preferences: 'Preferences'
        },
        subSections: {
            mails: 'Mails',
            templates: 'Templates'
        },
        updateStatusSuccess: 'Center status has been updated',
        updateStatusError: 'An error has occured, center status has not been updated',
        updateSuccess: 'Centre has been modified',
        updateError: 'An error has occured, center has not been updated',
        createSuccess: 'Center has been created',
        createError: 'An error has occured, center has not been created',
        pageTitle: {
            create: 'New center',
            update: 'Modify center'
        },
        notUniqueNumFiness: 'Finess number must be unique'
    },
    tracker: {
        name: 'Name',
        manufacturer: 'Manufacturer',
        type1: 'Type 1',
        type2: 'Type 2',
        temperatureInterval: 'Température range',
        fabricant: 'Manufacturer',
        nom: 'Name',
        minTemperature: 'Minimum temperature',
        maxTemperature: 'Maximum temperature ',
        updateStatusSuccess: 'Trackeur status has been modified',
        updateStatusError: 'An error has occured, tracker status has not been updated',
        updateSuccess: 'Tracker has been updated',
        updateError: 'An error has occured, tracker has not been updated',
        createSuccess: 'Tracker has been created',
        createError: 'An error has occured, tracker has not been created',
        formSection: {
            information: 'Tracker information',
            temperatureLimit: 'Temperature limits',
        },
        pageTitle: {
            create: 'New tracker',
            update: 'Update tracker'
        },
        notUniqueNom: 'Manufacturer/name must be unique'
    },
    container: {
        name: 'name',
        manufacturer: 'Manufacturer',
        type: 'Type',
        size: 'Size',
        weight: 'Weight',
        capacity: 'Capacity',
        fabricant: 'Manufacturer',
        nom: 'Name',
        taille: 'Size',
        poids: 'Weight',
        capacite: 'Capacit',
        updateStatusSuccess: 'Container status has been updated',
        updateStatusError: 'An error has occured, container status has not been updated',
        updateSuccess: 'Container has been modified',
        updateError: 'An error has occured, container has not been updated',
        createSuccess: 'Container has been created',
        createError: 'An error has occured, container has not been created',
        formSection: {
            information: 'Container information',
            characteristics: 'Characteristics',
        },
        pageTitle: {
            create: 'New container',
            update: 'Update a container'
        },
        notUniqueNom: 'Manufacturer/name must be unique'
    },
    carrier: {
        name: 'Name',
        centerName: 'Center',
        siret: 'Siret',
        address: 'Adress',
        phone: 'Telephone',
        contact: 'Contact',
        nom: 'Name',
        adresse: 'Address',
        complementAdresse: 'Address complement',
        codePostal: 'Postcode',
        ville: 'City',
        pays: 'Country',
        telephone: 'Téléphone',
        mailContact: 'Email',
        updateStatusSuccess: 'Carrier status has been modified',
        updateStatusError: 'An error has occured, carrier status has not been updated',
        updateSuccess: 'Carrier has been modified',
        updateError: 'An error has occured, carrier has not been updated',
        createSuccess: ' Carrier has been created',
        createError: 'An error has occured, carrier has not been created',
        formSection: {
            information: 'Carrier information',
            address: 'Address',
            coordinates: 'Coordinates',
        },
        pageTitle: {
            create: 'New carrier',
            update: 'Update carrier'
        },
        notUniqueNom: 'Name must be unique',
        notUniqueSiret: 'Siret must be unique',
        dispatchCenter: 'Dispatch center'
    },
    product: {
        type: 'Product type',
        name: 'Name',
        codeCip: 'CIP code',
        codeUcd: 'UCD code',
        formePharma: 'Pharmaceutical form',
        preservation: 'Conservation',
        typeCond: 'Packaging typr',
        nbunitBoite: 'Number of units per box',
        nbDoses: 'Number of doses',
        nomCommercial: 'Commercial name',
        nom: 'Name',
        surveillanceRenforcee: 'Enhanced surveillance',
        substanceActive1: 'Active ingredient  #1',
        substanceActive2: 'Active ingredient #2',
        voieAdministration: 'Mode of administration',
        peremption: 'Expiry date format',
        conservation: 'Conservation',
        stabilite28: 'Stability period (h) between 2 and 8°C',
        stabiliteMiseTemperature: 'Stability period after out of fridge',
        stabiliteReconstitution: 'Stability period after solution preparation',
        stabilite1erPrelevement: 'Stability period after 1st picking',
        stabilite28TransfertMoins20: 'Stability period (h) 2-8°C (Transfer -20°C)',
        dureeDecongelation: 'Defrosting time (h)',
        typeConditionnement: 'Packaging type',
        nbUnitesBoite: 'Number of units per box',
        commentaireBl: 'Delivery Note comment',
        updateSuccess: 'Product has been updated',
        updateError: 'An error has occured, product has not been updated',
        updateStatusSuccess: 'Product status has been modified',
        updateStatusError: 'An error has occured, status has not been modified',
        createSuccess: 'Product has been created',
        createError: 'An error has occured, Product has not been created',
        nbDosesError: 'Number of doses must contain maximum 3 figures and only 1 decimal',
        formSection: {
            information: 'Product information',
            constitution: 'Constitution',
            conservation: 'Conservation',
            conditioning: 'Packaging',
        },
        pageTitle: {
            create: 'New product',
            update: 'Update product'
        },
        notUniqueCip: 'CIP code must be unique'
    },
    procurement: {
        title: 'Allocation',
        fieldsLabel: {
            vaccinationLocation: 'Vaccination location',
            date: 'Week',
            modeQteLabel: 'Display in :',
            modeQte: 'Dose / Vial'
        },
        listLabels: {
            vaccinationLocation: 'Vaccination location',
            total: 'doses per week',
            conditioningType: 'Packaging type'
        },
        add: {
            sections: {
                weekSelect: '1. Select week',
                centerSelect: '2. Select center',
                productSelect: '3. Select product',
                quantityTyping: '4. Enter allocation',
            },
            noProductsAvailable: 'No Product to add.'
        },
        warning: 'This is only an allocation. You need to create a replenishment request to get tour product',
        updateSuccess: 'Allocation has been modified',
        countDoses: 'representing {count} {type}{count, plural, one {} other {s}} for the week',
        createReplenishment: 'Generate replenishment requests',
        import: 'Import allocations',
        createReplenishmentSuccess: 'replenisment request generation has been processed',
        createReplenishmentError: 'An error has occured, generation of replenishment requests has not been performed',
        export: {
            1: 'Details for one week',
            2: 'Details for two weeks',
            5: ' Details for five weeks'
        },
        exportCumul: {
            1: 'Cumulated for one week',
            2: 'Cumulated for two weeks',
            5: 'Cumulated for five weeks'
        },
        duplicate: {
            title: 'Duplication of allocation',
            infoSection: 'Allocation information',
            weeksSection: 'Duplicate for the next weeks',
            weeksInfo: 'You are ready to duplicate allocation for the next weeks. For how many weeks do you want to duplicate ?',
            nbWeeks: 'Number of weeks',
            weeksMax: 'You cannot duplicate beyond 5 weeks',
            btn: 'Duplicate',
            errorExistingProcurement: 'Allocationalready exists for the next weeks',
            success: 'Allocation has been duplicated',
            error: 'An error has occured, allocation has not been duplicated'
        },
        quantity: {
            Q: 'Quantity',
            D1: 'D1',
            D2: 'D2'
        },
        importDialog: {
            title: 'Import allocations',
            general: 'Import information',
            file: 'Data to import',
            warning: 'warning, import of a new file will overwrite existing data',
            model: 'Modèle : ',
            fields: {
                period: 'Import type',
                qtyMode: 'Packaging type',
                startWeek: 'First week',
                file: 'File'
            },
            errors: {
                title: 'Import errors',
                line: 'Line {line} : {errorMessage}',
                emptyFiness: 'Finess empty',
                emptyProduit: 'product empty',
                notFoundFiness: 'Finess unknown',
                notFoundProduit: 'product unknown',
                csvVide: 'File is empty'
            },
            success: 'Allocations have been imported',
            error: 'Import error, allocations have not been imported',
            confirm: 'Import'
        }
    },
    administrationTracking: {
        filters: {
            location: 'Vaccination location',
            administrationMode: 'Mode of administration',
            week: 'Administration week',
            startWeek: '1st week'
        },
        headers: {
            location: 'Vaccination location'
        },
        fields: {
            mode: {
                label: 'Display by',
                value: 'Week / Day'
            }
        }
    } ,
    template: {
        fieldsLabel: {
            nom: 'Name',
            type: 'Type',
            statut: 'Status',
            publie: 'Only display published templates',
            published: 'Published',
            unpublished: 'Unpublished',
            creationDate: 'Creation date',
            creator: 'Creator',
            file: 'File'
        },
        create: {
            formSection: {
                information: 'Information'
            }
        },
        alert: {
            fileInfoCreate: 'Format file must be .docx',
            fileInfoUpdate: 'This file will overwrite the previous file. Format file must be .docx'
        },
        category: {
            referent: 'Template stock center',
            dispatch: 'Template dispatch center',
            vaccination: 'Template vaccination center',
        },
        createTemplateSuccess: 'Template has been created',
        createTemplateError: 'An error has occured, template has not been created',
        updateTemplateSuccess: 'Template has been updated',
        updateTemplateError: 'An error has occured, template has not been updated',
        updateStatusSuccess: 'Yemplate status has been updated',
        updateStatusError: 'An error has occured, template status has not been updated',
        notUniqueNom: 'Name must be unique'
    },
    vaccination: {
        enterStock: {
            deliverySlipNumber: 'Delivery Note Number',
            formSection: {
                slipNumber: '1. Select Delivery Note',
                identifyBatch: '2. Product information',
                receptionConfirmation: '3. Confirm reception'
            },
            blNotFound: 'No Delivery Note found',
            success: 'Stock entry has been processed',
            successOffline: 'Stock entry has been saved offline',
            error: 'an error has occured, stock entry has not been created',
            warningIncoherentQuantity: 'If quantity received is different from quantity displayed, you need to scrap some quatities',
            compliantReception: 'Goods receipt OK',
            comment: 'Comment'
        },
        exitFridge: {
            formSection: {
                batchNumber: '2. Select  batch / Delivery Note',
                exitSelection: '3. Comsumption type',
                usingProduct: '4. Select quantity',
            },
            batchNumber: 'Batch / Delivery Note number',
            batchNotFound: 'There is no batch / Delivery Note with this number',
            success: 'Consumption has been processed',
            successOffline: 'Consumption has been saved offline',
            error: 'an error has occured, consumption has not been processed',
            vaccinationPoint: 'Vaccination point',
            sortieType: {
                INTERNE: 'Internal',
                VACCINATION_POINT: 'Vaccination point'
            },
            seringue: 'Syringe',
            flaconConsumed: 'consumed vial',
            flacon: 'vial'
        },
        declarationOfUse: {
            form: {
                sections: {
                    product: '1. Select product',
                    week: '2. Select week of vaccination',
                    day: '2. Select day of vaccination',
                    oldQuantity: '3. Enter number of jabs',
                    futureQuantity: '3. Enter allocated quantity'
                },
                fields: {
                    week: 'week of vaccination',
                    date: 'Date of vaccination',
                    doseCount1: 'Number of jabs 1st dose',
                    doseCount2: 'Number of jabs 2nd dose',
                    doseCount3: 'Number of jabs booster dose',
                    singleDoseCount: 'Number of jabs simgle dose',
                },
                oldWarning: 'according to the period, quantities are real quantities',
                futureWarning: 'according to the period, quantities are allocation quantities',
            },
            success: 'Jabs reporting has been processed',
            error: ' an error has occured, jabs reporting has not been processed'
        },
        scrapping: {
            formSection: {
                batchNumber: '2. Select batch',
                usingProduct: '3. Enter quantity',
            },
            success: 'Scrapping has been processed',
            successOffline: 'Scrapping has been saved offline',
            error: 'An error has occured, srapping has not been processed'
        },
        replenishment: {
            datetimeWanted: 'Requested reception date',
            dateWanted: 'Requested reception date',
            timeWanted: 'Hour of requested reception date',
            coveragePeriod: 'Coverage period',
            datetimeCoverageStart: 'Start date',
            dateCoverageStart: 'Start date',
            timeCoverageStart: 'Hour of Start date',
            datetimeCoverageEnd: 'End date',
            dateCoverageEnd: 'End date',
            timeCoverageEnd: 'End date',
            datePriorToTomorrow: 'Date cannot be prior to tomorrow',
            comment: 'Comment',
            modeQte: 'Input mode',
            quantity: 'Quantity',
            quantity1: '1st jab',
            quantity2: '2nd jab',
            quantityR: 'Booster jab',
            formSection: {
                info: '2. Requirement',
                comment: '3. Comment',
                carrier: 'Select carrier'
            },
            success: 'Replenishment request has been processed',
            error: 'An error has occured, replenishment request has not been created',
            finishedBtn: 'to be processed',
            showPrevision: 'Display weekly allocations',
            dialog: {
                title: 'Replenishment request confirmation',
                actionNo: 'No',
                actionYes: 'Yes',
                doYouConfirm: 'Do you confirm this request',
                summary: 'You are about to create a replenishment request of {productCount} {conditioningType}{productCount, plural, one {} other {s}} for {date}.'
            },
            warningTitle: 'Replenishment request',
            warningDesc: 'Quantity entered is greater than the allocated quantity for the center this week. Do you confirm this request ?'
        },
        movementHistory: {
            search: {
                center: 'Dispatch center',
                bl: 'Delivery Note number',
                destination: 'Dispatch center',
                movementDate: 'movement date',
                startDate: 'Start date',
                startTime: 'Hour of start date',
                endDate: 'End date',
                endTime: 'Hour of end date'
            },
            table: {
                creationDate: 'Date',
                movementType: 'Movement type',
                numBl: 'Delivery Note number',
            }
        },
        synchronisation: {
            emptyDataTitle: 'Synchronize data',
            receivedDataTitle: 'Data available offline',
            emptyData: 'No data available offline, please synchronize.',
            bl: 'Available Delivery Notes : {bl}',
            stock: 'Available stocks : {stock}',
            lastRefresh: 'Last synchronisation : {datetime}',
            waitingActionsTitle: 'In the waiting list to send to the server',
            synchroniseSuccess: 'Data have bean saved offline, you can proceed with offline actions.',
            waitingCard: {
                bl: 'BL : {bl}',
                comment: 'Comment : {comment}',
                bySeringue: '({quantity})',
                exitType: 'Consumption : {exitType}'
            }
        }
    },
    stockEdit: {
        dialog: {
            title: 'Stock editing',
            stockCurrent: 'Current stock',
            quantity: 'Quantity (+/-)',
            quantityAfterward: 'Stock afterwards',
            expiration: 'Current expiry date : {expiration}',
            expirationMonth: 'Month of expiry date',
            expirationYear: 'Year of expiry date',
            comment: 'Comment',
            updateSuccess: 'Stock has been updated',
            formSection: {
                quantity: 'Quantity',
                expiration: 'Expiry management',
                comment: 'tracking update'
            }
        },
        isVisibleOutOfStock: 'Display stock exhausted',
        onlyRetour: 'Only display returns',
        referentCenters: 'Stock center',
        dispatchCenters: 'Dispatch center',
        vaccinationCenters: 'Vaccination center',
        dataTable: {
            centerName: 'Center',
            usageLimit: 'Expiry',
            deliverySlipNumber: 'Delivery Note',
            seePrint: 'See return note'
        }
    },
    mail: {
        form: {
            fieds: {
                title: {
                    label: 'Subject'
                },
                body: {
                    label: 'Mail'
                }
            },
            success: 'Mail has been sent',
            error: 'An error has occured, mail has not been sent'
        }
    },
    button: {
        test: 'Test',
        download: 'Download',
        publish: 'Publish',
        unpublish: 'Unpublish',
        confirm: 'Confirm',
        validate: 'Validate',
        cancel: 'Cancel',
        save: 'Save',
        create: 'Create',
        delete: 'Delete',
        update: 'Update',
        disable: 'Disable',
        enable: 'Enable',
        search: 'Search',
        sendNeedsNotification: 'Send requirement notification',
        export: 'Export results',
        refuse: 'Reject',
        return: 'Return',
        send: 'Send',
        sendError: 'Errors',
        yes: 'Yes',
        no: 'No',
        synchronise: 'Synchronize',
        resynchronise: 'Resynchronize',
        transfer: 'Transfer',
        print: {
            initial: 'Print',
            BL: 'Delivery Note',
            label: 'Shipping labels',
            labelScrapping: 'Scrap labels',
            bottle36: 'Vial labels',
            contentLabel: 'Container labels',
            return: 'Return note'
        }
    },
    select: {
        all: 'All',
        none: 'None',
        default: 'Default'
    },
    enum: {
        centerType: {
            REFERENT: 'Stock center',
            DISPATCH: 'Dispatch center',
            VACCINATION: 'Vaccination center',
            VACCINATION_POINT: 'Vaccination point'
        },
        movementType: {
            ENTREE: 'Stock entry',
            REASSORT: 'Replenishment',
            TRANSFERT: 'Transfer',
            PREPARATION: 'Pick & Pack',
            SORTIE_FRIGO: 'Internal consumption',
            SORTIE_POINT: 'Send to point',
            DECHET: 'Scrap',
            CORRECTION: 'Stock update',
            RETOUR: 'Product return',
            MODIF_PEREMPTION: 'Update expiry date'
        },
        days: {
            MONDAY: 'Monday',
            TUESDAY: 'Tuesday',
            WEDNESDAY: 'Wednesday',
            THURSDAY: 'Thursday',
            FRIDAY: 'Friday',
            SATURDAY: 'Saturday',
            SUNDAY: 'Sunday',
        },
        months: {
            JANUARY: 'January',
            FEBRUARY: 'February',
            MARCH: 'March',
            APRIL: 'April',
            MAY: 'May',
            JUNE: 'June',
            JULY: 'July',
            AUGUST: 'August',
            SEPTEMBER: 'September',
            OCTOBER: 'October',
            NOVEMBER: 'November',
            DECEMBER: 'Décember',
        },
        replenishmentStatus: {
            EN_ATTENTE: 'On hold',
            TRAITE: 'Processed',
            DEPART_MARCHANDISES: 'Goods departure',
            CONFIRME: 'Confirmed',
            REFUSE: 'Refused',
        },
        dateRole: {
            creation: 'Creation date',
            reception: 'Reception date'
        },
        receiverMail: {
            REFERENT: 'Referent',
            CENTER: 'Mail of the center',
            BOTH: 'both'
        },
        tagCategories: {
            TRANSPORTEUR: 'Carrier',
            TYPE_CENTRE: 'Type of center',
            TOURNEE: 'Tour',
            Secteur: 'Area',
            AUTRE: 'Other'
        },
        tagCategoriesLabel: {
            TRANSPORTEUR: 'Carrier : {value}',
            TYPE_CENTRE: 'Type of center : {value}',
            TOURNEE: 'Tour : {value}',
            SECTEUR: 'Area : {value}',
            AUTRE: 'other ({label}) : {value}'
        },
        mailReasons: {
            RECEPTION_STOCK_TO_REFERENT: 'Reception booking by child stock center',
            BESOIN_APPROVISIONNEMENT: 'replenishment requirement',
            BESOIN_APPROVISIONNEMENT_POINT: 'Replenishment requirement from a point',
            RECEPTION_STOCK_VACCINATION: 'Reception booking by vaccination center',
            TRAITEMENT_APPROVISIONNEMENT: 'Processing of replenishment request',
            CONFIRMATION_APPROVISIONNEMENT: 'Confirmation of replenishment request',
            REFUS_APPROVISIONNEMENT: 'Rejection of replenishment request',
            ANNULATION_APPROVISIONNEMENT: 'Cancellation of replenishment request',
            ANNULATION_APPROVISIONNEMENT_POINT: 'Cancellation of a replenishment request from a point'
        },
        templateTypes: {
            REFERENT_BL: 'Delivery Note',
            REFERENT_ETIQUETTE: 'Shipping label',
            REFERENT_FLACON: 'Vial label',
            REFERENT_ETIQUETTE_CONTENANT: 'Container label',
            REFERENT_DECHET: 'Scrap label stock center',
            DISPATCH_BL: 'Delivery Note',
            DISPATCH_ETIQUETTE: 'Shipping label',
            DISPATCH_FLACON_36: 'Vial label 36',
            DISPATCH_ETIQUETTE_CONTENANT: 'Container label',
            DISPATCH_DECHET: 'Scrap label dispatch',
            DISPATCH_RETOUR: 'Return Note',
            VACCINATION_ETIQUETTE: 'Shipping label point point',
            VACCINATION_DECHET: 'Scrap label vaccination'
        },
        qtyMode: {
            DOSE: 'By dose',
            CONDITIONNEMENT: 'By vial'
        },
        exitMode : {
            FLACON: 'by vial',
            SERINGUE: 'by syringe'
        },
        productTypes: {
            VACCIN: 'Vaccine'
        },
        transfertType: {
            MOINS_20: '-70°C => -20°C',
            CONGELE: 'Frozen vaccines',
            DECONGELE: 'Defrost vaccines'
        },
        transfertTypeInStock: {
            MOINS_20: '-70°C => -20°C',
            CONGELE: 'Frozen transfer',
            DECONGELE: 'Defrost vaccines'
        },
        adminMode: {
            JOUR: 'By day',
            SEMAINE: 'By week'
        },
        procurementImportType: {
            JOUR: 'For 35 days',
            SEMAINE: 'for 10 weeks'
        },
        typeCondPoint: {
            SERINGUE: 'syringe'
        },
        expirationType: {
            JOUR: 'By day',
            MOIS: 'By month'
        },
        origin: {
            REFERENT: 'From my stock center',
            EXTERNAL: 'External'
        },
        waitingAction: {
            ENTRY: 'Stock entry',
            EXIT: 'Consumption',
            SCRAPPING: 'Scrapping',
            UPDATE_STOCK: 'Stock update'
        }
    },

    // Intégration clé isotope
    password: {
        errors: {
            no_match: 'Password and confirmation do not match',
            odlPasswordFailed: 'Password do not match with current password',
            newPasswordContainsOld: 'Password cannot contain old password',
            containsInfo: 'Password cannot contain personal information',
            nbCharacters: 'Password must contain at least {0} characters',
            formatPassword: 'Password must contain at least ' +
                '{0} lowercase {0, plural, =0 {} one {} other {s}}, {1} uppercase' +
                '{1, plural, =0 {} one {} other {s}}, {2} figure' +
                '{2, plural, =0 {} one {} other {s}} and {3} ' +
                '{2, plural, =0 {special character} one {caractère spécial} other {special characters}}'
        }
    },
    TABLE_SELECT_ALL: 'Sélectionnez tout',
    isotope: {
        datatable: {
            header: {
                selected: '{numSelected, plural, =0 {No element selected} one {1 element selected} other {# elements selected}}'
            },
            footer: {
                elementsPerPage: 'Elements per page :',
                pagination: '{startIndex, number} - {endIndex, number} out of {total, number}'
            }
        },
        forgottenUserPassword: {
            dialog: {
                title: 'Forgotten password',
                success: 'Your request has been submitted. You will receive a mail',
                description: 'In order to reset your password, enter your email',
                login: 'Login',
                email: 'Email'
            },
            changePassword: {
                title: 'Change password',
                password: 'Password',
                confirmPassword: '¨Password confirmation',
                errorMatchingEmail: 'Password confirmation is different from password',
                success: 'Password modification has been processed'
            }
        }
    },
    valueList: {
        recherche: 'Search',
        valeurs: 'Values',
        form: {
            name: 'Name',
            shortcut: 'Code',
            description: 'Description'
        },
        entries: {
            valeurs: 'Values',
            actif: 'Enabled',
            inactif: 'Disabled'
        },
        popin: {
            add: 'Add value',
            edit: 'Edit value',
            code: 'Code',
            inactif: 'Disabled',
            langue: 'Libellé {codeLang}'
        }
    },
    users: {
        lastname: 'Name',
        firstname: 'First name',
        login: 'Login',
        email: 'Mail',
        phoneNumber: 'Phone number',
        company: 'Company',
        profile: 'Profile',
        status: {
            header: 'Status',
            active: 'Enabled',
            inactive: 'Disabled'
        },
        query: 'Search',
        showDisabled: 'Display disabled users',
        search: 'Search',
        selectAll: 'All',
        password: 'Password',
        passwordConfirm: 'Password confirmation',
        actions: {
            updateStatusSuccess: 'User status has bee,n modified',
            updateStatusError: 'An error has occured, user status has not been updated',
            updateSuccess: 'User has been updated',
            createSuccess: 'User has been created',
            errors: {
                loginExists: 'Login already exists',
                emailExists: 'Mail already exists'
            }
        },
        form: {
            titleUpdate: 'Update user',
            titleCreate: 'Add user'
        }
    },
    btn: {
        save: 'Save',
        otherSave: 'Save',
        edit: 'Edit',
        confirm: 'Confirm',
        cancel: 'Cancel',
        validate: 'Validate',
        return: 'Return',
        import: 'Import',
        export: 'Export',
        delete: 'Delete',
        logout: 'Logout',
        account: 'My account',
        home: 'home'
    },
    notify: {
        success: 'Information registered',
        error: 'An error has occured',
        info: 'information message'
    },
    validation: {
        mandatory: 'Mandatory field',
        notEmpty: 'This field cannot be empty',
        notModified: 'No field has been modified  or password in incorrect',
        notValidEmail: 'Invalid email'
    },
    typeMismatch: 'Incorrect value',
    NotEmpty: 'Mandatory field',
    NotNull: 'Mandatory field',
    form: {
        needInformation: '(*) mandatory fields',
        lastname: 'Name *',
        firstname: 'First name *',
        mail: 'Email *',
        actualPassword: 'Current password',
        password: 'New password',
        passwordRepeated: 'Confirm new password',
        error: {
            firstname: 'First name missing',
            lastname: 'Name missing',
            email: 'Email missing',
            password: 'old password required',
            validNewPassword: 'Does not match with new password'
        }
    },
    snackMessage: {
        saveOk: 'Information has been saved'
    },
    title: {
        personalInformation: 'Personal information',
        newPassword: 'Change password'
    }
}

export default flatten<typeof en, ModelTranslations>(en)
