import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldInputProps } from 'react-final-form'
import { CenterDetailsDataModel } from '../services/centerDetailsDataModel'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			float: 'left',
			position: 'relative',
			display: 'block',
			lineHeight: '24px'
		},
		textFieldInput: {
			marginTop: 15,
			backgroundColor: theme.palette.common.white,
			fontSize: theme.typography.body1.fontSize,
			padding: '4px !important',
			border: '1px solid',
			borderColor: '#919191',
			borderRadius: 0
		}
	})
)

interface AutocompleteMultipleCenterProps {
	options: CenterDetailsDataModel[]
	input: FieldInputProps<any>
}

const AutocompleteMultipleCenter: React.FC<AutocompleteMultipleCenterProps> = (
	{
		options,
		input
	}
) => {
	const intl = useIntl()
	const classes = useStyles()

	return <>
		<label className={classes.label}>
			<Typography variant="body1">{intl.formatMessage({ id: 'center.childrenCenters' })}</Typography>
		</label>
		<Autocomplete
			id="multiple-center"
			options={options}
			getOptionLabel={(option) => `${option.nom}${option.service ? ` - ${option.service}` : ''}`}
			defaultValue={input.value}
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
					{...params}
					label=""
				/>
			)}
			onChange={(ev, value) => input.onChange(value)}
			noOptionsText={<FormattedMessage id="global.noOptions" />}
			classes={{
				inputRoot: classes.textFieldInput
			}}
			multiple
		/>
	</>
}

export default AutocompleteMultipleCenter
