import { fetchFactory } from 'isotope-client'
import { CreateTemplateModel, UpdateTemplateModel, UpdateTemplateStatusModel } from './templateModels'
import { postMultipart } from '../../../../utils/fetch'
import { fetchDownload } from '../../../../utils/fetchDownload'

export const updateTemplateStatus = (values: UpdateTemplateStatusModel) => fetchFactory('/template/publication', {
    method: 'PUT',
    body: JSON.stringify(values)
})

export const createTemplate = (values: CreateTemplateModel) => postMultipart('/template', {
    method: 'POST',
    body: values
})

export const updateTemplate = (values: UpdateTemplateModel) => postMultipart('/template', {
    method: 'PUT',
    body: values
})

export const getTemplateByID = (id: string) => fetchFactory(`/template/${id}`)

export const testTemplate = (id: string) => fetchDownload(`/template/${id}/test`)

export const getTemplateDocument = (id: string) => fetchDownload(`/template/${id}/download`)
