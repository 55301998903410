import React, { useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import { FormInput, FormSelect } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { FormApi } from 'final-form'
import moment from 'moment'
import { compose } from 'redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import FieldAutocomplete from '../../../components/fields/FieldAutocomplete'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import { SCREEN_SIZE, TRANSFERT_TYPE } from '../../../utils/constants'
import { CentreModel } from '../../common/phidemData/phidemDataModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import FieldProduct from '../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../components/fields/FieldUnitProduct'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import FieldQuantity from '../../../components/fields/FieldQuantity'
import { DispatchBlModel, DispatchLigneBlModel, DispatchMouvementModel, DispatchStockLigneModel } from '../services/dispatchModels'
import DialogProductReturnQtyWarning from './DialogProductReturnQtyWarning'
import { getBlLast15Days, getStockLine, postRetourStock } from '../services/dispatchApi'
import Loader from '../../../components/layout/Loader'
import Button from '../../../components/layout/buttons/Button'
import ErrorPositiveValue from '../../../components/layout/errors/ErrorPositiveValue'
import { compareLimitDate } from '../../../utils/formUtils'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			marginBottom: 80,
			display: 'flex'
		}
	})
)

interface FormValues {
	idVaccinationCenter: string
	idProduct: string
	unitProduct: string
	idLigneBl: string
	quantity: number
	batchNumber: string
	exitFridgeDatetime: string
	usageLimitDatetime: string
	forceValidation: boolean // used for logic not for input
	returnComment: string
}

interface ProductReturnProps {
	snackError: (value: any) => void
}

const ProductReturn: React.FC<ProductReturnProps> = (
	{
		snackError
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { user: { selectedCenter } } = useContext(PhidemDataContext)
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [blLines, setBlLines] = useState<DispatchLigneBlModel[]>([])
	const [dosesCount, setDosesCount] = useState<number>(0)
	const [showAllSections, setShowAllSections] = useState<boolean>(false)
	const [isOpenDialogQtyWarning, setIsOpenDialogQtyWarning] = useState<boolean>(false)
	const [isLoadingSection3, setIsLoadingSection3] = useState<boolean>(false)
	const [isLoadingSection4, setIsLoadingSection4] = useState<boolean>(false)

	const openDialogQtyWarning = () => setIsOpenDialogQtyWarning(true)

	const closeDialogQtyWarning = () => setIsOpenDialogQtyWarning(false)

	const clearFieldsSection34 = (form: FormApi<FormValues>) => {
		form.change('idLigneBl', undefined)
		form.change('quantity', undefined)
		form.change('batchNumber', undefined)
		form.change('exitFridgeDatetime', undefined)
		form.change('usageLimitDatetime', undefined)
	}

	const fetchStockLine = (idVaccinationCenter: string, idLigneBl: string, form: FormApi<FormValues>, blLinesCall?: DispatchLigneBlModel[]) => {
		const selectedLigneBl = (blLinesCall || blLines).find((blLine: DispatchLigneBlModel) => blLine.id === idLigneBl)

		setIsLoadingSection4(true)
		getStockLine(idLigneBl, idVaccinationCenter)
			.then((response: DispatchStockLigneModel) => {
				const dosesTotal = (selectedLigneBl?.stock?.produit?.nbDoses || 0) * (response.quantiteStock || 0)

				form.change('quantity', response.quantiteStock)
				setDosesCount(dosesTotal)
			})
			.finally(() => {
				const exitDate = selectedLigneBl?.dateSortie
				// Durée de stabilité selon le stock
				// - Stock classique = stabilite28
				// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
				const stability = (selectedLigneBl?.stock?.transfertType === TRANSFERT_TYPE.MOINS_20 ? selectedLigneBl?.stock?.produit?.stabilite28TransfertMoins20 : selectedLigneBl?.stock?.produit?.stabilite28) || 0
				let expirationDate = exitDate ? moment(exitDate).add(stability, 'hours') : undefined

				// On compare la date limite d'utilisation à la date de péremption du stock
				expirationDate = compareLimitDate(expirationDate, selectedLigneBl?.stock?.peremption, selectedLigneBl?.produit?.peremptionType)

				form.change('usageLimitDatetime', expirationDate ? formatDateInForm(expirationDate, DATE_FORMAT_IN_FORM.DATETIME) : undefined)
				form.change('batchNumber', selectedLigneBl?.stock?.lot)
				form.change('exitFridgeDatetime', exitDate ? formatDateInForm(moment(exitDate), DATE_FORMAT_IN_FORM.DATETIME) : undefined)
				setIsLoadingSection4(false)
			})
	}

	const fetchBlLines = (idVaccinationCenter: string, idProduct: string, form: FormApi<FormValues>) => {
		setBlLines([]) // clear
		setDosesCount(0) // clear
		clearFieldsSection34(form)
		setShowAllSections(false)
		setIsLoadingSection3(true)
		if (idVaccinationCenter && idProduct) {
			getBlLast15Days(idProduct, idVaccinationCenter)
				.then((blList: DispatchBlModel[]) => {
					const filteredBlLines = _.flatten(blList.map((bl: DispatchBlModel) => bl.lignes.filter((ligne) => ligne.produit.id === idProduct)))

					if (filteredBlLines.length === 1) {
						form.change('idLigneBl', filteredBlLines[0].id)
						fetchStockLine(idVaccinationCenter, filteredBlLines[0].id, form, filteredBlLines)
					}
					setBlLines(filteredBlLines)
				})
				.finally(() => {
					setIsLoadingSection3(false)
					setShowAllSections(true)
				})
		}
	}

	const onValidation = (formValues: FormValues) => {
		const errors: any = {}

		if (formValues.quantity !== undefined && formValues.quantity <= 0) {
			errors.quantity = <ErrorPositiveValue />
		}

		return errors
	}

	const onSubmit = async (formValues: FormValues) => {
		return postRetourStock({
			quantite: formValues.quantity,
			idLigneBl: formValues.idLigneBl,
			idProduit: formValues.idProduct,
			idCentreRetour: formValues.idVaccinationCenter,
			forceValidation: formValues.forceValidation || false,
			commentaireRetour: formValues.returnComment
		})
			.then((response: DispatchMouvementModel) => {
				closeDialogQtyWarning()
				history.push(`/dispatch/return/${response.id}`, { cause: formValues.returnComment })
			})
			.catch((error: any) => {
				if (error?.response?.status === 400 && error.quantite) {
					openDialogQtyWarning()
				} else {
					snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
				}
			})
	}

	return (
		<Form
			validate={onValidation}
			onSubmit={onSubmit}
			render={({ handleSubmit, submitting, form, values }) =>
				(
					<PageFormContainer
						onSubmit={handleSubmit}
					>
						{/* SECTION 1 */}
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="dispatch.productReturn.form.sections.selectVaccinationCenter"
									defaultMessage="1. Sélectionner le centre de vaccination"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Field
								name="idVaccinationCenter"
								label={<FormattedMessage
									id="global.functionnal.vaccinationCenter"
									defaultMessage="Centre de vaccination"
									description="Center to select"
								/>}
								component={FieldAutocomplete}
								options={selectedCenter.centreEnfantsByType.vaccinations.map((centre: CentreModel) => ({
									label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
									value: centre.id
								}))}
								required
							/>
							<OnChange name="idVaccinationCenter">
								{
									value => {
										if (value && values.idProduct) {
											fetchBlLines(value, values.idProduct, form)
										}
									}
								}
							</OnChange>
						</Grid>

						{/* SECTION 2 */}
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="dispatch.productReturn.form.sections.selectProduct"
									defaultMessage="2. Sélectionner le produit"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						<Grid item xs={10} md={4}>
							<FieldProduct name="idProduct" required />
							<OnChange name="idProduct">
								{
									value => {
										if (value && values.idVaccinationCenter) {
											fetchBlLines(values.idVaccinationCenter, value, form)
										}
									}
								}
							</OnChange>
						</Grid>
						<Grid item xs={6} md={4}>
							<FieldUnitProduct />
						</Grid>

						{/* SECTION 3 */}
						{isLoadingSection3 &&
							<Loader />
						}

						{!isLoadingSection3 && showAllSections &&
							<>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="dispatch.productReturn.form.sections.selectBl"
											defaultMessage="3. Sélectionner le numéro de bon de livraison"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{blLines.length > 0 ?
									<>
										<Grid item xs={12}>
											<Grid item xs={12} md={4}>
												<Field
													name="idLigneBl"
													component={FormSelect}
													label={
														<FormattedMessage
															id="dispatch.productReturn.form.inputs.deliverySlipNumber"
															defaultMessage="Numéro de bon de livraison"
															description="Input for delivery slip"
														/>
													}
													required
												>
													{blLines.map((bl: DispatchLigneBlModel) => (
														<MenuItem key={bl.id} value={bl.id}>{bl.numeroBl}</MenuItem>
													))}
												</Field>
												<OnChange name="idLigneBl">
													{
														value => {
															if (value && values.idVaccinationCenter) {
																fetchStockLine(values.idVaccinationCenter, value, form)
															}
														}
													}
												</OnChange>
											</Grid>
										</Grid>

										{/* SECTION 4 */}
										{isLoadingSection4 &&
											<Loader />
										}

										{!isLoadingSection4 && values.idLigneBl &&
											<>
												<Grid item xs={12}>
													<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
														<FormattedMessage
															id="dispatch.productReturn.form.sections.identifyBatch"
															defaultMessage="4. Identifier le lot"
															description="Form section title"
														/>
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="caption">
														<FormattedMessage
															id="dispatch.productReturn.form.warning"
															defaultMessage="La quantité indiquée est la quantité résiduelle présente dans le centre de vaccination"
															description="Warning message"
														/>
													</Typography>
												</Grid>
												<Grid item xs={4} md={2}>
													<FieldQuantity name="quantity" required />
													<OnChange name="quantity">
														{
															value => {
																const selectedLigneBl = blLines.find((blLine: DispatchLigneBlModel) => blLine.id === values.idLigneBl)
																const dosesTotal = (selectedLigneBl?.stock?.produit?.nbDoses || 0) * (value || 0)

																setDosesCount(dosesTotal)
															}
														}
													</OnChange>
												</Grid>
												<TotalCountCaption count={dosesCount} />
												<Grid item xs={12}>
													<Grid item xs={12} md={4}>
														<Field
															name="batchNumber"
															component={FormInput}
															label={
																<FormattedMessage
																	id="global.functionnal.batchNumber"
																	defaultMessage="Lot"
																	description="Batch number"
																/>
															}
															disabled
														/>
													</Grid>
												</Grid>
												{values.exitFridgeDatetime &&
													<>
														<Grid item xs={12} md={5}>
															<Field
																name="exitFridgeDatetime"
																component={FormInput}
																label={
																	<FormattedMessage
																		id="dispatch.global.functionnal.exitTime"
																		defaultMessage="Heure de sortie du congélateur"
																		description="Exit fridge datetime"
																	/>
																}
																disabled
															/>
														</Grid>
														<Grid item xs={12} md={5}>
															<Field
																name="usageLimitDatetime"
																component={FormInput}
																label={
																	<FormattedMessage
																		id="global.functionnal.usageLimitDate"
																		defaultMessage="Date limite d'utilisation"
																		description="Usage limit datetime"
																	/>
																}
																disabled
															/>
														</Grid>
														<Grid item xs={12} md={10}>
															<Field
																name="returnComment"
																component={FormInput}
																label={
																	<FormattedMessage
																		id="dispatch.productReturn.form.inputs.returnComment"
																		defaultMessage="Commentaire de retour"
																		description="Comment"
																	/>
																}
															/>
														</Grid>
													</>
												}
												<div
													className={classes.submitButtonRoot}
													style={{
														justifyContent: isLargeScreen ? 'flex-start' : 'center'
													}}
												>
													<Button type="submit" variant="contained" isLoading={submitting}>
														<FormattedMessage
															id="button.validate"
															defaultMessage="Valider"
															description="Message on form submission button"
														/>
													</Button>
												</div>
											</>
										}
									</>
									:
									<Grid item xs={12}>
										<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
											<FormattedMessage
												id="dispatch.productReturn.form.blListNotFound"
												defaultMessage="Aucun BL ne correspond à ce centre et produit"
												description="No BL message"
											/>
										</Typography>
									</Grid>
								}
							</>
						}
						<DialogProductReturnQtyWarning
							isOpen={isOpenDialogQtyWarning}
							handleClose={closeDialogQtyWarning}
							onConfirm={() => onSubmit({
								...values,
								forceValidation: true
							})}
						/>
					</PageFormContainer>
				)}
		/>
	)
}

export default compose(injectSnackbarActions)(ProductReturn)
