import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			paddingBottom: '6vh'
		},
		FAB: {
			marginTop: theme.spacing(1)
		}
	})
)

interface RedirectFABProps {
	redirectPath: string,
	redirectState?: any,
	showButton?: boolean
}

const RedirectFAB: React.FC<RedirectFABProps> = (
	{
		redirectPath,
		redirectState = {},
		showButton = true,
		children
	}
) => {
	const classes = useStyles()
	const history = useHistory()

	const handleOnClick = () => {
		history.push(redirectPath, redirectState)
	}

	return (
		<div className={classes.container}>
			{children}
			{showButton && <Fab color="primary" aria-label="add" className={classes.FAB} onClick={handleOnClick}>
				<AddIcon />
			</Fab>}
		</div>
	)
}

export default RedirectFAB
