import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import { RequestStockFiltersModel, ResponseStockModel } from '../../../../admin/stock/services/stockModel'
import { CENTER_TYPE_LOWERCASE, colors, DOWNLOAD_FILE_TYPE, PEREMPTION_HOUR, PRODUCT_EXPIRATION_TYPE, TRANSFERT_TYPE } from '../../../../../utils/constants'
import ExportExcelButton from '../../../../../components/layout/buttons/ExportExcelButton'
import { getLimitDateWithStability } from '../../../../../utils/formUtils'
import { PhidemDataContext } from '../../../phidemData/PhidemDataContext'
import { DATE_FORMAT, displayDate } from '../../../../../utils/dateUtils'
import { DispatchReferentStockModel } from '../stockModels'
import { getStockPageMobile } from '../stockApi'
import Loader from '../../../../../components/layout/Loader'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { IsotopeTableMenu } from 'isotope-client'
import { CancelablePromise, PageResult } from '../../../../../utils/fetchTypes'
import { makeCancellablePromise } from '../../../../../utils/fetchDownload'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: 8,
			marginBottom: 12
		},
		firstRow: {
			marginBottom: 8,
			paddingRight: 4
		},
		row: {
			marginBottom: 4
		},
		warningIcon: {
			fontSize: 16,
			marginLeft: 8,
			marginTop: -2
		},
		usageLimitDate: {
			color: colors.snackError
		},
		pagination: {
			padding: 0,
			border: 'none',
			display: 'flex',
			flexDirection: 'row-reverse'
		}
	})
)

interface StockListMobileProps {
	centerType: string
	filters: RequestStockFiltersModel,
	openDialog?: (row: ResponseStockModel) => void,
	showCenter?: boolean,
	showActions?: boolean
	isOpenDialog: boolean
}

const StockListMobile: React.FC<StockListMobileProps> = (
	{
		centerType,
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false,
		isOpenDialog
	}
) => {
	const history = useHistory()
	const classes = useStyles()
	const intl = useIntl()
	const { user: { selectedLang, isDateFormatEn } } = useContext(PhidemDataContext)
	const prefixCenter = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]
	const fetchData = useRef<CancelablePromise>()
	const [page, setPage] = useState<number>(0)
	const [result, setResult] = useState<PageResult<DispatchReferentStockModel> | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)

	const getData = useCallback(() => {
		setLoading(true)
		if (fetchData.current) {
			fetchData.current.cancel()
			fetchData.current = undefined
		}
		fetchData.current = makeCancellablePromise(getStockPageMobile(`/${prefixCenter}/stock`, filters, page))
		fetchData.current.promise
			.then(setResult)
			.finally(() => setLoading(false))
	}, [setLoading, prefixCenter, filters, page])

	useEffect(() => getData(), [getData])

	useEffect(() => setPage(0), [filters])

	// On close dialog, refresh data
	useEffect(() => {
		if (!isOpenDialog) {
			getData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenDialog])

	// Methods to render table
	const renderActions = (row: DispatchReferentStockModel) => {
		const actions = [
			{
				label: <FormattedMessage
					id="global.update"
					defaultMessage="Modifier"
					description="Update action"
				/>, action: () => {
					openDialog({
						id: row.id,
						produit: row.produit,
						quantite: row.quantite,
						peremption: row.peremption,
						lot: row.lot,
						centerType
					})
				}
			}
		]

		if (row.retourProduit) {
			actions.push(
				{
					label: <FormattedMessage
						id="stockEdit.dataTable.seePrint"
						defaultMessage="Voir la fiche de retour"
						description="Update action"
					/>, action: () => {
						history.push(`/dispatch/return/${row.id}`)
					}
				}
			)
		}

		return actions
	}
	const renderPeremption = (row: DispatchReferentStockModel) => {
		let dateLimit = undefined
		if (row.retourProduit) {
			if (row.retourBl?.dateSortie) {
				// Dans le cas d'un retour, on calcule la durée de stabilité selon le stock initial
				// - Stock classique = stabilite28
				// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
				dateLimit = getLimitDateWithStability(row.produit, row.retourBl?.dateSortie, row.retourBl?.stockTransfertType)
			}
		} else if (row.decongele && row.lignesBl && row.lignesBl.length > 0 && row.lignesBl[0].dateSortie) {
			const firsLineBl = row.lignesBl && row.lignesBl.length > 0 ? row.lignesBl[0] : undefined
			dateLimit = getLimitDateWithStability(row.produit, firsLineBl?.dateSortie, firsLineBl?.stockTransfertType)
		} else if (!!row.transfertType && row.transfertType !== TRANSFERT_TYPE.CONGELE) {
			dateLimit = moment(row.transfertDate)
		}

		// On compare la date limite d'utilisation à la date de péremption du stock
		// Si la date de péremption est antérieure => on n'affiche pas la date limite
		const stockPeremption = row.peremption
		const isPeremptionJour = row.produit.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR
		if (dateLimit && stockPeremption) {
			const peremptionDate = isPeremptionJour ? moment(stockPeremption) : moment(stockPeremption).endOf('month')
			const peremptionLimit = peremptionDate.hours(PEREMPTION_HOUR).minutes(0)
			if (peremptionLimit.isBefore(dateLimit)) {
				dateLimit = undefined
			}
		}

		return (
			<Typography variant="body2">
				{isPeremptionJour ? displayDate(moment(row.peremption), DATE_FORMAT.DATE, isDateFormatEn)
					: displayDate(moment(row.peremption).locale(selectedLang), DATE_FORMAT.MONTH_YEAR, isDateFormatEn)}
				{dateLimit ?
					<span className={classes.usageLimitDate}>{` (${displayDate(dateLimit, DATE_FORMAT.DATETIME, isDateFormatEn)})`}</span>
					: ''
				}
			</Typography>
		)
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>
			{(!result || result.content.length === 0) ? <></>
				:
				<>
					{result.content.map(row => (<Paper key={row.id} className={classes.container}>
						<Grid item container xs={12}>
							<Grid item container xs={12} className={classes.firstRow} justify="space-between">
								<Grid item xs={10}>
									<Typography variant="body2">{row.produit.nomCommercial}</Typography>
								</Grid>
								{showActions && <Grid item>
									<IsotopeTableMenu actions={renderActions(row)} element={row} />
								</Grid>}
							</Grid>
							<Grid item container xs={12} justify="space-between" className={classes.row}>
								<Grid item container xs={6}>
									<Typography variant="body2">
										<FormattedMessage
											id="global.functionnal.batch"
											defaultMessage="Numéro de lot"
											description="Header column name"
										/>
										: {row.lot}
									</Typography>
									{row.retourProduit && <WarningIcon className={classes.warningIcon} />}
								</Grid>
								<Grid item>
									<Typography variant="body2">
										{row.quantite}
										<FormattedMessage
											id="global.functionnal.conditioningType"
											defaultMessage={row.produit.typeConditionnement}
											description="Conditioning type"
											values={
												{
													count: row.quantite,
													conditioningType: row.produit.typeConditionnement
												}
											}
										/>
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} className={classes.row}>
								{renderPeremption(row)}
							</Grid>
							{showCenter && <Grid item xs={12} className={classes.row}>
								<Typography variant="body2">
									<FormattedMessage
										id="stockEdit.dataTable.centerName"
										defaultMessage="Centre"
										description="Header column name"
									/>
									: {row.centre.nom}
								</Typography>
							</Grid>}
						</Grid>
					</Paper>))}
					<TablePagination
						className={classes.pagination}
						rowsPerPageOptions={[]}
						colSpan={3}
						count={result.totalElements}
						rowsPerPage={5}
						page={page}
						onChangePage={(ev, newPage) => setPage(newPage)}
						labelDisplayedRows={({ from, to, count }) => (
							intl.formatMessage({ id: 'isotope.datatable.footer.pagination' }, {
								startIndex: from,
								endIndex: to,
								total: count
							})
						)}
					/>
				</>
			}
			<ExportExcelButton
				url={`/${prefixCenter}/stock/export`}
				fileName={DOWNLOAD_FILE_TYPE.DISPATCH_REFERENT_STOCK.outputFile}
				filters={filters}
			/>
		</>
	)
}

export default StockListMobile
