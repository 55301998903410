import React, { useContext } from 'react'
import { dataTableActions, SpringDataTable } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import { CarrierModel } from './services/carrierModel'
import { useHistory } from 'react-router-dom'
import RedirectFAB from '../RedirectFAB'
import { updateCarrierStatus } from './services/carrierApi'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

interface CarrierListProps {
    refreshTable: () => void,
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const CarrierList: React.FC<CarrierListProps> = (
    {
        refreshTable,
        snackSuccess,
        snackError
    }
) => {
    const history = useHistory()
    const { user: { isAdmin }, reloadReferentiel } = useContext(PhidemDataContext)

    const handleCarrierStatus = (id: string, status: boolean) => {
        updateCarrierStatus(id, status)
            .then(() => {
                refreshTable()
                snackSuccess({
                    id: 'carrier.updateStatusSuccess',
                    defaultMessage: 'Le statut du transporteur a bien été modifié',
                    description: 'Success message'
                })
                reloadReferentiel(REFERENTIEL.CARRIER)
                reloadReferentiel(REFERENTIEL.CENTER)
            })
            .catch(() => snackError({
                id: 'carrier.updateStatusError',
                defaultMessage: 'Une erreur est survenue, le statut du transporteur n\'a pas été modifiée',
                description: 'Error message'
            }))
    }

    const getHeaders = () => {
        const headers = [
            {
                key: 'nom',
                name: <FormattedMessage
                    id="carrier.name"
                    defaultMessage="Nom"
                    description="Header column name"
                />,
                sortable: true
            },
            {
                key: 'siret',
                name: <FormattedMessage
                    id="carrier.siret"
                    defaultMessage="SIRET"
                    description="Header column siret"
                />,
                sortable: true
            },
            {
                key: 'adresse',
                name: <FormattedMessage
                    id="carrier.address"
                    defaultMessage="Adresse"
                    description="Header column address"
                />,
                sortable: true,
                render: (row: CarrierModel) => <span>{row.adresse} {row.codePostal} {row.ville} {row.pays}</span>
            },
            {
                key: 'telephone',
                name: <FormattedMessage
                    id="carrier.phone"
                    defaultMessage="Téléphone"
                    description="Header column phone"
                />,
                sortable: true
            },
            {
                key: 'mailContact',
                name: <FormattedMessage
                    id="carrier.contact"
                    defaultMessage="Contact"
                    description="Header column contact"
                />,
                sortable: true
            },
            {
                key: 'actif',
                name: <FormattedMessage
                    id="global.status.label"
                    defaultMessage="Statut"
                    description="Header column status"
                />,
                sortable: true,
                render: (row: CarrierModel) => row.actif ? <FormattedMessage
                    id="global.status.enabled"
                    defaultMessage="Actif"
                    description="Center status"
                /> : <FormattedMessage
                    id="global.status.disabled"
                    defaultMessage="Inactif"
                    description="Center status"
                />,

            }
        ]

        if (isAdmin) {
            headers.unshift({
                key: 'centreDispatch.nom',
                name: <FormattedMessage
                    id="carrier.centerName"
                    defaultMessage="Nom du centre"
                    description="Header column name"
                />,
                sortable: true,
                render: (row: CarrierModel) => <span>{`${row.centreDispatch.nom}${row.centreDispatch.service ? ` - ${row.centreDispatch.service}` : ''}`}</span>
            })
        }

        return headers
    }

    return (
        <RedirectFAB redirectPath="/carriers/add">
            <SpringDataTable
                apiUrl="/referentiel/transporteur"
                nom="carrierList"
                headers={getHeaders()}
                makeActions={(row: CarrierModel) => [
                    {
                        label: <FormattedMessage
                            id="global.update"
                            defaultMessage="Modifier"
                            description="Update action"
                        />, action: () => {
                            history.push(`/carriers/${row.id}`)
                        }
                    },
                    {
                        label: row.actif ? <FormattedMessage
                            id="global.disable"
                            defaultMessage="Désactiver"
                            description="Disable action"
                        /> : <FormattedMessage
                            id="global.enable"
                            defaultMessage="Activer"
                            description="Enable action"
                        />, action: () => handleCarrierStatus(row.id, !row.actif)
                    },
                ]}
                showActions
                noResultFragment={<NotFoundPage />}
            />
        </RedirectFAB>
    )
}

const actions = {
    refreshTable: () => dataTableActions.refresh('carrierList')
}

export default compose<any>(
    connect(null, actions),
    injectSnackbarActions
)(CarrierList)
