import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { postTracker } from './services/trackerApi'
import { TrackerModel } from './services/trackerModel'
import TrackerForm from './components/TrackerForm'
import errorsMapper from '../../../utils/errorsMapper'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'

const defaultValues = {
    fabricant: '',
    nom: '',
    type1: '',
    type2: '',
    minTemperature: 0,
    maxTemperature: 0,
    actif: false
}

interface TrackerCreateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const TrackerCreate: React.FC<TrackerCreateProps> = (
    {
        snackSuccess,
        snackError
    }) => {
    const history = useHistory()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    const onSubmit = async (formValues: any) => {
        return postTracker(formValues)
            .then((response: TrackerModel) => {
                history.push(`/trackers/${response.id}`)
                snackSuccess({ id: 'tracker.createSuccess', defaultMessage: 'Le tracker a bien été créé', description: 'Success message' })
            })
            .catch((e: any) => {
                const { errors, displayFields } = errorsMapper(e)
                if (displayFields) {
                    snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
                    reloadReferentiel(REFERENTIEL.TRACKER)
                } else {
                    snackError({ id: 'tracker.createError', defaultMessage: 'Une erreur est survenue, le tracker n\'a pas été créé', description: 'Error message' })
                }
                return errors
            })
    }

    return (
        <TrackerForm
            initialValues={defaultValues}
            onSubmit={onSubmit}
            isCreationForm={true}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="tracker.pageTitle.create"
        defaultMessage="Nouveau tracker"
        description="Page title"
    />
})), injectSnackbarActions)(TrackerCreate)
