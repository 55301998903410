import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { Field, Form } from 'react-final-form'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '../../../../components/layout/dialog/Dialog'
import Button from '../../../../components/layout/buttons/Button'
import { FormSelect } from 'isotope-client'
import { ADMIN_MODE, QTY_MODE } from '../../../../utils/constants'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid } from '@material-ui/core'
import FieldWeekPicker from '../../../../components/fields/FieldWeekPicker'
import { WeekRange } from '../../../../components/weekPicker/WeekPicker'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { importProcurement } from '../services/procurementApi'
import FieldFile from '../../../../components/fields/FieldFile'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const ERROR_IMPORT = {
	COLONNE: 'erreurColonne',
	CSV_VIDE: 'csvVide'
}

const useStyles = makeStyles(() =>
	createStyles({
		sectionTitle: {
			fontWeight: 500,
			marginBottom: 16,
			marginTop: 20
		},
		sectionError: {
			fontWeight: 500,
			marginBottom: 16,
			marginTop: 20,
			color: 'red'
		},
		error: {
			color: 'red'
		}
	})
)

interface FormValues {
	period: string,
	qtyMode: string,
	startWeek: WeekRange,
	file: string
}

interface DialogImportProcurementProps {
	isOpen: boolean
	startWeek: WeekRange
	handleClose: () => any,
	onSuccess: () => any,
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void
}

const DialogImportProcurement: React.FC<DialogImportProcurementProps> = (
	{
		isOpen,
		startWeek,
		handleClose,
		onSuccess,
		snackSuccess,
		snackError
	}) => {
	const classes = useStyles()
	const intl = useIntl()
	const { user: { selectedCenter, isDateFormatEn, selectedLang } } = useContext(PhidemDataContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [errors, setErrors] = useState<any>([])

	const getFileModel = (qtyMode: string, period: string) => {
		if (period === ADMIN_MODE.JOUR) {
			if (qtyMode === QTY_MODE.DOSE) {
				return 'import_prevision_jour_dose.xlsx'
			}
			return 'import_prevision_jour_flacon.xlsx'
		} else {
			if (qtyMode === QTY_MODE.DOSE) {
				return 'import_prevision_semaine_dose.xlsx'
			}
			return 'import_prevision_semaine_flacon.xlsx'
		}
	}

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (!formValues.period) {
			errors.period = <ErrorMessageRequired />
		}
		if (!formValues.qtyMode) {
			errors.qtyMode = <ErrorMessageRequired />
		}
		if (!formValues.startWeek.from || !formValues.startWeek.to) {
			errors.weekRange = <ErrorMessageRequired />
		}
		if (!formValues.file) {
			errors.file = <ErrorMessageRequired />
		}

		return errors
	}

	const handleOnSubmit = (values: FormValues) => {
		setLoading(true)
		setErrors([])
		return importProcurement({
			...values,
			startWeek: values.startWeek.from ? new Date(values.startWeek.from).toISOString() : ''
		})
			.then(() => {
				onSuccess()
				snackSuccess({ id: 'procurement.importDialog.success' })
			})
			.catch(e => {
				const resErrors: any = []
				if (e._error && e._error.length > 0) {
					e._error.forEach((err: any) => {
						if (err.id === ERROR_IMPORT.COLONNE && !!err.values && Object.values(err.values).length > 0) {
							resErrors.push(...Object.values(err.values)
								.filter((errVal: any) => !!errVal)
								.map((errVal: any) => {
									return ({
										line: errVal.line + 1,
										messages: errVal.errorList.map((errL: any) => errL.message)
									})
								}))
						} else if (err.id === ERROR_IMPORT.CSV_VIDE) {
							resErrors.push({
								global: true,
								message: err.id
							})
						}
					})
				}
				setErrors(resErrors)
				snackError({ id: 'procurement.importDialog.error' })
			})
			.finally(() => setLoading(false))
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={handleOnSubmit}
			initialValues={{
				period: ADMIN_MODE.SEMAINE,
				qtyMode: selectedCenter.modeQte,
				startWeek
			}}
			render={({ values, handleSubmit }) => {
				const fileModel = getFileModel(values.qtyMode, values.period)
				return (
					<form id="formDialogImportProcurement" onSubmit={handleSubmit}>
						<Dialog
							title={
								<FormattedMessage
									id="procurement.importDialog.title"
									defaultMessage="Import des allocations"
									description="Dialog title"
								/>
							}
							isOpen={isOpen}
							handleClose={handleClose}
							buttons={[
								<Button onClick={handleClose} color="primary" key={0} variant="outlined">
									<FormattedMessage
										id="button.cancel"
										defaultMessage="Annuler"
										description="Button label"
									/>
								</Button>,
								<Button isLoading={loading} type="submit" autoFocus color="primary" key={1} variant="contained" form="formDialogImportProcurement">
									<FormattedMessage
										id="procurement.importDialog.confirm"
										defaultMessage="Importer"
										description="Button label"
									/>
								</Button>
							]}
						>
							<Typography gutterBottom>
								<FormattedMessage
									id="procurement.importDialog.warning"
									defaultMessage="Attention, l'import d'un nouveau fichier écrasera les données déjà présentes pour les semaines importées."
									description="Dialog warning"
								/>
							</Typography>
							<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
								<FormattedMessage
									id="procurement.importDialog.general"
									defaultMessage="Informations sur l'import"
									description="Dialog subtitle"
								/>
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<Field
										name="period"
										component={FormSelect}
										label={<FormattedMessage
											id="procurement.importDialog.fields.period"
											defaultMessage="Type d'import"
											description="Import type"
										/>}>
										{Object.values(ADMIN_MODE).map((mode: string) => (
											<MenuItem key={mode} value={mode}>
												<FormattedMessage
													id={`enum.procurementImportType.${mode}`}
													defaultMessage={mode}
													description="Period type"
												/>
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={4}>
									<Field
										name="qtyMode"
										component={FormSelect}
										label={<FormattedMessage
											id="procurement.importDialog.fields.qtyMode"
											defaultMessage="Type de conditionnement"
											description="Conditionning type"
										/>}
									>
										{Object.keys(QTY_MODE).map((qty: string) => (
											<MenuItem key={qty} value={qty}>
												<FormattedMessage
													id={`enum.qtyMode.${qty}`}
													defaultMessage={qty}
													description="Qty mode"
												/>
											</MenuItem>)
										)}
									</Field>
								</Grid>
							</Grid>
							<Grid container>
								<FieldWeekPicker
									name="startWeek"
									label={
										<FormattedMessage
											id="procurement.importDialog.fields.startWeek"
											defaultMessage="Semaine de départ"
											description="Week start input"
										/>
									}
									isDateFormatEn={isDateFormatEn}
									selectedLang={selectedLang}
								/>
							</Grid>
							<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
								<FormattedMessage
									id="procurement.importDialog.file"
									defaultMessage="Données à importer"
									description="Dialog subtitle"
								/>
							</Typography>
							{values.qtyMode && values.period && <Typography gutterBottom>
								<FormattedMessage
									id="procurement.importDialog.model"
									defaultMessage="Modèle : "
									description="File model"
								/>
								<a href={`/model/${fileModel}`} target="_blank" rel="noopener noreferrer" download>{fileModel}</a>
							</Typography>}

							<FieldFile
								label={<FormattedMessage
									id="procurement.importDialog.fields.file"
									defaultMessage="Fichier"
									description="File input"
								/>}
								required
								accept=".xls,.xlsx"
							/>
							{errors && errors.length > 0 && <>
								<Typography gutterBottom variant="h5" className={classes.sectionError}>
									<FormattedMessage
										id="procurement.importDialog.errors.title"
										defaultMessage="Erreurs d'import"
										description="Dialog subtitle"
									/>
								</Typography>
								{errors.map((err: any) => <Typography gutterBottom className={classes.error}>
									{err.global ?
										<FormattedMessage
											id={`procurement.importDialog.errors.${err.message}`}
											defaultMessage="Erreur globale "
											description="Globale error"
										/>
										:
										<FormattedMessage
											id="procurement.importDialog.errors.line"
											defaultMessage="Ligne : "
											description="Error line"
											values={{
												line: err.line,
												errorMessage: err.messages.map((errMess: any) => intl.formatMessage({ id: `procurement.importDialog.errors.${errMess}` })).join(', ')
											}}
										/>}
								</Typography>)}

							</>}
						</Dialog>
					</form>
				)
			}}
		/>
	)
}

export default injectSnackbarActions(DialogImportProcurement)
