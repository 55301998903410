import React from 'react'
import { Field } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import { FormInput, FormSelect } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { EXPIRATION_YEARS_ADD_COUNT, MONTHS } from '../../utils/constants'
import { isDateInputTypeSupported } from '../../utils/form/inputTypes'

interface FieldMonthProps {
    dateName: string
    monthName: string
    yearName: string
    dateLabel?: string | React.ReactElement
    monthLabel?: string | React.ReactElement
    yearLabel?: string | React.ReactElement
    required?: boolean
}

const FieldMonth: React.FC<FieldMonthProps> = (
    {
        dateName,
        monthName,
        yearName,
        dateLabel,
        monthLabel,
        yearLabel,
        required = true
    }) => {
    const isMonthSupported = isDateInputTypeSupported('month')

    return (
        isMonthSupported // using alternative inputs if not
            ?
            <Grid item xs={12} md={5}>
                <Field
                    name={dateName}
                    component={FormInput}
                    label={dateLabel}
                    type="month"
                    required={required}
                />
            </Grid>
            :
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={5}>
                    <Field
                        name={monthName}
                        component={FormSelect}
                        label={monthLabel}
                        required={required}
                    >
                        {Object.values(MONTHS).map((month: string, index: number) => (
                            <MenuItem key={month} value={index}>
                                <FormattedMessage
                                    id={`enum.months.${month}`}
                                    defaultMessage={month}
                                    description="Month"
                                />
                            </MenuItem>
                        ))}
                    </Field>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Field
                        name={yearName}
                        component={FormSelect}
                        label={yearLabel}
                        required={required}
                    >
                        {Array.from(Array(EXPIRATION_YEARS_ADD_COUNT))
                            .map((_: number, index: number) => {
                                const year = moment()
                                    .add(index, 'year').format('YYYY')

                                return (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                )
                            })}
                    </Field>
                </Grid>
            </Grid>
    )
}

export default FieldMonth
