import React, { useContext } from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { ReplenishmentDialogDataModel } from '../../ReplenishmentModel'
import DialogReplenishmentBody from '../DialogReplenishmentBody'
import { DATE_FORMAT, displayDate } from '../../../../../utils/dateUtils'
import { PhidemDataContext } from '../../../phidemData/PhidemDataContext'

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		},
		sectionTitle: {
			fontWeight: 500,
			marginBottom: 16
		}
	})
)

interface DialogReplenishmentProcessProps {
	isOpen: boolean
	loading: boolean
	handleClose: () => any
	onSubmit: (value: any) => any,
	replenishment: ReplenishmentDialogDataModel
}

const DialogReplenishmentProcess: React.FC<DialogReplenishmentProcessProps> = (
	{
		isOpen,
		loading,
		handleClose,
		onSubmit,
		replenishment
	}
) => {
	const classes = useStyles()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const {
		productCond,
		quantiteConfirmee,
		dateConfirmee
	} = replenishment

	const hasConfirmationData = quantiteConfirmee && dateConfirmee

	return (
		<Form
			onSubmit={onSubmit}
			render={({ values }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.processing.dialog.title"
							defaultMessage="Traitement de la demande"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.cancel"
								defaultMessage="Annuler"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => onSubmit(values)} isLoading={loading}>
							<FormattedMessage
								id="button.validate"
								defaultMessage="Valider"
								description="Button label"
							/>
						</Button>
					]}
				>
					{hasConfirmationData &&
						<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
							<FormattedMessage
								id="dispatch.confirm.dialog.sections.initial"
								defaultMessage="Demande initiale"
								description="Dialog content"
							/>
						</Typography>
					}
					<DialogReplenishmentBody replenishment={replenishment} />

					{hasConfirmationData && <>
						<Typography gutterBottom variant="h5" className={`${classes.inputContainer} ${classes.sectionTitle}`}>
							<FormattedMessage
								id="dispatch.confirm.dialog.sections.confirmed"
								defaultMessage="Demande confirmée"
								description="Dialog content"
							/>
						</Typography>

						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.process.quantiteConfirmee"
								defaultMessage={`Quantité confirmée : ${quantiteConfirmee}`}
								description="Dialog content"
								values={{
									quantity: quantiteConfirmee,
									conditioningType: productCond
								}}
							/>
						</Typography>
						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.process.dateConfirmee"
								defaultMessage={`Date confirmée : ${displayDate(moment(dateConfirmee), DATE_FORMAT.DATETIME, isDateFormatEn)}`}
								description="Dialog content"
								values={{
									date: displayDate(moment(dateConfirmee), DATE_FORMAT.DATETIME, isDateFormatEn)
								}}
							/>
						</Typography>
					</>}
					<Field
						name="comment"
						component={FormInput}
						className={classes.inputContainer}
						label={
							<FormattedMessage
								id="dispatch.processing.dialog.comment"
								defaultMessage="Commentaire"
								description="Comment label input"
							/>
						}
					/>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentProcess
