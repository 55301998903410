import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { FormCheckbox, FormInput, FormSelect, injectToolbarData } from 'isotope-client'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import Button from '../../../../components/layout/buttons/Button'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import { CentreModel } from '../../../common/phidemData/phidemDataModel'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import { CarrierModel } from '../../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../../admin/container/services/containerModel'
import { TrackerModel } from '../../../admin/tracker/services/trackerModel'
import { isDateInputTypeSupported } from '../../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../../utils/form/decorators'
import { CENTER_TYPE, colors, SCREEN_SIZE } from '../../../../utils/constants'
import DialogAddProduct from './components/DialogAddProduct'
import ProductsTable from '../../../../components/layout/productsTable/ProductsTable'
import FieldAutocomplete from '../../../../components/fields/FieldAutocomplete'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../../utils/dateUtils'
import { postExitStock } from './services/exitStockApi'
import { ExitStockProductValues } from './services/exitStockModels'

// TODO: Refacto
// L'objectif était de mutualiser complètement le formulaire exit stock pour dispatch et référent (comme pour enterStock)
// Faute de temps: mutualisation des dialog et certains modèles mais pas intégralement

const getInitialDates = () => {
	const currentDateTime = moment()
	return {
		sortie: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		exitDate: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		exitTime: formatDateInForm(currentDateTime.local(), DATE_FORMAT_IN_FORM.HOUR)
	}
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-start'
		},
		table: {
			width: '100%'
		},
		tableHeader: {
			backgroundColor: colors.primary,
			color: colors.elementOnPrimary
		},
		tableBody: {
			color: colors.text
		},
		tableFooter: {
			color: colors.primary
		},
		tableAction: {
			display: 'flex',
			flexDirection: 'row',
			borderBottomWidth: 0
		}
	})
)

interface ProductToEditValues {
	produit: ExitStockProductValues
	index: number
}

interface ExitStockValues {
	isDefrosted: boolean
	produits: ExitStockProductValues[]
	destination: number
	commentaireDestination?: string
	idTracker?: number
	numTracker?: string
	idTransporteur?: string
	idContenant?: number
	numContenant?: string
}

interface ExitStockProps {
	snackError: (value: any) => void,
}

const ExitStock: React.FC<ExitStockProps> = ({
	                                             snackError
                                             }) => {
	const [productToEdit, setProductToEdit] = React.useState<ProductToEditValues | undefined>(undefined)
	const [isOpenDialogAddProduct, setIsOpenDialogAddProduct] = React.useState<boolean>(false)

	const { user: { selectedCenter }, referentialData } = useContext(PhidemDataContext)
	const classes = useStyles()
	const intl = useIntl()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const history = useHistory()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('exitDate', 'exitTime', 'sortie'),
				dateAndTimeDecorator('usageLimitDate', 'usageLimitTime', 'usageLimitDatetime')]
		, [isDatetimeSupported])
	const [submitLoading, setSubmitLoading] = React.useState<boolean>(false)

	const openDialogAddProduct = () => setIsOpenDialogAddProduct(true)
	const closeDialogAddProduct = () => {
		setProductToEdit(undefined)
		setIsOpenDialogAddProduct(false)
	}

	const submit = async (values: ExitStockValues) => {
		setSubmitLoading(true)
		return postExitStock({
			decongele: values.isDefrosted,
			produits: values.produits.map(produit => ({
				idProduit: produit.idProduit || '',
				quantite: produit.quantite || 0,
				idStock: produit.idStock || '',
				sortie: produit.sortie ? new Date(produit.sortie || '').toISOString() : '',
				refExterne: produit.refExterne
			})),
			destination: values.destination,
			commentaireDestination: values.commentaireDestination,
			idTracker: values.idTracker,
			numTracker: values.numTracker,
			idTransporteur: values.idTransporteur,
			idContenant: values.idContenant,
			numContenant: values.numContenant,
		})
			.then((response: any) => {
				history.push(`/referent/exits/${response.id}`)
			})
			.catch((e: any) => {
				if (e && e.bodyError) {
					if (e.bodyError.fieldErrors && e.bodyError.fieldErrors.length > 0) {
						const erreursProduits = e.bodyError.fieldErrors.map((err: any) => {
							const produit = values.produits[err.code]
							return ` - ${produit.nomCommercial} (ligne ${Number(err.code) + 1})`
						})
						// Affichage de l'erreur de quantité
						snackError(<>
								{intl.formatMessage({ id: e.bodyError.fieldErrors[0].defaultMessage, defaultMessage: 'Erreur de quantité', description: 'Error message' })}
								<br />
								{erreursProduits.map((err: string) => <>{err}<br /></>)}
							</>
						)
					}
				} else {
					snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
				}
			})
			.finally(() => setSubmitLoading(false))
	}

	const onValidate = (values: ExitStockValues) => {
		const errors: any = {}

		if (!values.destination) {
			errors.destination = <ErrorMessageRequired />
		}
		if (!!values.numContenant && !values.idContenant) {
			errors.idContenant = <ErrorMessageRequired />
		}
		if (!!values.numTracker && !values.idTracker) {
			errors.idTracker = <ErrorMessageRequired />
		}

		return errors
	}

	const convertProductToTable = (products: ExitStockProductValues[]) => {
		return products.map((product: ExitStockProductValues) => ({
			nom: product.nomCommercial,
			unit: product.unitProduct,
			lot: product.lot,
			reference: product.refExterne,
			peremption: product.peremption,
			peremptionType: product.peremptionType,
			quantite: product.quantite,
			sortie: product.sortie,
			usageLimit: product.usageLimitDate,
			isRetour: false,
			transfertType: product.transfertType,
			transfertDate: product.transfertDate
		}))
	}

	const initValues = useMemo(() => ({
		isDefrosted: false,
		produits: [],
		...getInitialDates()
	}), [])

	const getDestinationOptions = () => {
		// Je ne prends que les enfants de premier niveau
		// Pour les référents, il faut qu'il gère le stock également
		const options = selectedCenter.centreEnfantsByType.refDispatchs
			.filter(center => center.centreParents.map(c => c.id).includes(selectedCenter.id) &&
				((center.type === CENTER_TYPE.REFERENT && center.gestionStock) || center.type === CENTER_TYPE.DISPATCH))

		return options.map((centre: CentreModel) => ({
			label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
			value: centre.id
		}))
	}

	return (
		<Form
			initialValues={initValues}
			onSubmit={submit}
			validate={onValidate}
			// @ts-ignore
			decorators={formDecorators}
			render={({ handleSubmit, submitting, form, values }) => {
				const { produits } = values

				return (
					<PageFormContainer
						onSubmit={handleSubmit}
					>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="common.exitStock.formSection.preparation"
									defaultMessage="Liste des produits"
									description="Form section title"
								/>
							</Typography>
						</Grid>

						<Grid item container>
							<Grid item xs={12} md={5}>
								<Field
									name="isDefrosted"
									component={FormCheckbox}
									label={
										<FormattedMessage
											id="common.exitStock.form.isDefrosted"
											defaultMessage="Avec action de décongélation"
											description="Checkbox description"
										/>
									}
								/>
							</Grid>
							<OnChange name="isDefrosted">{() => {
								form.change('produits', [])
							}}</OnChange>
						</Grid>

						<ProductsTable
							withTitle={false}
							produits={convertProductToTable(produits)}
							canEdit
							frostedMode={!values.isDefrosted}
							handleEdit={(index: number) => {
								setProductToEdit({ produit: values.produits[index], index })
								openDialogAddProduct()
							}}
							handleDelete={(index: number) => {
								const newProduits = [...values.produits]
								if (index >= 0 && index < newProduits.length) {
									newProduits.splice(index, 1)
								}
								form.change('produits', newProduits)
							}}
							handleAdd={openDialogAddProduct}
						/>

						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="common.exitStock.formSection.shipment"
									defaultMessage="Expédition"
									description="Form section title"
								/>
							</Typography>
						</Grid>

						<Grid item xs={12} md={5}>
							<Field
								name="destination"
								label={<FormattedMessage
									id="global.functionnal.destination"
									defaultMessage="Destination"
									description="Destination to select"
								/>}
								component={FieldAutocomplete}
								options={getDestinationOptions()}
								required
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<Field
								name="idTransporteur"
								component={FormSelect}
								label={
									<FormattedMessage
										id="global.functionnal.carrier"
										defaultMessage="Transporteur"
										description="Product carrier"
									/>
								}
							>
								<MenuItem key={0} value={undefined}><FormattedMessage
									id="select.none"
									defaultMessage="Aucun"
									description="No option"
								/></MenuItem>
								{referentialData.carriers.map((carrier: CarrierModel) => (
									<MenuItem key={carrier.id} value={carrier.id}>{carrier.nom}</MenuItem>
								))}
							</Field>
						</Grid>
						<Grid item xs={12} md={10}>
							<Field
								name="commentaireDestination"
								component={FormInput}
								label={
									<FormattedMessage
										id="common.exitStock.form.destinationComment"
										defaultMessage="Informations complémentaires"
										description="Destination comment input"
									/>
								}
								multiline
							/>
						</Grid>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={4}>
								<Field
									name="idContenant"
									component={FormSelect}
									label={
										<FormattedMessage
											id="common.exitStock.form.container"
											defaultMessage="Contenant"
											description="Product container"
										/>
									}
								>
									{referentialData.containers.map((container: ContainerModel) => (
										<MenuItem key={container.id} value={container.id}>{container.nom}</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									name="numContenant"
									component={FormInput}
									label={
										<FormattedMessage
											id="common.exitStock.form.containerNumber"
											defaultMessage="Nᵒ contenant"
											description="Container number"
										/>
									}
								/>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} md={4}>
								<Field
									name="idTracker"
									component={FormSelect}
									label={
										<FormattedMessage
											id="common.exitStock.form.tracker"
											defaultMessage="Trackeur température"
											description="Tracking temperature system"
										/>
									}
								>
									{referentialData.trackers.map((tracker: TrackerModel) => (
										<MenuItem key={tracker.id} value={tracker.id}>{tracker.nom}</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									name="numTracker"
									component={FormInput}
									label={
										<FormattedMessage
											id="common.exitStock.form.trackerNumber"
											defaultMessage="Trackeur №"
											description="Tracking temperature system ID"
										/>
									}
								/>
							</Grid>
						</Grid>
						<div className={classes.submitButtonRoot}>
							<Button
								variant="contained"
								isLoading={submitting || submitLoading}
								onClick={handleSubmit}
								disabled={!values.produits || values.produits.length === 0}
							>
								<FormattedMessage
									id="button.validate"
									defaultMessage="Valider"
									description="Message on form submission button"
								/>
							</Button>
						</div>

						{isOpenDialogAddProduct && <DialogAddProduct
							isOpen={isOpenDialogAddProduct}
							showOtherThanVaccin={false}
							centerType={CENTER_TYPE.REFERENT}
							frostedMode={!values.isDefrosted}
							handleClose={closeDialogAddProduct}
							onConfirm={(produit: ExitStockProductValues, index?: number) => {
								const newProduits = [...values.produits]
								if (index !== undefined && index >= 0 && index < newProduits.length) {
									newProduits.splice(index, 1, produit)
								} else {
									newProduits.push(produit)
								}
								form.change('produits', newProduits)
								closeDialogAddProduct()
							}}
							value={productToEdit && productToEdit.produit}
							index={productToEdit && productToEdit.index}
						/>}

					</PageFormContainer>
				)
			}}
		/>
	)
}

export default compose<any>(injectToolbarData(() => ({
	title: <FormattedMessage
		id="common.exitStock.pageTitle"
		defaultMessage="Nouvelle préparation"
		description="Page title"
	/>
})), injectSnackbarActions)(ExitStock)
