import React, { useState } from 'react'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './WeekPicker.css'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const FIXED_HOUR = 12

export interface WeekRange {
	from: Date | null
	to: Date | null
}

interface WeekPickerProps {
	onChange: (weekRange: WeekRange) => any
	initialWeekRange?: WeekRange
	locale: string
}

const getWeekDays = (weekStart: Date | null) => {
	const days: Date[] = []

	if (!weekStart) {
		return []
	}

	Array.from(Array(7)).map((_, i) => days.push(
		moment(weekStart)
			.add(i, 'days')
			.hours(FIXED_HOUR)
			.toDate()
	))

	return days
}

const WeekPicker: React.FC<WeekPickerProps> = ({ onChange, initialWeekRange = { from: null, to: null }, locale }) => {
	const [hoverRange, setHoverRange] = useState<WeekRange>()
	const [selectedDays, setSelectedDays] = useState<Date[]>(getWeekDays(initialWeekRange.from))
	const daysAreSelected = selectedDays.length > 0
	const modifiers: any = {
		hoverRange,
		selectedRange: daysAreSelected && {
			from: selectedDays[0],
			to: selectedDays[6]
		},
		hoverRangeStart: hoverRange && hoverRange.from,
		hoverRangeEnd: hoverRange && hoverRange.to,
		selectedRangeStart: daysAreSelected && selectedDays[0],
		selectedRangeEnd: daysAreSelected && selectedDays[6]
	}

	const getWeekRange = (date: Date) => {
		return {
			from: moment(date)
				.startOf('week')
				.hours(FIXED_HOUR)
				.toDate(),
			to: moment(date)
				.endOf('week')
				.hours(FIXED_HOUR)
				.toDate()
		}
	}

	const handleDayChange = (date: Date) => {
		const weekRange = getWeekRange(date)

		setSelectedDays(getWeekDays(weekRange.from))
		onChange(weekRange)
	}

	const handleDayEnter = (date: Date) => setHoverRange(getWeekRange(date))

	const handleDayLeave = () => setHoverRange(undefined)

	return (
		<DayPicker
			selectedDays={selectedDays}
			showOutsideDays
			modifiers={modifiers}
			onDayClick={handleDayChange}
			onDayMouseEnter={handleDayEnter}
			onDayMouseLeave={handleDayLeave}
			firstDayOfWeek={1} // Monday
			localeUtils={MomentLocaleUtils}
			locale={locale}
			navbarElement={({ onPreviousClick, onNextClick }) => <div className="DayPicker-NavBar">
				<span onClick={() => onPreviousClick()} className="DayPicker-NavButton" style={{ marginRight: '1.5em', top: '0.5em' }}><ArrowLeftIcon fontSize="large" /></span>
				<span onClick={() => onNextClick()} className="DayPicker-NavButton" style={{ top: '0.5em' }}><ArrowRightIcon fontSize="large" /></span>
			</div>}
		/>
	)
}

export default WeekPicker
