import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput, FormSelect, FormSwitch } from 'isotope-client'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import Loader from '../../../../components/layout/Loader'
import { ProductModel, RequestCreateOrUpdateProductFormModel } from '../services/productModel'
import ButtonCreate from '../../../../components/layout/buttons/ButtonCreate'
import ButtonSave from '../../../../components/layout/buttons/ButtonSave'
import ButtonActivate from '../../../../components/layout/buttons/ButtonActivate'
import ErrorPositiveValue from '../../../../components/layout/errors/ErrorPositiveValue'
import { PRODUCT_EXPIRATION_TYPE, PRODUCT_TYPES, SCREEN_SIZE, VALUE_LIST_SHORTCUT } from '../../../../utils/constants'
import FieldRadio from '../../../../components/fields/FieldRadio'
import { formatDecimal } from '../../../../utils/formUtils'
import MenuItem from '@material-ui/core/MenuItem'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'

const useStyles = makeStyles(() =>
	createStyles({
		buttonRoot: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly'
		},
		rowContainer: {
			margin: 0
		}
	})
)

interface ProductFormProps {
	isLoading?: boolean,
	initialValues: RequestCreateOrUpdateProductFormModel | undefined,
	onSubmit: (event: any) => void,
	isCreationForm: boolean,
	handleUpdateProductStatus?: () => void,
}

const ProductForm: React.FC<ProductFormProps> = (
	{
		isLoading = false,
		initialValues,
		onSubmit,
		isCreationForm,
		handleUpdateProductStatus
	}
) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { valueList } = useContext(PhidemDataContext)

	const onValidate = (formValues: ProductModel) => {
		const errors: any = {}

		const isVaccin = formValues.type === PRODUCT_TYPES.VACCIN

		if (!formValues.codeCip) {
			errors.codeCip = <ErrorMessageRequired />
		}
		if (!formValues.codeUcd && isVaccin) {
			errors.codeUcd = <ErrorMessageRequired />
		}
		if (!formValues.nomCommercial) {
			errors.nomCommercial = <ErrorMessageRequired />
		}
		if (!formValues.nom) {
			errors.nom = <ErrorMessageRequired />
		}
		if (!formValues.formePharma && isVaccin) {
			errors.formePharma = <ErrorMessageRequired />
		}
		if (!formValues.substanceActive1 && isVaccin) {
			errors.substanceActive1 = <ErrorMessageRequired />
		}
		if (!formValues.typeConditionnement) {
			errors.typeConditionnement = <ErrorMessageRequired />
		}
		if (!formValues.peremptionType) {
			errors.peremptionType = <ErrorMessageRequired />
		}

		if (!!formValues.stabilite28 && formValues.stabilite28 < 1) {
			errors.stabilite28 = <ErrorPositiveValue />
		}

		if (!!formValues.stabilite1erPrelevement && formValues.stabilite1erPrelevement < 1) {
			errors.stabilite1erPrelevement = <ErrorPositiveValue />
		}

		if (!!formValues.stabiliteMiseTemperature && formValues.stabiliteMiseTemperature < 1) {
			errors.stabiliteMiseTemperature = <ErrorPositiveValue />
		}

		if (!!formValues.stabiliteReconstitution && formValues.stabiliteReconstitution < 1) {
			errors.stabiliteReconstitution = <ErrorPositiveValue />
		}

		if (!!formValues.stabilite28TransfertMoins20 && formValues.stabilite28TransfertMoins20 < 1) {
			errors.stabilite28TransfertMoins20 = <ErrorPositiveValue />
		}

		if (!formValues.nbDoses && isVaccin) {
			errors.nbDoses = <ErrorMessageRequired />
		} else if (formValues.nbDoses && formValues.nbDoses < 1) {
			errors.nbDoses = <ErrorPositiveValue />
		} else if (formValues.nbDoses && !formatDecimal(formValues.nbDoses).match(/^\d{1,3}(,\d)?$/)) {
			errors.nbDoses = <FormattedMessage
				id="product.nbDosesError"
				defaultMessage="Le nombre de doses doit contenir deux chiffres entiers maximum et ne peut avoir qu'une seule décimale"
				description="Error concerning nbDoses format"
			/>
		}

		if (!formValues.nbUnitesBoite && isVaccin) {
			errors.nbUnitesBoite = <ErrorMessageRequired />
		} else if (formValues.nbUnitesBoite && formValues.nbUnitesBoite < 1) {
			errors.nbUnitesBoite = <ErrorPositiveValue />
		}

		return errors
	}

	return (
		<>
			{(isLoading)
				?
				<Loader />
				:
				<Form
					initialValues={{ ...initialValues, nbDoses: formatDecimal(initialValues?.nbDoses || 1) }}
					onSubmit={onSubmit}
					validate={onValidate}
					render={({ handleSubmit, submitting, values }) => {
						const isVaccin = values.type === PRODUCT_TYPES.VACCIN
						return (
							<PageFormContainer onSubmit={handleSubmit}>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="product.formSection.information"
											defaultMessage="Informations du produit"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<FieldRadio
										name="type"
										label={<FormattedMessage
											id="product.type"
											defaultMessage="Type de produit"
											description="Product type selection"
										/>}
										choices={Object.keys(valueList[VALUE_LIST_SHORTCUT.PRODUCT_TYPE]).map((productType: string) => ({
											label: (valueList[VALUE_LIST_SHORTCUT.PRODUCT_TYPE] || {})[productType] || '',
											value: productType,
											disabled: !isCreationForm
										}))}
									/>
								</Grid>
								<Grid item xs={3}>
									<Field
										name="codeCip"
										component={FormInput}
										label={
											<FormattedMessage
												id="product.codeCip"
												defaultMessage="Code CIP"
												description="form input"
											/>
										}
										required
									/>
								</Grid>
								<Grid item xs={3}>
									<Field
										name="codeUcd"
										component={FormInput}
										label={
											<FormattedMessage
												id="product.codeUcd"
												defaultMessage="Code UCD"
												description="form input"
											/>
										}
										required={isVaccin}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="nomCommercial"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.nomCommercial"
													defaultMessage="Nom commercial"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="nom"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.nom"
													defaultMessage="Nom"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Field
											name="surveillanceRenforcee"
											component={FormSwitch}
											label={
												<FormattedMessage
													id="product.surveillanceRenforcee"
													defaultMessage="Surveillance renforcée"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Field
											name="commentaireBl"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.commentaireBL"
													defaultMessage="Commentaire bon de livraison"
													description="form input"
												/>
											}
											multiline
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="product.formSection.constitution"
											defaultMessage="Constitution"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={5}>
										<Field
											name="formePharma"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.formePharma"
													defaultMessage="Forme pharmaceutique"
													description="form input"
												/>
											}
											required={isVaccin}
										/>
									</Grid>
								</Grid>
								<Grid item xs={4}>
									<Field
										name="substanceActive1"
										component={FormInput}
										label={
											<FormattedMessage
												id="product.substanceActive1"
												defaultMessage="Substance active #1"
												description="form input"
											/>
										}
										required={isVaccin}
									/>
								</Grid>
								<Grid item xs={4}>
									<Field
										name="substanceActive2"
										component={FormInput}
										label={
											<FormattedMessage
												id="product.substanceActive2"
												defaultMessage="Substance active #2"
												description="form input"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={4}>
										<Field
											name="voieAdministration"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.voieAdministration"
													defaultMessage="Voie d'administration"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="product.formSection.conservation"
											defaultMessage="Conservation"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid container item xs={12} spacing={10}>
									<Grid item xs={5}>
										<Field
											name="peremptionType"
											component={FormSelect}
											label={
												<FormattedMessage
													id="product.peremption"
													defaultMessage="Expiration type"
													description="Expiration type"
												/>
											}
											required
										>
											{Object.values(PRODUCT_EXPIRATION_TYPE).map((TYPE: string) => (
												<MenuItem key={TYPE} value={TYPE}>
													<FormattedMessage
														id={`enum.expirationType.${TYPE}`}
														defaultMessage={TYPE}
														description="Expiration type"
													/>
												</MenuItem>
											))}
										</Field>
									</Grid>
									<Grid item xs={5}>
										<Field
											name="conservation"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.conservation"
													defaultMessage="Conservation"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={10}>
									<Grid item xs={5}>
										<Field
											name="stabilite28"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.stabilite28"
													defaultMessage="Stabilité 2-8°C"
													description="form input"
												/>
											}
										/>
									</Grid>
									<Grid item xs={5}>
										<Field
											name="stabiliteMiseTemperature"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.stabiliteMiseTemperature"
													defaultMessage="Stabilité mise température"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={10}>
									<Grid item xs={5}>
										<Field
											name="stabiliteReconstitution"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.stabiliteReconstitution"
													defaultMessage="Stabilité reconstitution"
													description="form input"
												/>
											}
										/>
									</Grid>
									<Grid item xs={5}>
										<Field
											name="stabilite1erPrelevement"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.stabilite1erPrelevement"
													defaultMessage="Stabilité 1er prélèvement"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={10}>
									<Grid item xs={5}>
										<Field
											name="stabilite28TransfertMoins20"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.stabilite28TransfertMoins20"
													defaultMessage="Stabilité 2-8°C transfert -20°C"
													description="form input"
												/>
											}
										/>
									</Grid>
									<Grid item xs={5}>
										<Field
											name="dureeDecongelation"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.dureeDecongelation"
													defaultMessage="Durée de décongélation"
													description="form input"
												/>
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="product.formSection.conditioning"
											defaultMessage="Conditionnement"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={6}>
										<Field
											name="typeConditionnement"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.typeConditionnement"
													defaultMessage="Type de conditionnement"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>
								{isVaccin && <Grid container item xs={12} spacing={2}>
									<Grid item xs={3}>
										<Field
											name="nbDoses"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.nbDoses"
													defaultMessage="Nombre de doses"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
									<Grid item xs={3}>
										<Field
											name="nbUnitesBoite"
											component={FormInput}
											label={
												<FormattedMessage
													id="product.nbUnitesBoite"
													defaultMessage="Nombre d'unités par boite"
													description="form input"
												/>
											}
											required
										/>
									</Grid>
								</Grid>}
								<div
									className={classes.buttonRoot}
									style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
								>
									{isCreationForm
										?
										<ButtonCreate
											type="submit"
											isLoading={submitting}
										/>
										:
										<ButtonSave
											type="submit"
											isLoading={submitting}
										/>
									}
									<ButtonActivate actif={initialValues?.actif} handleStatus={handleUpdateProductStatus} />
								</div>
							</PageFormContainer>
						)
					}}
				/>
			}
		</>
	)
}

export default ProductForm
