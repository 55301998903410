import React, { useContext } from 'react'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { injectToolbarData } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { postProduct } from './services/productApi'
import { ProductModel } from './services/productModel'
import ProductForm from './components/ProductForm'
import errorsMapper from '../../../utils/errorsMapper'
import { PRODUCT_EXPIRATION_TYPE, PRODUCT_TYPES } from '../../../utils/constants'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { REFERENTIEL } from '../../common/phidemData/phidemDataModel'
import { formatDecimal } from '../../../utils/formUtils'

const defaultValues = {
    type: PRODUCT_TYPES.VACCIN,
    codeCip: '',
    codeUcd: '',
    nomCommercial: '',
    nom: '',
    surveillanceRenforcee: false,
    formePharma: '',
    substanceActive1: '',
    substanceActive2: '',
    voieAdministration: '',
    peremptionType: PRODUCT_EXPIRATION_TYPE.MOIS,
    conservation: '',
    stabilite28: 0,
    stabiliteMiseTemperature: 0,
    stabiliteReconstitution: 0,
    stabilite1erPrelevement: 0,
    stabilite28TransfertMoins20: 0,
    dureeDecongelation: 0,
    typeConditionnement: '',
    nbDoses: 0,
    nbUnitesBoite: 0,
    commentaireBl: '',
    actif: false
}

interface ProductCreateProps {
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const ProductCreate: React.FC<ProductCreateProps> = (
    {
        snackSuccess,
        snackError
    }) => {
    const history = useHistory()
    const { reloadReferentiel } = useContext(PhidemDataContext)

    const onSubmit = async (formValues: any) => {
        formValues  = {
            ...formValues,
            nbDoses: formatDecimal(formValues.nbDoses)
        }

        return postProduct(formValues)
            .then((response: ProductModel) => {
                history.push(`/products/${response.id}`)
                snackSuccess({ id: 'product.createSuccess', defaultMessage: 'Le produit a bien été créé', description: 'Success message' })
                reloadReferentiel(REFERENTIEL.PRODUCT)
            })
            .catch((e: any) => {
                const { errors, displayFields } = errorsMapper(e)
                if (displayFields) {
                    snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
                } else {
                    snackError({ id: 'product.createError', defaultMessage: 'Une erreur est survenue, le produit n\'a pas été créé', description: 'Error message' })
                }
                return errors
            })
    }

    return (
        <ProductForm
            initialValues={defaultValues}
            onSubmit={onSubmit}
            isCreationForm={true}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="product.pageTitle.create"
        defaultMessage="Nouveau produit"
        description="Page title"
    />
})), injectSnackbarActions)(ProductCreate)
