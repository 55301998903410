import React, { useContext, useMemo, useState } from 'react'
import { Search } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { FormInput, FormSelect, SpringDataTable } from 'isotope-client'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import PageLayout from '../../../../components/layout/PageLayout'
import Button from '../../../../components/layout/buttons/Button'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import { VaccinModel } from '../../../admin/product/services/productModel'
import { CENTER_TYPE, CENTER_TYPE_LOWERCASE, DOWNLOAD_FILE_TYPE, MOVEMENT_SENS, MOVEMENT_TYPE, SELECT_VALUES } from '../../../../utils/constants'
import { RequestMovementHistorySearchModel } from './movementHistoryModel'
import NotFoundPage from '../../../../components/layout/NotFoundPage'
import { CentreModel } from '../../../common/phidemData/phidemDataModel'
import { isDateInputTypeSupported } from '../../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../../utils/form/decorators'
import FieldDatetime from '../../../../components/fields/FieldDatetime'
import ExportExcelButton from '../../../../components/layout/buttons/ExportExcelButton'
import FieldAutocomplete from '../../../../components/fields/FieldAutocomplete'
import { DATE_FORMAT, DATE_FORMAT_IN_FORM, displayDate, formatDateInForm } from '../../../../utils/dateUtils'

type MovementHistoryListRow = any

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperForm: {
			width: '70%',
			minWidth: 300,
			maxWidth: 880,
			padding: theme.spacing(2),
			marginBottom: 50
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

interface FormValues extends RequestMovementHistorySearchModel {
	startDatetime?: string
	endDatetime?: string
}

interface MovementHistoryProps {
	centerType: string
}

const DELAY = 10

const MovementHistory: React.FC<MovementHistoryProps> = ({ centerType }) => {
	const classes = useStyles()
	const intl = useIntl()
	const { vaccins, user: { selectedCenter, isDateFormatEn } } = useContext(PhidemDataContext)
	const prefixCenter = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]
	const history = useHistory()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[
				dateAndTimeDecorator('startDate', 'startTime', 'startDatetime'),
				dateAndTimeDecorator('endDate', 'endTime', 'endDatetime')
			]
		, [isDatetimeSupported])
	const initRequest = {
		startDatetime: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		startDate: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		startTime: formatDateInForm(moment().startOf('isoWeek').local(), DATE_FORMAT_IN_FORM.HOUR),
		endDatetime: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE),
		endDate: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.DATE_DASH),
		endTime: formatDateInForm(moment().add(DELAY, 'minute').local(), DATE_FORMAT_IN_FORM.HOUR)
	}

	const [filters, setFilters] = useState<RequestMovementHistorySearchModel>({
		startDate: new Date(initRequest.startDatetime).toISOString(),
		endDate: new Date(initRequest.endDatetime).toISOString()
	})

	const getMovements = (formValues: FormValues) => {
		setFilters({
			idProduit: formValues.idProduit !== SELECT_VALUES.ALL ? formValues.idProduit : undefined,
			numBl: formValues.numBl,
			destination: formValues.destination,
			startDate: new Date(formValues.startDatetime || '').toISOString(),
			endDate: new Date(formValues.endDatetime || '').toISOString()
		})
	}

	const getRowBl = (row: any) => {
		return row.referentBl || row.dispatchBl
	}

	const getCentreEnfants = () => {
		return selectedCenter.type === CENTER_TYPE.DISPATCH
			? selectedCenter.centreEnfantsByType.vaccinations
			: selectedCenter.centreEnfantsByType.refDispatchs
	}

	return (
		<PageLayout>
			<Paper className={classes.paperForm}>
				<Form
					initialValues={{
						idProduit: SELECT_VALUES.ALL,
						destination: undefined,
						...initRequest
					}}
					onSubmit={getMovements}
					// @ts-ignore
					decorators={formDecorators}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								direction="row"
								justify="flex-start"
								spacing={2}
							>
								<Grid item xs={12} md={5}>
									<Field
										name="idProduit"
										component={FormSelect}
										label={
											<FormattedMessage
												id="global.functionnal.product"
												defaultMessage="Produit"
												description="Product"
											/>
										}
										required
									>
										<MenuItem key="default" value={SELECT_VALUES.ALL}>
											<FormattedMessage
												id="select.all"
												defaultMessage="Tous"
												description="All"
											/>
										</MenuItem>
										{vaccins.map((vaccin: VaccinModel) => (
											<MenuItem
												key={vaccin.id}
												value={vaccin.id}
											>
												{vaccin.nomCommercial}
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={12} md={3}>
									<Field
										name="numBl"
										component={FormInput}
										label={
											<FormattedMessage
												id="common.movementHistory.search.bl"
												defaultMessage="N° bon de livraison"
												description="Bl number"
											/>
										}
									/>
								</Grid>
								<Grid item xs={12} md={5}>
									<Field
										name="destination"
										label={<FormattedMessage
											id="common.movementHistory.search.destination"
											defaultMessage="Destination"
											description="Destination field"
										/>}
										placeholder={intl.formatMessage({
											id: 'select.all',
											defaultMessage: 'Tous',
											description: 'All'
										})}
										component={FieldAutocomplete}
										options={getCentreEnfants().map((centre: CentreModel) => ({
											label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
											value: centre.id
										}))}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography>
										<FormattedMessage
											id="common.movementHistory.search.movementDate"
											defaultMessage="Date du mouvement"
											description="Section label for datetime"
										/>
									</Typography>
								</Grid>
								<FieldDatetime
									datetimeName="startDatetime"
									dateName="startDate"
									timeName="startTime"
									dateLabel={
										<FormattedMessage
											id="common.movementHistory.search.startDate"
											defaultMessage="Date de début"
											description="input label"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="common.movementHistory.search.startTime"
											defaultMessage="Heure de début"
											description="input label"
										/>
									}
								/>
								<FieldDatetime
									datetimeName="endDatetime"
									dateName="endDate"
									timeName="endTime"
									dateLabel={
										<FormattedMessage
											id="common.movementHistory.search.endDate"
											defaultMessage="Date de fin"
											description="input label"
										/>
									}
									timeLabel={
										<FormattedMessage
											id="common.movementHistory.search.endTime"
											defaultMessage="Heure de fin"
											description="input label"
										/>
									}
								/>
								<Grid item xs={12} md={12}>
									<div className={classes.submitButtonRoot}>
										<Button
											variant="contained"
											startIcon={<Search />}
											type="submit"
											isLoading={submitting}
										>
											<FormattedMessage
												id="button.search"
												defaultMessage="Rechercher"
												description="Search button label"
											/>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					)} />
			</Paper>
			<SpringDataTable
				apiUrl={`/${prefixCenter}/mouvement`}
				nom="movementList"
				headers={[
					{
						key: 'history.creationDate',
						name: <FormattedMessage
							id="common.movementHistory.table.creationDate"
							defaultMessage="Date"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => <span>{
							displayDate(moment(row.history.creationDate), DATE_FORMAT.DATETIME, isDateFormatEn)}</span>
					},
					{
						key: 'stock.produit.nomCommercial',
						name: <FormattedMessage
							id="global.functionnal.product"
							defaultMessage="Produit"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => <span>{row.stock.produit.nomCommercial}</span>
					},
					{
						key: 'type',
						name: <FormattedMessage
							id="common.movementHistory.table.type"
							defaultMessage="Type de mouvement"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) =>
							<span>
                                <FormattedMessage
	                                id={`enum.movementType.${row.type}`}
	                                defaultMessage="Type de mouvement"
	                                description="Header column name"
                                />
								{row.type === MOVEMENT_TYPE.MODIF_PEREMPTION &&
									<><br />{row.commentaireHistory}</>
								}
                            </span>
					},
					{
						key: 'quantite',
						name: <FormattedMessage
							id="global.functionnal.quantity"
							defaultMessage="Quantité"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => {
							if (row.type === MOVEMENT_TYPE.MODIF_PEREMPTION) {
								return <span />
							}

							return <span>
                            <span>{`${row.sens === MOVEMENT_SENS.SORTIE ? '-' : ''}${row.quantite}`}</span>
                            <FormattedMessage
	                            id="global.functionnal.conditioningType"
	                            defaultMessage={row.stock.produit.typeConditionnement}
	                            description="Conditioning type"
	                            values={
		                            {
			                            count: row.quantite,
			                            conditioningType: row.stock.produit.typeConditionnement
		                            }
	                            }
                            />
                        </span>
						}
					},
					{
						key: `${centerType}Bl.destination.nom`,
						name: <FormattedMessage
							id="global.functionnal.destination"
							defaultMessage="Destination"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => {
							if (row.sens === MOVEMENT_SENS.SORTIE) {
								const bl = getRowBl(row)
								return <span>{bl && bl.destination ? bl.destination.nom : ''}</span>
							}
							return <></>
						}
					},
					{
						key: `${centerType}Bl.numeroBl`,
						name: <FormattedMessage
							id="common.movementHistory.table.numBl"
							defaultMessage="Numéro bon de livraison"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => {
							const bl = getRowBl(row)
							return <span>{bl ? bl.numeroBl : ''}</span>
						}
					},
					{
						key: 'stock.lot',
						name: <FormattedMessage
							id="global.functionnal.batchNumber"
							defaultMessage="Numéro de lot"
							description="Header column name"
						/>,
						sortable: true,
						render: (row: MovementHistoryListRow) => <span>{row.stock.lot}</span>
					},
					{
						key: 'visibility',
						name: '',
						render: (row: MovementHistoryListRow) => {
							if (row.type === MOVEMENT_TYPE.PREPARATION || row.type === MOVEMENT_TYPE.DECHET) {
								const bl = getRowBl(row)
								return <IconButton onClick={() => {
									history.push(row.type === MOVEMENT_TYPE.PREPARATION ? `/${prefixCenter}/exits/${bl.id}` : `/${prefixCenter}/scrappings/${row.id}`)

								}}>
									<VisibilityIcon />
								</IconButton>
							} else if (row.type === MOVEMENT_TYPE.RETOUR) {
								return <IconButton onClick={() => {
									history.push(`/dispatch/return/${row.id}`)

								}}>
									<VisibilityIcon />
								</IconButton>
							}
							return <></>
						}
					}
				]}
				filters={filters}
				noResultFragment={<NotFoundPage />}
			/>
			<ExportExcelButton
				url={`/${prefixCenter}/mouvement/export`}
				fileName={DOWNLOAD_FILE_TYPE.DISPATCH_MOUVEMENT.outputFile}
				filters={filters}
			/>
		</PageLayout>
	)
}

export default MovementHistory
