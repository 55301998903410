import React from 'react'
import { CentreInfoMailingModel } from '../../modules/common/phidemData/phidemDataModel'

interface MailToProps {
	center: CentreInfoMailingModel
}

/**
 * Composant pour mailTo sur les listes de demande
 * @param center
 * @constructor
 */
const MailTo: React.FC<MailToProps> = ({ center }) => {
	if (!center) {
		return <></>
	}

	const email = [center.referentEmail, center.centerEmail].filter(item => !!item && item.length > 0).join(';')
	if (email) {
		return <a href={`mailto:${email}`}>{center.nom}</a>
	}
	return <span>{center.nom}</span>
}

export default MailTo
