import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from '../../../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: colors.primary,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            color: colors.elementOnPrimary
        },
        closeButton: {
            color: colors.elementOnPrimary,
        }
    }),
)

interface DialogTitleProps {
    handleClose?: () => void
}

const DialogTitle: React.FC<DialogTitleProps> = (
    {
        handleClose,
        children
    }
) => {
    const classes = useStyles()

    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography className={classes.title} variant="h6">{children}</Typography>
            {handleClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

export default DialogTitle
