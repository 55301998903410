import React, { useContext } from 'react'
import { RequestStockFiltersModel, ResponseStockModel } from '../../admin/stock/services/stockModel'
import { SCREEN_SIZE } from '../../../utils/constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import VaccinationStockListDesktop from './components/VaccinationStockListDesktop'
import VaccinationStockListMobile from './components/VaccinationStockListMobile'
import { OfflineContext } from '../offline/context/OfflineContext'

interface VaccinationStockListProps {
	filters: RequestStockFiltersModel
	openDialog?: (row: ResponseStockModel) => void
	showCenter?: boolean
	showActions?: boolean
	isOpenDialog: boolean
}

const VaccinationStockList: React.FC<VaccinationStockListProps> = (
	{
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false,
		isOpenDialog
	}
) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { offlineMode } = useContext(OfflineContext)
	const listProps = {
		filters,
		openDialog,
		showCenter,
		showActions
	}

	return (<>
		{(isLargeScreen && !offlineMode) ? <VaccinationStockListDesktop {...listProps} /> : <VaccinationStockListMobile {...listProps} isOpenDialog={isOpenDialog} />}
	</>)
}

export default VaccinationStockList
