import React from 'react'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { ReplenishmentDialogDataModel } from '../../ReplenishmentModel'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import DialogReplenishmentBody from '../DialogReplenishmentBody'

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		}
	})
)

interface DialogReplenishmentRefuseProps {
	isOpen: boolean
	loading: boolean
	handleClose: () => any
	onSubmit: (value: any) => any,
	replenishment: ReplenishmentDialogDataModel
}

const DialogReplenishmentRefuse: React.FC<DialogReplenishmentRefuseProps> = (
	{
		isOpen,
		loading,
		handleClose,
		onSubmit,
		replenishment
	}
) => {
	const classes = useStyles()

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (!formValues.comment) {
			errors.comment = <ErrorMessageRequired />
		}

		return errors
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={onSubmit}
			render={({ values, handleSubmit }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.replenishmentList.dialog.refuse.title"
							defaultMessage="Refuser la demande de réapprovisionnement"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.cancel"
								defaultMessage="Annuler"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => handleSubmit(values)} isLoading={loading}>
							<FormattedMessage
								id="button.refuse"
								defaultMessage="Refuser"
								description="Button label"
							/>
						</Button>
					]}
				>
					<DialogReplenishmentBody replenishment={replenishment} />
					<Field
						name="comment"
						component={FormInput}
						className={classes.inputContainer}
						label={
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.refuse.comment"
								defaultMessage="Raison du refus"
								description="Comment label input"
							/>
						}
						required
					/>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentRefuse
