import React from 'react'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core/styles'
import { SCREEN_SIZE } from '../../utils/constants'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: 64
		},
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'flex-start'
		},
		containerStyle: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center'
		},
		formDesktop: {
			margin: theme.spacing(3),
			marginLeft: '13vw',
			width: 600,
			alignItems: 'flex-start'
		},
		formDesktopLarge: {
			margin: theme.spacing(3),
			marginLeft: '13vw',
			width: 800,
			alignItems: 'flex-start'
		},
		formMobile: {
			margin: theme.spacing(1),
			marginLeft: theme.spacing(1),
			width: 300,
			alignItems: 'center'
		}
	})
)

interface PageFormContainerProps {
	isLarge?: boolean,
	onSubmit: (event: any) => void,
	theme: Theme
}

const PageFormContainer: React.FC<PageFormContainerProps> = (
	{
		isLarge = false,
		children,
		theme,
		...rest
	}
) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)

	return (
		<div className={classNames({ [classes.containerStyle]: !isLargeScreen })}>
			<form
				className={classNames(
					classes.form,
					isLargeScreen ? (isLarge ? classes.formDesktopLarge : classes.formDesktop) : classes.formMobile
				)}
				{...rest}
			>
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					xs={10} sm={12}
					spacing={2}
					children={children}
				/>
			</form>
		</div>
	)
}

export default withTheme(PageFormContainer)
