import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../../components/layout/buttons/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '../../../../../components/layout/dialog/Dialog'

interface DialogPrepareProductReturnProps {
    isOpen: boolean
    handleClose: () => any
    onConfirm: () => any
}

const DialogPrepareProductReturn: React.FC<DialogPrepareProductReturnProps> = (
    {
        isOpen,
        handleClose,
        onConfirm
    }
) => {
    return (
        <Dialog
            title={
                <FormattedMessage
                    id="common.exitStock.dialogDefrostedProduct.title"
                    defaultMessage="Retours de produits disponibles"
                    description='Dialog title'
                />
            }
            isOpen={isOpen}
            handleClose={handleClose}
            buttons={[
                <Button onClick={handleClose} color="primary" key={0} variant="outlined">
                    <FormattedMessage
                        id="button.no"
                        defaultMessage="NON"
                        description="Button label"
                    />
                </Button>,
                <Button color="primary" key={1} variant="contained" onClick={onConfirm}>
                    <FormattedMessage
                        id="button.yes"
                        defaultMessage="OUI"
                        description="Button label"
                    />
                </Button>
            ]}
        >
            <Typography gutterBottom>
                <FormattedMessage
                    id="common.exitStock.dialogDefrostedProduct.content"
                    defaultMessage="Des retours sont présents pour ce produit, souhaitez-vous les utiliser ?"
                    description="Dialog content"
                />
            </Typography>
        </Dialog>
    )
}

export default DialogPrepareProductReturn
