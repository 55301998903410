import React from 'react'

const SvgUser = () => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={72.162}
    height={87.793}
>
  <path
      d="M71.74 87.418H.421a35.909 35.909 0 0126.981-30.617 36.28 36.28 0 018.684-1.054A35.9 35.9 0 0171.74 87.418z"
      fill="#fbfbfb"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      d="M44.769 56.812v30.606H27.402V56.801a36.28 36.28 0 018.684-1.054 35.66 35.66 0 018.683 1.065z"
      fill="#e8d654"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      fill="#3dd380"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
      d="M8.193 76.722h14.832v4.654H8.193z"
  />
  <path
      d="M31.144.375h9.873a14.81 14.81 0 0114.81 14.81v10.19a17.333 17.333 0 01-17.333 17.336h-4.827a17.333 17.333 0 01-17.333-17.336v-10.19A14.81 14.81 0 0131.144.375z"
      fill="#4670eb"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      d="M36.08 65.35h0a8.631 8.631 0 008.632-8.632v-16.73H27.449v16.73a8.631 8.631 0 008.631 8.632z"
      fill="#fbfbfb"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      d="M27.45 47.3v.39a17.465 17.465 0 0017.261 3.874V47.3a17.468 17.468 0 01-17.261 0z"
      fill="#342d3a"
  />
  <path
      d="M58.655 26.497a5.47 5.47 0 00-5.138-2.459V21.31c0-10.249-7.807-18.556-17.44-18.556A16.861 16.861 0 0023.746 8.19a19.091 19.091 0 00-5.108 13.12v2.708a5.47 5.47 0 10.383 10.895c.1.482.206.958.337 1.425a18.109 18.109 0 008.089 10.848 16.559 16.559 0 0017.261 0h.005a18.169 18.169 0 008.084-10.848c.135-.482.247-.973.345-1.471a5.466 5.466 0 005.513-8.37z"
      fill="#fbfbfb"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      d="M41.578 22.581a10.231 10.231 0 014.348-.826 9.825 9.825 0 014.243 1.033M31.297 22.581a10.224 10.224 0 00-4.347-.826 9.83 9.83 0 00-4.244 1.033M41.578 27.009a4.6 4.6 0 013.277-1.245 4.79 4.79 0 013.2 1.553"
      fill="none"
      stroke="#342d3a"
      strokeLinecap="round"
      strokeLinejoin="round"
  />
  <path
      d="M43.449 27.015a1.367 1.367 0 111.367 1.367 1.367 1.367 0 01-1.367-1.367z"
      fill="#342d3a"
  />
  <path
      d="M30.873 27.009a4.6 4.6 0 00-3.276-1.245 4.794 4.794 0 00-3.2 1.553"
      fill="none"
      stroke="#342d3a"
      strokeLinecap="round"
      strokeLinejoin="round"
  />
  <path
      d="M29.003 27.015a1.367 1.367 0 10-1.367 1.367 1.367 1.367 0 001.367-1.367z"
      fill="#342d3a"
  />
  <path
      d="M39.766 38.858h-7.23a3.615 3.615 0 007.23 0z"
      fill="none"
      stroke="#342d3a"
      strokeLinecap="round"
      strokeLinejoin="round"
  />
  <path
      d="M36.547 27.015l1.77 7.894H34.6"
      fill="none"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
  <path
      d="M31.802 2.754h22.177V4.61A13.329 13.329 0 0140.65 17.939H18.473h0v-1.856A13.329 13.329 0 0131.802 2.754z"
      fill="#4670eb"
      stroke="#131a2d"
      strokeMiterlimit={10}
      strokeWidth={0.75}
  />
</svg>)

export default SvgUser
