import React from 'react'

const SvgDispatchExit = () => (<svg
  xmlns="http://www.w3.org/2000/svg"
  width={53.649}
  height={66.851}
>
  <g data-name="Groupe 319" transform="translate(-140.295 -246.602)">
    <path
      data-name="Trac\xE9 1092"
      d="M193.6 273.217h-52.961v-24.939a1.676 1.676 0 011.676-1.676h49.609a1.677 1.677 0 011.677 1.676z"
      fill="#dcdcdc"
    />
    <path
      data-name="Trac\xE9 1093"
      d="M191.564 260.2a4.191 4.191 0 00-4.141-3.543h-40.608a4.192 4.192 0 00-4.141 3.544l-2.174 13.93a16.749 16.749 0 00-.2 2.591v20.247a2.515 2.515 0 002.515 2.514h48.62a2.515 2.515 0 002.514-2.514v-20.247a16.772 16.772 0 00-.2-2.592z"
      fill="#0082aa"
    />
    <path
      data-name="Trac\xE9 1094"
      d="M147.859 280.133V294.8l2.725 4.68H143.4v-20.509z"
      fill="#006e96"
    />
    <path
      data-name="Trac\xE9 1095"
      d="M153.429 312.615a.839.839 0 01-.839.838h-8.628a.838.838 0 01-.838-.838v-4.61h10.305z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1096"
      d="M186.381 280.133V294.8l-2.725 4.68h7.187v-20.509z"
      fill="#006e96"
    />
    <path
      data-name="Trac\xE9 1097"
      d="M191.115 312.615a.838.838 0 01-.838.838h-8.627a.838.838 0 01-.838-.838v-4.61h10.3z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1098"
      d="M190.466 279.563a1.688 1.688 0 01-1.266.57h-44.16a1.69 1.69 0 01-1.266-.57 1.663 1.663 0 01-.394-1.325l.554-4.175 1.508-11.333a1.683 1.683 0 011.668-1.458h40.02a1.683 1.683 0 011.668 1.458l.821 6.2.889 6.623.352 2.681a1.663 1.663 0 01-.394 1.329z"
      fill="#414042"
    />
    <rect
      data-name="Rectangle 423"
      width={53.649}
      height={8.941}
      rx={2.515}
      transform="translate(140.295 299.483)"
      fill="#787878"
    />
    <path
      data-name="Rectangle 424"
      d="M159.924 285.441h14.392a.838.838 0 01.838.838v2.795a.838.838 0 01-.838.838h-14.391a.839.839 0 01-.839-.839v-2.794a.838.838 0 01.838-.838z"
      fill="#ffdc00"
    />
    <path
      data-name="Trac\xE9 1099"
      d="M181.162 299.482h-28.083v-.559a2.515 2.515 0 012.515-2.515h23.053a2.515 2.515 0 012.515 2.515z"
      fill="#00b4c8"
    />
    <path
      data-name="Rectangle 425"
      d="M144.146 302.154h5.868a.839.839 0 01.839.839v1.921a.838.838 0 01-.838.838h-5.868a.839.839 0 01-.839-.839v-1.921a.838.838 0 01.838-.838z"
      fill="#fff"
    />
    <rect
      data-name="Rectangle 426"
      width={7.544}
      height={3.598}
      rx={0.838}
      transform="translate(183.388 302.154)"
      fill="#fff"
    />
    <path
      data-name="Trac\xE9 1100"
      d="M167.12 299.482h9.588l-3.354 8.941h-12.468l-3.354-8.941z"
      fill="#fff"
      opacity={0.15}
      style={{
        mixBlendMode: "screen",
        isolation: "isolate",
      }}
    />
    <path
      data-name="Trac\xE9 1101"
      d="M174.036 302.137H160.2a.419.419 0 110-.838h13.833a.419.419 0 110 .838z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1102"
      d="M173.268 304.373h-12.3a.419.419 0 110-.838h12.3a.419.419 0 110 .838z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1103"
      d="M172.22 306.607h-10.2a.419.419 0 110-.837h10.2a.419.419 0 110 .837z"
      fill="#414042"
    />
    <path
      data-name="Trac\xE9 1104"
      d="M166.8 261.272l-18.86 18.861h-2.9a1.69 1.69 0 01-1.266-.57 1.663 1.663 0 01-.394-1.325l.554-4.175 12.792-12.791z"
      fill="#fff"
      opacity={0.1}
      style={{
        mixBlendMode: "screen",
        isolation: "isolate",
      }}
    />
    <path
      data-name="Trac\xE9 1105"
      d="M190.508 275.557l-4.577 4.576h-7.511l11.2-11.2z"
      fill="#fff"
      opacity={0.1}
      style={{
        mixBlendMode: "screen",
        isolation: "isolate",
      }}
    />
    <path
      data-name="Trac\xE9 1106"
      d="M180.812 308.424v4.191a.836.836 0 00.837.838h8.628a.837.837 0 00.838-.838v-4.191h.315a2.513 2.513 0 002.514-2.514V302a2.515 2.515 0 00-2.514-2.516 2.513 2.513 0 002.514-2.513v-20.25a16.658 16.658 0 00-.2-2.592l-.142-.912v-24.938a1.677 1.677 0 00-1.677-1.677h-24.8v61.822z"
      fill="#fff"
      opacity={0.15}
      style={{
        mixBlendMode: "screen",
        isolation: "isolate",
      }}
    />
  </g>
</svg>)

export default SvgDispatchExit
