import React from 'react'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SCREEN_SIZE } from '../../../../../utils/constants'
import { Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import { Grid } from '@material-ui/core'
import LabelSelectionLine from './LabelSelectionLine'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { ExitStockPrintModel } from '../services/exitStockModels'
import { DispatchReferentLigneBlModel } from '../../stock/stockModels'

const useStyles = (isLargeScreen: boolean) => makeStyles((theme) => createStyles({
	submitButtonRoot: {
		width: '100%',
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'flex-end'
	},
	marginComment: {
		marginBottom: 30
	},
	table: {
		width: '100%'
	},
	title: {
		marginTop: 5
	},
	dialogContainer: {
		...(isLargeScreen && { minWidth: 500 }),
		minHeight: 525
	}
}))

interface DialogLabelSelectionProps {
	handleClose: () => void
	downloadDocument: (values: any) => void
	data: ExitStockPrintModel | undefined
	isLoadingDocument: boolean
}

const DialogLabelSelection = ({ handleClose, downloadDocument, data, isLoadingDocument }: DialogLabelSelectionProps) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const classes = useStyles(isLargeScreen)()

	const submit = (values: any) => {
		const formattedValues = {...values}
		// On récupère les clés des lignes produits dont on veut une génération d'étiquettes multiples
		const valuesKeys = Object.entries(values).map(([key]) => key.substr(1, 1))

		if (!!data) {
			// On insère dans les values les lignes produits non modifiées (= étiquette originelle)
			data.produits.forEach((element: DispatchReferentLigneBlModel, index: number) => {
				if (valuesKeys.indexOf(`${index}`) === -1) {
					formattedValues[`#${index}${element.produit.nomCommercial}`] = {
						nbRestant: element.quantite
					}
				}
			})

			// On construit l'objet de retour
			// Pour chaque ligne produit: nbRestant = nb unités ; lignes = nb étiquettes personnalisées
			const result = Object.entries(formattedValues)
				.sort()
				.map(([key, dataValues]) => {
					// Si le nombre restant est déjà calculé, on n'a donc pas de boxes
					// @ts-ignore
					if (!!dataValues.nbRestant) {
						return dataValues
					}

					// Sinon on récupère les boxes et calcule le nombre restant
					const index = parseInt(key.substr(1,1))

					// @ts-ignore
					const totalBottles = dataValues.map((division: any) => (parseInt(division.boxes) || 0) * (parseInt(division.bottles) || 0))
						.reduce((p: number, c: number) => p + c)

					return {
						nbRestant: ((data?.produits[index].quantite || 0) - totalBottles),
						// @ts-ignore
						lignes: dataValues.filter((line: any) => line.boxes !== '0' && line.bottles !== '0')
					}
				})
			downloadDocument(result)
		}
	}

	return (
		<Dialog
			isOpen={true}
			title={
				<FormattedMessage
					id="common.exitStock.dialogLabelSelection.title"
					defaultMessage="Génération des étiquettes"
					description="Dialog title"
				/>
			}
			containerClass={classes.dialogContainer}
			handleClose={handleClose}
		>
			<Form
				key="labels"
				onSubmit={submit}
				mutators={{ ...arrayMutators }}
				render={({ handleSubmit, submitting, form: { mutators: { push } }, values, errors }) => {
					return (
						<Grid container xs={12}>
							<FieldArray name="vaccines">
								{() => data?.produits.map((vaccine, index) => (
									<Field
										key={vaccine.id}
										name={vaccine.produit.nomCommercial}
										render={() => <LabelSelectionLine
											key={vaccine.id}
											data={vaccine}
											push={push}
											values={values[`#${index}${vaccine.produit.nomCommercial}`] && values[`#${index}${vaccine.produit.nomCommercial}`].length > 0 ?
												values[`#${index}${vaccine.produit.nomCommercial}`]
												:
												[]
											}
											vaccineIndex={index}
										/>}
									/>
								))}
							</FieldArray>
							<Grid item xs={12}>
								<div className={classes.submitButtonRoot}>
									<Button variant="contained" isLoading={isLoadingDocument} onClick={handleSubmit}>
										<FormattedMessage
											id="button.print.initial"
											defaultMessage="Imprimer"
											description="Message on form submission button"
										/>
									</Button>
								</div>
							</Grid>
						</Grid>
					)
				}}
			/>
		</Dialog>
	)
}

export default DialogLabelSelection
