import React from 'react'
import { FormattedMessage } from 'react-intl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from './Button'
import { PowerSettingsNew } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(2),
        }
    })
)

interface ButtonActivateProps {
    handleStatus?: () => void
    actif?: boolean
}

const ButtonActivate: React.FC<ButtonActivateProps> = (
    {
        handleStatus,
        actif
    }
) => {
    const classes = useStyles()

    if (!handleStatus) {
        return <></>
    }

    return (<Button
        className={classes.button}
        variant="contained"
        startIcon={<PowerSettingsNew />}
        onClick={handleStatus}
    >
        {actif ?
            <FormattedMessage
                id="global.disable"
                defaultMessage="Désactiver"
                description="Disable action"
            />
            :
            <FormattedMessage
                id="global.enable"
                defaultMessage="Activer"
                description="Enable action"
            />
        }
    </Button>)
}

export default ButtonActivate
