import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CENTER_TYPE, SELECT_VALUES } from '../../../../utils/constants'
import PageLayout from '../../../../components/layout/PageLayout'
import DialogEditStock from '../DialogEditStock'
import { RequestStockFiltersModel, ResponseStockModel } from '../services/stockModel'
import VaccinationStockList from '../../../common/stock/VaccinationStockList'
import StockForm, { StockListFormValues } from '../../../common/stock/StockForm'
import CollapseWithTitle from '../../../../components/layout/CollapseWithTitle'
import StockList from '../../../common/dispatchAndReferent/stock/StockList'

type StockAdminProps = {
	fromReferentAffiliatedView?: boolean
}

const StockAdmin: React.FC<StockAdminProps> = ({ fromReferentAffiliatedView = false }) => {
	const [filters, setFilters] = useState<RequestStockFiltersModel>({
		idProduit: undefined,
		idCentre: undefined,
		quantiteNonNulle: true,
		onlyRetour: false,
		fromReferentAffiliatedView
	})
	const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
	const [selectedRow, setSelectedRow] = useState<ResponseStockModel | undefined>(undefined)

	const openDialog = (row: ResponseStockModel) => {
		setSelectedRow(row)
		setIsOpenDialog(true)
	}

	const closeDialog = () => setIsOpenDialog(false)

	const getStocks = (formValues: StockListFormValues) => {
		setFilters({
			idProduit: formValues.idProduit === SELECT_VALUES.ALL ? undefined : formValues.idProduit,
			idCentre: formValues.idCentre === SELECT_VALUES.ALL ? undefined : formValues.idCentre,
			lot: formValues.batchNumber,
			quantiteNonNulle: !formValues.isVisibleOutOfStock,
			onlyRetour: formValues.onlyRetour,
			fromReferentAffiliatedView
		})
	}

	return (
		<PageLayout>
			<StockForm onSubmit={getStocks} showRetourFilter />
			<CollapseWithTitle
				title={
					<FormattedMessage
						id="stockEdit.referentCenters"
						defaultMessage="Centres référent"
						description="List title"
					/>
				}
			>
				<StockList
					centerType={CENTER_TYPE.REFERENT}
					filters={filters}
					openDialog={openDialog}
					isOpenDialog={isOpenDialog}
					showCenter
					showActions
				/>
			</CollapseWithTitle>
			<CollapseWithTitle
				title={
					<FormattedMessage
						id="stockEdit.dispatchCenters"
						defaultMessage="Centres de dispatch"
						description="List title"
					/>
				}
			>
				<StockList
					centerType={CENTER_TYPE.DISPATCH}
					filters={filters}
					openDialog={openDialog}
					isOpenDialog={isOpenDialog}
					showCenter
					showActions
				/>
			</CollapseWithTitle>
			<CollapseWithTitle
				title={
					<FormattedMessage
						id="stockEdit.vaccinationCenters"
						defaultMessage="Centres de vaccination"
						description="List title"
					/>
				}
			>
				<VaccinationStockList
					filters={filters}
					openDialog={openDialog}
					isOpenDialog={isOpenDialog}
					showCenter
					showActions
				/>
			</CollapseWithTitle>
			{selectedRow && <DialogEditStock
				isOpen={isOpenDialog}
				handleClose={closeDialog}
				selectedRow={selectedRow}
			/>}
		</PageLayout>
	)
}

export default StockAdmin
