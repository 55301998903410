import { fetchFactory } from 'isotope-client'
import { ReplenishmentStatusUpdateModel } from '../../common/replenishment/ReplenishmentModel'
import { RequestPointNeedsFormModel } from './pointVaccinationModels'

export const postPointNeeds = (values: RequestPointNeedsFormModel) => fetchFactory(`/point-vaccination/besoin`, {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updatePointReplenishmentStatus = (values: ReplenishmentStatusUpdateModel) => fetchFactory('/point-vaccination/besoin', {
    method: 'PUT',
    body: JSON.stringify(values)
})