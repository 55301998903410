import React from 'react'

const SvgPictoLogo = (props: any) => (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={88.271}
    height={74.966}
    {...props}
>
  <path
      d="M76.223 33.41c-.878.74-1.777 1.464-2.63 2.224-3.037 2.7-4.775 2.742-7.859.057-1.735-1.511-3.376-3.1-5.072-4.639-5.14-4.673-6.65-4.773-12.382-.843-4.372 3-7.137 2.9-11.167-.517-3.12-2.643-6.105-5.4-9.247-8.022-4.372-3.65-8.54-4.046-13.773-1.435-3.315 1.654-6.636 3.3-9.932 4.979-4.7 2.4-5.579 5.914-1.8 9.243a274.76 274.76 0 0016.134 13.155c3.956 2.988 8.823 2.67 12.882-.3q2.953-2.163 5.931-4.3c3.928-2.828 6.949-2.735 10.665.387 2.168 1.822 4.209 3.753 6.4 5.555 3.733 3.068 6.263 2.969 9.693-.27.831-.785 1.658-1.572 2.5-2.348 2.736-2.517 4.471-2.575 7.346-.135 1.757 1.491 3.245 3.237 5.152 4.562 1.085.753 3 1.5 4.149 1.194 4.805-1.288 6.705-7.869 3.413-11.061-2.544-2.466-5.124-4.906-7.687-7.358a36.274 36.274 0 003.552-2.484c3.123-2.779 3.11-3.828.124-6.8-1.464-1.458-2.969-2.886-4.466-4.32-4.248-4.066-4.892-4.1-9.808-.583-3.013 2.157-3.2 3.59-.514 6.218 2.725 2.67 5.594 5.236 8.4 7.848m-3.243 31.054c-.459-1.075-.578-2.273-1.339-2.98-3.333-3.1-6.7-6.2-10.369-9a4.651 4.651 0 00-6.085.451c-2.022 1.8-4.08 3.569-6.061 5.4-2.327 2.151-2.524 4.759-.147 6.936 3.211 2.941 6.6 5.759 10.107 8.45a5.074 5.074 0 007.311-.68q2.708-2.928 5.2-5.993a14.585 14.585 0 001.379-2.581M49.863 4.711c-2.2 1.028-4.277 1.957-6.306 2.957-3.187 1.572-3.629 3.089-1.312 5.5 1.99 2.074 4.263 3.955 6.427 5.908 5.7 5.141 6.276 5.175 12.725.8 2.8-1.9 3.136-3.2.89-5.5-2.586-2.653-5.34-5.2-8.216-7.634-1.054-.891-2.665-1.313-4.209-2.037m20.223-4.7c-2.708.055-4.707 2.236-3.554 3.548a42.17 42.17 0 004.91 4.691c1.765 1.45 3.262.126 4.269-.873a2.459 2.459 0 00-.085-2.606 23.964 23.964 0 00-3.679-3.71 8.931 8.931 0 00-1.861-1.05"
      fill="#fefefd"
  />
  <path
      d="M78.936 33.532c2.564 2.452 5.143 4.891 7.687 7.357 3.292 3.192 1.392 9.773-3.413 11.061-1.145.307-3.064-.441-4.149-1.194-1.908-1.325-3.4-3.071-5.152-4.562-2.875-2.44-4.611-2.382-7.346.135-.843.776-1.67 1.563-2.5 2.348-3.431 3.239-5.96 3.339-9.693.27-2.191-1.8-4.232-3.732-6.4-5.554-3.716-3.122-6.737-3.215-10.665-.387q-2.975 2.142-5.931 4.3c-4.06 2.974-8.926 3.293-12.882.3A275.904 275.904 0 012.358 34.451c-3.775-3.329-2.9-6.846 1.8-9.243 3.3-1.68 6.618-3.325 9.933-4.979 5.232-2.611 9.4-2.215 13.772 1.435 3.142 2.623 6.127 5.379 9.247 8.022 4.03 3.414 6.8 3.515 11.167.517 5.732-3.93 7.242-3.83 12.381.843 1.7 1.542 3.337 3.129 5.073 4.639 3.083 2.685 4.822 2.647 7.859-.057.853-.76 1.752-1.484 2.63-2.224l2.713.122"
      fill="#e94f28"
  />
  <path
      d="M72.98 64.463a14.587 14.587 0 01-1.379 2.581q-2.49 3.063-5.2 5.994a5.074 5.074 0 01-7.311.68c-3.507-2.69-6.9-5.509-10.107-8.45-2.377-2.177-2.18-4.785.147-6.936 1.981-1.83 4.038-3.6 6.061-5.4a4.651 4.651 0 016.085-.451c3.672 2.8 7.036 5.9 10.369 9 .761.707.88 1.905 1.339 2.981"
      fill="#383838"
  />
  <path
      d="M49.863 4.7c1.544.724 3.155 1.146 4.209 2.037a116.52 116.52 0 018.216 7.634c2.246 2.3 1.914 3.6-.89 5.5-6.45 4.372-7.028 4.339-12.725-.8-2.165-1.953-4.437-3.835-6.427-5.908-2.316-2.413-1.875-3.931 1.312-5.5 2.028-1 4.106-1.929 6.306-2.957"
      fill="#19ad8d"
  />
  <path
      d="M78.936 33.532l-2.713-.122c-2.806-2.612-5.674-5.178-8.4-7.848-2.682-2.628-2.5-4.061.514-6.218 4.916-3.519 5.56-3.483 9.808.583 1.5 1.433 3 2.862 4.466 4.32 2.986 2.974 3 4.022-.124 6.8a36.282 36.282 0 01-3.552 2.485"
      fill="#3a3a3a"
  />
  <path
      d="M70.081 0a8.931 8.931 0 011.861 1.05 23.965 23.965 0 013.679 3.71 2.459 2.459 0 01.085 2.606c-1.007 1-2.5 2.322-4.269.873a42.171 42.171 0 01-4.91-4.691C65.374 2.236 67.373.055 70.081 0"
      fill="#3e3e3e"
  />
</svg>)

export default SvgPictoLogo
