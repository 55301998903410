import React, { useContext } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { FormattedMessage } from 'react-intl'
import { Field, Form } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import { FormInput, FormSelect, injectToolbarData } from 'isotope-client'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { useHistory } from 'react-router-dom'
import { OnChange } from 'react-final-form-listeners'
import FieldProduct from '../../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../../components/fields/FieldUnitProduct'
import FieldQuantity from '../../../../components/fields/FieldQuantity'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import { PhidemDataContext } from '../../../common/phidemData/PhidemDataContext'
import Loader from '../../../../components/layout/Loader'
import Button from '../../../../components/layout/buttons/Button'
import PageFormContainer from '../../../../components/layout/PageFormContainer'
import { VaccinModel } from '../../../admin/product/services/productModel'
import TotalCountCaption from '../../../../components/fields/TotalCountCaption'
import { CENTER_TYPE_LOWERCASE, SCRAPPING_OTHER_OPTION, SCREEN_SIZE, VALUE_LIST_SHORTCUT } from '../../../../utils/constants'
import { getStockForScrapping, postDechetStock } from './services/scrappingApi'
import { ScrappingStockModel } from './services/scrappingModels'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submitButtonRoot: {
            width: '100%',
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-start'
        },
        dosageContainer: {
            margin: '-18px 0 6px 12px'
        }
    }),
)

interface Values {
    product: string
    unitProduct: string
    quantity: number
    idStock: string
    doseTotal: number
    scrappingCause: string
    scrappingComment: string
}

interface ScrappingProps {
    centerType: string,
    snackSuccess: (value: any) => void,
    snackError: (value: any) => void,
}

const Scrapping: React.FC<ScrappingProps> = (
    {
        centerType,
        snackSuccess,
        snackError
    }) => {
    const [isLoadingStock, setIsLoadingStock] = React.useState<boolean>(false)
    const [stocks, setStocks] = React.useState<ScrappingStockModel[]>([])
    const [showSecondPart, setShowSecondPart] = React.useState<boolean>(false)
    const [selectedProduit, setSelectedProduit] = React.useState<VaccinModel | undefined>(undefined)
    const [doseTotal, setDoseTotal] = React.useState<number | undefined>()
    const prefixApiUrl = CENTER_TYPE_LOWERCASE[centerType as keyof typeof CENTER_TYPE_LOWERCASE]

    const { vaccins, valueList } = useContext(PhidemDataContext)
    const classes = useStyles()
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
    const history = useHistory()
    const scrappingCauseOptions = valueList[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE] || {}

    const onSubmit = async (values: Values) => {
        return postDechetStock(prefixApiUrl, {
            idProduit: values.product,
            quantite: values.quantity,
            idStock: values.idStock,
            cause: values.scrappingCause,
            commentaire: values.scrappingComment
        })
            .then((response: any) => {
                snackSuccess({ id: 'common.scrapping.success', defaultMessage: 'La mise en déchet a bien été prise en compte', description: 'Success message' })
                history.push(`/${prefixApiUrl}/scrappings/${response.id}`)
            })
            .catch((e: any) => {
                if (e.bodyError && e.bodyError.fieldErrors && e.bodyError.fieldErrors.length > 0) {
                    // Affichage du warning quantité
                    snackError({ id: e.bodyError.fieldErrors[0].code, defaultMessage: 'Erreur de quantité', description: 'Error message' })
                } else {
                    snackError({ id: 'global.error.occured', defaultMessage: 'Une erreur est survenue', description: 'Error message' })
                }
            })
    }

    const getStock = (idProduit: string) => {
        setIsLoadingStock(true)
        setShowSecondPart(false)
        setSelectedProduit(vaccins.find(vaccin => vaccin.id === idProduit))
        getStockForScrapping(prefixApiUrl, idProduit)
            .then((stocks: ScrappingStockModel[]) => setStocks(stocks))
            .finally(() => {
                setIsLoadingStock(false)
                setShowSecondPart(true)
            })
    }

    const resetSecondPartFields = (form: any) => {
        form.change('quantity', undefined)
        form.change('doseTotal', undefined)
        form.change('idStock', undefined)
        form.change('scrappingCause', undefined)
    }

    const onValidate = (values: Values) => {
        const errors: any = {}

        if (!values.product) {
            errors.product = <ErrorMessageRequired />
        }
        if (!values.unitProduct) {
            errors.unitProduct = <ErrorMessageRequired />
        }
        if (!values.quantity) {
            errors.quantity = <ErrorMessageRequired />
        }
        if (!values.idStock) {
            errors.idStock = <ErrorMessageRequired />
        }
        if (!values.scrappingCause) {
            errors.scrappingCause = <ErrorMessageRequired />
        } else if (values.scrappingCause === SCRAPPING_OTHER_OPTION && !values.scrappingComment) {
            errors.scrappingComment = <ErrorMessageRequired />
        }

        return errors
    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={onValidate}
            render={({ handleSubmit, submitting, form, values }) => (
                <PageFormContainer
                    onSubmit={handleSubmit}
                >
                    <Grid item xs={12}>
                        <Typography variant={isLargeScreen ? 'h1' : 'h3'}>
                            <FormattedMessage
                                id="common.scrapping.formSection.productID"
                                defaultMessage="Identification du produit"
                                description="Form section title"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <FieldProduct required />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FieldUnitProduct />
                    </Grid>

                    <OnChange name="product">{(value) => {
                        resetSecondPartFields(form)
                        getStock(value)
                    }}</OnChange>
                    {isLoadingStock && <Loader />}

                    {showSecondPart && <>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id='common.scrapping.formSection.receiving'
									defaultMessage='2. Identifier le lot'
									description='Form section title'
								/>
							</Typography>
						</Grid>
                        {stocks.length > 0 ? <>
                                <Grid item xs={12}>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="idStock"
                                            component={FormSelect}
                                            label={
                                                <FormattedMessage
                                                    id="global.functionnal.batch"
                                                    defaultMessage="Lot"
                                                    description="Batch number"
                                                />
                                            }
                                            required
                                        >
                                            {stocks.map((stock: ScrappingStockModel) => (
                                                <MenuItem key={stock.id} value={stock.id}>{stock.lot}</MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <FieldQuantity required />
                                    <OnChange name="quantity">{(value) => {
                                        setDoseTotal(value * (selectedProduit ? selectedProduit.nbDoses : 0))
                                    }}</OnChange>
                                </Grid>
                                <TotalCountCaption count={doseTotal} />

                                <Grid item xs={12}>
                                    <Grid item xs={12} md={8}>
                                        <Field
                                            name="scrappingCause"
                                            component={FormSelect}
                                            label={
                                                <FormattedMessage
                                                    id="common.scrapping.scrappingCause"
                                                    defaultMessage="Cause du déchet"
                                                    description="Scrapping cause"
                                                />
                                            }
                                            required
                                        >
                                            {Object.keys(scrappingCauseOptions).map((optionKey: string) => (
                                                <MenuItem key={optionKey} value={optionKey}>{scrappingCauseOptions[optionKey]}</MenuItem>
                                            ))}
                                            <MenuItem key={SCRAPPING_OTHER_OPTION} value={SCRAPPING_OTHER_OPTION}>
                                                <FormattedMessage
                                                    id="common.scrapping.scrappingOtherOption"
                                                    defaultMessage="Autre"
                                                    description="Scrapping other option"
                                                />
                                            </MenuItem>
                                        </Field>
                                        <OnChange name="scrappingCause">{() => form.change('scrappingComment', '')}</OnChange>
                                        {values.scrappingCause === SCRAPPING_OTHER_OPTION && <Field
                                            name="scrappingComment"
                                            component={FormInput}
                                            label={
                                                <FormattedMessage
                                                    id="common.scrapping.scrappingComment"
                                                    defaultMessage="Commentaire"
                                                    description="Scrapping comment"
                                                />
                                            }
                                            required
                                        />}
                                    </Grid>
                                </Grid>
                                <div className={classes.submitButtonRoot}>
                                    <Button variant='contained' isLoading={submitting} onClick={handleSubmit}>
                                        <FormattedMessage
                                            id="button.validate"
                                            defaultMessage="Valider"
                                            description="Message on form submission button"
                                        />
                                    </Button>
                                </div>
                            </>
                            :
                            <Grid item xs={12}>
                                <Typography variant={isLargeScreen ? 'body1' : 'body2'}>
                                    <FormattedMessage
                                        id="common.scrapping.stockNotFound"
                                        defaultMessage="Aucun lot ne possède ce produit"
                                        description="No batch found"
                                    />
                                </Typography>
                            </Grid>}
					</>}
                </PageFormContainer>
            )}
        />
    )
}

export default compose<any>(injectToolbarData(() => ({
    title: <FormattedMessage
        id="menu.dispatchCenter.dechet"
        defaultMessage="Nouvelle mise en déchet"
        description="Page title"
    />
})), injectSnackbarActions)(Scrapping)
