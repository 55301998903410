import { fetchFactory } from 'isotope-client'
import { DispatchEntryFormModel, DispatchPreparationFormModel, RetourStockModel } from './dispatchModels'
import { MultipleReplenishmentStatusUpdateModel, ReplenishmentStatusUpdateModel } from '../../common/replenishment/ReplenishmentModel'
import { ProcurementListRequestModel } from '../../common/procurement/services/procurementModels'
import { DispatchPreparationMultipleFormModel } from '../../common/replenishment/components/DialogReplenishmentPrepare'

export const postEntryStock = (values: DispatchEntryFormModel) => fetchFactory(`/dispatch/entree-stock`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getStockByProduit = (idProduit: string) => fetchFactory(`/dispatch/preparation?idProduit=${idProduit}`)

export const postPreparationStock = (values: DispatchPreparationFormModel) => fetchFactory('/dispatch/preparation', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const postPreparationMultipleStock = (values: DispatchPreparationMultipleFormModel) => fetchFactory('/dispatch/preparation/multiple', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getBlByID = (idBl: string) => fetchFactory(`/dispatch/preparation/${idBl}`)

export const updateReplenishmentStatus = (values: ReplenishmentStatusUpdateModel) => fetchFactory('/vaccination/besoin', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const updateMultipleReplenishmentStatus = (values: MultipleReplenishmentStatusUpdateModel) => fetchFactory('/vaccination/besoin/multiple', {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const cancelPreparation = (blID: string) => fetchFactory(`/dispatch/preparation/${blID}`, {
	method: 'PUT'
})

export const getProcurement = (values: ProcurementListRequestModel) => fetchFactory(
	`/vaccination/approvisionnement/unique?idCentre=${values.idCentre}&idProduit=${values.idProduit}&debutSemaine=${values.debutSemaine}`
)

export const getBlLast15Days = (idProduit: string, idVaccination: string) => fetchFactory(
	`/dispatch/preparation/retour?idProduit=${idProduit}&idVaccination=${idVaccination}`
)

export const getStockLine = (idLigneBl: string, idCentre: string) => fetchFactory(
	`/vaccination/stock/ligne?idLigneBl=${idLigneBl}&idCentre=${idCentre}`
)

export const postRetourStock = (values: RetourStockModel) => fetchFactory(`/dispatch/retour-stock`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getStockPrint = (idStock: string) => fetchFactory(`/dispatch/stock/${idStock}`)

export const getRetourPrint = (idRetour: string) => fetchFactory(`/dispatch/retour/${idRetour}`)
