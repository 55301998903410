import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        itemsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        }
    }),
)

interface AddToListProps {
    label: string | React.ReactElement,
    items: any[],
    renderItem: (item: any, index: number, array: any[]) => any,
    onAdd: () => void
}

const AddToList: React.FC<AddToListProps> = (
    {
        label,
        items,
        renderItem,
        onAdd
    }
) => {
    const classes = useStyles()

    return (
        <>
            <Grid container item direction="row" xs={12} alignItems="center">
                <Grid item>{label}</Grid>
                <Grid item>
                    <IconButton onClick={onAdd}>
                        <AddIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <div className={classes.itemsContainer}>
                {items.map(renderItem)}
            </div>
        </>
    )
}

export default AddToList
