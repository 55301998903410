import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { OnChange } from 'react-final-form-listeners'
import { FormattedMessage } from 'react-intl'
import { FormSelect } from 'isotope-client'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { SCREEN_SIZE } from '../../../../../utils/constants'
import ProductsTable from '../../../../../components/layout/productsTable/ProductsTable'
import { BlItem, BlLineItem } from '../services/enterStockModels'
import { getReferentBlList } from '../services/enterStockApi'

interface StockFormWithBlProps {
	change: (name: string, value?: any) => void
	selectedBl?: string
}

const StockFormWithBl: React.FC<StockFormWithBlProps> = ({
	                                                         change,
	                                                         selectedBl
                                                         }) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const [blList, setBlList] = useState<BlItem[]>([])
	const [showSecondPart, setShowSecondPart] = useState<boolean>(false)

	useEffect(() => {
		getList()
	}, [])

	const getList = () => {
		getReferentBlList()
			.then((fetchedBlList: BlItem[]) => {
				setBlList(fetchedBlList)
				setShowSecondPart(false)
			})
	}

	const resetSecondPartFields = () => {
		setShowSecondPart(true)
		change('compliantReception', true)
		change('comment', undefined)
	}

	const convertProductToTable = (blLines: BlLineItem[]) => {
		return blLines.map((line: BlLineItem) => ({
			nom: line.produit.nomCommercial,
			unit: line.produit.typeConditionnement,
			lot: line.stock && line.stock.lot,
			reference: line.refExterne,
			peremption: line.stock && line.stock.peremption,
			peremptionType: line.produit.peremptionType,
			quantite: line.quantite,
			sortie: line.dateSortie,
			isRetour: line.stock && line.stock.retourProduit
		}))
	}

	const selectedBL = blList.find((bl: BlItem) => bl.id === selectedBl)

	return (
		<>
			<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
				<FormattedMessage
					id="common.enterStock.formSection.referent.bl"
					defaultMessage="Sélectionner le numéro de bon de livraison"
					description="Form section title"
				/>
			</Typography>
			{(blList.length > 0) ?
				<>
					<Grid item xs={12}>
						<Grid item xs={12} md={4}>
							<Field
								name="referentBlId"
								component={FormSelect}
								label={
									<FormattedMessage
										id="common.enterStock.form.referentBl"
										defaultMessage="Numéro de bon de livraison"
										description="Input for delivery slip"
									/>
								}
								required
							>
								{blList.map((bl: BlItem) => (
									<MenuItem key={bl.id} value={bl.id}>{bl.numeroBl}</MenuItem>
								))}
							</Field>
						</Grid>
					</Grid>
					<OnChange name="referentBlId">
						{() => {
							if (selectedBL) {
								resetSecondPartFields()
							}
						}}
					</OnChange>

					{showSecondPart &&
						<>
							<ProductsTable
								title="common.enterStock.formSection.referent.product"
								frostedMode={!selectedBL?.decongele}
								produits={convertProductToTable(selectedBL?.lignes || [])}
							/>
							<Grid item xs={12}>
								<Typography variant="caption">
									<FormattedMessage
										id="common.enterStock.warningIncoherentQuantity"
										defaultMessage="Si la quantité reçue diffère de la quantité affichée, veuillez procéder à une mise en déchet"
										description="Warning message"
									/>
								</Typography>
							</Grid>
						</>}
				</> :
				<Grid item xs={12}>
					<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
						<FormattedMessage
							id="common.enterStock.blNotFound"
							defaultMessage="Aucun bon de livraison n'a été trouvé"
							description="No bl found"
						/>
					</Typography>
				</Grid>
			}
		</>
	)
}

export default compose(injectSnackbarActions)(StockFormWithBl)
