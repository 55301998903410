import React, { ReactElement } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../utils/constants'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		circle: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: colors.background,
			width: 150,
			height: 150,
			borderRadius: '50%'
		}
	})
)

interface MenuTileIllustrationProps {
	icon: ReactElement
}

const MenuTileIllustration: React.FC<MenuTileIllustrationProps> = ({icon}) => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div className={classes.circle}>
				{icon}
			</div>
		</div>
	)
}

export default MenuTileIllustration
