import React from 'react'
import { Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            marginBottom: 2
        }
    }),
)

interface FieldFileProps {
    name?: string
    label?: string | React.ReactElement
    required?: boolean
    accept?: string
}

const FieldFile: React.FC<FieldFileProps> = (
    {
        name = "file",
        label,
        required = false,
        accept = ""
    }
) => {
    const classes = useStyles()

    return (
        <Grid item xs={5}>
            <Field name={name}>
                {({ input: { value, onChange, ...input } }) => (
                    <>
                        {label &&
						<label htmlFor={name}>
							<Typography className={classes.label}>{label}</Typography>
						</label>
                        }
                        <input
                            {...input}
                            type="file"
                            onChange={({ target }) => onChange(target.files)}
                            required={required}
                            accept={accept}
                        />
                    </>
                )}
            </Field>
        </Grid>
    )
}

export default FieldFile
