import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '../../../../components/layout/dialog/Dialog'
import DisplayLineData from '../../../../components/layout/DisplayLineData'
import { DAYS, QTY_MODE } from '../../../../utils/constants'
import Button from '../../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../../components/layout/errors/ErrorMessageRequired'
import ErrorPositiveValue from '../../../../components/layout/errors/ErrorPositiveValue'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'

const useStyles = makeStyles(() =>
	createStyles({
		dayQuantity: {
			marginTop: 16
		},
		totalLine: {
			marginTop: 24,
			marginBottom: 12
		},
		sectionTitle: {
			fontWeight: 500,
			marginBottom: 16
		},
		input: {
			marginTop: 15
		}
	})
)

interface FormValues {
	nbSemaines: number
}


export interface DataDialogDuplicateProcurement {
	id: string,
	centerName: string,
	productName: string,
	conditioningType: string,
	modeQte: string,
	debutSemaine: string,
	lundi: number,
	lundi1: number,
	lundi2: number,
	mardi: number,
	mardi1: number,
	mardi2: number,
	mercredi: number,
	mercredi1: number,
	mercredi2: number,
	jeudi: number,
	jeudi1: number,
	jeudi2: number,
	vendredi: number,
	vendredi1: number,
	vendredi2: number,
	samedi: number,
	samedi1: number,
	samedi2: number,
	dimanche: number
	dimanche1: number
	dimanche2: number
}

interface DialogDuplicateProcurementProps {
	isOpen: boolean
	handleClose: () => any
	data: DataDialogDuplicateProcurement | undefined,
	onSubmit: (value: any) => any
}

const DialogDuplicateProcurement: React.FC<DialogDuplicateProcurementProps> = (
	{
		isOpen,
		handleClose,
		data,
		onSubmit
	}) => {
	const classes = useStyles()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)

	if (!data) {
		return null
	}

	const {
		centerName,
		productName,
		conditioningType,
		modeQte,
		debutSemaine,
		lundi,
		mardi,
		mercredi,
		jeudi,
		vendredi,
		samedi,
		dimanche
	} = data
	const total = lundi + mardi + mercredi + jeudi + vendredi + samedi + dimanche

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (!formValues.nbSemaines) {
			errors.nbSemaines = <ErrorMessageRequired />
		} else if (Number(formValues.nbSemaines) <= 0) {
			errors.nbSemaines = <ErrorPositiveValue />
		} else if (Number(formValues.nbSemaines) > 5) {
			errors.nbSemaines = <FormattedMessage
				id="procurement.duplicate.weeksMax"
				defaultMessage="Vous ne pouvez pas duppliquer au delà de 5 semaines"
				description="Error weeks number"
			/>
		}

		return errors
	}

	const handleOnSubmit = (values: FormValues) => {
		return onSubmit({
			id: data.id,
			nbSemaines: values.nbSemaines
		})
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={handleOnSubmit}
			render={({ values, handleSubmit }) => (
				<form id="formDialogDuplicateProcurement" onSubmit={handleSubmit}>
					<Dialog
						title={
							<FormattedMessage
								id="procurement.duplicate.title"
								defaultMessage="Duplication d'une allocation"
								description="Dialog title"
							/>
						}
						isOpen={isOpen}
						handleClose={handleClose}
						buttons={[
							<Button onClick={handleClose} color="primary" key={0} variant="outlined">
								<FormattedMessage
									id="button.cancel"
									defaultMessage="Annuler"
									description="Button label"
								/>
							</Button>,
							<Button type="submit" autoFocus color="primary" key={1} variant="contained" form="formDialogDuplicateProcurement">
								<FormattedMessage
									id="procurement.duplicate.btn"
									defaultMessage="Dupliquer"
									description="Button label"
								/>
							</Button>
						]}
					>
						<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
							<FormattedMessage
								id="procurement.duplicate.infoSection"
								defaultMessage="Informations d'allocation"
								description="Dialog subtitle"
							/>
						</Typography>
						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.procurement.center"
								defaultMessage={`Centre : ${centerName}`}
								description="Dialog content"
								values={{ centerName }}
							/>
						</Typography>
						<Typography gutterBottom>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.procurement.product"
								defaultMessage={`Produit : ${productName}`}
								description="Dialog content"
								values={{ productName, conditioningType }}
							/>
						</Typography>

						{/* DAYS QUANTITY */}
						<Grid container spacing={2}>
							{Object.values(DAYS).map((day, i) => {
									const date = moment(debutSemaine).add(i, 'days')

									return (
										<Grid item xs={3} className={classes.dayQuantity} key={day}>
											<DisplayLineData
												label={
													<FormattedMessage
														id={`dispatch.replenishmentList.dialog.procurement.${day.toLowerCase()}`}
														defaultMessage={`${day[0].toUpperCase()}${day.slice(1).toLowerCase()}`}
														description="Dialog content"
														values={{ date: displayDate(date, DATE_FORMAT.SHORT_DATE, isDateFormatEn) }}
													/>}
												// @ts-ignore
												data={modeQte === QTY_MODE.CONDITIONNEMENT ? data[day.toLowerCase()] : `D1 : ${data[day.toLowerCase() + 1] ? data[day.toLowerCase() + 1] : 0} - D2 : ${data[day.toLowerCase() + 2] ? data[day.toLowerCase() + 2] : 0}`
												}
											/>
										</Grid>)
								}
							)}
						</Grid>
						<Typography gutterBottom className={classes.totalLine}>
							<FormattedMessage
								id="dispatch.replenishmentList.dialog.procurement.total"
								defaultMessage={`Soit ${total} ${conditioningType}(s) au total`}
								description="Dialog content"
								values={{ quantity: total, conditioningType }}
							/>
						</Typography>
						<Typography gutterBottom variant="h5" className={classes.sectionTitle}>
							<FormattedMessage
								id="procurement.duplicate.weeksSection"
								defaultMessage="Duplication sur les semaines suivantes"
								description="Dialog subtitle"
							/>
						</Typography>
						<Typography gutterBottom>
							<FormattedMessage
								id="procurement.duplicate.weeksInfo"
								defaultMessage="Vous souhaitez dupliquer l'allocation sur les semaines suivantes. Sur combien de semaines souhaitez-vous dupliquer ?"
								description="Dialog weeks info"
							/>
						</Typography>
						<Grid xs={4} className={classes.input}>
							<Field
								name="nbSemaines"
								component={FormInput}
								label={
									<FormattedMessage
										id="procurement.nbWeeks"
										defaultMessage="Nombre de semaines"
										description="label input"
									/>
								}
								type="number"
								inputProps={{ min: 0 }}
								required
							/>
						</Grid>

					</Dialog>
				</form>
			)}
		/>
	)
}

export default DialogDuplicateProcurement
