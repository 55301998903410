import React, { useMemo } from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { FormInput } from 'isotope-client'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/layout/buttons/Button'
import Dialog from '../../../../../components/layout/dialog/Dialog'
import { isDateInputTypeSupported } from '../../../../../utils/form/inputTypes'
import { dateAndTimeDecorator } from '../../../../../utils/form/decorators'
import ErrorMessageRequired from '../../../../../components/layout/errors/ErrorMessageRequired'
import Grid from '@material-ui/core/Grid'
import FieldDatetime from '../../../../../components/fields/FieldDatetime'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../../../utils/dateUtils'

const useStyles = makeStyles(() =>
	createStyles({
		inputContainer: {
			marginTop: 32
		}
	})
)

interface DialogReplenishmentDepartureProps {
	isOpen: boolean
	loading: boolean
	handleClose: () => any
	onSubmit: (value: any) => any
}

const DialogReplenishmentDeparture: React.FC<DialogReplenishmentDepartureProps> = (
	{
		isOpen,
		loading,
		handleClose,
		onSubmit
	}
) => {
	const classes = useStyles()
	const isDatetimeSupported = isDateInputTypeSupported('datetime-local')
	const formDecorators = useMemo(() => isDatetimeSupported
			? []
			:
			[dateAndTimeDecorator('departureDate', 'departureTime', 'departureDatetime')]
		, [isDatetimeSupported])

	const onValidate = (formValues: any) => {
		const errors: any = {}

		if (isDatetimeSupported) {
			if (!formValues.departureDatetime) {
				errors.departureDatetime = <ErrorMessageRequired />
			} else if (moment(formValues.departureDatetime)
				.isBefore(moment())) {
				// should be older than now
				errors.departureDatetime = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
			}
		} else {
			if (!formValues.departureDate) {
				errors.departureDate = <ErrorMessageRequired />
			}
			if (!formValues.departureTime) {
				errors.departureTime = <ErrorMessageRequired />
			}
			if (formValues.departureDate && formValues.departureTime && formValues.departureDatetime &&
				moment(formValues.departureDatetime).isBefore(moment())) {
				// should be older than now
				errors.departureDate = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
				errors.departureTime = <FormattedMessage
					id="global.error.validation.datePriorToNow"
					defaultMessage="La date ne peut pas être antérieure à maintenant"
					description="Error message of date, prohibit past"
				/>
			}
		}

		return errors
	}

	return (
		<Form
			validate={onValidate}
			onSubmit={onSubmit}
			// @ts-ignore
			decorators={formDecorators}
			initialValues={{
				departureDatetime: formatDateInForm(moment().local(), DATE_FORMAT_IN_FORM.DATETIME_TIMEZONE)
			}}
			render={({ values }) => (
				<Dialog
					title={
						<FormattedMessage
							id="dispatch.departure.dialog.title"
							defaultMessage="Départ marchandises"
							description="Dialog title"
						/>
					}
					isOpen={isOpen}
					handleClose={handleClose}
					buttons={[
						<Button onClick={handleClose} color="primary" key={0} variant="outlined">
							<FormattedMessage
								id="button.cancel"
								defaultMessage="Annuler"
								description="Button label"
							/>
						</Button>,
						<Button autoFocus color="primary" key={1} variant="contained" onClick={() => onSubmit(values)} isLoading={loading}>
							<FormattedMessage
								id="button.validate"
								defaultMessage="Valider"
								description="Button label"
							/>
						</Button>
					]}
				>
					<Typography gutterBottom>
						<FormattedMessage
							id="dispatch.departure.dialog.disclaimer"
							defaultMessage="Via le départ marchandises, vous attestez de l'envoi des marchandises à l'heure saisie."
							description="Dialog disclaimer"
						/>
					</Typography>
					<Grid container item xs={12} spacing={2} className={classes.inputContainer}>
						<FieldDatetime
							datetimeName="departureDatetime"
							dateName="departureDate"
							timeName="departureTime"
							datetimeLabel={
								<FormattedMessage
									id="dispatch.departure.dialog.departureDatetime"
									defaultMessage="Date de départ (jj/mm/aaaa hh:mm)"
									description="input label"
								/>
							}
							dateLabel={
								<FormattedMessage
									id="dispatch.departure.dialog.departureDate"
									defaultMessage="Date de départ (jj/mm/aaaa)"
									description="input label"
								/>
							}
							timeLabel={
								<FormattedMessage
									id="dispatch.departure.dialog.departureTime"
									defaultMessage="Heure de départ (hh:mm)"
									description="input label"
								/>
							}
						/>
						<Grid item xs={12}>
							<Field
								name="comment"
								component={FormInput}
								label={
									<FormattedMessage
										id="dispatch.processing.dialog.comment"
										defaultMessage="Commentaire"
										description="Comment label input"
									/>
								}
							/>
						</Grid>
					</Grid>
				</Dialog>
			)}
		/>
	)
}

export default DialogReplenishmentDeparture
