import React, { useState } from 'react'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            marginTop: 32,
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    }),
)

interface CollapseWithTitleProps {
    title: string | React.ReactElement
}

const CollapseWithTitle: React.FC<CollapseWithTitleProps> = (
    {
        title,
        children
    }
) => {
    const classes = useStyles()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    return (
        <>
            <div className={classes.titleContainer}>
                <Typography variant="h3" style={{ margin: 0 }}>
                    {title}
                </Typography>
                <IconButton
                    className={classNames(classes.expand, {
                        [classes.expandOpen]: isExpanded,
                    })}
                    onClick={() => setIsExpanded(state => !state)}
                    aria-label="show more"
                >
                    <ExpandMore />
                </IconButton>
            </div>
            <Collapse in={isExpanded} timeout="auto">
                {children}
            </Collapse>
        </>
    )
}

export default CollapseWithTitle
