import React from 'react'
import classnames from 'classnames'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormattedMessage } from 'react-intl'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { CENTER_TYPE } from '../../utils/constants'
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import RoomIcon from '@material-ui/icons/Room'
import DomainIcon from '@material-ui/icons/Domain'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textFieldInput: {
			marginTop: 15,
			height: 35,
			backgroundColor: theme.palette.common.white,
			fontSize: theme.typography.body1.fontSize,
			padding: '0 !important',
			border: '1px solid',
			borderColor: '#919191',
			borderRadius: 0
		},
		inputIcon: {
			padding: '2px !important'
		},
		inputField: {
			padding: '0px !important',
			paddingLeft: '10px !important'
		}
	})
)

interface AutocompleteOption {
	label: any,
	type: string,
	value: string
}

interface AutocompleteCenterProps {
	options: AutocompleteOption[],
	onChange: (item: any) => void,
	defaultValue?: AutocompleteOption,
	disabled?: boolean,
	selectedCenter: string
}

const AutocompleteCenter: React.FC<AutocompleteCenterProps> = (
	{
		options,
		onChange,
		disabled = false,
		selectedCenter
	}
) => {
	const classes = useStyles()
	const selectedOption = options.find(opt => opt.value === selectedCenter)

	const getIcon = (type: string) => {
		switch (type) {
			case CENTER_TYPE.REFERENT:
				return <DomainIcon />
			case CENTER_TYPE.DISPATCH:
				return <AccountBalanceIcon />
			case CENTER_TYPE.VACCINATION:
				return <LocalPharmacyIcon />
			default:
				return <RoomIcon />
		}
	}

	return <Autocomplete
		options={options}
		disabled={disabled}
		getOptionLabel={(option: AutocompleteOption) => option.label}
		{...(onChange ? { onChange: (event, value) => onChange(value ? value.value : null) } : {})}
		getOptionSelected={(option, value) => option.value === value.value}
		defaultValue={selectedOption}
		noOptionsText={<FormattedMessage id="global.noOptions" />}
		classes={{
			inputRoot: classnames(classes.textFieldInput)
		}}
		renderInput={params => {
			return (
				<Grid container spacing={1} alignItems="flex-end">
					{!!selectedOption && <Grid item xs={1} className={classes.inputIcon}>
						{getIcon(selectedOption.type)}
					</Grid>}
					<Grid item xs={11} className={classes.inputField}>
						<TextField
							{...params}
							value={!!selectedOption ? selectedOption.label : ''}
							onBlur={() => {
							}}
							variant="filled"
						/>
					</Grid>
				</Grid>

			)
		}}
		renderOption={(option: AutocompleteOption) => (<Grid container alignItems="center" component="span">
			<Grid item xs={2} component="span">
				{getIcon(option.type)}
			</Grid>
			<Grid item xs={10} component="span"><Typography variant="caption">{option.label}</Typography></Grid>
		</Grid>)}
	/>
}

export default AutocompleteCenter
